var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/popups/SearchResultPopup/SearchResultNewsItem/SearchResultNewsItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/popups/SearchResultPopup/SearchResultNewsItem/SearchResultNewsItem.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n\n<div class=\"SearchResultNewsItem\">\n    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"url"), env.opts.autoescape);
output += "\"\n       data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"url"), env.opts.autoescape);
output += "\"\n       data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"api_url"), env.opts.autoescape);
output += "\"\n       class=\"SearchResultNewsItem-link\">\n        <span class=\"SearchResultNewsItem-month s-text-tiny c-dark\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"date")),"month"), env.opts.autoescape);
output += "<span>,&nbsp;</span></span>\n        <span class=\"SearchResultNewsItem-year s-text-tiny c-dark\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"date")),"year"), env.opts.autoescape);
output += "</span>\n        <span class=\"SearchResultNewsItem-day\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"date")),"day"), env.opts.autoescape);
output += "</span>\n        <span class=\"SearchResultNewsItem-title s-title-normal c-dark\" data-toggle=\"ellipsis\"\n              data-type=\"lines\" data-count=\"3\" data-lines=\"3\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
output += "</span>\n    </a>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/popups/SearchResultPopup/SearchResultNewsItem/SearchResultNewsItem.html"] , dependencies)