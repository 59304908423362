'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./IndexMission.less');

const Selectors = {
    indexMission: '.IndexMission',
    cellP: '.IndexMission-cell p',
    cellPSpan: '.IndexMission-cell p span',
    cellLink: '.IndexMission-cell a'
};

const BindFunctions = [
    'onResize',
    '_initIntersectionObserver',
    '_initCheckVisible',
    '_attachEvents'
];

const CssClasses = {
    textInitial: 'IndexMission-cell-text--intial',
    linkInitial: 'IndexMission-cell-link--intial',
    cellLine: 'IndexMission-cell-line'
};

module.exports = Backbone.View.extend({

    el: Selectors.indexMission,

    events: {},

    initialize: function (options = {}) {
        this.options = options;

        _.bindAll(this, BindFunctions);

        this.onResize();

        if (Constants.intersectionObserver in window) {
            this._initIntersectionObserver();
        } else {
            this._initCheckVisible();
        }
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
    },

    _initCheckVisible: function () {
        const $text = this.$(Selectors.cellP);
        const self = this;
        const checkVisible = _.throttle(function () {
            if ($text.offset().top < ($(window)
                .height() + $(window)
                .scrollTop())) {
                self.showText();
                $(window)
                    .off(`${ViewportEvents.resize} ${ViewportEvents.scroll} ${ViewportEvents.orientationChange}`
                        , checkVisible);
            }
        }, 100);

        $(window)
            .on(`${ViewportEvents.resize} ${ViewportEvents.scroll} ${ViewportEvents.orientationChange}`
                , checkVisible);

        checkVisible();
    },

    _initIntersectionObserver: function () {
        const $text = this.$(Selectors.cellP);
        const self = this;
        const imageObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const item = entry.target;
                    self.showText();
                    imageObserver.unobserve(item);
                }
            });
        });

        imageObserver.observe($text[0]);
    },

    showText: function () {
        setTimeout(function () {
            this.$(Selectors.cellP)
                .removeClass(CssClasses.textInitial);

            const delay = (this.$(Selectors.cellPSpan).length + 1) * 70;
            setTimeout(function () {
                this.$(Selectors.cellLink)
                    .removeClass(CssClasses.linkInitial);
            }.bind(this), delay);
        }.bind(this), 300);
    },


    onResize: function () {
        const $text = this.$(Selectors.cellP);

        app.utils.splitMultilineToSeparateSpans({
            $container: $text,
            className: CssClasses.cellLine,
            text: $text.attr('data-text') || $text.text()
        });
    }

});
