'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./RowFilter.less');

const Selectors = {
    el: '.RowFilter',
    innerWrapper: '.RowFilter-inner',
    item: '.RowFilter-item'
};

const BindFunctions = [
    '_initVariables',
    'selectValue',
    'setActiveItem',
    '_getDefaultActiveElement'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.selectValue
        };
    },

    /**
     *
     * @param options = {
     *     preFiltered: number - number of active element on this component init,
     *     selectFirst: boolean - if the first element preselected by default
     *     callback: function - will be triggered when filter change. Takes item id as parameter
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        const activeItemId = this._getDefaultActiveElement();
        this.setActiveItem(activeItemId);
    },

    _getDefaultActiveElement: function () {
        if (this.options.preFiltered) {
            return this.options.preFiltered;
        }

        if (!this.options.selectFirst) {
            return Constants.filter.noFilterValue;
        }

        const $firstItem = this.$items.first();

        return $firstItem.data('id');
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.callback = options.callback;
        this.$items = this.$(Selectors.item);
        this.selectFirst = !!options.selectFirst;
    },

    selectValue: function (event) {
        const $item = $(event.target)
            .closest(Selectors.item);
        const id = $item.data('id');

        this.setActiveItem(id);
    },

    /**
     *
     * @param id - id of new active item
     */
    setActiveItem: function (id) {
        this.$items.removeClass(Constants.cssClasses.active);

        const $newActiveItem = this.$(`${Selectors.item}[data-id="${id}"]`);
        $newActiveItem.addClass(Constants.cssClasses.active);
        const apiUrl = $newActiveItem.data('api-url');
        this.callback && this.callback(id, apiUrl);
    }
});
