'use strict';

const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const InputField = require('components/Common/InputField/InputField');
const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactServices';

require('./ContactServices.less');

const Selectors = {
    filterRegionItemSelect: '.ContactServices-filterRegionItemSelect',
    filterRegionItemSelectText: '.ContactServices-filterRegionItemSelectText',
    filterRegionItemSelectTextParagraph: '.ContactServices-filterRegionItemSelectText p',
    filterRegionItemSelectMenu: '.ContactServices-filterRegionItemSelectMenu',
    filterRegionItemSelectMenuVal: '.ContactServices-filterRegionItemSelectMenuVal',
    filterRegionItemSelectMenuValFirstChild: '.ContactServices-filterRegionItemSelectMenuVal:first-child',
    filterRegionItemSelectMenuValActive:
        `.ContactServices-filterRegionItemSelectMenuVal--${Constants.cssClasses.active}`,
    langContact: '.ContactServices-langContact',
    submit: '.ContactServices-submit',
    success: '.ContactServices-success',
    back: '.ContactServices-back',
    form: '.ContactServices-fields'
};

const BindFunctions = [
    '_initVariables',
    '_attachEvents',
    '_initChildComponents',
    'submit',
    'clickOutside',
    'onInputChange',
    'onDropdownChange',
    'toggleSubmitButton',
    'toggleResultMessage'
];

const DefaultView = createContactComponent({
    selectorComponent: SelectorComponent,
    bindFunctions: BindFunctions,
    selectors: Selectors
});

const InputModifier = 'contactServicesInput';
const DropdownModifier = 'contactServicesDropdown';

module.exports = DefaultView.extend({
    events: function () {
        return {
            ...DefaultView.prototype.events.call(this),
            [`${ViewportEvents.click} ${Selectors.filterRegionItemSelectMenuVal}`]: this.changeRegionFilter,
            [`${ViewportEvents.click} ${Selectors.submit}`]: this.submit,
            [`${ViewportEvents.click} ${Selectors.back}`]: this.toggleResultMessage
        };
    },

    initialize: function (options = {}) {
        DefaultView.prototype.initialize.call(this, options);

        this._initChildComponents();
        this._initVariables(options);
        this._attachEvents();
    },


    _attachEvents: function () {
        window.app.els.$body
            .on(ViewportEvents.click, this.clickOutside);
    },

    _initChildComponents: function () {
        this.$fields = this.$('.InputField, .Dropdown');
        this.form = {};
        this.$fields.each((index, item) => {
            const $item = $(item);
            let model;

            if ($item.hasClass('InputField')) {
                const name = $item.find('input')
                    .attr('name');
                model = {
                    object: new InputField({
                        onModelChange: this.onInputChange,
                        modifier: InputModifier,
                        id: index,
                        name
                    }),
                    value: ''
                };
                this.form[name] = model;
            } else {
                const name = $item.data('name');
                model = {
                    object: new Dropdown({
                        id: index,
                        onChooseOption: this.onDropdownChange,
                        modifier: DropdownModifier,
                        hasPlaceholder: true
                    })
                };
                model.value = model.object.getDefaultValue();
                this.form[name] = model;
                this.dropdownName = name;
            }
        });
    },

    onInputChange: function (name, value) {
        this.form[name].value = value;
    },

    onDropdownChange: function (id, idItem) {
        this.form[this.dropdownName].value = idItem;
        this.form[this.dropdownName].object.validate();
    },

    _initVariables: function (options = {}) {
        this.options = options;
    },

    clickOutside: function (e) {
        const elDropDown = this.$(Constants.selectors.dropdownSelect);

        //check if the clicked area is dropDown or not
        if (elDropDown.has(e.target).length === 0) {
            this.$(elDropDown)
                .removeClass(Constants.cssClasses.active);
        }
    },

    toggleSubmitButton: function (isDisabled) {
        this.$(Selectors.submit)
            .toggleClass(Constants.cssClasses.disabled, isDisabled);
    },

    toggleResultMessage: function (isHidden = true) {
        this.$(Selectors.submit)
            .toggleClass(Constants.cssClasses.hide, !isHidden);
        this.$(Selectors.back)
            .toggleClass(Constants.cssClasses.hide, isHidden);
        this.$(Selectors.success)
            .toggleClass(Constants.cssClasses.hide, isHidden);
        this.$(Selectors.form)
            .toggleClass(Constants.cssClasses.hide, !isHidden);
    },

    submit: function () {
        let isValid = true;
        Object.values(this.form)
            .forEach((value) => {
                value.object.validate();
                if (value.object.isValid()) {
                    return;
                }

                isValid = false;
            });

        if (!isValid) {
            return;
        }

        const apiUrl = this.$el.data('api-url');

        const data = Object.entries(this.form)
            .reduce((result, [key, model]) => {
                result[key] = model.value;

                return result;
            }, {});

        this.toggleSubmitButton(true);
        $.ajax({
            type: 'POST',
            url: apiUrl,
            data: JSON.stringify(data),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: () => {
                this.toggleResultMessage(false);
            },
            failure: (errMsg) => {
                console.err(errMsg);
            },
            complete: () => {
                this.toggleSubmitButton(false);
            }
        });
    }
});
