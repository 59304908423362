'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

require('./ProductDetailsDescription.less');

const ProductDetailsSpecifications = require('components/ProductDetailsSpecifications/ProductDetailsSpecifications');
const ProductDetailsOverview = require('components/ProductDetailsOverview/ProductDetailsOverview');
const ProductDetailsDownloads = require('components/ProductDetailsDownloads/ProductDetailsDownloads');
const ProductDetailsInspiration = require('components/ProductDetailsInspiration/ProductDetailsInspiration');
const ProductDetailsPhotometry = require('components/ProductDetailsPhotometry/ProductDetailsPhotometry');
const TopPageButtons = require('components/TopPageButtons/TopPageButtons');

const Selectors = {
    el: '.ProductDetailsDescription',
    elPrefix: '.ProductDetailsDescription-',
    mobileAnchor: '.ProductDetailsDescription-mobileAnchor',
    headButton: '.ProductDetailsDescription-headButton'
};

const BindFunctions = [
    'openOverlay',
    'closeOverlay',
    '_initChildComponents',
    '_attachEvents'
];

const ChildViews = [
    {
        name: 'productDetailsSpecifications',
        Constructor: ProductDetailsSpecifications
    },
    {
        name: 'productDetailsOverview',
        Constructor: ProductDetailsOverview
    },
    {
        name: 'productDetailsDownloads',
        Constructor: ProductDetailsDownloads
    },
    {
        name: 'productDetailsInspiration',
        Constructor: ProductDetailsInspiration
    },
    {
        name: 'productDetailsPhotometry',
        Constructor: ProductDetailsPhotometry
    },
    {
        name: 'topPageButtons',
        Constructor: TopPageButtons
    }
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.mobileAnchor}`]: this.openOverlay
        };
    },

    initialize: function (options = {}) {
        this.options = options;

        _.bindAll(this, BindFunctions);

        this._initChildComponents();
        this._attachEvents();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose, this.closeOverlay);
        this.$el.find(`${Selectors.headButton}[data-name="contact"]`).on(ViewportEvents.click, this.openContactOverlay);
    },

    openOverlay: function (e) {
        this.id = $(e.target)
            .closest(Selectors.mobileAnchor)
            .data('id');
        this.$(Selectors.elPrefix + this.id)
            .addClass(Constants.cssClasses.active);
        window.app.els.$html
            .css('overflow', 'hidden');
        this.savedScroll = window.app.els.$window
            .scrollTop();
        app.vent.trigger(Events.popup.popupShown);
    },

    closeOverlay: function () {
        this.$(Selectors.elPrefix + this.id)
            .removeClass(Constants.cssClasses.active);
        window.app.els.$html
            .css('overflow', '');
        window.app.els.$window
            .scrollTop(this.savedScroll);
    },

    _initChildComponents: function () {
        if (!this.childViews) {
            this.childViews = {};
        }
        for (let i = 0; i < ChildViews.length; i++) {
            this.childViews[ChildViews[i].name] = new ChildViews[i].Constructor({page: this});
        }
    },

    openContactOverlay: function (event) {
        event.preventDefault();
        window.app.views.contacts.show();
    }
});
