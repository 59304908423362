'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');
const Events = require('constants/Events');

const CompanyLandingHead = require('components/CompanyLandingHead/CompanyLandingHead');
const History = require('components/Common/History/History');
const Cards = require('components/CompanyCards/CompanyCards');
const TextWithLink = require('components/Common/TextWithLink/TextWithLink');
const LatestFinancialReports = require('components/Common/LatestFinancialReports/LatestFinancialReports');
const GlobalPresence = require('components/CompanyGlobalPresence/CompanyGlobalPresence');
const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

require('./PageCompanyLanding.less');

const ChildViews = [
    {
        name: 'companyLandingHead',
        Constructor: CompanyLandingHead
    },
    {
        name: 'history',
        Constructor: History
    },
    {
        name: 'cards',
        Constructor: Cards
    },
    {
        name: 'textWithLink',
        Constructor: TextWithLink
    },
    {
        name: 'latestReports',
        Constructor: LatestFinancialReports
    },
    {
        name: 'globalPresence',
        Constructor: GlobalPresence
    }
];

const BindFunctions = [
    'openPopup'
];

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);

        PageLanding.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    },

    _attachEvents: function () {
        PageLanding.prototype._attachEvents.call(this);

        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    }
});
