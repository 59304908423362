'use strict';

const Backbone = require('backbone/backbone');
const GlobalSlider = require('components/GlobalSlider/GlobalSlider');
const ViewportEvents = require('constants/ViewportEvents');
const HistoryPopup = require('popups/HistoryPopup/HistoryPopup');

require('./CompanyHistoryItems.less');

const Selectors = {
    el: '.CompanyHistoryItems',
    item: '.CompanyHistoryItems-item',
    link: '.CompanyHistoryItems-itemUrl'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openHistoryPopup
        };
    },

    initialize: function (options = {}) {
        this.options = options;
        if (!app.isPhoneLayout()) {
            return;
        }

        this.globalSlider = new GlobalSlider({
            modifier: 'company-history-items',
            withoutArrows: true
        });
    },

    openHistoryPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.item);
        const apiUrl = $target.data('api-url');
        const url = $target.data('url');

        const popup = new HistoryPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
