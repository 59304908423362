'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');

const TopPageButtons = require('components/TopPageButtons/TopPageButtons');
const CommonBlock = require('components/Common/CommonBlock/CommonBlock');

require('./NewsStoryPopup.less');

const Selectors = {
    contentWrapper: '.NewsStoryPopup-content'
};

module.exports = BasePopup.extend({

    el: '.NewsStoryPopup',

    events: {},


    initialize: function (options = {}) {
        this.popupClass = '.NewsStoryPopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(TopPageButtons, {page: this});

        const blocksCount = this.$(Selectors.contentWrapper)
            .data('blocks-count');

        for (let i = 0; i < blocksCount; i++) {
            this.registerChildView(CommonBlock, {page: this, blockSelector: `.NewsStory-block--${i}`});
        }
    }
});
