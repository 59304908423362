var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/Common/DownloadCard/DownloadCard.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/Common/DownloadCard/DownloadCard.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n";
var macro_t_4 = runtime.makeMacro(
["title", "description", "linkText", "url", "backgroundUrl", "disabled", "zip", "webcast"], 
["animation", "runtime"], 
function (l_title, l_description, l_linkText, l_url, l_backgroundUrl, l_disabled, l_zip, l_webcast, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("title", l_title);
frame.set("description", l_description);
frame.set("linkText", l_linkText);
frame.set("url", l_url);
frame.set("backgroundUrl", l_backgroundUrl);
frame.set("disabled", l_disabled);
frame.set("zip", l_zip);
frame.set("webcast", l_webcast);
frame.set("animation", Object.prototype.hasOwnProperty.call(kwargs, "animation") ? kwargs["animation"] : true);frame.set("runtime", Object.prototype.hasOwnProperty.call(kwargs, "runtime") ? kwargs["runtime"] : false);var t_5 = "";t_5 += "\n    <div class=\"DownloadCard ";
if(l_disabled) {
t_5 += " disabled ";
;
}
t_5 += " ";
if(l_backgroundUrl) {
t_5 += " withBackground ";
;
}
t_5 += "\"\n            ";
if(runtime.contextOrFrameLookup(context, frame, "animation")) {
t_5 += " data-fade-in-on-scroll";
;
}
t_5 += ">\n        ";
if(l_url) {
t_5 += "\n            <a href=\"";
t_5 += runtime.suppressValue(l_url, env.opts.autoescape);
t_5 += "\" class=\"DownloadCard-backgroundLink\"></a>\n        ";
;
}
t_5 += "\n        <span class=\"DownloadCard-wrapper\">\n            ";
if(l_backgroundUrl) {
t_5 += "\n                <span class=\"DownloadCard-background\">\n                    <span class=\"DownloadCard-backgroundWrapper\">\n                        ";
if(runtime.contextOrFrameLookup(context, frame, "runtime")) {
t_5 += "\n                            <img src=\"";
t_5 += runtime.suppressValue(l_backgroundUrl, env.opts.autoescape);
t_5 += "\"/>\n                        ";
;
}
else {
t_5 += "\n                            ";
t_5 += runtime.suppressValue((lineno = 32, colno = 50, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [l_backgroundUrl,"jpg",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "\n                        ";
;
}
t_5 += "\n                    </span>\n                </span>\n            ";
;
}
t_5 += "\n            <span class=\"s-title-medium DownloadCard-title\">";
t_5 += runtime.suppressValue(l_title, env.opts.autoescape);
t_5 += "</span>\n            <span class=\"DownloadCard-description s-title-small\">";
t_5 += runtime.suppressValue(l_description, env.opts.autoescape);
t_5 += "</span>\n            <div class=\"DownloadCardDownloads\">\n              <a ";
if(l_url) {
t_5 += "href=\"";
t_5 += runtime.suppressValue(l_url, env.opts.autoescape);
t_5 += "\"";
;
}
t_5 += " class=\"DownloadCardDownloads-link s-text-tiny\">\n                  ";
t_5 += runtime.suppressValue((lineno = 41, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["product-download-icon-light",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
t_5 += "\n                  <span>";
t_5 += runtime.suppressValue(l_linkText, env.opts.autoescape);
t_5 += "</span>\n              </a>\n              ";
if(l_webcast) {
t_5 += "\n                <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_webcast),"url"), env.opts.autoescape);
t_5 += "\" target=\"_blank\"  class=\"DownloadCardDownloads-link s-text-tiny\">\n                  ";
t_5 += runtime.suppressValue((lineno = 46, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["product-download-icon-light",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
t_5 += "\n                  <span>";
t_5 += runtime.suppressValue(runtime.memberLookup((l_webcast),"linkText"), env.opts.autoescape);
t_5 += "</span>\n                </a>\n              ";
;
}
t_5 += "\n              ";
if(l_zip) {
t_5 += "\n                <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((l_zip),"url"), env.opts.autoescape);
t_5 += "\" target=\"_blank\"  class=\"DownloadCardDownloads-link s-text-tiny\">\n                  ";
t_5 += runtime.suppressValue((lineno = 52, colno = 43, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["product-download-icon-light",runtime.makeKeywordArgs({"caller": (function (){var macro_t_12 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_13 = "";;
frame = frame.pop();
return new runtime.SafeString(t_13);
});
return macro_t_12;})()})])), env.opts.autoescape);
t_5 += "\n                  <span>";
t_5 += runtime.suppressValue(runtime.memberLookup((l_zip),"linkText"), env.opts.autoescape);
t_5 += "</span>\n                </a>\n              ";
;
}
t_5 += "\n            </div>\n        </span>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("DownloadCard");
context.setVariable("DownloadCard", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/Common/DownloadCard/DownloadCard.html"] , dependencies)