'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('jquery-touchswipe');

require('./IndexApplications.less');

const Selectors = {
    indexApplications: '.IndexApplications',
    arrow: '.IndexApplications-arrow',
    card: '.IndexApplications-card',
    cards: '.IndexApplications-cards',
    arrowNext: '.IndexApplications-arrow[data-tp="next"]',
    arrowPrev: '.IndexApplications-arrow[data-tp="prev"]'
};

const BindFunctions = [
    'onResize',
    '_initVariables',
    '_attachEvents',
    '_initSwipe'
];

const CssClasses = {
    arrowHidden: 'IndexApplications-arrow--hidden'
};

module.exports = Backbone.View.extend({

    el: Selectors.indexApplications,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrow}`]: this.onArrowClick,
            [`${ViewportEvents.click} ${Selectors.card}`]: this.onCardClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);

        this.onResize();

        this._attachEvents();

        app.utils.blockScrollOnSwipe(this.$cards[0]);

        this._initSwipe();
    },

    _initSwipe: function () {
        this.$cards
            .swipe({
                allowPageScroll: 'auto',
                threshold: 50,
                fallbackToMouseEvents: true,
                excludedElements: 'button, input, select, textarea, .noSwipe',
                swipe: function (event, direction) {
                    (direction === Constants.swipeDirections.left) && this.shiftCards(+1);
                    (direction === Constants.swipeDirections.right) && this.shiftCards(-1);
                }.bind(this)
            });
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$cards = this.$(Selectors.cards);
        this.$card = this.$(Selectors.card);

        this.currentCardIndex = this.$cards
            .attr('data-pos') - 0;

        this.cardCount = this.$card.length;
    },

    onResize: function () {
        //to fix last card shift when switching from mobile layout to desktop
        this.shiftCards(0);
    },


    onCardClick: function (e) {
        const $card = $(e.currentTarget);
        const leftEdge = $card.offset().left;
        const rightEdge = leftEdge + $card.width();
        const elWidth = this.$el.width();

        if (leftEdge < 0) {
            e.preventDefault();
            this.shiftCards(-1);
        } else if (rightEdge > elWidth) {
            e.preventDefault();
            this.shiftCards(+1);
        }
    },


    onArrowClick: function (e) {
        const tp = $(e.currentTarget)
            .attr('data-tp');

        this.shiftCards(tp === 'next' ? +1 : -1);
    },


    shiftCards: function (direction) {
        const maxCards = window.app.isPhoneLayout() ? this.cardCount - 1 : this.cardCount - 4;
        this.currentCardIndex += direction;
        this.currentCardIndex = Math.max(Math.min(this.currentCardIndex, maxCards), 0);

        this.$cards
            .attr('data-pos', this.currentCardIndex);
        this.$(Selectors.arrowPrev)
            .toggleClass(CssClasses.arrowHidden, this.currentCardIndex === 0);

        this.$(Selectors.arrowNext)
            .toggleClass(CssClasses.arrowHidden, this.currentCardIndex === maxCards);
    }
});
