'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./ProductFamilyServices.less');

const Selectors = {
    link: '.ProductFamilyServices-link'
};

const BindFunctions = [
    'openPopup'
];

module.exports = Backbone.View.extend({

    el: '.ProductFamilyServices',

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openPopup
        };
    },

    initialize: function () {
        _.bindAll(this, BindFunctions);
    },

    openPopup: function (event) {
        event.preventDefault();

        const $target =  $(event.target).closest(Selectors.link);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.productFamily
            + Events.popup.servicesPopupPrefix + Events.popup.popupOpen, {apiUrl, url});
    }
});
