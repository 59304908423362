'use strict';

module.exports = {
    intersectionObserver: 'IntersectionObserver',
    keyCodes: {
        enter: 13,
        esc: 27
    },

    selectors: {
        dropdownSelect: '.Dropdown .Dropdown-select',
        dropdownModel: '.Dropdown-selectModel',
        dropdownLinkList: '.Dropdown .Dropdown-linkList',
        needFixOnPopupFilter: '.NeedFixOnPopupFilter',
        needFixOnPopup: '.NeedFixOnPopup',
        anchorLink: '.anchor-link'
    },

    cssClasses: {
        show: 'show',
        hide: 'hide',
        active: 'active',
        success: 'success',
        animation: 'animation',
        error: 'error',
        disabled: 'disabled',
        absolute: 'absolute',
        fixed: 'fixed',
        blurry: 'blurry',
        isBlur: 'isBlur',
        pageIndex: 'PageIndex',
        pageGlobalIndex: 'PageGlobalIndex',
        popupShow: 'PopupShow',
        overlayShow: 'OverlayShow',
        needFixOnPopup: 'NeedFixOnPopup',
        fixedOnPopup: 'FixedOnPopup',
        fixedOnFilter: 'FixedOnFilter',
        needFixOnPopupFilter: 'NeedFixOnPopupFilter',
        footerHidden: 'Footer--hidden',
        footerSlimHidden: 'FooterSlim--hidden',
        search: {
            searchIsFocus: 'Search--isFocus',
            searchWithContent: 'Search--withcontent',
            searchHide: 'Search--hide',
            searchShown: 'search-shown'
        },
        halfPagePopup: 'HalfPagePopup',
        halfPagePopupShown: 'HalfPagePopupShown',
        bodyHalfPagePopupShown: 'half-page-popup-shown',
        hovered: 'hovered'
    },

    cssProperties: {
        visibility: 'visibility',
        opacity: 'opacity',
        top: 'top',
        backdropFilter: 'backdrop-filter',
        filter: 'filter',
        backgroundColor: 'background-color',
        transform: 'transform',
        transition: 'transition',
        fill: 'fill',
        overflow: 'overflow',
        fontSize: 'font-size',
        display: 'display'
    },

    cssTransforms: {
        rotate: 'rotate'
    },

    cssValues: {
        hidden: 'hidden',
        visible: 'visible'
    },

    swipeDirections: {
        left: 'left',
        right: 'right'
    },

    scrollDirections: {
        up: 1,
        down: -1
    },

    translateDirections: {
        left: -1,
        right: 1
    },

    dataAttributes: {
        id: 'data-id',
        url: 'data-url',
        apiUrl: 'data-api-url',
        animation: 'data-animation'
    },

    queryStringParams: {
        filter: 'filter',
        category: 'category',
        offset: 'offset'
    },

    filter: {
        noFilterValue: 'all'
    },

    slickEvents: {
        beforeChange: 'beforeChange',
        afterChange: 'afterChange'
    },

    slickMethods: {
        goTo: 'slickGoTo',
        getSlick: 'getSlick',
        goPrev: 'slickPrev',
        goNext: 'slickNext',
        currentSlide: 'slickCurrentSlide'
    },

    slickCustomClasses: {
        previousSlide: 'slick-slide-prev',
        nextSlide: 'slick-slide-next',
        currentSlide: 'slick-slide-current'
    }
};
