'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

const NewsInsights = require('components/Common/NewsInsights/NewsInsights');

require('./IndexInsights.less');

const Selectors = {
    indexInsights: '.IndexInsights',
    search: '.IndexInsights-search',
    searchInput: '.IndexInsights-search-input',
    searchGo: '.IndexInsights-search-go',
    newsItemTitle: '.IndexInsights-news-item-title',
    inspirationLink: '.IndexInsights-inspiration-itemLink'
};

const BindFunctions = [
    'onResize',
    '_initVariables',
    '_attachEvents',
    'onCardClick',
    '_initChildComponents'
];

const CssClasses = {
    searchIsFocus: 'IndexInsights-search--isFocus',
    searchWithContent: 'IndexInsights-search--withcontent'
};

module.exports = Backbone.View.extend({

    el: Selectors.indexInsights,

    events: function () {
        return {
            [`${ViewportEvents.focus} ${Selectors.searchInput}`]: this.onFocus,
            [`${ViewportEvents.blur} ${Selectors.searchInput}`]: this.onBlur,
            [`${ViewportEvents.input} ${Selectors.searchInput}`]: this.onInput,
            [`${ViewportEvents.keyUp} ${Selectors.searchInput}`]: this.onKeyUp,
            [`${ViewportEvents.click} ${Selectors.searchGo}`]: this.goSearch,
            [`${ViewportEvents.click} ${Selectors.inspirationLink}`]: this.onCardClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initChildComponents();
        this._attachEvents();

        this.onResize();
    },

    _initChildComponents: function () {
        this.newsInsights = new NewsInsights();
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);

        window.app.vent.on(Events.common.fontsLoaded, this.onResize);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$search = this.$(Selectors.search);
        this.$searchInput = this.$(Selectors.searchInput);
        this.onResize = _.debounce(this.onResize, 400);
    },

    onResize: function () {
        this.$(Selectors.newsItemTitle)
            .each(function () {
                const $item = $(this);
                const lineHeight = parseInt($item.css('line-height'), 10);
                const height = $item.height();
                const linesCount = Math.round(height / lineHeight);
                $item.attr('data-lines', linesCount);
            });
    },

    onFocus: function () {
        this.$search.addClass(CssClasses.searchIsFocus);
    },


    onInput: function () {
        this.$search.toggleClass(CssClasses.searchWithContent, !!this.$searchInput.val());
    },


    onBlur: function () {
        this.$search.removeClass(CssClasses.searchIsFocus);
    },


    onKeyUp: function (e) {
        if (e.keyCode === Constants.keyCodes.enter) {
            this.goSearch();
        }
    },

    goSearch: function () {
        const searchString = this.$searchInput.val();
        const apiUrl = window.app.els.$search.data('api-url');
        app.vent.trigger(Events.search.showSearchResult, {searchString, apiUrl});
    },

    onCardClick: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.inspirationLink);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.index
            + Events.popup.inspirationPopupPrefix
            + Events.popup.popupOpen, {apiUrl, url});
    }

});
