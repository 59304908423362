'use strict';

const ViewportEvents = require('constants/ViewportEvents');
const BasePopup = require('popups/BasePopup/BasePopup');
const Constants = require('constants/Constants');
const Events = require('constants/Events');
const _ = require('underscore');

const LayoutsServicesHeader = require('components/LayoutsServicesHeader/LayoutsServicesHeader');
const Layouts111Sections = require('components/Layouts111Sections/Layouts111Sections');
const LayoutsFooter = require('components/LayoutsFooter/LayoutsFooter');

require('./PageServicesPopup.less');

const Selectors = {
    el: '.PageServicesPopup',
    tabWrapper: '.PageServicesPopup-tabWrapper',
    tabContent: '.PageServicesPopup-tabContent',
    tabsWrapper: '.PageServicesPopup-tabsWrapper',
    menuDropdown: '.PageServicesPopup-menuDropdown',
    menuDropdownSelect: '.PageServicesPopup-menuDropdownSelect',
    menuDropdownSelectText: '.PageServicesPopup-menuDropdownSelectText',
    menuDropdownMenuItem: '.PageServicesPopup-menuDropdownMenuItem',
    tabContentPrefix: '#PageServicesPopup-tabContent--',
    header: '.PageServicesPopup-header'
};

const CssClasses = {
    isOpenDropdown: 'isOpenDropdown'
};

const BindFunctions = [
    '_attachEvents',
    'setActiveTab',
    'clickOutSide'
];

module.exports = BasePopup.extend({

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.tabWrapper}`]: this.onTabClick,
            [`${ViewportEvents.click} ${Selectors.menuDropdownSelect}`]: this.toggleDropdown,
            [`${ViewportEvents.click} ${Selectors.menuDropdownMenuItem}`]: this.onDropdownItemClick
        };
    },


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.popupClass = Selectors.el;

        this._attachEvents();

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(LayoutsServicesHeader, {page: this});
        this.registerChildView(Layouts111Sections, {page: this});
        this.registerChildView(LayoutsFooter, {page: this});
    },

    _attachEvents: function () {
        window.app.els.$body
            .on(ViewportEvents.click, this.clickOutSide);
        app.vent.on(Events.productServicesTabChange, this.setActiveTab);
    },

    onTabClick: function (event) {
        const tabId = $(event.target)
            .closest(Selectors.tabWrapper)
            .data('id');

        this.setActiveTab(tabId);
    },

    setActiveTab: function (tabId) {
        const $tabWrapper = this.$(Selectors.tabWrapper);
        const $tabContent = this.$(Selectors.tabContent);
        const $activeTabWrapper = this.$(`${Selectors.tabWrapper}[data-id="${tabId}"]`);
        const $activeContent = this.$(`${Selectors.tabContent}[data-id="${tabId}"]`);
        $tabWrapper.removeClass(Constants.cssClasses.active);
        $tabContent.removeClass(Constants.cssClasses.active);

        $activeTabWrapper.addClass(Constants.cssClasses.active);
        $activeContent.addClass(Constants.cssClasses.active);

        const scrollTop = this.$(Selectors.header).height();

        window.app.data.currentPopup.scrollTop(scrollTop);

        if (!window.app.settings.isMobile) {
            return;
        }

        const offsetLeft = $activeTabWrapper
            .offset().left;
        const windowWidth = window.app.els.$window
            .innerWidth();
        const tabWrapperWidth = $activeTabWrapper
            .innerWidth();
        const shift = offsetLeft - windowWidth / 2 + tabWrapperWidth / 2;
        const currentScrollLeft = this.$(Selectors.tabsWrapper)
            .scrollLeft();

        this.$(Selectors.tabsWrapper)
            .animate({scrollLeft: (shift + currentScrollLeft)}, 200);
    },

    onDropdownItemClick: function (e) {
        const tabContent = this.$(Selectors.tabContent);
        for (let i = 0; i < tabContent.length; i++) {
            $(tabContent[i])
                .removeClass(Constants.cssClasses.active);
        }

        this.$(Selectors.menuDropdownSelectText)
            .text($(e.target)
                .text());

        const contentId = Selectors.tabContentPrefix + parseInt($(e.target)
            .attr('data-id'), 10);
        this.$(contentId)
            .addClass(Constants.cssClasses.active);
    },

    clickOutSide: function (e) {
        const elDropDown = this.$(Selectors.menuDropdown);

        if (elDropDown.has(e.target).length === 0) {
            this.closeDropdown();
        }
    },

    toggleDropdown: function () {
        this.$(Selectors.menuDropdown)
            .toggleClass(CssClasses.isOpenDropdown);
    },

    closeDropdown: function () {
        this.$(Selectors.menuDropdown)
            .removeClass(CssClasses.isOpenDropdown);
    }

});
