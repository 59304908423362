'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');

const Events = require('constants/Events');
const CareersLandingHead = require('components/CareersLandingHead/CareersLandingHead');
const CareersLandingLocations = require('components/CareersLandingLocations/CareersLandingLocations');
const CareersLandingAreas = require('components/CareersLandingAreas/CareersLandingAreas');
const CareersWhyZumtobel = require('components/CareersWhyZumtobel/CareersWhyZumtobel');
const CommonText = require('components/Common/CommonText/CommonText');
const CareersFooter = require('components/CareersFooter/CareersFooter');
const CareersEmployeeExperience = require('components/CareersEmployeeExperience/CareersEmployeeExperience');
const CareersFactoryPopup = require('popups/CareersFactoryPopup/CareersFactoryPopup');
const CareersJobPopup = require('popups/CareersJobPopup/CareersJobPopup');
const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');
const LandingItemsBlock = require('components/LandingItemsBlock/LandingItemsBlock');

require('./PageCareersLanding.less');

const Selectors = {
    footerQuote: '.PageCareersLanding-footer .CommonText',
    footer: '.PageCareersLanding-footer'
};

const ChildViews = [
    {
        name: 'head',
        Constructor: CareersLandingHead
    },
    {
        name: 'locations',
        Constructor: CareersLandingLocations
    },
    {
        name: 'areas',
        Constructor: CareersLandingAreas
    },
    {
        name: 'whyZumtobel',
        Constructor: CareersWhyZumtobel
    },
    {
        name: 'commonText',
        Constructor: CommonText
    },
    {
        name: 'footer',
        Constructor: CareersFooter
    },
    {
        name: 'employeeExperience',
        Constructor: CareersEmployeeExperience
    },
    {
        name: 'ways',
        Constructor: LandingItemsBlock
    }
];

const BindFunctions = [
    '_attachEvents',
    'openJobPopup',
    'openFactoryPopup',
    'openPopup'
];

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);

        PageLanding.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.careers
            + Events.popup.careersFactory + Events.popup.popupOpen, this.openFactoryPopup);

        app.vent.on(Events.eventPrefixes.careers
            + Events.popup.careersJob + Events.popup.popupOpen, this.openJobPopup);

        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);

        PageLanding.prototype._attachEvents.call(this);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);

        this.$lastBlockAbsoluteTriggerElement = this.$(Selectors.footer);
        this.scrollMagicTriggerHook = 0;
    },

    openFactoryPopup: function (data) {
        this.detachOnScroll();
        const popup = new CareersFactoryPopup({
            page: this,
            backUrl: '/careers.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    },

    openJobPopup: function (data) {
        this.detachOnScroll();
        const popup = new CareersJobPopup({
            page: this,
            backUrl: '/careers.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    }
});
