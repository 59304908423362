'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const ViewportEvents = require('constants/ViewportEvents');
const Cookies = require('js-cookie');

require('./RegionRibbon.less');

const Selectors = {
    el: '.RegionRibbon',
    close: '.RegionRibbon-close'
};

const BindFunctions = [
    'closeRibbon'
];

const RibbonDropdownModifier = 'region-ribbon';
const CookieAcceptValue = true;
const RegionRibbonCookieName = 'hide_region_ribbon';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.close}`]: this.closeRibbon
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.dropdown = new Dropdown({
            modifier: RibbonDropdownModifier,
            id: 'region'
        });

        const isRibbonHidden = Cookies.get(RegionRibbonCookieName);
        console.log(isRibbonHidden);
        if (isRibbonHidden) {
            return;
        }
        this.$el.show();
    },

    closeRibbon: function () {
        Cookies.set(RegionRibbonCookieName, CookieAcceptValue);
        this.$el.hide();
    }
});
