'use strict';

const Backbone = require('backbone');
const _ = require('underscore');

require('./UpdateInterests.less');
const Checkbox = require('components/Common/Checkbox/Checkbox');

const Selectors = {
    el: '.UpdateInterests',
    fields: '.UpdateInterests-field'
};

const BindFunctions = [
    '_initChildComponents',
    '_addToModel',
    'onCheckboxChange'
];

const Modifier = 'update-interests';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.page = options.page;
        this.model = {};
        this._initChildComponents();

        this.checkChanges();
    },

    _initChildComponents: function () {
        this.$(Selectors.fields)
            .each((index, field) => {
                const $field = $(field);
                const name = $field.data('name');
                const value = $field.data('checked');

                new Checkbox({
                    modifier: Modifier,
                    id: index,
                    value,
                    name,
                    onModelChange: this.onCheckboxChange
                });

                this._addToModel(name, value);
            });
    },

    _addToModel: function (name, value) {
        const checkboxValue = !!value;

        this.model[name] = {
            value: checkboxValue,
            defaultValue: checkboxValue
        };
    },

    onCheckboxChange: function (name, value) {
        this.model[name].value = value;
        this.checkChanges();
    },

    checkChanges: function () {
        let wasModelChanged = false;
        const modelValues = Object.values(this.model);
        modelValues.forEach((modelValue) => {
            if (!wasModelChanged) {
                wasModelChanged = modelValue.value !== modelValue.defaultValue;
            }
        });
        wasModelChanged && this.page.onChange();
    },

    getValues() {
        return Object.entries(this.model)
            .reduce((res, [key, value]) => {
                res[key] = value.value;

                return res;
            }, {});
    }
});
