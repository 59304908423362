'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const ViewportEvents = require('constants/ViewportEvents');

require('./SearchResultFaqItem.less');

const Template = require('./SearchResultFaqItem.html');
const FaqPopup = require('popups/FaqPopup/FaqPopup');

const Selectors = {
    el: '.SearchResultFaqItem',
    link: '.SearchResultFaqItem-contentLink'
};

const BindFunctions = [
    'render',
    'openFaqPopup',
    'attachElement'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openFaqPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    render: function (data = {}) {
        return Template.render({data});
    },

    attachElement: function () {
        this.setElement(Selectors.el);
    },

    openFaqPopup: function (event) {
        event.preventDefault();

        const popup = new FaqPopup({
            page: this,
            backUrl: '/faq.html'
        });

        const $target = $(event.target)
            .closest(Selectors.link);

        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        popup.openPopup(apiUrl, url);
    }
});
