'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');

require('./AboutFooter.less');

const Selectors = {
    aboutFooter: '.AboutFooter',
    contactLink: '.AboutFooter-link#contact'
};

const BindFunctions = [
    'openContactOverlay'
];


module.exports = Backbone.View.extend({

    el: Selectors.aboutFooter,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.contactLink}`]: this.openContactOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    openContactOverlay: function (event) {
        event.preventDefault();
        window.app.views.contacts.show();
    }
});
