'use strict';

const _ = require('underscore');
const Page = require('pages/Page/Page');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const GlobalMenu = require('components/Common/GlobalMenu/GlobalMenu');
const GlobalMenuMobile = require('components/Common/GlobalMenuMobile/GlobalMenuMobile');
const CommonText = require('components/Common/CommonText/CommonText');
const CommonColumnsTemplate = require('components/Common/CommonColumnsTemplate/CommonColumnsTemplate');
const ThreeColumnFacts = require('components/Common/ThreeColumnFacts/ThreeColumnFacts');
const CommonDonut = require('components/Common/CommonDonut/CommonDonut');
const TwoColumnsTable = require('components/Common/TwoColumnsTable/TwoColumnsTable');
const DownloadLinks = require('components/Common/DownloadLinks/DownloadLinks');
const DownloadCards = require('components/Common/DownloadCards/DownloadCards');
const CommonListLinks = require('components/Common/CommonListLinks/CommonListLinks');
const Accordion = require('components/Common/Accordion/Accordion');
const NewsInsights = require('components/Common/NewsInsights/NewsInsights');
const FeaturesIcons = require('components/Common/FeaturesIcons/FeaturesIcons');
const TitleCard = require('components/Common/TitleCard/TitleCard');
const ImageTextRight = require('components/Common/ImageTextRight/ImageTextRight');
const InvestorsGovernanceTable = require('components/InvestorsGovernanceTable/InvestorsGovernanceTable');
const LayoutsGallery = require('components/LayoutsGallery/LayoutsGallery');
const GlobalSlider = require('components/GlobalSlider/GlobalSlider');

require('./PageGlobalTemplate.less');

const Selectors = {
    el: '.PageGlobalTemplate',
    bgMobile: '.PageGlobalTemplate-headBg_mobile',
    bg: '.PageGlobalTemplate-headBg',
    head: '.PageGlobalTemplate-head',
    topNavLogo: '.TopNav-logo',
    search: '.TopNav-search',
    searchLogo: '.TopNav-search-icon',
    searchLabel: '.TopNav-search-label',
    donut: '.CommonDonut',
    gallery: '.LayoutsGallery',
    slider: '.GlobalSlider'
};

const BindFunctions = [
    '_attachEvents',
    'shadowBackground',
    'unshadowBackground',
    'onResize',
    '_initVariables',
    'onScroll',
    'initDonut',
    '_initGallery',
    '_initSlider'
];

const ChildViews = [
    {
        name: 'globalMenu',
        Constructor: GlobalMenu
    },
    {
        name: 'globalMenuMobile',
        Constructor: GlobalMenuMobile
    },
    {
        name: 'commonText',
        Constructor: CommonText
    },
    {
        name: 'commonColumnsTemplate',
        Constructor: CommonColumnsTemplate
    },
    {
        name: 'threeColumnFacts',
        Constructor: ThreeColumnFacts
    },
    {
        name: 'twoColumnsTable',
        Constructor: TwoColumnsTable
    },
    {
        name: 'downloadLinks',
        Constructor: DownloadLinks
    },
    {
        name: 'downloadCards',
        Constructor: DownloadCards
    },
    {
        name: 'investorsGovernanceTable',
        Constructor: InvestorsGovernanceTable
    },
    {
        name: 'commonListLinks',
        Constructor: CommonListLinks
    },
    {
        name: 'news',
        Constructor: NewsInsights
    },
    {
        name: 'featuresIcons',
        Constructor: FeaturesIcons
    },
    {
        name: 'titleCard',
        Constructor: TitleCard
    },
    {
        name: 'locations',
        Constructor: ImageTextRight
    }
];

const PageModifier = 'pageGlobalTemplate';

const CssClasses = {
    shadowBg: 'shadow',
    isOnWhiteBackground: 'onWhiteBackground'
};

module.exports = Page.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initVariables();
        this.initDonut();
        this._initChildViews(ChildViews);
        this._initGallery();
        this._initSlider();
        this._attachEvents();
        this.onResize();
        this.onScroll();

        Page.prototype.initialize.call(this, options);
    },

    _initGallery: function () {
        this.galleries = [];
        this.$(Selectors.gallery)
            .each((index, item) => {
                const $item = $(item);
                const id = $item.data('id');
                const gallery = new LayoutsGallery({
                    modifier: PageModifier,
                    id
                });

                this.galleries.push(gallery);
            });
    },

    _initSlider: function () {
        this.sliders = [];
        this.$(Selectors.slider)
            .each((index, item) => {
                const $item = $(item);
                const modifier = $item.data('modifier');
                const isCustom = $item.data('is-custom');
                if (typeof isCustom !== 'undefined') {
                    return;
                }
                const slider = new GlobalSlider({
                    modifier
                });

                this.sliders.push(slider);
            });
    },

    _attachEvents: function () {
        window.app.vent.on(Events.subMenuNav.navShown, this.shadowBackground);
        window.app.vent.on(Events.subMenuNav.navHidden, this.unshadowBackground);
        $(window)
            .on(ViewportEvents.resize, this.onResize);
        $(window)
            .on(ViewportEvents.scroll, this.onScroll);

        const self = this;
        $(document)
            .ready(function () {
                self.$(Selectors.bg)
                    .addClass('animation');
            });
    },

    initDonut() {
        this.$(Selectors.donut)
            .each((index, donut) => {
                const dataIndex = $(donut)
                    .data('index');
                new CommonDonut({
                    index: dataIndex
                });
            });
    },

    _initVariables: function () {
        this.$head = this.$(Selectors.head);
        this.$topNavLogo = window.app.els.$topNav.find(Selectors.topNavLogo);
        const $search = $(Selectors.search);
        this.$searchLogo = $search.find(Selectors.searchLogo);
        this.$searchLabel = $search.find(Selectors.searchLabel);
        this.topNavLogoHeight = this.$topNavLogo.height() + this.$topNavLogo.position().top;
        this.searchHeight = $search.height() + $search.position().top;
        this.accordion = new Accordion({
            modifier: PageModifier
        });
    },

    onScroll: function () {
        if (window.app.isPhoneLayout()) {
            return;
        }

        const scroll = $(window)
            .scrollTop();
        const isScrollMoreThanHead = scroll > (this.headHeight - this.searchHeight);
        const isScrollMoreThanTopNav = scroll > (this.headHeight - this.topNavLogoHeight);

        this.$topNavLogo.toggleClass(CssClasses.isOnWhiteBackground,
            isScrollMoreThanTopNav);
        this.$searchLogo.toggleClass(CssClasses.isOnWhiteBackground, isScrollMoreThanHead);
        this.$searchLabel.toggleClass(CssClasses.isOnWhiteBackground, isScrollMoreThanHead);
    },

    onResize: function () {
        this.headHeight = this.$head.height();
    },

    shadowBackground: function () {
        this.$(Selectors.bgMobile)
            .addClass(CssClasses.shadowBg);
    },

    unshadowBackground: function () {
        this.$(Selectors.bgMobile)
            .removeClass(CssClasses.shadowBg);
    }
});
