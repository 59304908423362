'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore/underscore.js');
const GlobalTopNav = require('components/Common/GlobalTopNav/GlobalTopNav');

require('./TopNav.less');

const Selectors = {
    topNav: '.TopNav',
    menu: '.TopNav-menu',
    logo: '.TopNav-logo',
    search: '.TopNav-search',
    searchClose: '.TopNav-search-close',
    searchResultsClose: '.TopNav-searchResults-close'
};

const BindFunctions = [
    'onKeydown',
    'closeMenu',
    '_attachEvents',
    '_initVariables',
    '_initChildComponents',
    'toggleTopNavMenu',
    'onTopNavScroll'
];

const CssClasses = {
    menuOpened: 'TopNav--menu-opened',
    searchOpened: 'TopNav--search-opened',
    searchResultsOpened: 'TopNav--searchResults-opened'
};

const ScrollPositionToHideTopNav = 50;
const ScrollOffset = 100;

module.exports = Backbone.View.extend({

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.menu}`]: this.triggerMenu,
            [`${ViewportEvents.click} ${Selectors.logo}`]: this.onLogoClick,
            [`${ViewportEvents.click} ${Selectors.search}`]: this.onSearchClick,
            [`${ViewportEvents.click} ${Selectors.searchClose}`]: this.onSearchCloseClick,
            [`${ViewportEvents.click} ${Selectors.searchResultsClose}`]: this.onSearchResultsCloseClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);
        this._initChildComponents();
        this._attachEvents();
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isMenuOpened = false;
        this.scroll = 0;
        this.isTopNavHidden = false;
    },

    _initChildComponents: function () {
        this.globalTopNav = new GlobalTopNav({
            page: this
        });
    },

    _attachEvents: function () {
        app.vent.on(Events.menu.menuShown, function () {
            this.$el.addClass(CssClasses.menuOpened);
            this.isMenuOpened = true;

            $(window)
                .on(ViewportEvents.keyDown, this.onKeydown);

            this.toggleTopNavMenu(false);
        }.bind(this));

        app.vent.on(Events.menu.menuHidden, function () {
            this.$el.removeClass(CssClasses.menuOpened);
            this.isMenuOpened = false;

            $(window)
                .off(ViewportEvents.keyDown, this.onKeydown);
            this.toggleTopNavMenu(true);
        }.bind(this));

        app.vent.on(Events.search.searchShown, function () {
            this.$el.addClass(CssClasses.searchOpened);
        }.bind(this));

        app.vent.on(Events.search.searchHidden, function () {
            this.$el.removeClass(CssClasses.searchOpened);
        }.bind(this));

        app.vent.on(Events.search.showCloseButton, function () {
            $(Selectors.searchResultsClose)
                .addClass(Constants.cssClasses.active);
        }.bind(this));

        if (!app.isPhoneLayout()) {
            return;
        }

        app.els.$window.on(ViewportEvents.scroll, this.onTopNavScroll);
    },

    onTopNavScroll: function () {
        const scroll = app.els.$window.scrollTop();
        if (scroll < ScrollPositionToHideTopNav) {
            if (!this.isTopNavHidden) {
                return;
            }
            this.$el.removeClass(Constants.cssClasses.hide);
            this.isTopNavHidden = false;

            return;
        }

        const direction = scroll > this.scroll ? Constants.scrollDirections.up : Constants.scrollDirections.down;
        const scrolledToBottom = scroll + app.sizes.windowHeight >= app.sizes.documentHeight - ScrollOffset;

        this.scroll = scroll;
        this.isTopNavHidden = direction === Constants.scrollDirections.up || scrolledToBottom;
        this.$el.toggleClass(Constants.cssClasses.hide, this.isTopNavHidden);
    },

    toggleTopNavMenu: function (isShow) {
        if (!this.globalTopNav) {
            return;
        }

        this.globalTopNav.toggle(isShow);
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.closeMenu();
        }
    },

    triggerMenu: function () {
        if (this.isMenuOpened) {
            app.vent.trigger(Events.menu.hideMenu);
        } else {
            app.vent.trigger(Events.menu.showMenu);
        }
    },

    closeMenu: function () {
        app.vent.trigger(Events.menu.hideMenu);
    },


    onSearchClick: function () {
        app.vent.trigger(Events.search.showSearch);
    },


    onSearchCloseClick: function () {
        app.vent.trigger(Events.search.hideSearch);
    },


    onSearchResultsCloseClick: function () {
        app.vent.trigger(Events.search.hideResults);
        $(Selectors.searchResultsClose)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.topNav)
            .removeClass(CssClasses.searchResultsOpened);
    },


    onLogoClick: function (e) {
        if (window.app.els.$body.hasClass(Constants.cssClasses.pageIndex)) {
            e.preventDefault();
            window.app.els.$htmlBody.animate({'scrollTop': 0}, 400);
        }
    }
});
