'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const Dropdown = require('components/Common/Dropdown/Dropdown');

require('./ProductDetailsSpecifications.less');

const Selectors = {
    el: '.ProductDetailsSpecifications',
    arrowBack: '.ProductDetailsSpecifications-arrowBack',
    contentItemDropdown: '.ProductDetailsSpecifications-contentItemDropdown',
    contentItemDropdownModel: '.ProductDetailsSpecifications-contentItemDropdownModel',
    contentItemDropdownOption: '.ProductDetailsSpecifications-contentItemDropdownOption'
};

const BindFunctions = [
    '_attachEvents',
    '_initChildComponents'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowBack}`]: this.closeOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this._initChildComponents();

        this._attachEvents();
    },

    _attachEvents: function () {
        const self = this;
        window.app.els.$body
            .click(function (e) {
                e.stopPropagation();
                const $dropdown = this.$(Selectors.contentItemDropdown);

                //check if the clicked area is dropDown or not
                if ($dropdown.has(e.target).length === 0) {
                    self.dropdown.close();
                }
            }.bind(this));
    },

    _initChildComponents: function () {
        this.dropdown = new Dropdown({
            id: 0,
            modifier: 'productDetailsSpecification'
        });
    },

    closeOverlay: function () {
        app.vent.trigger(Events.popup.popupHidden);
        app.vent.trigger(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose);
    },

    closeDropdown: function () {
        this.$(Selectors.contentItemDropdownModel)
            .removeClass(Constants.cssClasses.active);
    }
});
