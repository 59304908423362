'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const Slider = require('components/MobileSlider/MobileSlider');
require('slick-carousel');
require('slick-carousel/slick/slick.less');
require('./CareersEmployeeExperience.less');

const Selectors = {
    el: '.CareersEmployeeExperience',
    videos: '.CareersEmployeeExperience-videos',
    navLine: '.CareersEmployeeExperience-navLine',
    arrowRight: '.CareersEmployeeExperience-arrowRight',
    arrowLeft: '.CareersEmployeeExperience-arrowLeft',
    mobileInnerWrapper: '.CareersEmployeeExperience-videosInner_mobile',
    mobileEl: '.CareersEmployeeExperience-videos_mobile',
    mobileItem: '.CareersEmployeeExperience-video_mobile'
};

const BindFunctions = [
    '_initCarousel',
    'setActiveNavLine',
    'changeActiveSlide',
    '_initVariables',
    'goToSlide',
    '_initMobileSlider'
];

const SlickAfterChange = 'afterChange';
const SlickCurrentSlide = 'slickCurrentSlide';
const SlickGoTo = 'slickGoTo';
const SlickCarouselSpeed = 400;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowRight}`]: this.changeActiveSlide,
            [`${ViewportEvents.click} ${Selectors.arrowLeft}`]: this.changeActiveSlide,
            [`${ViewportEvents.click} ${Selectors.navLine}`]: this.goToSlide
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initMobileSlider();
        this._initCarousel();
    },

    _initVariables: function (options = {}) {
        this.options = options;

        this.$arrowLeft = this.$(Selectors.arrowLeft);
        this.$arrowRight = this.$(Selectors.arrowLeft);
        this.$desktopSlider = this.$(Selectors.videos);
    },

    _initCarousel: function () {
        this.$(Selectors.videos)
            .slick({
                infinite: true,
                speed: SlickCarouselSpeed,
                arrows: true,
                centerMode: true,
                variableWidth: true,
                prevArrow: false,
                nextArrow: false
            });

        const self = this;

        this.$desktopSlider
            .on(SlickAfterChange, function (event, slick, currentSlide) {
                self.setActiveNavLine(currentSlide);
            });

        this.setActiveNavLine(0);
    },

    _initMobileSlider: function () {
        this.slider = new Slider({
            page: this,
            el: Selectors.el,
            innerWrapper: Selectors.mobileInnerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.mobileItem,
            activeClassName: Constants.cssClasses.active,
            clickableNav: false,
            withAnimationDesktop: true,
            transitionWidth: this.$(Selectors.mobileItem).outerWidth(true),
            infiniteDesktop: true
        });
    },

    changeActiveSlide: function (e) {
        const isRightArrowClicked = $(e.target)
            .closest(Selectors.arrowRight).length;

        const slideMove = isRightArrowClicked ?
            Constants.translateDirections.right : Constants.translateDirections.left;

        const slickCurrentSlide = parseInt(this.$desktopSlider.slick(SlickCurrentSlide), 10);
        const newSlideNumber = slickCurrentSlide + slideMove;

        this.$desktopSlider.slick(SlickGoTo, newSlideNumber);
        this.setActiveNavLine(newSlideNumber);
    },

    goToSlide: function (e) {
        const slideNum = $(e.target)
            .closest(Selectors.navLine)
            .data('id') - 1;

        this.$desktopSlider.slick(SlickGoTo,
            slideNum);

        this.setActiveNavLine(slideNum);
    },

    setActiveNavLine: function (number) {
        this.$(Selectors.navLine)
            .removeClass(Constants.cssClasses.active);

        this.$(`${Selectors.navLine}[data-id="${(number + 1)}"]`)
            .addClass(Constants.cssClasses.active);
    }
});
