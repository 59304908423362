'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

require('./EightFacts.less');

const Selectors = {
    el: '.EightFacts',
    title: '.EightFacts-itemTitle'
};

const BindFunctions = [
    'adjustTitlesHeight'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        if (app.isPhoneLayout()) {
            return;
        }
        this.rowIndex = 0;
        this.maxRowHeight = 0;
        this.adjustTitlesHeight();
    },

    adjustTitlesHeight: function () {
        this.$(Selectors.title)
            .each((index, item) => {
                const rowIndex = Math.floor(index / 4);
                const isLastElem = (index + 1) % 4 === 0;
                const itemHeight = $(item)
                    .height();

                $(item)
                    .attr('data-row-index', rowIndex);

                if (isLastElem) {
                    this.$(`${Selectors.title}[data-row-index=${this.rowIndex}]`)
                        .height(this.maxRowHeight);

                    this.rowIndex++;
                    this.maxRowHeight = itemHeight;

                    return;
                }

                if (itemHeight > this.maxRowHeight) {
                    this.maxRowHeight = itemHeight;
                }
            });
    }
});
