'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

require('./CommonDonut.less');

const BindFunctions = [
    'setItemsDonuts'
];

const Selectors = {
    el: '.CommonDonut'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this.setElement(`${Selectors.el}--${options.index}`);

        this.setItemsDonuts();
    },

    setItemsDonuts: function () {
        let offset = 0;

        this.$('svg circle').each((id, circle) => {
            const percent = $(circle).data('percent');
            const totalLength = 2 * Math.PI * circle.getAttribute('r');
            const length = percent * totalLength / 100;

            $(circle).css({
                'stroke-dasharray': length + 'px ' + totalLength + 'px',
                'stroke-dashoffset': -offset + 'px'
            });

            offset += (length + 1);
        });
    }
});
