'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');
const CampusEventPopupHead = require('components/CampusEventPopupHead/CampusEventPopupHead');
const TopPageButtons = require('components/TopPageButtons/TopPageButtons');

require('./CampusEventPopup.less');

module.exports = BasePopup.extend({

    el: '.CampusEventPopup',

    events: {},


    initialize: function (options = {}) {
        this.popupClass = '.CampusEventPopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(CampusEventPopupHead, {page: this});
        this.registerChildView(TopPageButtons, {page: this});
    }
});
