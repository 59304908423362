'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Page = require('pages/Page/Page.js');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

const ProductFamilyHead = require('components/ProductFamilyHead/ProductFamilyHead');
const ProductFinder = require('components/ProductFinder/ProductFinder');
const ProductFamilyRoom = require('components/ProductFamilyRoom/ProductFamilyRoom');
const ProductFamilyFeatures = require('components/ProductFamilyFeatures/ProductFamilyFeatures');
const ProductFamilyInspiration = require('components/ProductFamilyInspiration/ProductFamilyInspiration');
const ProductFamilyDownloads = require('components/ProductFamilyDownloads/ProductFamilyDownloads');
const ProductFamilyAccessories = require('components/ProductFamilyAccessories/ProductFamilyAccessories');
const ProductFamilyServices = require('components/ProductFamilyServices/ProductFamilyServices');
const ProductFamilyVideos = require('components/ProductFamilyVideos/ProductFamilyVideos');
const ProductFamilyDesigner = require('components/ProductFamilyDesigner/ProductFamilyDesigner');
const ProductFooter = require('components/ProductDetailsFooter/ProductDetailsFooter');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');
const ProductDetailsPopup = require('popups/ProductDetailsPopup/ProductDetailsPopup');
const ServicesPopup = require('popups/PageServicesPopup/PageServicesPopup');
const ProductFamilyPopup = require('popups/ProductFamilyPopup/ProductFamilyPopup');

require('./PageProductFamily.less');

const Urls = {
    popupUrl: '/product-family/product.html',
    pageUrl: '/product-family.html'
};

const ChildViews = [
    {
        name: 'productFamilyHead',
        Constructor: ProductFamilyHead
    },
    {
        name: 'productFamilyRoom',
        Constructor: ProductFamilyRoom
    },
    {
        name: 'productFamilyFeatures',
        Constructor: ProductFamilyFeatures
    },
    {
        name: 'productFamilyInspiration',
        Constructor: ProductFamilyInspiration
    },
    {
        name: 'productFamilyDownloads',
        Constructor: ProductFamilyDownloads
    },
    {
        name: 'productFamilyAccessories',
        Constructor: ProductFamilyAccessories
    },
    {
        name: 'productFamilyServices',
        Constructor: ProductFamilyServices
    },
    {
        name: 'productFamilyVideos',
        Constructor: ProductFamilyVideos
    },
    {
        name: 'productFamilyDesigner',
        Constructor: ProductFamilyDesigner
    },
    {
        name: 'productFooter',
        Constructor: ProductFooter
    }
];

const BindFunctions = [
    'openInspirationCasePopup',
    'openProductDetailPopup',
    'openServicesPopup',
    'openAllOptionsPopup',
    '_attachEvents',
    '_initProductFinders',
    'onScroll',
    'onResize'
];

const CssClasses = {
    isDark: 'isDark'
};

const Selectors = {
    finder: '.ProductFinder',
    content: '.PageProductFamily-content',
    head: '.ProductFamilyHead',
    topNavLogo: '.TopNav-logo'
};

module.exports = Page.extend({

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initChildViews(ChildViews);
        this._initProductFinders();
        this._attachEvents();

        this.$topNavLogo = window.app.els.$topNav.find(Selectors.topNavLogo);
        this.isDark = false;
        this.onResize();

        Page.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.productFamily
            + Events.popup.inspirationPopupPrefix + Events.popup.popupOpen, this.openInspirationCasePopup);
        app.vent.on(Events.eventPrefixes.productFamily
            + Events.popup.servicesPopupPrefix + Events.popup.popupOpen, this.openServicesPopup);
        app.vent.on(Events.eventPrefixes.productFamily
            + Events.popup.productDetailsPopupPrefix + Events.popup.popupOpen, this.openProductDetailPopup);
        app.vent.on(Events.eventPrefixes.productFamily
            + Events.popup.allOptions + Events.popup.popupOpen, this.openAllOptionsPopup);

        app.els.$window.on(ViewportEvents.scroll, this.onScroll);
        app.els.$window.on(ViewportEvents.resize, this.onResize);
    },

    onScroll: function () {
        const scroll = app.els.$window.scrollTop();
        if (scroll + this.topNavHeight > this.headBlockHeight) {
            if (this.isDark) {
                return;
            }

            this.$el.addClass(CssClasses.isDark);

            this.isDark = true;

            return;
        }

        this.$el.removeClass(CssClasses.isDark);
        this.isDark = false;
    },

    onResize: function () {
        this.headBlockHeight = this.$(Selectors.head)
            .height();
        this.topNavHeight = this.$topNavLogo.height() + this.$topNavLogo.position().top;
    },

    _initProductFinders: function () {
        this.$(Selectors.finder)
            .each((index) => {
                const selector = `${Selectors.finder}--${index + 1}`;
                new ProductFinder({el: selector, index: (index + 1)});
            });
    },

    openPopup: function () {
        this.popup.openPopup();
        Backbone.history.navigate(Urls.popupUrl);
    },

    openInspirationCasePopup: function (data) {
        const popup = new InspirationCasePopup({
            page: this,
            backUrl: Urls.pageUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openProductDetailPopup: function (data) {
        const popup = new ProductDetailsPopup({
            page: this,
            backUrl: Urls.pageUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openServicesPopup: function (data) {
        const popup = new ServicesPopup({
            page: this,
            backUrl: Urls.pageUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openAllOptionsPopup: function (data) {
        const popup = new ProductFamilyPopup({
            page: this,
            backUrl: Urls.pageUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    }
});
