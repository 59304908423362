'use strict';

const _ = require('underscore');
const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');
const InspirationCard = require('components/Common/InspirationCard/InspirationCard');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./PageCompanyInnovation.less');

const Selectors = {
    el: '.PageCompanyInnovation'
};

const BindFunctions = [
    '_initInspirationCard',
    'onInspirationCardClick'
];

const popupBackUrl = '/company/innovation.html';

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initInspirationCard();

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initInspirationCard: function () {
        this.inspiration = new InspirationCard({onInspirationCardClick: this.onInspirationCardClick});
    },

    onInspirationCardClick: function (data) {
        const popup = new InspirationCasePopup({
            page: this,
            backUrl: popupBackUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    }
});
