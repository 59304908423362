'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const Constants = require('constants/Constants');

const RowFilter = require('components/Common/RowFilter/RowFilter');
const InvestorsFinancialItems = require('components/InvestorsFinancialItems/InvestorsFinancialItems');

require('./InvestorsFinancialCards.less');

const Selectors = {
    el: '.InvestorsFinancialCards',
    item: '.InvestorFinancialItems .DownloadCard'
};

const BindFunctions = [
    'onFilter',
    '_initRowFilter',
    'renderNewCards'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        const urlParams = new URLSearchParams(window.location.search);
        const preFiltered = urlParams.get(Constants.queryStringParams.filter);

        this._initRowFilter(preFiltered);
        this._initItemsComponent();
    },

    _initRowFilter: function (preFiltered) {
        this.rowFilter = new RowFilter({
            preFiltered,
            callback: this.onFilter,
            selectFirst: true
        });
    },

    _initItemsComponent: function () {
        this.itemsComponent = new InvestorsFinancialItems();
    },

    onFilter: function (newFilterId, apiUrl) {
        this.currentFilterId = newFilterId;

        this.getDataFromApi(this.renderNewCards,
            {
                isNeedRerender: true,
                newFilterId: this.currentFilterId,
                apiUrl: apiUrl
            });
    },

    renderNewCards: function (res, params) {
        this.itemsComponent.renderNewCards(res.cards, params.isNeedRerender);
    },

    getDataFromApi: function (callback, params) {
        // newFilterId will be sent to server to get filtered data
        const newFilterId = params.newFilterId || 0;

        // data is needed to load new items from server according to
        // current filter value and offset (when show more button is clicked)

        const data = {
            filter: newFilterId
        };

        $.getJSON(params.apiUrl, data, (res) => {
            if (!res) {
                return;
            }

            callback(res, params);
        });
    }
});
