'use strict';

const moment = require('moment');

const translations = {
    'zumtobel': {
        'en-en': require('./translated/zumtobel/en-en.json'),
        'de-de': require('./translated/zumtobel/de-de.json')
    },
    'thorn': {
        'en-en': require('./translated/thorn/en-en.json'),
        'de-de': require('./translated/thorn/de-de.json')
    }
};

let currentLang = 'en-en';
let currentTranslation = {};
const DefaultBrand = 'zumtobel';
const StaticPath = '/static/';
const TimeFormat = 'YYYYMMDDHHmmss';

module.exports = function (env) {
    env.addGlobal('static', function (file) {
        return StaticPath + file;
    }, true);

    env.addFilter('tojson', function (obj) {
        return JSON.stringify(obj);
    });

    env.addFilter('replace_quotes', function (obj) {
        obj = obj.replace(/"/g, '\'');

        return obj;
    });

    env.addGlobal('version', function () {
        return moment()
            .format(TimeFormat);
    });

    env.addGlobal('gettext', function (key, text, args) {
        let result = (currentTranslation || translations.zumtobel['en-en'])[key] || text;

        Object.keys(args)
            .forEach((key) => {
                result = result.replace('\$\{' + key + '\}', args[key]);
            });

        return result;
    });

    env.addGlobal('static_intl', function (path, file) {
        return StaticPath + (currentLang ? 'img/intl/' + currentLang + '/' : path) + file;
    });

    env.addGlobal('set_config', function (brand, lang) {
        console.log('build config:', [brand, lang]);
        currentLang = lang;
        const translationJsonPath = './translated/'
            + (brand || DefaultBrand)
            + '/' + (currentLang || 'en-en') + '.json';
        delete require.cache[require
            .resolve(translationJsonPath)];
        currentTranslation = translationJsonPath;

        return '';
    });

    env.addGlobal('add_key', function (index, array, key, value) {
        if (array[index]) {
            array[index][key] = value;
        }

        return array;
    });

    return env;
};
