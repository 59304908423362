'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const PerfectScrollbar = require('perfect-scrollbar').default;
const scrollLock = require('scroll-lock');

require('./ContactsPopup.less');

const ContactHeadquarters = require('components/ContactComponents/ContactHeadquarters/ContactHeadquarters');
const ContactRegionalOffices = require('components/ContactComponents/ContactRegionalOffices/ContactRegionalOffices');
const ContactFileAComplaint = require('components/ContactComponents/ContactFileAComplaint/ContactFileAComplaint');
const ContactTechnicalSupport = require('components/ContactComponents/ContactTechnicalSupport/ContactTechnicalSupport');
const ContactServices = require('components/ContactComponents/ContactServices/ContactServices');
const ContactHrAndJobs = require('components/ContactComponents/ContactHrAndJobs/ContactHrAndJobs');
const ContactEshopAccount = require('components/ContactComponents/ContactEshopAccount/ContactEshopAccount');
const ContactLightForum = require('components/ContactComponents/ContactLightForum/ContactLightForum');
const ContactProblems = require('components/ContactComponents/ContactProblems/ContactProblems');
const ContactInvestor = require('components/ContactComponents/ContactInvestor/ContactInvestor');
const ContactQualified = require('components/ContactComponents/ContactQualified/ContactQualified');
const ContactCorporate = require('components/ContactComponents/ContactCorporate/ContactCorporate');
const ContactPress = require('components/ContactComponents/ContactPress/ContactPress');
const ContactSupplier = require('components/ContactComponents/ContactSupplier/ContactSupplier');

const Selectors = {
    el: '.ContactsPopup',
    bg: '.ContactsPopup-bg',
    close: '.ContactsPopup-close',
    inputWrapper: '.ContactsPopup-inputWrapper',
    inputSearch: '.ContactsPopup-inputSearch',
    accordionSectionContentLink: '.ContactsPopup-accordionSectionContentLink',
    mobileAnchor: '.ContactsPopup-mobileAnchor',
    tabContentWrapper: '.ContactsPopup-tabContentWrapper',
    headQuartersTab: '.ContactsPopup-headquartersWrapper'
};

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    'getDataFromApi',
    'openOverlay',
    'closeOverlay',
    '_initChildComponents',
    '_attachEvents',
    'scrollWrapperTop',
    'triggerPopupHidden',
    'triggerPopupShown',
    'setActiveTabByQueryString',
    'activateTab'
];

const CssClasses = {
    contactsPopupHide: 'ContactsPopup--hide'
};

const ContactPageQueryParameter = 'contact';

const ChildViews = [
    {
        name: 'ContactHeadquarters',
        Constructor: ContactHeadquarters
    },
    {
        name: 'ContactRegionalOffices',
        Constructor: ContactRegionalOffices
    },
    {
        name: 'ContactFileAComplaint',
        Constructor: ContactFileAComplaint
    },
    {
        name: 'ContactTechnicalSupport',
        Constructor: ContactTechnicalSupport
    },
    {
        name: 'ContactServices',
        Constructor: ContactServices
    },
    {
        name: 'ContactHrAndJobs',
        Constructor: ContactHrAndJobs
    },
    {
        name: 'ContactEshopAccount',
        Constructor: ContactEshopAccount
    },
    {
        name: 'ContactLightForum',
        Constructor: ContactLightForum
    },
    {
        name: 'ContactProblems',
        Constructor: ContactProblems
    },
    {
        name: 'ContactInvestor',
        Constructor: ContactInvestor
    },
    {
        name: 'ContactQualified',
        Constructor: ContactQualified
    },
    {
        name: 'ContactCorporate',
        Constructor: ContactCorporate
    },
    {
        name: 'ContactPress',
        Constructor: ContactPress
    },
    {
        name: 'ContactSupplier',
        Constructor: ContactSupplier
    }
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.accordionSectionContentLink}`]: this.onMenuItemClick,
            [`${ViewportEvents.click} ${Selectors.mobileAnchor}`]: this.openOverlay
        };
    },

    initialize: function (options = {}) {
        this.options = options;

        _.bindAll(this, BindFunctions);

        this._initChildComponents();
        this._attachEvents();

        this.popupWrapper = Selectors.inputWrapper;

        this.getDataFromApi();

        const contactQueryParam = app.utils.getQueryParameterValue(ContactPageQueryParameter);
        if (contactQueryParam === null) {
            return;
        }

        setTimeout(() => {
            this.show(contactQueryParam);
        });
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.contacts + Events.popup.popupOpen, this.show);
        app.vent.on(Events.search.hideResults, this.hide);
        app.vent.on(Events.popup.hideCloseButton, function () {
            self.$('.ContactsPopup-close')
                .hide();
        }.bind(this));

        app.vent.on(Events.popup.showCloseButton, function () {
            self.$('.ContactsPopup-close')
                .show();
        }.bind(this));
        app.vent.on(Events.eventPrefixes.contact + Events.overlay.mobileOverlayClose, this.closeOverlay);
        app.vent.on(Events.eventPrefixes.contact + Events.common.scrollTop, this.scrollWrapperTop);
    },

    onMenuItemClick: function (event) {
        const $target = $(event.target)
            .closest(Selectors.accordionSectionContentLink);
        event.preventDefault();
        const itemId = $target.data('id');
        this.activateTab(itemId, $target);
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },

    show: function (activeTab = false) {
        if (!app.isPhoneLayout()) {
            this.$(Selectors.headQuartersTab)
                .addClass(Constants.cssClasses.active);
        }


        this.triggerPopupShown();

        scrollLock.addScrollableSelector(this.popupWrapper);
        scrollLock.addScrollableSelector(Selectors.tabContentWrapper);

        window.app.els.$window
            .on(ViewportEvents.keyDown, this.onKeydown);

        if (activeTab !== false && !app.isPhoneLayout()) {
            this.setActiveTabByQueryString(activeTab);

            return;
        }

        app.utils.clearQueryParameters();
        app.utils.addQueryParameter(ContactPageQueryParameter);
    },


    hide: function () {
        if (window.app.isPhoneLayout()) {
            this.triggerPopupHidden();
        } else {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', this.triggerPopupHidden, 300);
        }
        scrollLock.removeScrollableSelector(this.popupWrapper);
        scrollLock.removeScrollableSelector(Selectors.tabContentWrapper);
        this.$(Selectors.tabContentWrapper)
            .removeClass(Constants.cssClasses.active);
        this.$el.addClass(CssClasses.contactsPopupHide);
        app.utils.removeQueryParameter(ContactPageQueryParameter);
        window.app.els.$window
            .off(ViewportEvents.keyDown, this.onKeydown);
    },

    setActiveTabByQueryString: function (queryId) {
        const $tabLink = this.$(`${Selectors.accordionSectionContentLink}[data-query-id="${queryId}"]`);
        const $firstTab = this.$(Selectors.accordionSectionContentLink).first();
        const $newActiveTab = $tabLink.length ? $tabLink : $firstTab;
        const tabId = $newActiveTab.data('id');
        this.activateTab(tabId, $newActiveTab);
    },

    activateTab: function (tabId, $tabLink) {
        this.$(Selectors.accordionSectionContentLink)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.tabContentWrapper)
            .removeClass(Constants.cssClasses.active);
        $tabLink
            .addClass(Constants.cssClasses.active);

        this.$(`${Selectors.tabContentWrapper}[data-id="${tabId}--tab"]`)
            .addClass(Constants.cssClasses.active);

        app.utils.clearQueryParameters();
        app.utils.addQueryParameter(ContactPageQueryParameter, tabId);
    },

    triggerPopupShown: function () {
        this.savedScroll = $(window)
            .scrollTop();
        this.$el.removeClass(CssClasses.contactsPopupHide);
        if (window.app.isPhoneLayout()) {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', () => {
                app.vent.trigger(Events.popup.popupShown);
            }, 300);
        } else {
            app.vent.trigger(Events.popup.popupShown);
        }

        window.app.els.$body
            .addClass(Constants.cssClasses.popupShow);
    },

    triggerPopupHidden: function () {
        app.vent.trigger(Events.popup.popupHidden);
        if (window.app.state.countOfOpenedPopups === 0) {
            window.app.els.$body
                .removeClass(Constants.cssClasses.popupShow);
        }
        window.requestAnimationFrame(() => {
            window.app.els.$window
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });
    },

    render: function (data) {
        console.log(data); // data will be used for render popup template in runtime. Console.log is just to
        // avoid linter errors and to show that data parameter will be passed into render function

        if (window.app.settings.isDesktop) {
            this.perfectScrollbar = new PerfectScrollbar(this.popupWrapper);
            $(this.popupWrapper)
                .on(ViewportEvents.scroll, this.onScroll);
            $(window)
                .on(ViewportEvents.resize, this.onResize);
            this.onResize();
            this.onScroll();
            this.perfectScrollbar.update();
        }
    },

    getDataFromApi: function () {
        // function to get data from API when popup is rendering in runtime
        // must be overrided


        // render method must be call after async request completed
        const data = {};
        this.render(data);
    },

    onCloseClick: function () {
        this.hide();
    },

    onScroll: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    onResize: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    openOverlay: function (e) {
        this.id = $(e.target)
            .closest(Selectors.mobileAnchor)
            .data('id');
        this.$('.ContactsPopup-' + this.id + 'Wrapper')
            .addClass(Constants.cssClasses.active);

        app.vent.trigger(Events.popup.hideCloseButton);
        app.vent.trigger(Events.popup.popupShown);
    },

    closeOverlay: function () {
        this.$('.ContactsPopup-' + this.id + 'Wrapper')
            .removeClass(Constants.cssClasses.active);
        app.vent.trigger(Events.popup.popupHidden);

        app.vent.trigger(Events.popup.showCloseButton);
    },

    _initChildComponents: function () {
        if (!this.childViews) {
            this.childViews = {};
        }
        for (let i = 0; i < ChildViews.length; i++) {
            this.childViews[ChildViews[i].name] = new ChildViews[i].Constructor({page: this});
        }
    },

    scrollWrapperTop: function () {
        this.$(this.popupWrapper)
            .scrollTop(0);
        this.perfectScrollbar.update();
    }
});
