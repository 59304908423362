var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/NewsLandingItems/NewsLandingItemRuntime.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/NewsLandingItems/NewsLandingItemRuntime.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<div class=\"NewsLandingItems-item\">\n    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\" class=\"NewsLandingItems-itemLink\" data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\"\n       data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"api_url"), env.opts.autoescape);
output += "\"></a>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image")) {
output += "\n        <div class=\"NewsLandingItems-itemImage_mobile\">\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image_alt"), env.opts.autoescape);
output += "\">\n        </div>\n    ";
;
}
output += "\n    <div class=\"NewsLandingItems-itemDate\">\n        <div class=\"NewsLandingItems-itemDateMonthYear\">\n            <div class=\"s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"month"), env.opts.autoescape);
output += "</div>\n            <div class=\"s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"year"), env.opts.autoescape);
output += "</div>\n        </div>\n        <div class=\"NewsLandingItems-itemDateDay s-title-big\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"day"), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"NewsLandingItems-share\">\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"shareLinks")),"facebook"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 19, colno = 78, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["share-facebook",runtime.makeKeywordArgs({"caller": (function (){var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
return macro_t_4;})()})])), env.opts.autoescape);
output += "</a>\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"shareLinks")),"twitter"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 20, colno = 77, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["share-twitter",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
output += "</a>\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"shareLinks")),"linkedin"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 21, colno = 78, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["share-linkedin",runtime.makeKeywordArgs({"caller": (function (){var macro_t_8 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_9 = "";;
frame = frame.pop();
return new runtime.SafeString(t_9);
});
return macro_t_8;})()})])), env.opts.autoescape);
output += "</a>\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"shareLinks")),"mail"), env.opts.autoescape);
output += "\">";
output += runtime.suppressValue((lineno = 22, colno = 74, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["share-mail",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
output += "</a>ƒ\n        </div>\n    </div>\n    <div class=\"NewsLandingItems-itemDate_mobile\">\n        <div class=\"NewsLandingItems-itemDateDay s-title-big\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"day"), env.opts.autoescape);
output += "\n        </div>\n        <div class=\"NewsLandingItems-itemDateMonthYear\">\n            <div class=\"s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"month"), env.opts.autoescape);
output += ",</div>\n            <div class=\"s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"date")),"year"), env.opts.autoescape);
output += "</div>\n        </div>\n    </div>\n    <div class=\"NewsLandingItems-itemContent\">\n        <div class=\"NewsLandingItems-itemTitleBlock\">\n            <div class=\"NewsLandingItems-itemTitleWrapper\">\n                <div class=\"NewsLandingItems-itemTitle s-title-normal\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n                <div class=\"NewsLandingItems-itemDescription s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"description"), env.opts.autoescape);
output += "</div>\n            </div>\n            <div class=\"NewsLandingItems-itemReadMore\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "readMore"), env.opts.autoescape);
output += "</div>\n        </div>\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image")) {
output += "\n            <div class=\"NewsLandingItems-itemImage\">\n                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image_alt"), env.opts.autoescape);
output += "\">\n            </div>\n        ";
;
}
output += "\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/NewsLandingItems/NewsLandingItemRuntime.html"] , dependencies)