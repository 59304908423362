'use strict';

const _ = require('underscore');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactRegionalOffices';

const Dropdown = require('components/Common/Dropdown/Dropdown');

const CardItem = require('./ContactRegionalCardItem.html');
const LocationItem = require('./ContactRegionalLocationItem.html');
const TeamItem = require('./ContactRegionalTeamItem.html');

require('./ContactRegionalOffices.less');

const CurrentPage = 'regional';

const BindFunctions = [
    'onFilterDropdown',
    'setDefaultValues',
    'setFilterValues',
    'initChildComponents',
    'getApiUrl',
    'getFilteredValues',
    'renderNewItems',
    'checkClearFiltersVisibility',
    'isFiltered'
];

const Selectors = {
    clearFilters: '.ContactRegionalOffices-clearFilters',
    filters: '.ContactRegionalOffices-filters',
    resultsWrapper: '.ContactRegionalOffices-infoWrapper',
    cardsWrapper: '.ContactRegionalOffices-cardsWrapper',
    locationsWrapper: '.ContactRegionalOffices-infoWrapper',
    teamWrapper: '.ContactRegionalOffices-teamWrapper'
};

const DefaultView = createContactComponent({selectorComponent: SelectorComponent});

module.exports = DefaultView.extend({
    events: function () {
        return {
            ...DefaultView.prototype.events.call(this),
            [`${ViewportEvents.click} ${Selectors.clearFilters}`]: this.setDefaultValues
        };
    },

    initialize: function (options = {}) {
        DefaultView.prototype.initialize.call(this, options);
        _.bindAll(this, BindFunctions);
        this.initChildComponents();
        this.checkClearFiltersVisibility();
    },

    getApiUrl: function () {
        return this.$(Selectors.filters)
            .data('api-url');
    },

    initChildComponents: function () {
        const brand = new Dropdown({
            id: 'brand',
            onChooseOption: this.onFilterDropdown,
            modifier: CurrentPage
        });

        const region = new Dropdown({
            id: 'region',
            onChooseOption: this.onFilterDropdown,
            modifier: CurrentPage
        });

        const country = new Dropdown({
            id: 'country',
            onChooseOption: this.onFilterDropdown,
            modifier: CurrentPage
        });

        this.filters = {
            brand: {
                dropdown: brand,
                model: brand.getDefaultValue(),
                isFiltered: false,
                defaultModel: brand.getDefaultValue()
            },
            region: {
                dropdown: region,
                model: region.getDefaultValue(),
                isFiltered: false,
                defaultModel: region.getDefaultValue()
            },
            country: {
                dropdown: country,
                model: country.getDefaultValue(),
                isFiltered: false,
                defaultModel: country.getDefaultValue()
            }
        };

        this.setFilterValues();
    },

    setFilterValues: function () {
        this.filters.brand.dropdown.setValue(undefined, this.filters.brand.model);
        this.filters.region.dropdown.setValue(undefined, this.filters.region.model);
        this.filters.country.dropdown.setValue(undefined, this.filters.country.model);
        this.checkClearFiltersVisibility();
    },

    setDefaultValues: function () {
        this.filters.brand.model = this.filters.brand.defaultModel;
        this.filters.region.model = this.filters.region.defaultModel;
        this.filters.country.model = this.filters.country.defaultModel;

        this.setFilterValues();
        this.checkClearFiltersVisibility();
    },

    checkClearFiltersVisibility: function () {
        this.$(Selectors.clearFilters)
            .toggleClass(Constants.cssClasses.hide, !this.isFiltered());
    },

    isFiltered: function () {
        return !!Object.values(this.filters)
            .find(value => value.model !== value.defaultModel);
    },

    onFilterDropdown: function (id, itemId, itemType) {
        if (!itemType) {
            return;
        }

        this.filters[itemType].model = itemId;

        this.getFilteredValues();
        this.checkClearFiltersVisibility();
    },

    getFilteredValues: function () {
        const data = {
            brand: this.filters.brand.model,
            region: this.filters.region.model,
            country: this.filters.country.model
        };

        $.getJSON(this.getApiUrl(), data, this.renderNewItems);
    },

    renderNewItems: function (res) {
        if (!res) {
            return;
        }

        let cardsHtml = '';
        const cards = res.cards;
        cards.forEach((item) => {
            const cardHtml = CardItem.render({item});

            cardsHtml += cardHtml;
        });

        this.$(Selectors.cardsWrapper)
            .html(cardsHtml);

        let locationsHtml = '';
        const locations = res.locations;
        locations.forEach((item) => {
            const locationHtml = LocationItem.render({item});

            locationsHtml += locationHtml;
        });

        this.$(Selectors.locationsWrapper)
            .html(locationsHtml);

        let teamsHtml = '';
        const team = res.team;
        team.forEach((member) => {
            const memberHtml = TeamItem.render({member});

            teamsHtml += memberHtml;
        });

        this.$(Selectors.teamWrapper)
            .html(teamsHtml);
    }
});
