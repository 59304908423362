'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./GlobalTopNav.less');

const Selectors = {
    el: '.GlobalTopNav',
    tab: '.GlobalTopNav-tab'
};

const BindFunctions = [
    'setActiveTab',
    'onTopNavScroll',
    'toggle'
];

const CssClasses = {
    showBackground: 'showBackground',
    hideOpacity: 'hideOpacity'
};

const ScrollPositionToHideTopNav = 50;
const ScrollOffset = 100;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.scroll = 0;
        this.setActiveTab();
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.els.$window.on(ViewportEvents.scroll, this.onTopNavScroll);
    },

    onTopNavScroll: function () {
        const scroll = window.app.els.$window.scrollTop();
        if (!(Math.abs(scroll - this.scroll) > ScrollOffset)) {
            return;
        }

        const direction = scroll > this.scroll ? Constants.scrollDirections.up : Constants.scrollDirections.down;
        const scrolledToBottom = window.app.els.$window.scrollTop() + window.app.els.$window.height() >= $(document)
            .height() - ScrollOffset;
        const showBackground = direction === Constants.scrollDirections.down
            && scroll > ScrollOffset && !scrolledToBottom;

        if (scroll < ScrollPositionToHideTopNav) {
            this.$el.removeClass(Constants.cssClasses.hide);
            window.app.els.$topNav
                .removeClass(CssClasses.showBackground);

            return;
        }

        this.$el.toggleClass(Constants.cssClasses.hide,
            direction === Constants.scrollDirections.up || scrolledToBottom);

        window.app.els.$topNav
            .toggleClass(CssClasses.showBackground, showBackground);

        this.scroll = scroll;
    },

    setActiveTab: function () {
        const location = window.location.pathname;
        const $tabs = this.$(Selectors.tab);

        $tabs.each((index, tab) => {
            const $tab = $(tab);
            const url = $tab.data('url');
            if (url === location) {
                $tab.addClass('active');
            }
        });
    },

    toggle: function (isShow) {
        this.$el.toggleClass(CssClasses.hideOpacity, !isShow);
    }
});
