'use strict';

const _ = require('underscore');
const Events = require('constants/Events');
const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');
const CompanyAnnualReportsList = require('components/CompanyAnnualReportsList/CompanyAnnualReportsList');

const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

require('./PageCompanyAnnual.less');

const Selectors = {
    el: '.PageCompanyAnnual'
};

const ChildViews = [
    {
        name: 'annualReportsList',
        Constructor: CompanyAnnualReportsList
    }
];

const BindFunctions = [
    '_attachEvents'
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initChildViews(ChildViews);
        this._attachEvents();
        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        PageGlobalTemplate.prototype._attachEvents.call(this);
        app.vent.on(Events.eventPrefixes.companyAnnual + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        const popup = new NewsPopup({
            page: this
        });

        popup.openPopup(data.apiUrl, data.url);
    }
});
