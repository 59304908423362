'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore/underscore');
require('svg4everybody');

const RegionRibbon = require('components/RegionRibbon/RegionRibbon');

require('./Page.less');

const ChildViews = [
    {
        name: 'regionRibbon',
        Constructor: RegionRibbon
    }
];

module.exports = Backbone.View.extend({

    el: 'body',

    events: {},

    initialize: function (options = {}) {
        _.extend(this, options);

        this._initChildViews(ChildViews);

        if (!options.popup) {
            window.app.els.$popupContainer.html('');

            return;
        }

        // if the page is opened by overlay url
        const popup = new options.popup({
            page: this
        });

        popup.openRenderedPopup();
    },

    _initChildViews(views) {
        if (!this.childViews) {
            this.childViews = {};
        }
        for (let i = 0; i < views.length; i++) {
            this.childViews[views[i].name] = new views[i].Constructor({page: this});
        }
    }
});
