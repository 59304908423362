'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

require('./SearchResultProductItem.less');

const Template = require('./SearchResultProductItem.html');
const ProductDetailsPopup = require('popups/ProductDetailsPopup/ProductDetailsPopup');

const Selectors = {
    el: '.SearchResultProductItem',
    productItem: '.SearchResultProductItem-productTitle',
    link: '.SearchResultProductItem-optionId'
};

const BindFunctions = [
    'attachEvents',
    'openSkuOverlay',
    'attachElement'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openSkuOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this.attachEvents();
    },

    attachElement: function (modifier) {
        const el = modifier ? `${Selectors.el}--${modifier}` : Selectors.el;
        this.setElement(el);
    },

    render: function (data = {}) {
        return Template.render({data});
    },

    attachEvents: function () {
        this.attachElement();
        this.$(Selectors.productItem)
            .off(ViewportEvents.click, this.onProductItemClick);
        this.$(Selectors.productItem)
            .on(ViewportEvents.click, this.onProductItemClick);
    },

    onProductItemClick: function (event) {
        const $productItem = $(event.target)
            .closest(Selectors.productItem);
        if ($productItem
            .is(`.${Constants.cssClasses.active}`)) {
            $productItem
                .removeClass(Constants.cssClasses.active)
                .next(event.target)
                .slideUp(300);
        } else {
            $productItem
                .addClass(Constants.cssClasses.active)
                .next(event.target)
                .slideDown(300);
        }
        event.preventDefault();
    },

    openSkuOverlay: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.link);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        const popup = new ProductDetailsPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
