'use strict';

const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const _ = require('underscore');

require('./AboutInnovation.less');

const Selectors = {
    aboutInnovation: '.AboutInnovation',
    aboutInnovationTitle: '.AboutInnovation-title'
};

const BindFunctions = [
    'showText'
];

module.exports = Backbone.View.extend({

    el: Selectors.aboutInnovation,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
    },

    showText: function () {
        setTimeout(function () {
            this.$(Selectors.aboutInnovationTitle)
                .addClass(Constants.cssClasses.show);
        });
    }
});
