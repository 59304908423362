'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

require('./GlobalMenuMobile.less');

const Selectors = {
    el: '.GlobalMenuMobile',
    model: '.GlobalMenuMobile-model',
    dropdown: '.GlobalMenuMobile-dropdown',
    arrow: '.GlobalMenuMobile-arrow',
    link: '.GlobalMenuMobile-dropdownItem'
};

const BindFunctions = [
    'onModelClick',
    '_initVariables',
    '_attachEvents',
    'outsideMenuClick',
    '_toggleMenu'
];

const CssClasses = {
    arrowReverse: 'reverse'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.model}`]: this.onModelClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables();
        this._attachEvents();
        this.options = options;
    },

    _attachEvents: function () {
        window.app.els.$body.on(ViewportEvents.click, this.outsideMenuClick);
    },

    _initVariables: function () {
        this.$model = this.$(Selectors.model);
        this.$dropdown = this.$(Selectors.dropdown);
        this.$arrow = this.$(Selectors.arrow);
        this.$link = this.$(Selectors.link);
        this.isMenuOpened = false;
    },

    _toggleMenu: function (show) {
        this.$dropdown.toggleClass(Constants.cssClasses.show, show);
        this.$model.toggleClass(Constants.cssClasses.show, !show);
        this.$arrow.toggleClass(CssClasses.arrowReverse, show);
        this.isMenuOpened = show;
        const event = show ? Events.subMenuNav.navShown : Events.subMenuNav.navHidden;
        window.app.vent.trigger(event);
    },

    onModelClick: function () {
        this._toggleMenu(true);
    },

    outsideMenuClick: function (e) {
        if (!this.isMenuOpened) {
            return;
        }
        const $target = $(e.target);
        if (!$target.closest(this.$model).length && !$target.closest(this.$link).length) {
            this._toggleMenu(false);
        }
    }
});
