'use strict';

const CardsSlider = require('components/Common/CardsSlider/CardsSlider');

require('./CompanyCards.less');

module.exports = CardsSlider.extend({
    _initVariables: function (options = {}) {
        CardsSlider.prototype._initVariables.call(this, options);
        this.maxTransitions = window.app.isPhoneLayout() ? this.$item.length - 1 : this.$item.length - 3;
    }
});
