'use strict';

const _ = require('underscore');
const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');
const CompanyHistoryItems = require('components/CompanyHistoryItems/CompanyHistoryItems');
const HistoryPopup = require('popups/HistoryPopup/HistoryPopup');

require('./PageCompanyHistory.less');

const Selectors = {
    el: '.PageCompanyHistory'
};

const ChildViews = [
    {
        name: 'historyItems',
        Constructor: CompanyHistoryItems
    }
];

const BindFunctions = [
    'openPopup'
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initChildViews(ChildViews);
        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    openPopup: function (data) {
        const popup = new HistoryPopup({page: this});

        popup.openPopup(data.apiUrl, data.url);
    }
});
