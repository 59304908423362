'use strict';

const Backbone = require('backbone');

require('./ProductsExtraItem.less');

module.exports = Backbone.View.extend({

    el: '.ProductsExtraItem',

    events: {
    },

    initialize: function (options = {}) {
        this.options = options;
    }
});
