'use strict';

const CardsSlider = require('components/Common/CardsSlider/CardsSlider');
require('./History.less');

const Selectors = {
    el: '.History',
    objectsArrowRight: '.History-objectsArrowRight',
    objectsArrowLeft: '.History-objectsArrowLeft',
    object: '.History-object',
    objects: '.History-objects',
    objectsInner: '.History-objectsInner',
    navLine: '.History-navLine'
};

module.exports = CardsSlider.extend({
    _setSelectors: function () {
        this.selectors = Selectors;
    }
});
