'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./CompanyAnnualReportsList.less');

const Selectors = {
    el: '.CompanyAnnualReportsList',
    item: '.CompanyAnnualReportsList-item'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.onReportClick
        };
    },

    initialize: function (options = {}) {
        this.options = options;
    },

    onReportClick: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.item);
        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.companyAnnual + Events.popup.popupOpen, {apiUrl, url});
    }
});
