var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/AwardsNominees/AwardsNomineesItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"AwardsNominees-item\" data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"apiUrl"), env.opts.autoescape);
output += "\" data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\">\n    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\" class=\"AwardsNominees-itemUrl\"></a>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"label")) {
output += "\n        <div class=\"s-text-tiny AwardsNominees-itemLabel\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"label"), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n    <div class=\"AwardsNominees-itemImage\">\n        ";
if(runtime.contextOrFrameLookup(context, frame, "isRuntime")) {
output += "\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image"), env.opts.autoescape);
output += "\"/>\n        ";
;
}
else {
output += "\n            ";
output += runtime.suppressValue((lineno = 9, colno = 34, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image"),"jpg",runtime.makeKeywordArgs({"caller": (function (){var macro_t_1 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_2 = "";;
frame = frame.pop();
return new runtime.SafeString(t_2);
});
return macro_t_1;})()})])), env.opts.autoescape);
output += "\n        ";
;
}
output += "\n    </div>\n    <div class=\"AwardsNominees-itemDescription\">\n        <div class=\"s-title-normal AwardsNominees-itemDescriptionTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n        <div class=\"s-text-small AwardsNominees-itemDescriptionDetails\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"description"), env.opts.autoescape);
output += "</div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/AwardsNominees/AwardsNomineesItem.html"] , dependencies)