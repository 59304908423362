var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/utils/macros.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
var macro_t_1 = runtime.makeMacro(
["id"], 
["bound", "style", "classes"], 
function (l_id, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("id", l_id);
frame.set("bound", Object.prototype.hasOwnProperty.call(kwargs, "bound") ? kwargs["bound"] : "");frame.set("style", Object.prototype.hasOwnProperty.call(kwargs, "style") ? kwargs["style"] : false);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");var t_2 = "";t_2 += "<svg";
if(runtime.contextOrFrameLookup(context, frame, "bound")) {
t_2 += " viewBox=\"";
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "bound"), env.opts.autoescape);
t_2 += "\"";
;
}
t_2 += "\n                       class=\"svg svg--";
t_2 += runtime.suppressValue(l_id, env.opts.autoescape);
t_2 += " ";
if(runtime.contextOrFrameLookup(context, frame, "classes")) {
t_2 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "classes"), env.opts.autoescape);
;
}
t_2 += "\">\n        <use xlink:href=\"";
t_2 += runtime.suppressValue((lineno = 3, colno = 34, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, ["svg/symbol/svg/sprite.symbol.svg"])), env.opts.autoescape);
t_2 += "#";
t_2 += runtime.suppressValue(l_id, env.opts.autoescape);
t_2 += "\"></use>\n        ";
t_2 += runtime.suppressValue((lineno = 4, colno = 17, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
t_2 += "\n    </svg>";
;
frame = callerFrame;
return new runtime.SafeString(t_2);
});
context.addExport("svg_sprite");
context.setVariable("svg_sprite", macro_t_1);
output += "\n\n";
var macro_t_3 = runtime.makeMacro(
["component"], 
["mods", "classes", "data"], 
function (l_component, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("component", l_component);
frame.set("mods", Object.prototype.hasOwnProperty.call(kwargs, "mods") ? kwargs["mods"] : []);frame.set("classes", Object.prototype.hasOwnProperty.call(kwargs, "classes") ? kwargs["classes"] : "");frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});var t_4 = "";if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item")) {
var t_5;
t_5 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item");
frame.set("item", t_5, true);
if(frame.topLevel) {
context.setVariable("item", t_5);
}
if(frame.topLevel) {
context.addExport("item", t_5);
}
;
}
var tasks = [];
tasks.push(
function(callback) {
env.getTemplate(l_component, false, "assets/app/utils/macros.html", false, function(t_7,t_6) {
if(t_7) { cb(t_7); return; }
callback(null,t_6);});
});
tasks.push(
function(template, callback){
template.render(context.getVariables(), frame, function(t_9,t_8) {
if(t_9) { cb(t_9); return; }
callback(null,t_8);});
});
tasks.push(
function(result, callback){
t_4 += result;
callback(null);
});
env.waterfall(tasks, function(){
t_4 += runtime.suppressValue((lineno = 12, colno = 36, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "caller"), "caller", context, [])), env.opts.autoescape);
});
frame = callerFrame;
return new runtime.SafeString(t_4);
});
context.addExport("include_component");
context.setVariable("include_component", macro_t_3);
output += "\n\n";
var macro_t_10 = runtime.makeMacro(
[], 
["src", "ext", "layoutSize"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("src", Object.prototype.hasOwnProperty.call(kwargs, "src") ? kwargs["src"] : "");frame.set("ext", Object.prototype.hasOwnProperty.call(kwargs, "ext") ? kwargs["ext"] : "png");frame.set("layoutSize", Object.prototype.hasOwnProperty.call(kwargs, "layoutSize") ? kwargs["layoutSize"] : "1920");var t_11 = "";t_11 += "\n    <img src=\"";
t_11 += runtime.suppressValue((lineno = 16, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@720." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += "\"\n         srcset=\"";
t_11 += runtime.suppressValue((lineno = 17, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1920." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += " 1920w,\n                 ";
t_11 += runtime.suppressValue((lineno = 18, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1680." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += " 1680w,\n                 ";
t_11 += runtime.suppressValue((lineno = 19, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1280." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += " 1280w,\n                 ";
t_11 += runtime.suppressValue((lineno = 20, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@960." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += " 960w,\n                 ";
t_11 += runtime.suppressValue((lineno = 21, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@720." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_11 += " 720w\"\n         sizes=\"(min-width: 1920px) calc((";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "layoutSize"), env.opts.autoescape);
t_11 += " / 1920) * 100vw),\n                (min-width: 1240px) and (max-width: 1919px) calc((";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "layoutSize"), env.opts.autoescape);
t_11 += " / 1440) * 100vw),\n                (min-width: 760px) and (max-width: 1239px) calc((";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "layoutSize"), env.opts.autoescape);
t_11 += " / 1240) * 100vw),\n                (max-width: 759px) calc((";
t_11 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "layoutSize"), env.opts.autoescape);
t_11 += " / 760) * 100vw)\">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_11);
});
context.addExport("picture");
context.setVariable("picture", macro_t_10);
output += "\n\n";
var macro_t_12 = runtime.makeMacro(
["image"], 
["size", "mobile_size"], 
function (l_image, kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("image", l_image);
frame.set("size", Object.prototype.hasOwnProperty.call(kwargs, "size") ? kwargs["size"] : "50vw");frame.set("mobile_size", Object.prototype.hasOwnProperty.call(kwargs, "mobile_size") ? kwargs["mobile_size"] : "100vw");var t_13 = "";t_13 += "\n    <img src=\"";
t_13 += runtime.suppressValue(runtime.memberLookup((l_image),"w800Url"), env.opts.autoescape);
t_13 += "\"\n         srcset=\"";
t_13 += runtime.suppressValue(runtime.memberLookup((l_image),"w1700Url"), env.opts.autoescape);
t_13 += " 1700w,\n                 ";
t_13 += runtime.suppressValue(runtime.memberLookup((l_image),"w800Url"), env.opts.autoescape);
t_13 += " 800w,\n                 ";
t_13 += runtime.suppressValue(runtime.memberLookup((l_image),"w300Url"), env.opts.autoescape);
t_13 += " 300w\"\n         sizes=\"(max-width: 759px) ";
t_13 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "mobile_size"), env.opts.autoescape);
t_13 += ",\n                ";
t_13 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
t_13 += "\">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_13);
});
context.addExport("picturePDB");
context.setVariable("picturePDB", macro_t_12);
output += "\n\n";
output += "\n";
var macro_t_14 = runtime.makeMacro(
[], 
["src", "ext", "is_first", "size", "size_mobile"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("src", Object.prototype.hasOwnProperty.call(kwargs, "src") ? kwargs["src"] : "");frame.set("ext", Object.prototype.hasOwnProperty.call(kwargs, "ext") ? kwargs["ext"] : "png");frame.set("is_first", Object.prototype.hasOwnProperty.call(kwargs, "is_first") ? kwargs["is_first"] : false);frame.set("size", Object.prototype.hasOwnProperty.call(kwargs, "size") ? kwargs["size"] : "100vw");frame.set("size_mobile", Object.prototype.hasOwnProperty.call(kwargs, "size_mobile") ? kwargs["size_mobile"] : "100vw");var t_15 = "";t_15 += "\n    <img ";
t_15 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "is_first")?"src":"data-src"), env.opts.autoescape);
t_15 += "=\"";
t_15 += runtime.suppressValue((lineno = 39, colno = 59, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@720." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += "\"\n         ";
t_15 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "is_first")?"srcset":"data-srcset"), env.opts.autoescape);
t_15 += "=\"";
t_15 += runtime.suppressValue((lineno = 40, colno = 65, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1920." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += " 1920w,\n                 ";
t_15 += runtime.suppressValue((lineno = 41, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1680." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += " 1680w,\n                 ";
t_15 += runtime.suppressValue((lineno = 42, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@1280." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += " 1280w,\n                 ";
t_15 += runtime.suppressValue((lineno = 43, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@960." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += " 960w,\n                 ";
t_15 += runtime.suppressValue((lineno = 44, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@720." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_15 += " 720w\"\n         sizes=\"(max-width: 759px) ";
t_15 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size_mobile"), env.opts.autoescape);
t_15 += ",\n                ";
t_15 += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "size"), env.opts.autoescape);
t_15 += "\"\n         style=\"";
t_15 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "is_first")?"":"display: none"), env.opts.autoescape);
t_15 += "\" class=\"";
t_15 += runtime.suppressValue((runtime.contextOrFrameLookup(context, frame, "is_first")?"":"fade-out"), env.opts.autoescape);
t_15 += "\">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_15);
});
context.addExport("picture_rotating");
context.setVariable("picture_rotating", macro_t_14);
output += "\n\n";
output += "\n";
var macro_t_16 = runtime.makeMacro(
[], 
["src", "ext"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("src", Object.prototype.hasOwnProperty.call(kwargs, "src") ? kwargs["src"] : "");frame.set("ext", Object.prototype.hasOwnProperty.call(kwargs, "ext") ? kwargs["ext"] : "png");var t_17 = "";t_17 += "\n    <img src=\"";
t_17 += runtime.suppressValue((lineno = 52, colno = 23, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_17 += "\"\n         srcset=\"";
t_17 += runtime.suppressValue((lineno = 53, colno = 26, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.contextOrFrameLookup(context, frame, "src") + "@2x." + runtime.contextOrFrameLookup(context, frame, "ext")])), env.opts.autoescape);
t_17 += " 2x\">\n";
;
frame = callerFrame;
return new runtime.SafeString(t_17);
});
context.addExport("picture2x");
context.setVariable("picture2x", macro_t_16);
output += "\n\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/utils/macros.html"] , dependencies)