'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');

const CampusHead = require('components/CampusHead/CampusHead');
const CampusHighlight = require('components/CampusHighlight/CampusHighlight');
const CampusLightCenters = require('components/CampusLightCenters/CampusLightCenters');
const PastEvents = require('components/CareersEmployeeExperience/CareersEmployeeExperience');
const GlobalSlider = require('components/GlobalSlider/GlobalSlider');
const UpcomingEvents = require('components/CampusUpcomingEvents/CampusUpcomingEvents');

const CampusCenterPopup = require('popups/CampusCenterPopup/CampusCenterPopup');
const CampusEventPopup = require('popups/CampusEventPopup/CampusEventPopup');

require('./PageCampusLanding.less');

const ChildViews = [
    {
        name: 'head',
        Constructor: CampusHead
    },
    {
        name: 'highlight',
        Constructor: CampusHighlight
    },
    {
        name: 'lightCenters',
        Constructor: CampusLightCenters
    },
    {
        name: 'pastEvents',
        Constructor: PastEvents
    }
];

const BindFunctions = [
    '_initVideoSlider',
    'openEventPopup',
    'openCenterPopup',
    'openPopup'
];

const PageModifier = 'page-campus';

module.exports = PageLanding.extend({

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);
        this._initVideoSlider();

        PageLanding.prototype.initialize.call(this, options);
    },

    _initVideoSlider: function () {
        this.globalSlider = new GlobalSlider({
            modifier: PageModifier
        });

        this.upcomingEvents = new UpcomingEvents({
            modifier: PageModifier
        });
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    },

    openEventPopup: function (data) {
        this.openPopup(CampusEventPopup, data.url, data.apiUrl);
    },

    openCenterPopup: function (data) {
        this.openPopup(CampusCenterPopup, data.url, data.apiUrl);
    },

    openPopup(popupObject, url, apiUrl) {
        this.detachOnScroll();
        const popup = new popupObject({
            page: this
        });

        popup.openPopup(apiUrl, url, this.attachOnScroll);
    }
});
