'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore');
const Slider = require('components/MobileSlider/MobileSlider');

const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./AboutLightArchitecture.less');

const Selectors = {
    aboutLightArchitecture: '.AboutLightArchitecture',
    objectsArrowRight: '.AboutLightArchitecture-objectsArrowRight',
    objectsArrowLeft: '.AboutLightArchitecture-objectsArrowLeft',
    object: '.AboutLightArchitecture-object',
    objects: '.AboutLightArchitecture-objects',
    objectsInner: '.AboutLightArchitecture-objectsInner',
    navLine: '.AboutLightArchitecture-navLine'
};

const BindFunctions = [
    'move',
    'moveRight',
    'moveLeft',
    'onResize',
    'checkArrows',
    '_initVariables',
    '_initMobileSlider',
    '_initSwipeDesktop'
];

module.exports = Backbone.View.extend({

    el: Selectors.aboutLightArchitecture,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.objectsArrowRight}`]: this.moveRight,
            [`${ViewportEvents.click} ${Selectors.objectsArrowLeft}`]: this.moveLeft,
            [`${ViewportEvents.click} ${Selectors.object}`]: this.onObjectClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        if (window.app.isPhoneLayout()) {
            this._initMobileSlider();
        }

        if (window.app.settings.isDesktop) {
            this._initSwipeDesktop();
            this._attachEvents();
            this.onResize();
            this.checkArrows();
        }
    },

    _initSwipeDesktop: function () {
        this.$(Selectors.objects)
            .swipe({
                allowPageScroll: 'auto',
                threshold: 50,
                fallbackToMouseEvents: true,
                excludedElements: 'button, input, select, textarea, .noSwipe',
                swipe: function (event, direction) {
                    (direction === 'left') && this.moveRight();
                    (direction === 'right') && this.moveLeft();
                }.bind(this)
            });
    },

    _initMobileSlider: function () {
        this.slider = new Slider({
            page: this,
            el: Selectors.objects,
            arrowLeft: Selectors.objectsArrowLeft,
            arrowRight: Selectors.objectsArrowRight,
            innerWrapper: Selectors.objectsInner,
            navLine: Selectors.navLine,
            singleItem: Selectors.object,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            withAnimationDesktop: true,
            transitionWidth: this.$item.outerWidth(true),
            infiniteDesktop: true
        });
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
    },

    _initVariables: function (options = {}) {
        this.$item = this.$(Selectors.object);
        this.itemWidth = this.$item.outerWidth(true);
        this.$wrapperInner = this.$(Selectors.objectsInner);
        this.$rightArrow = this.$(Selectors.objectsArrowRight);
        this.$leftArrow = this.$(Selectors.objectsArrowLeft);
        this.transitionIndex = 0;
        this.maxTransitions = window.app.isPhoneLayout() ? this.$item.length - 1 : this.$item.length - 2;
        this.currentTransition = 0;
        this.options = options;
        this.pageUrl = options.pageUrl;
    },

    onResize: function () {
        this.ww = $(window)
            .width();
    },

    checkArrows: function () {
        if (this.transitionIndex >= this.maxTransitions) {
            this.$rightArrow.hide();
        } else {
            this.$rightArrow.show();
        }

        if (this.transitionIndex === 0) {
            this.$leftArrow.hide();
        } else {
            this.$leftArrow.show();
        }
    },

    moveRight: function () {
        this.currentTransition = this.currentTransition - this.itemWidth;
        this.transitionIndex++;
        this.move();
    },

    moveLeft: function () {
        this.currentTransition = this.currentTransition + this.itemWidth;
        this.transitionIndex--;
        this.move();
    },

    move: function () {
        this.$wrapperInner.css('transform', 'translate3d(' + this.currentTransition + 'px, 0, 0)');
        this.checkArrows();
    },

    onObjectClick: function (event) {
        const $object = $(event.currentTarget);
        const leftEdge = $object.offset().left;
        const rightEdge = leftEdge + $object.width();

        event.preventDefault();

        if (leftEdge < 0) {
            this.moveLeft();
        } else if (rightEdge > this.$el.width()) {
            this.moveRight();
        } else {
            const $target = $(event.target).closest(Selectors.object);
            const url = $target
                .data('url');

            const apiUrl = $target.data('api-url');

            const popup = new InspirationCasePopup({
                page: this,
                backUrl: this.pageUrl
            });

            popup.openPopup(apiUrl, url);
        }
    }
});
