'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');

require('./PageInvestorsCalendar.less');

const InvestorsFinancialCalendar = require('components/InvestorsFinancialCalendar/InvestorsFinancialCalendar');

const Selectors = {
    el: '.PageInvestorsCalendar'
};

const ChildViews = [
    {
        name: 'investorsFinancialCalendar',
        Constructor: InvestorsFinancialCalendar
    }
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;

        this._initChildViews(ChildViews);

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageGlobalTemplate.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    }
});
