'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

module.exports = function (args) {
    const Selectors = {
        selectorComponent: args.selectorComponent,
        mobileArrowBack: `${args.selectorComponent}-mobileArrowBack`
    };

    return Backbone.View.extend({
        el: args.selectorComponent,

        events: function () {
            return {
                [`${ViewportEvents.click} ${Selectors.mobileArrowBack}`]: this.closeOverlay
            };
        },

        initialize: function (options = {}) {
            if (args.bindFunctions) {
                _.bindAll(this, args.bindFunctions);
            }

            this.options = options;
        },

        closeOverlay: function () {
            app.vent.trigger(Events.eventPrefixes.contact + Events.overlay.mobileOverlayClose);
        },

        scrollTop: function () {
            app.vent.trigger(Events.eventPrefixes.contact + Events.common.scrollTop);
        }
    });
};
