'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const Constants = require('constants/Constants');

const RowFilter = require('components/Common/RowFilter/RowFilter');
const PressDownloadsItems = require('components/PressDownloadsItems/PressDownloadsItems');

require('./PressDownloads.less');

const Selectors = {
    el: '.PressDownloads'
};

const BindFunctions = [
    'onFilter',
    '_initRowFilter',
    'renderNewCards'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        const urlParams = new URLSearchParams(window.location.search);
        const preFiltered = urlParams.get(Constants.queryStringParams.category);

        this._initRowFilter(preFiltered);
        this._initItemsComponent();
    },

    _initRowFilter: function (preFiltered) {
        this.rowFilter = new RowFilter({
            preFiltered: preFiltered,
            selectFirst: true, // if there was no preFiltered in url, then select first filter
            callback: this.onFilter
        });
    },

    _initItemsComponent: function () {
        this.itemsComponent = new PressDownloadsItems();
    },

    onFilter: function (newFilterId, apiUrl) {
        this.currentFilterId = newFilterId;

        this.getDataFromApi(this.renderNewCards,
            {
                isNeedRerender: true,
                newFilterId: this.currentFilterId,
                apiUrl: apiUrl
            });
    },

    renderNewCards: function (res, params) {
        this.itemsComponent.renderNewCards(res.items, params.isNeedRerender);
    },

    getDataFromApi: function (callback, params) {
        // newFilterId value will be sent to the server to get filtered values
        const newFilterId = params.newFilterId || 0;

        // data is needed to load new items from server according to
        // current filter value and offset (when show more button is clicked)

        const data = {
            filter: newFilterId
        };

        $.getJSON(params.apiUrl, data, (res) => {
            if (!res) {
                return;
            }
            callback(res, params);
        });
    }
});
