'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');
const TopPageButtons = require('components/TopPageButtons/TopPageButtons');
const Layouts111Sections = require('components/Layouts111Sections/Layouts111Sections');

require('./AwardsPopup.less');

module.exports = BasePopup.extend({

    el: '.AwardsPopup',

    events: {},


    initialize: function (options = {}) {
        this.popupClass = '.AwardsPopup';

        this.initChildComponents();

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(TopPageButtons, {page: this});
        this.registerChildView(Layouts111Sections, {page: this});
    }
});
