'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

var VimeoPlayer = require('@vimeo/player').default;

require('./VideoPlayer.less');

const Selectors = {
    el: '.VideoPlayer',
    close: '.VideoPlayer-close',
    overlay: '.VideoPlayer-overlay',
    mainElement: '.VideoPlayer.main',
    description: '.VideoPlayer-description',
    descriptionTitle: '.VideoPlayer-descriptionTitle',
    descriptionText: '.VideoPlayer-descriptionText',
    descriptionVideoTime: '.VideoPlayer-descriptionVideoTime',
    descriptionLinks: '.VideoPlayer-descriptionLinks'
};

const BindFunctions = [
    'openPlayer',
    'initPlayer',
    'onYoutubePlayerAPIReady',
    '_attachEvents',
    'playVideo',
    'onKeydown',
    'openMobilePlayer',
    'openDesktopPlayer',
    'setDescription',
    'clearDescription',
    '_initVariables'
];

const CssClasses = {
    descriptionLink: 'VideoPlayer-descriptionLink',
    withDescription: 'withDescription'
};

const Providers = {
    youtube: 'youtube',
    vimeo: 'vimeo'
};
const YoutubeVideoPlayerId = 'youtube-video-player';
const VimeoVideoPlayerId = 'vimeo-video-player';
const VideoPlayerId = 'video-player';
const YoutubePlayerEvents = {
    onReady: 'onReady',
    onStateChange: 'onStateChange'
};
const YoutubeVideoUrlRegExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)(?<id>[^#&?]*).*/;
// eslint-disable-next-line
const VimeoVideoUrlRexExp = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:P<n>channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(?<id>\d+)(?:$|\/|\?)/;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.close}`]: this.closePlayer,
            [`${ViewportEvents.click} ${Selectors.overlay}`]: this.closePlayer
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._attachEvents();
        this._initVariables();
    },

    _initVariables: function () {
        this.$description = $(Selectors.mainElement)
            .find(Selectors.description);
        this.$descriptionTitle = $(Selectors.mainElement)
            .find(Selectors.descriptionTitle);
        this.$descriptionVideoTime = $(Selectors.mainElement)
            .find(Selectors.descriptionVideoTime);
        this.$descriptionText = $(Selectors.mainElement)
            .find(Selectors.descriptionText);
        this.$descriptionLinks = $(Selectors.mainElement)
            .find(Selectors.descriptionLinks);
    },

    _attachEvents: function () {
        window.app.vent.on(Events.videoPlayer.openPlayer, this.openDesktopPlayer);
    },

    onKeydown: function (event) {
        if (event.keyCode === Constants.keyCodes.esc) {
            this.closePlayer();
        }
    },

    openDesktopPlayer(data) {
        this.$element = $(Selectors.mainElement)
            .find(`#${VideoPlayerId}`);

        this.clearDescription();
        if (data.description && data.links) {
            this.setDescription(data);
        }

        this.openPlayer(data);
    },

    clearDescription: function () {
        this.description = '';
        this.$descriptionLinks.empty();
        this.$description.hide();
        $(Selectors.mainElement)
            .removeClass(CssClasses.withDescription);
    },

    setDescription: function (data) {
        this.$description.show();
        this.description = data.description;

        $(Selectors.mainElement)
            .addClass(CssClasses.withDescription);
        this.$descriptionTitle.text(data.title);
        this.$descriptionVideoTime.text(data.duration);
        this.$descriptionText.text(data.description);

        if (!data.links || !data.links.length) {
            return;
        }

        data.links.forEach((link) => {
            const $elem = $('<a></a>');
            $elem.attr('href', link.url);
            $elem.addClass(CssClasses.descriptionLink);
            $elem.text(link.title);
            if (link.isExternal) {
                $elem.attr('target', '_blank');
                $elem.attr('rel', 'nofollow');
            }
            this.$descriptionLinks.append($elem);
        });
    },

    openMobilePlayer(data, $element) {
        this.clearDescription();
        this.$element = $element.find(`#${VideoPlayerId}`);

        this.openPlayer(data);
    },

    openPlayer: function (data = {}) {
        this.url = data.url;
        this.provider = data.provider;

        $(document)
            .on(ViewportEvents.keyDown, this.onKeydown);

        this.initPlayer();
    },

    initPlayer: function () {
        if (this.provider === Providers.vimeo) {
            const videoId = this.getVideoId(this.url, VimeoVideoUrlRexExp);
            this.elemId = `${VimeoVideoPlayerId}-${videoId}`;
            this.$element.attr('id', this.elemId);
            this.player = new VimeoPlayer(this.$element, {
                url: this.url
            });
            const self = this;
            this.player.ready()
                .then(self.playVideo);
            this.playerIsReady = true;
        }

        if (this.provider !== Providers.youtube) {
            return;
        }

        if (window.app.state.youtubePlayerAPIReady) {
            this.onYoutubePlayerAPIReady();
        } else {
            window.app.vent.on(Events.videoPlayer.youtubeApiReady, this.onYoutubePlayerAPIReady);
        }
    },

    getVideoId: function (url, regExp) {
        const match = url.match(regExp);

        return (match && match.groups && match.groups.id) || false;
    },

    onYoutubePlayerAPIReady: function () {
        const videoId = this.getVideoId(this.url, YoutubeVideoUrlRegExp);
        this.elemId = `${YoutubeVideoPlayerId}-${videoId}`;
        this.$element.attr('id', this.elemId);

        this.player = new window.YT.Player(this.elemId, {
            videoId: videoId,
            events: {
                [YoutubePlayerEvents.onReady]: function () {
                    this.youtubePlayerReady = true;

                    this.playerIsReady = true;

                    this.playVideo();
                }.bind(this)
            }
        });
    },

    playVideo: function () {
        $(`#${this.elemId}`)
            .closest(Selectors.el)
            .addClass(Constants.cssClasses.show);

        if (!window.app.isPhoneLayout() || this.description !== '') {
            this.savedScroll = $(window)
                .scrollTop();
            app.vent.trigger(Events.popup.popupShown, this.$description[0]);
        }
        if (this.provider !== Providers.youtube) {
            this.player.play();

            return;
        }

        this.player.playVideo();
        this.$(`#${YoutubeVideoPlayerId}`)
            .closest(Selectors.el)
            .addClass(Constants.cssClasses.show);
    },

    closePlayer: function () {
        this.$element.attr('id', VideoPlayerId);
        if (this.provider === Providers.youtube) {
            app.settings.isDesktop && this.player.pauseVideo();
            this.$(`#${YoutubeVideoPlayerId}`)
                .closest(Selectors.el)
                .removeClass(Constants.cssClasses.show);
        } else {
            app.settings.isDesktop && this.player.pause();
        }

        $(document)
            .off(ViewportEvents.keyDown, this.onKeydown);

        this.player.destroy();
        this.$element.closest(Selectors.el)
            .removeClass(Constants.cssClasses.show);
        app.vent.trigger(Events.popup.popupHidden);
        if (!window.app.isPhoneLayout() || this.description !== '') {
            $(window)
                .scrollTop(this.savedScroll);
        }

        app.vent.trigger(Events.videoPlayer.closePlayer);
    }
});
