var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/popups/SearchResultPopup/SearchResultProductItem/SearchResultProductItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/popups/SearchResultPopup/SearchResultProductItem/SearchResultProductItem.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n\n<div class=\"SearchResultProductItem SearchResultProductItem--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"modifier"), env.opts.autoescape);
output += "\">\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tip")) {
output += "\n        <div class=\"SearchResultProductItem-tip s-title-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tip"), env.opts.autoescape);
output += "</div>\n    ";
;
}
output += "\n    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"familyUrl"), env.opts.autoescape);
output += "\" class=\"SearchResultProductItem-link\">\n        <span class=\"SearchResultProductItem-image\">\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"familyImage"), env.opts.autoescape);
output += "\" alt=\"\">\n        </span>\n        <span class=\"SearchResultProductItem-info\">\n                <span class=\"SearchResultProductItem-title s-title-medium\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"familyTitle"), env.opts.autoescape);
output += "</span>\n                <span class=\"SearchResultProductItem-description s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"familyDescription"), env.opts.autoescape);
output += "</span>\n            </span>\n        <span class=\"SearchResultProductItem-arrow\"></span>\n    </a>\n    <div class=\"SearchResultProductItem-productsWrapper\">\n        <div class=\"SearchResultProductItem-products\">\n            <div class=\"SearchResultProductItem-productsInner\">\n                ";
frame = frame.push();
var t_6 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"productList");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("product", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n                    <a class=\"SearchResultProductItem-productTitle s-title-small\"\n                       href=\"#product-";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"modifier"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\">\n                        <div class=\"SearchResultProductItem-productTitleWrapper\">\n                            <div class=\"SearchResultProductItem-productTitle--text\">";
output += runtime.suppressValue(runtime.memberLookup((t_7),"title"), env.opts.autoescape);
output += "</div>\n                            <div class=\"SearchResultProductItem-productTitle--count s-text-tiny\"> ";
output += runtime.suppressValue(env.getFilter("length").call(context, runtime.memberLookup((t_7),"optionList")), env.opts.autoescape);
output += "\n                                options\n                            </div>\n                        </div>\n                        <div class=\"SearchResultProductItem-productIcon\">\n                            <div class=\"SearchResultProductItem-productIconItem SearchResultProductItem-productIconItem_1\"></div>\n                            <div class=\"SearchResultProductItem-productIconItem SearchResultProductItem-productIconItem_2\"></div>\n                        </div>\n                    </a>\n                    <div id=\"product--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"modifier"), env.opts.autoescape);
output += "_";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\" class=\"SearchResultProductItem-options\">\n                        ";
frame = frame.push();
var t_10 = runtime.memberLookup((t_7),"optionList");
if(t_10) {t_10 = runtime.fromIterator(t_10);
var t_9 = t_10.length;
for(var t_8=0; t_8 < t_10.length; t_8++) {
var t_11 = t_10[t_8];
frame.set("option", t_11);
frame.set("loop.index", t_8 + 1);
frame.set("loop.index0", t_8);
frame.set("loop.revindex", t_9 - t_8);
frame.set("loop.revindex0", t_9 - t_8 - 1);
frame.set("loop.first", t_8 === 0);
frame.set("loop.last", t_8 === t_9 - 1);
frame.set("loop.length", t_9);
output += "\n                            <a class=\"SearchResultProductItem-optionId s-text-tiny\" data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((t_11),"url"), env.opts.autoescape);
output += "\"\n                               data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((t_11),"api_url"), env.opts.autoescape);
output += "\">\n                                ";
output += runtime.suppressValue(runtime.memberLookup((t_11),"id"), env.opts.autoescape);
output += " <span\n                                    class=\"SearchResultProductItem-optionName s-text-tiny\"> ";
output += runtime.suppressValue(runtime.memberLookup((t_11),"title"), env.opts.autoescape);
output += " </span>\n                            </a>\n                        ";
;
}
}
frame = frame.pop();
output += "\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n    </div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/popups/SearchResultPopup/SearchResultProductItem/SearchResultProductItem.html"] , dependencies)