'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const PerfectScrollbar = require('perfect-scrollbar').default;
const Checkbox = require('components/Common/Checkbox/Checkbox');

const ProductFamilyFilterMobile = require('components/ProductFamilyFilterMobile/ProductFamilyFilterMobile');

require('./ProductFamilyFilter.less');

const Selectors = {
    el: '.ProductFamilyFilter',
    filterItem: '.ProductFamilyFilter-filterItem',
    filterItemSelect: '.ProductFamilyFilter-filterItemSelect',
    filterItemSelectText: '.ProductFamilyFilter-filterItemSelectText',
    filterItemSelectMenuApply: '.ProductFamilyFilter-filterItemSelectMenuApply',
    filterClear: '.ProductFamilyFilter-filterClear',
    filterMore: '.ProductFamilyFilter-filterMore',
    filterActiveItem: '.ProductFamilyFilter-filterActiveItem',
    filterActiveItems: '.ProductFamilyFilter-filterActiveItems',
    filterActiveItemsWrapper: '.ProductFamilyFilter-filterActiveItemsWrapper',
    filterItemSelectMenu: '.ProductFamilyFilter-filterItemSelectMenu',
    filterItemSelectMenuInput: '.ProductFamilyFilter-filterItemSelectMenuInput',
    filterItemSelectMenuInputWrapper: '.ProductFamilyFilter-filterItemSelectMenuInputWrapper',
    filterItemSelectMenuInputNumber: '.ProductFamilyFilter-filterItemSelectMenuInputNumber',
    filterHideItems: '.ProductFamilyFilter-filterHideItems',
    filterWrapper: '.ProductFamilyFilter-filterWrapper',
    filterAccessories: '.Checkbox--productFamilyFilterAccessories'
};

const BindFunctions = [
    'disableAllBtns',
    'changeFilter',
    'clearFilter',
    'changeDesktopFilter',
    'processNumberFilterItem',
    'processCheckboxFilterItem',
    '_initChildComponents',
    '_initVariables',
    '_attachEvents',
    'remapFilterValues',
    '_initPerfectScroll',
    'changeAccessories'
];

const AccessoriesCheckboxModifier = 'productFamilyFilterAccessories';
const AccessoriesCheckboxName = 'IsAccessory';

const CssClasses = {
    isDisabled: 'isDisabled',
    isActive: 'isActive',
    isHidden: 'isHidden',
    isOpenFilters: 'isOpenFilters',
    filterClearHide: 'ProductFamilyFilter-filterClear--hide',
    filterActiveItem: 'ProductFamilyFilter-filterActiveItem'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.filterItemSelect}`]: this.toggleSelect,
            [`${ViewportEvents.click} ${Selectors.filterItemSelectMenuApply}`]: this.changeFilter,
            [`${ViewportEvents.click} ${Selectors.filterClear}`]: this.clearFilter,
            [`${ViewportEvents.click} ${Selectors.filterMore}`]: this.openAllFilters,
            [`${ViewportEvents.click} ${Selectors.filterActiveItem}`]: this.clearInputs,
            [`${ViewportEvents.change} ${Selectors.filterItemSelectMenuInput}`]: this.onChangeInput,
            [`${ViewportEvents.keyUp} ${Selectors.filterItemSelectMenuInputNumber}`]: this.onChangeInput
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initChildComponents();
        this._attachEvents();
        this._initPerfectScroll();
    },

    _initPerfectScroll: function () {
        if (!window.app.settings.isDesktop) {
            return;
        }
        this.$(Selectors.filterItemSelectMenuInputWrapper)
            .each((index, wrapper) => {
                const perfectScrollbar = new PerfectScrollbar(wrapper);
                perfectScrollbar.update();
            });
    },

    _attachEvents: function () {
        window.app.els.$body
            .on(ViewportEvents.click, this.clickOutSide.bind(this));

        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.mobileOnChange, this.changeFilter);
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.clearFilters, this.clearFilter);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isOpenSelect = false;
        this.isOpenFilters = false;
        this.accessoriesFilter = new Checkbox({
            modifier: AccessoriesCheckboxModifier,
            id: 0,
            name: AccessoriesCheckboxName,
            value: false,
            onModelChange: this.changeAccessories
        });

        this.accessoriesFilterLabel = this.accessoriesFilter.getLabelText();


        this.$hideFilters = this.$(Selectors.filterHideItems);
        this.$filterActiveItems = this.$(Selectors.filterActiveItems);
        this.$filterActiveItemsWrapper = this.$(Selectors.filterActiveItemsWrapper);

        this.$btnOpenFilters = this.$(Selectors.filterMore);

        this.valuesItems = [];
        this.$itemsActiveFilterCheckbox = [];
        this.$itemsActiveFilterNumber = [];
    },

    changeAccessories: function () {
        this.changeFilter();
    },

    getAccessoriesCheckboxState: function () {
        const isAccessoriesOnly = this.accessoriesFilter.getValue();
        if (!isAccessoriesOnly) {
            return null;
        }

        return {
            isAccessories: true,
            text: this.accessoriesFilter.getLabelText(),
            type: this.accessoriesFilter.getName(),
            value: this.accessoriesFilter.getValue()
        };
    },

    _initChildComponents: function () {
        this.productFamilyFilterMobile = new ProductFamilyFilterMobile({
            page: this,
            accessoriesFilter: this.accessoriesFilter
        });
    },

    onChangeInput: function (e) {
        const item = e.target;
        const $selectMenu = $(item)
            .closest(Selectors.filterItemSelectMenu);

        this.toggleValid($selectMenu);
    },

    toggleValid: function ($selectMenu) {
        if (!$selectMenu) {
            return;
        }
        let isValid = false;
        let valuesNumber = [];
        const $btnApply = $selectMenu.find(Selectors.filterItemSelectMenuApply);

        if ($selectMenu.find('input')[0].type === 'checkbox') {
            isValid = !!$selectMenu.find('input:checked').length;
        } else {
            $selectMenu.find('input')
                .each(function (id, input) {
                    valuesNumber.push($(input)
                        .val());
                });

            if (!!valuesNumber[0] && !!valuesNumber[1]) {
                isValid = (Number(valuesNumber[0]) < Number(valuesNumber[1]) ||
                    Number(valuesNumber[0]) === Number(valuesNumber[1]));
            } else {
                isValid = (!!valuesNumber[0] || !!valuesNumber[1]);
            }
        }

        $btnApply.toggleClass(CssClasses.isDisabled, !isValid);
    },

    disableAllBtns: function () {
        this.accessoriesFilter.setValue(false);
        this.$(Selectors.filterItemSelectMenuApply)
            .addClass(CssClasses.isDisabled);
    },

    openAllFilters: function () {
        this.isOpenFilters = !this.isOpenFilters;

        this.$hideFilters.toggleClass(CssClasses.isHidden);
        this.$btnOpenFilters.toggleClass(CssClasses.isOpenFilters);

        const $btnOpenFiltersSpan = this.$btnOpenFilters.find('span');
        const moreText = $btnOpenFiltersSpan.data('more-text');
        const lessText = $btnOpenFiltersSpan.data('less-text');
        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.openMore);
        if (this.isOpenFilters) {
            $btnOpenFiltersSpan
                .text(lessText);

            return;
        }

        $btnOpenFiltersSpan
            .text(moreText);
    },

    toggleSelect: function (e) {
        const $item = $(e.currentTarget);

        if (!$(e.target)
            .closest(Selectors.filterItemSelectMenu).length) {
            if (!$item.closest(Selectors.filterItemSelect)
                .hasClass(CssClasses.isActive)) {
                this.closeAllSelect();
                this.isOpenSelect = !this.isOpenSelect;
            } else {
                this.isOpenSelect = false;
            }

            $item.toggleClass(CssClasses.isActive, this.isOpenSelect);
        }
    },

    clickOutSide: function (e) {
        const elSelect = this.$(Selectors.filterItemSelect);

        //check if the clicked area is dropDown or not
        if (elSelect.has(e.target).length === 0) {
            this.closeAllSelect();
        }
    },

    closeAllSelect: function () {
        this.isOpenSelect = false;
        this.$(`${Selectors.filterItemSelect}.${CssClasses.isActive}`)
            .removeClass(CssClasses.isActive);
    },

    changeDesktopFilter: function () {
        const self = this;

        this.$(`${Selectors.filterItemSelectMenu} input`)
            .each(function (id, input) {
                const text = $(input)
                    .data('text');
                const inputValue = $(input)
                    .val();
                const value = $(input)
                    .data('value');
                const type = $(input)
                    .data('type');
                const range = $(input)
                    .data('range');
                if (input.type === 'checkbox') {
                    if (input.checked) {
                        self.valuesItems.push({
                            title: $(input)
                                .closest(Selectors.filterItem)
                                .find(`${Selectors.filterItemSelectText} p`)
                                .text()
                                .trim(),
                            type,
                            text,
                            value,
                            range,
                            isCheckbox: true
                        });
                    }
                } else {
                    if (!!inputValue) {
                        self.valuesItems.push({
                            title: $(input)
                                .closest(Selectors.filterItem)
                                .find(`${Selectors.filterItemSelectText} p`)
                                .text()
                                .trim(),
                            type,
                            value: inputValue,
                            text: inputValue,
                            range,
                            isCheckbox: false
                        });
                    }
                }
            });
    },

    remapFilterValues: function (values = []) {
        return _.groupBy(values.map(value => {
            return {
                type: value.type,
                value: value.value,
                text: value.text,
                range: value.range
            };
        }), (val) => {
            return val.type;
        });
    },

    processCheckboxFilterItem: function (index) {
        const checkboxFilterLabel = document.createElement('div');
        checkboxFilterLabel.className = CssClasses.filterActiveItem;
        checkboxFilterLabel.dataset.value = this.valuesItems[index].value;
        checkboxFilterLabel.dataset.type = this.valuesItems[index].type;
        checkboxFilterLabel.dataset.text = this.valuesItems[index].text;
        checkboxFilterLabel.innerHTML =
            '<span>' + this.valuesItems[index].title + ':</span>&nbsp;' + this.valuesItems[index].text + '' +
            '<div class="ProductFamilyFilter-filterActiveItemIcon"></div>';

        this.$filterActiveItemsWrapper.append(checkboxFilterLabel);
        this.$itemsActiveFilterCheckbox.push(checkboxFilterLabel);
    },

    processAccessoriesFilterItem: function (value, type, text) {
        const accessoriesLabel = document.createElement('div');
        accessoriesLabel.className = CssClasses.filterActiveItem;
        accessoriesLabel.dataset.value = value;
        accessoriesLabel.dataset.type = type;
        accessoriesLabel.dataset.text = text;
        accessoriesLabel.innerHTML =
            '<span>' + text + '</span>' +
            '<div class="ProductFamilyFilter-filterActiveItemIcon"></div>';

        $(accessoriesLabel)
            .addClass(CssClasses.filterActiveItem);

        this.$filterActiveItemsWrapper.append(accessoriesLabel);
        this.$itemsActiveFilterCheckbox.push(accessoriesLabel);
        this.$filterActiveItems.toggleClass(CssClasses.isHidden, false);
    },

    processNumberFilterItem: function (index) {
        if (!this.isNextExclude) {
            if (index !== this.valuesItems.length - 1 &&
                (this.valuesItems[index].type === this.valuesItems[index + 1].type)) {
                this.isNextExclude = true;
                this.vMin = this.valuesItems[index].text;
                this.vMax = this.valuesItems[index + 1].text;
                this.text = (this.vMin === this.vMax) ? this.vMin : (this.vMin + ' - ' + this.vMax);
            } else {
                this.text = (this.valuesItems[index].range === 'max') ? '<' : '>';
                this.text += this.valuesItems[index].text;
            }

            const numberFilterLabel = document.createElement('div');
            numberFilterLabel.className = CssClasses.filterActiveItem;
            numberFilterLabel.dataset.value = this.valuesItems[index].value;
            numberFilterLabel.dataset.type = this.valuesItems[index].type;
            numberFilterLabel.dataset.valueMin = this.vMin;
            numberFilterLabel.dataset.valueMax = this.vMax;
            numberFilterLabel.innerHTML =
                '<span>' + this.valuesItems[index].title + ':</span>&nbsp;' + this.text +
                '<div class="ProductFamilyFilter-filterActiveItemIcon"></div>';

            this.$filterActiveItemsWrapper.append(numberFilterLabel);
            this.$itemsActiveFilterNumber.push(numberFilterLabel);
        } else {
            this.isNextExclude = false;
        }
    },

    changeFilter: function (valItems) {
        const isFromMobile = Array.isArray(valItems);
        const isEmptyFromMobile = (isFromMobile) ? !valItems.length : true;

        this.valuesItems = (isFromMobile) ? valItems : [];
        this.$itemsActiveFilterCheckbox = [];
        this.$itemsActiveFilterNumber = [];
        this.$filterActiveItemsWrapper.html('');

        const checkboxValue = this.getAccessoriesCheckboxState();

        if (!isFromMobile || isEmptyFromMobile) {
            this.changeDesktopFilter();
        }

        if (checkboxValue) {
            this.valuesItems.push(checkboxValue);
        }

        this.isNextExclude = false;
        this.text = '';
        this.vMin = undefined;
        this.vMax = undefined;

        this.valuesItems.forEach((item, index) => {
            if (item.isCheckbox) {
                this.processCheckboxFilterItem(index);
            } else if (item.isAccessories) {
                this.processAccessoriesFilterItem(item.value, item.type, item.text);
            } else {
                this.processNumberFilterItem(index);
            }
        });

        this.$(Selectors.filterClear)
            .toggleClass(CssClasses.filterClearHide, (!this.valuesItems.length));

        this.$filterActiveItems.toggleClass(CssClasses.isHidden, (!this.valuesItems.length));

        this.closeAllSelect();

        const remappedFilterValues = this.remapFilterValues(this.valuesItems);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.onFilter, remappedFilterValues);
    },

    clearFilter: function () {
        this.clearInputs();

        this.$(Selectors.filterClear)
            .addClass(CssClasses.filterClearHide);

        const remappedFilterValues = this.remapFilterValues(this.valuesItems);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.clearFilters, remappedFilterValues);
    },

    clearInputs: function (event) {
        let isOneItem = false;
        let selectMenu;

        if (!!event) {
            isOneItem = true;
        }

        const $item = (isOneItem) ? $(event.currentTarget) : null;

        const itemType = this.getItemType($item);
        const itemValue = this.getItemValue($item);

        this.valuesItems = [];
        this.$itemsActiveFilterCheckbox = [];
        this.$itemsActiveFilterNumber = [];
        const isAccessories = itemType === this.accessoriesFilter.getName();

        if (isAccessories) {
            this.accessoriesFilter.setValue(false);
        } else {
            this.$('input')
                .each(function (id, input) {
                    const inputType = $(input)
                        .data('type');
                    const inputValue = $(input)
                        .data('value');

                    const needDelete = this.getNeedDeleteInput({
                        inputType, itemType, inputValue,
                        itemValue, input, isOneItem
                    });

                    selectMenu = $(input)
                        .closest(Selectors.filterItemSelectMenu);

                    if (!needDelete) {
                        return;
                    }

                    if (input.type === 'checkbox') {
                        needDelete.checked = false;
                    } else {
                        $(needDelete)
                            .val('');
                    }
                }.bind(this));
        }

        this.$filterActiveItemsWrapper.html('');
        this.$filterActiveItems.addClass(CssClasses.isHidden);

        if (isOneItem) {
            this.changeFilter();
            if (isAccessories) {
                app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.disableMobileAccessories);
            } else {
                this.toggleValid(selectMenu);
                app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.mobileChange);
            }
        } else {
            this.disableAllBtns();
        }

        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.toggleValidMobile);
    },

    getNeedDeleteInput: function (data = {}/*inputType, itemType, inputValue, itemValue, input, isOneItem*/) {
        return !data.isOneItem || (data.inputType === data.itemType && data.inputValue === data.itemValue)
            ? data.input : undefined;
    },

    getItemType($item) {
        return $item && $item.data('type');
    },

    getItemValue($item) {
        return $item && $item.data('value');
    }
});
