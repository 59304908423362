'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

const ItemTemplate = require('./ProductFamilyAccesoriesItem.html');

require('./ProductFamilyAccessories.less');

const BindFunctions = [
    'openPopup',
    'loadMore'
];

const TransitionDelayCoefficient = 0.1;

const Selectors = {
    item: '.ProductFamilyAccessories-item',
    showMore: '.ProductFamilyAccessories-showMoreText',
    showMoreButton: '.ProductFamilyAccessories-showMore',
    showMoreCount: '.ProductFamilyAccessories-showMoreCount',
    itemsWrapper: '.ProductFamilyAccessories-contentBlock'
};

module.exports = Backbone.View.extend({

    el: '.ProductFamilyAccessories',

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.openPopup,
            [`${ViewportEvents.click} ${Selectors.showMore}`]: this.loadMore
        };
    },

    initialize: function () {
        _.bindAll(this, BindFunctions);
    },

    openPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.item);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.productFamily
            + Events.popup.productDetailsPopupPrefix + Events.popup.popupOpen, {apiUrl, url});
    },

    loadMore: function (event) {
        const self = this;
        const $showMoreButton = $(event.target)
            .closest(Selectors.showMoreButton);
        const apiUrl = $showMoreButton.data('api-url');
        const offset = this.$(Selectors.item).length;

        const data = {
            offset
        };

        $.getJSON(apiUrl, data, (res) => {
            if (!res) {
                return;
            }
            const items = res.items;
            const moreCount = res.more;

            items.forEach((item, index) => {
                const newItem = ItemTemplate.render({item: item});
                self.$(Selectors.itemsWrapper)
                    .append(newItem);
                self.$(`${Selectors.item}:last`)
                    .css('transition-delay', `${index * TransitionDelayCoefficient}s`);
            });

            setTimeout(() => {
                self.$(`${Selectors.item}`)
                    .removeClass(Constants.cssClasses.hide);
            });

            if (moreCount) {
                self.$(Selectors.showMoreCount).text(`(${moreCount})`);

                return;
            }

            self.$(Selectors.showMore)
                .hide();
        });
    }
});
