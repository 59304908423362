'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const scrollLock = require('scroll-lock');

require('./RegionLanguagePopup.less');

const Selectors = {
    el: '.RegionLanguagePopup',
    bg: '.RegionLanguagePopup-bg',
    close: '.RegionLanguagePopup-close',
    inputWrapper: '.RegionLanguagePopup-inputWrapper',
    visitButton: '.RegionLanguagePopup-visitButton',
    accordionSectionContentLink: '.RegionLanguagePopup-accordionSectionContentLink',
    filterSelectRegion: '.RegionLanguagePopup-selectRegion',
    filterSelectLang: '.RegionLanguagePopup-selectLanguage',
    filterRegionItemSelect: '.RegionLanguagePopup-filterRegionItemSelect',
    filterRegionItemSelectTag: '.RegionLanguagePopup-filterRegionItemSelectTag',
    filterRegionItemSelectText: '.RegionLanguagePopup-filterRegionItemSelectText',
    filterRegionItemSelectTextP: '.RegionLanguagePopup-filterRegionItemSelectText p',
    filterRegionItemSelectMenu: '.RegionLanguagePopup-filterRegionItemSelectMenu',
    filterRegionItemSelectMenuVal: '.RegionLanguagePopup-filterRegionItemSelectMenuVal',
    filterRegionItemSelectMenuValFirstChild: '.RegionLanguagePopup-filterRegionItemSelectMenuVal:first-child',
    filterRegionItemSelectMenuValActive:
        `.RegionLanguagePopup-filterRegionItemSelectMenuVal--${Constants.cssClasses.active}`,
    filterLanguageItemSelect: '.RegionLanguagePopup-filterLanguageItemSelect',
    filterLanguageItemSelectTag: '.RegionLanguagePopup-filterLanguageItemSelectTag',
    filterLanguageItemSelectText: '.RegionLanguagePopup-filterLanguageItemSelectText',
    filterLanguageItemSelectTextP: '.RegionLanguagePopup-filterLanguageItemSelectText p',
    filterLanguageItemSelectMenu: '.RegionLanguagePopup-filterLanguageItemSelectMenu',
    filterLanguageItemSelectMenuVal: '.RegionLanguagePopup-filterLanguageItemSelectMenuVal',
    filterLanguageItemSelectMenuValFirstChild: '.RegionLanguagePopup-filterLanguageItemSelectMenuVal:first-child',
    filterLanguageItemSelectMenuValActive:
        `.RegionLanguagePopup-filterLanguageItemSelectMenuVal--${Constants.cssClasses.active}`
};

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    'getDataFromApi',
    'clickOutside',
    '_initSelect',
    '_initVariables',
    '_attachEvents',
    'changeLanguageFilter',
    'changeRegionFilter',
    'onSelectOpen',
    'triggerPopupHidden',
    'triggerPopupShown'
];

const CssClasses = {
    filterRegionItemSelectMenuValActive:
        `RegionLanguagePopup-filterRegionItemSelectMenuVal--${Constants.cssClasses.active}`,
    filterLanguageItemSelectMenuValActive:
        `RegionLanguagePopup-filterLanguageItemSelectMenuVal--${Constants.cssClasses.active}`,
    popupHide: 'RegionLanguagePopup--hide'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.accordionSectionContentLink}`]: this.onMenuItemClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);

        $(Selectors.visitButton)
            .addClass(Constants.cssClasses.hide);

        this._initSelect();

        this._attachEvents();

        this.getDataFromApi();
    },

    _attachEvents: function () {
        app.els.$body.click(this.clickOutside);
        app.vent.on(Events.eventPrefixes.regionLang + Events.popup.popupOpen, this.show);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isOpenSelectRegion = false;
        this.isOpenSelectLanguage = false;
        this.currentReg = this.$(Selectors.filterRegionItemSelectText)
            .attr('data-default');
        this.currentLang = this.$(Selectors.filterLanguageItemSelectText)
            .attr('data-default');
        this.popupWrapper = Selectors.inputWrapper;
    },

    _initSelect: function () {
        this.dropdowns = [];
        this.dropdowns.push(new Dropdown({
            modifier: 'regionLanguagePopup',
            id: 0,
            onChooseOption: this.changeRegionFilter,
            onSelectOpen: this.onSelectOpen
        }));

        this.dropdowns.push(new Dropdown({
            modifier: 'regionLanguagePopup',
            id: 1,
            onChooseOption: this.changeLanguageFilter,
            onSelectOpen: this.onSelectOpen
        }));
    },

    onSelectOpen: function (dropdownId) {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown.id !== dropdownId) {
                dropdown.close();
            }
        });
    },

    onMenuItemClick: function (e) {
        this.$(Selectors.accordionSectionContentLink)
            .removeClass(Constants.cssClasses.active);
        $(e.target)
            .addClass(Constants.cssClasses.active);
    },

    clickOutside: function (e) {
        const $target = $(e.target);

        if (!$target.closest(`${Constants.selectors.dropdownSelect}.${Constants.cssClasses.active}`).length) {
            this.dropdowns.forEach((dropdown) => {
                dropdown.close();
            });
        }
    },

    changeRegionFilter: function (dropdownId, idItem, typeItem, $item) {
        const textItem = $item.data('text');

        if (this.currentReg !== textItem) {
            $(Selectors.visitButton)
                .removeClass(Constants.cssClasses.hide);
        }
        this.currentReg = textItem;

        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, idItem, typeItem);

        this.closeAllSelect();
    },

    changeLanguageFilter: function (dropdownId, idItem, typeItem, $item) {
        const textItem = $item.data('text');

        if (this.currentLang !== textItem) {
            $(Selectors.visitButton)
                .removeClass(Constants.cssClasses.hide);
        }
        this.currentLang = textItem;

        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, idItem, typeItem);

        this.closeAllSelect();
    },

    closeAllSelect: function () {
        this.dropdowns.forEach((dropdown) => {
            dropdown.close();
        });
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },

    show: function () {
        this.triggerPopupShown();

        scrollLock.addScrollableSelector(Selectors.inputWrapper);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.mobileOpen);
        this.$el.removeClass(CssClasses.popupHide);
        window.app.els.$window
            .on(ViewportEvents.keyDown, this.onKeydown);
    },


    hide: function () {
        if (window.app.isPhoneLayout()) {
            this.triggerPopupHidden();
        } else {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', this.triggerPopupHidden, 300);
        }
        scrollLock.removeScrollableSelector(Selectors.inputWrapper);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.mobileClose);
        this.$el.addClass(CssClasses.popupHide);
        $(Selectors.visitButton)
            .addClass(Constants.cssClasses.hide);
        window.app.els.$window
            .off(ViewportEvents.keyDown, this.onKeydown);
    },

    render: function (data) {
        console.log(data); // data will be used for render popup template in runtime. Console.log is just to
        // avoid linter errors and to show that data parameter will be passed into render function
    },

    getDataFromApi: function () {
        // function to get data from API when popup is rendering in runtime
        // must be overrided


        // render method must be call after async request completed
        const data = {};
        this.render(data);
    },

    onCloseClick: function (e) {
        // to prevent form submission default action
        e.preventDefault();
        this.hide();
    },

    triggerPopupShown: function () {
        this.savedScroll = $(window)
            .scrollTop();

        if (window.app.isPhoneLayout()) {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', () => {
                app.vent.trigger(Events.popup.popupShown);
            }, 300);
        } else {
            app.vent.trigger(Events.popup.popupShown);
        }

        window.app.els.$body
            .addClass(Constants.cssClasses.overlayShow);
    },

    triggerPopupHidden: function () {
        app.vent.trigger(Events.popup.popupHidden);
        window.app.els.$body
            .removeClass(Constants.cssClasses.overlayShow);
        window.requestAnimationFrame(() => {
            window.app.els.$window
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });
    }
});
