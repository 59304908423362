'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
require('slick-carousel');
require('slick-carousel/slick/slick.less');

require('./ProductFamilyFeatures.less');

const Selectors = {
    el: '.ProductFamilyFeatures',
    arrowLeft: '.ProductFamilyFeatures-arrowLeft',
    arrowRight: '.ProductFamilyFeatures-arrowRight',
    wrapperInner: '.ProductFamilyFeatures-wrapperInner',
    navLine: '.ProductFamilyFeatures-navLine',
    feature: '.ProductFamilyFeatures-feature',
    featureContent: '.ProductFamilyFeatures-featureContent'
};

const BindFunctions = [
    '_initSlick',
    'onRightArrowClick',
    'onLeftArrowClick',
    '_setTheme',
    'onNavLineClick',
    '_setInitialNavClasses',
    '_attachSlickEvents'
];

const MobileTransitionSpeed = 300;
const DesktopTransitionSpeed = 0;
const TextColorAttribute = 'text-color';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowLeft}`]: this.onLeftArrowClick,
            [`${ViewportEvents.click} ${Selectors.arrowRight}`]: this.onRightArrowClick,
            [`${ViewportEvents.click} ${Selectors.navLine}`]: this.onNavLineClick
        };
    },

    initialize: function () {
        _.bindAll(this, BindFunctions);
        this.rightArrowClicked = false;
        this.isMobile = window.app.settings.isMobile;
        this.transitionSpeed = this.isMobile ? MobileTransitionSpeed : DesktopTransitionSpeed;
        this.slickFade = !this.isMobile;
        this.$wrapperInner = this.$(Selectors.wrapperInner);
        this._initSlick();
        this._setInitialNavClasses();
    },

    _initSlick: function () {
        this.$wrapperInner
            .slick({
                infinite: true,
                arrows: true,
                prevArrow: false,
                nextArrow: false,
                fade: this.fade,
                speed: this.transitionSpeed,
                draggable: false
            });

        this._attachSlickEvents();
    },

    _attachSlickEvents: function () {
        this.$wrapperInner
            .on(Constants.slickEvents.beforeChange, (event, slick, currentSlide, nextSlide) => {
                const $nextSlide = $(slick.$slides.get(nextSlide));
                const $currentSlide = $(slick.$slides.get(currentSlide));

                const textColorCurrent = $currentSlide
                    .find(Selectors.feature)
                    .data(TextColorAttribute);
                const textColorNext = $nextSlide
                    .find(Selectors.feature)
                    .data(TextColorAttribute);

                this._setTheme(textColorNext, textColorCurrent);
                this._setActiveNavLine(nextSlide);

                if (this.isMobile) {
                    return;
                }

                $nextSlide
                    .removeClass(Constants.slickCustomClasses.currentSlide);

                if (this.rightArrowClicked) {
                    $nextSlide
                        .addClass(Constants.slickCustomClasses.nextSlide);
                } else {
                    $nextSlide
                        .addClass(Constants.slickCustomClasses.previousSlide);
                }
            })
            .on(Constants.slickEvents.afterChange, (event, slick, currentSlide) => {
                this.rightArrowClicked = false;
                $(slick.$slides.get(currentSlide))
                    .removeClass(Constants.slickCustomClasses.nextSlide)
                    .addClass(Constants.slickCustomClasses.currentSlide)
                    .removeClass(Constants.slickCustomClasses.previousSlide);
            });
    },

    _setInitialNavClasses: function () {
        const initialSlideIndex = this.$wrapperInner
            .slick(Constants.slickMethods.currentSlide);

        const $initialSlide = $(this.$wrapperInner
            .slick(Constants.slickMethods.getSlick)
            .$slides
            .get(initialSlideIndex));
        const initialSlideTextColor = $initialSlide
            .find(Selectors.feature)
            .data(TextColorAttribute);
        this._setTheme(initialSlideTextColor);
        this._setActiveNavLine(initialSlideIndex);
    },

    _setActiveNavLine: function (slideNumber) {
        this.$(Selectors.navLine)
            .removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.navLine}[data-id="${slideNumber}"]`)
            .addClass(Constants.cssClasses.active);
    },

    _setTheme: function (textColorNext, textColorCurrent) {
        const $navElements = this.$(`${Selectors.arrowLeft}, ${Selectors.arrowRight}, ${Selectors.navLine}`);
        if (textColorCurrent) {
            $navElements.removeClass(textColorCurrent);
        }
        $navElements
            .addClass(textColorNext);
    },

    onLeftArrowClick: function () {
        this.rightArrowClicked = false;
        this.$wrapperInner
            .slick(Constants.slickMethods.goPrev);
    },

    onRightArrowClick: function () {
        this.rightArrowClicked = true;
        this.$wrapperInner
            .slick(Constants.slickMethods.goNext);
    },

    onNavLineClick: function (event) {
        const navLineId = $(event.target)
            .closest(Selectors.navLine)
            .data('id');

        this.$(Selectors.wrapperInner)
            .slick(Constants.slickMethods.goTo, navLineId);
    }
});
