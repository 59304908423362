'use strict';

const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

require('./RapidluxCalculator.less');

const ProductQuickCalcSelectors = {
    arrowBack: '.RapidluxCalculator-arrowBack'
};

module.exports = Backbone.View.extend({

    el: '.RapidluxCalculator',

    events: function () {
        return {
            [`${ViewportEvents.click} ${ProductQuickCalcSelectors.arrowBack}`]: this.closeOverlay
        };
    },

    initialize: function (options = {}) {
        this.options = options;
    },

    closeOverlay: function () {
        app.vent.trigger(Events.popup.popupHidden);
        app.vent.trigger(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose);
    }
});
