'use strict';

const Backbone = require('backbone');
const _ = require('underscore');
const { UnsupportedValueError } = require('errors');

require('./CurrentSubscriptions.less');
const Trigger = require('components/Common/Trigger/Trigger');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const Checkbox = require('components/Common/Checkbox/Checkbox');

const Selectors = {
    el: '.CurrentSubscriptions',
    subscriptions: '.CurrentSubscriptions-subscription'
};

const BindFunctions = [
    '_initChildComponents',
    '_addToModel',
    'onLanguageChange',
    'onTriggerChange',
    'onUnsubscribeChange',
    '_disableAllSubscriptions',
    '_resetSubscriptionsToDefault',
    'modelToView'
];

const Modifier = 'current-subscriptions';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.page = options.page;
        this.model = {};
        this._initChildComponents();
        this.unsubscribeCheckbox = new Checkbox({
            modifier: Modifier,
            id: 0,
            value: false,
            onModelChange: this.onUnsubscribeChange
        });
        this.checkChanges();
    },

    _initChildComponents: function () {
        this.$(Selectors.subscriptions)
            .each((index, subscription) => {
                const $subscription = $(subscription);
                const name = $subscription.data('name');
                const languageName = $subscription.data('language-name');
                const defaultValues = {
                    language: $subscription.data('default-language'),
                    checked: $subscription.data('subscribed')
                };

                const trigger = new Trigger({
                    onModelChange: this.onTriggerChange,
                    name,
                    value: defaultValues.checked,
                    modifier: Modifier,
                    id: index
                });

                const dropdown = new Dropdown({
                    modifier: Modifier,
                    id: index,
                    value: defaultValues.language,
                    onChooseOption: this.onLanguageChange
                });

                this._addToModel({ name, languageName, trigger, dropdown, defaultValues });
            });
    },

    _addToModel: function ({ name, languageName, trigger, dropdown, defaultValues }) {
        this.model[name] = {
            languageName,
            trigger,
            dropdown,
            values: {...defaultValues},
            defaultValues
        };
    },

    onLanguageChange(id, idItem, itemType) {
        if (!itemType) {
            throw new UnsupportedValueError(idItem);
        }
        this.model[itemType].values.language = idItem;
        this.checkChanges();
    },

    onTriggerChange(name, value) {
        this.model[name].values.checked = value;
        this.checkChanges();
    },

    checkChanges: function () {
        const wasModelChanged = Object.values(this.model)
            .reduce((changed, modelValue) => {
                if (changed) {
                    return true;
                }

                const {defaultValues, values} = modelValue;

                return (values.language !== defaultValues.language)
                    || (values.checked !== defaultValues.checked);
            }, false);
        wasModelChanged && this.page.onChange();
    },

    _disableAllSubscriptions: function () {
        Object.values(this.model)
            .forEach((model) => {
                model.values.checked = false;
            });
    },

    _resetSubscriptionsToDefault: function () {
        Object.values(this.model)
            .forEach((model) => {
                model.values.checked = model.defaultValues.checked;
            });
    },

    modelToView: function () {
        Object.values(this.model)
            .forEach((model) => {
                model.trigger.setValue(model.values.checked);
            });
    },

    onUnsubscribeChange: function (name, value) {
        if (value) {
            this._disableAllSubscriptions();
        } else {
            this._resetSubscriptionsToDefault();
        }

        this.modelToView();
        this.checkChanges();
    },

    getValues() {
        return Object.entries(this.model)
            .reduce((res, [subscriptionName, data]) => {
                return Object.assign(res, {
                    [subscriptionName]: data.values.checked,
                    [data.languageName]: data.values.language
                });
            }, {});
    }
});
