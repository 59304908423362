'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore/underscore.js');

const ProductsCategory = require('components/ProductsCategory/ProductsCategory');

require('./ProductsSections.less');

const Selectors = {
    el: '.ProductsSections',
    sections: '.ProductsSections-sections',
    sectionsFiltered: '.ProductsSections-sectionsFiltered',
    sectionsFilteredItemsNo: '.ProductsSections-sectionsFilteredItemsNo',
    sectionsFilteredItems: '.ProductsSections-sectionsFilteredItems',
    productsCategory: '.ProductsCategory',
    productsCategoryItem: '.ProductsCategory-item',
    filtersItemNoClear: '.ProductsSections-sectionsFilteredItemsNo-clear',
    productsItem: '.ProductsItem',
    mountingImage: '.ProductsItem-mountingImage',
    mainImage: '.ProductsItem-imgInner'
};

const BindFunctions = [
    'onFilter',
    '_initChildComponents',
    '_initVariables',
    '_attachEvents',
    'cloneProductsItems'
];

const CssClasses = {
    isInitialState: 'isInitialState'
};

const DataAttributeSplitSymbol = ';';
const MountingType = 'mounting';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.filtersItemNoClear}`]: this.clearFilters
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initChildComponents();
        this.cloneProductsItems();
        this._attachEvents();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.products + Events.filter.onFilter, this.onFilter);
    },

    cloneProductsItems: function () {
        this.allItems = [];
        this.$(Selectors.productsCategoryItem)
            .each(function (id, item) {
                this.allItems.push($(item)
                    .clone());
            }.bind(this));
    },

    _initChildComponents: function () {
        this.$(Selectors.productsCategory)
            .each(function (id, item) {
                new ProductsCategory({
                    el: $(item)
                });
            }.bind(this));
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$sections = $(Selectors.sections);
        this.$sectionsFiltered = $(Selectors.sectionsFiltered);
        this.$sectionsFilteredItemsNo = $(Selectors.sectionsFilteredItemsNo);
        this.$sectionsFilteredItems = $(Selectors.sectionsFilteredItems);

        this.allItems = [];
    },

    clearFilters: function () {
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.clearFilters);
    },

    onFilter: function (idItem, typeItem) {
        let countItems = -1;
        this.filteredItems = [];
        this.$sectionsFilteredItems.html('');
        this.cloneProductsItems();
        const $mainImage = this.$(Selectors.mainImage);
        $mainImage.removeClass(Constants.cssClasses.hide);

        if (idItem === null) {
            this.$sections.removeClass(Constants.cssClasses.hide);
            this.$sectionsFiltered.addClass(Constants.cssClasses.hide);
        } else {
            this.$sectionsFiltered.addClass(CssClasses.isInitialState);
            for (let i = 0; i < this.allItems.length; i++) {
                const $filterItem = this.allItems[i].find(Selectors.productsItem);
                const filterDataAttr = $filterItem.data(typeItem);
                if (!filterDataAttr) {
                    continue;
                }
                const data = filterDataAttr.toString()
                    .split(DataAttributeSplitSymbol);

                const result = data.filter((item) => {
                    return item === idItem.toString();
                });

                if (!result.length) {
                    continue;
                }

                this.filteredItems.push(this.allItems[i]);
            }

            // make items unique by product family unique id
            this.filteredItems = this.filteredItems.filter((filteredItem, index, self) => {
                const filteredItemId = filteredItem.data('id');

                return index === self.map(item => item.data('id'))
                    .indexOf(filteredItemId);
            });

            this.filteredItems.forEach(($item, index) => {
                $item.css('transition', 0.05 + 's' + ' opacity ' + (index * 0.05) + 's ease-out');
            });

            for (let i = 0; i < this.filteredItems.length; i++) {
                this.$sectionsFilteredItems.append(this.filteredItems[i]);
                this.filteredItems[i].find(Selectors.mountingImage)
                    .removeClass(Constants.cssClasses.show);
                const $mountingImage = this.filteredItems[i]
                    .find(`${Selectors.mountingImage}[data-mounting-id=${idItem}]`);
                const $mainItemImage = this.filteredItems[i]
                    .find(Selectors.mainImage);

                this.setMountingImage($mountingImage, typeItem, $mainItemImage);
            }

            this.$sections.addClass(Constants.cssClasses.hide);
            this.$sectionsFiltered.removeClass(Constants.cssClasses.hide);
            countItems = this.filteredItems.length;
        }

        this.$sectionsFiltered.innerHeight();
        this.$sectionsFiltered.removeClass(CssClasses.isInitialState);
        this.$sectionsFilteredItemsNo.toggleClass(Constants.cssClasses.hide, !!countItems);

        app.vent.trigger(Events.eventPrefixes.products + Events.filter.filtered, countItems);
    },

    setMountingImage: function ($mountingImage, typeItem, $mainItemImage) {
        if ($mountingImage.length && typeItem === MountingType) {
            $mountingImage.addClass(Constants.cssClasses.show);
            $mainItemImage.addClass(Constants.cssClasses.hide);
        } else {
            $mainItemImage.removeClass(Constants.cssClasses.hide);
        }
    }
});
