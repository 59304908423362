'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');

require('./ProductFamilyFilterMobile.less');

const Selectors = {
    el: '.ProductFamilyFilterMobile',
    itemSelect: '.ProductFamilyFilterMobile-itemSelect',
    itemSelectText: '.ProductFamilyFilterMobile-itemSelectText',
    popupFilterApply: '.ProductFamilyFilterMobile-popupFilterApply',
    popupFilterClear: '.ProductFamilyFilterMobile-popupFilterClear',
    popupFilterItem: '.ProductFamilyFilterMobile-popupFilterItem',
    popupFilterItemSelectText: '.ProductFamilyFilterMobile-popupFilterItemSelectText',
    popupFilterItemSelectMenu: '.ProductFamilyFilterMobile-popupFilterItemSelectMenu',
    popupFilterItemSelectMenuInput: '.ProductFamilyFilterMobile-popupFilterItemSelectMenuInput',
    popupFilterItemSelectMenuInputNumber: '.ProductFamilyFilterMobile-popupFilterItemSelectMenuInputNumber',
    popup: '.ProductFamilyFilterMobile-popup',
    popupBackdrop: '.ProductFamilyFilterMobile-popupBackdrop'
};

const BindFunctions = [
    'closeAllFilterItemMobile',
    'toggleValidMobile',
    'changeFilterMobile',
    '_initVariables',
    '_attachEvents',
    'disableMobileAccessories'
];

const CssClasses = {
    isOpenFilterItem: 'isOpenFilterItem',
    popupHide: 'ProductFamilyFilterMobile-popup--hide',
    isDisabled: 'isDisabled'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.itemSelect}`]: this.openSelectMobile,
            [`${ViewportEvents.click} ${Selectors.popupFilterApply}`]: this.changeFilterMobile,
            [`${ViewportEvents.click} ${Selectors.popupFilterClear}`]: this.clearAllFilter,
            [`${ViewportEvents.click} ${Selectors.popupFilterItem}`]: this.toggleFilterItemMobile,
            [`${ViewportEvents.change} ${Selectors.popupFilterItemSelectMenuInput}`]: this.toggleValidMobile,
            [`${ViewportEvents.keyUp} ${Selectors.popupFilterItemSelectMenuInputNumber}`]: this.toggleValidMobile
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._attachEvents();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.mobileChange, this.changeFilterMobile);
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.toggleValidMobile, this.toggleValidMobile);
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.disableMobileAccessories,
            this.disableMobileAccessories);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$popupSelect = this.$(Selectors.popup);
        this.$popupBackdrop = this.$(Selectors.popupBackdrop);

        this.valuesItemsMobile = [];
        this.$itemsActiveFilterCheckbox = [];
        this.$itemsActiveFilterNumber = [];
        this.isFiltersValid = true;
        this.invalidFields = [];

        this.accessoriesFilter = options.accessoriesFilter;
    },

    toggleFilterItemMobile: function (e) {
        const $item = $(e.currentTarget);
        if (!$(e.target)
            .closest(Selectors.popupFilterItemSelectMenu).length) {
            $item.toggleClass(CssClasses.isOpenFilterItem);
        }
    },

    closeAllFilterItemMobile: function () {
        this.$(Selectors.popupFilterItem)
            .removeClass(CssClasses.isOpenFilterItem);
    },

    clearAllFilter: function () {
        this.$(Selectors.itemSelectText)
            .text('Filters');
        this.closeSelectMobileNoPopup();
        app.vent.trigger(Events.popup.showCloseButton);
        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.clearFilters);
    },

    openSelectMobile: function () {
        this.$popupSelect.removeClass(CssClasses.popupHide);
        this.$popupBackdrop.removeClass(CssClasses.popupHide);
        this.$(Selectors.popup)
            .scrollTop(0);
        setTimeout(function () {
            app.vent.trigger(Events.popup.hideCloseButton);
            app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.mobileOpen);
        }, 100);
    },

    closeSelectMobile: function () {
        this.$popupSelect.addClass(CssClasses.popupHide);
        this.$popupBackdrop.addClass(CssClasses.popupHide);
        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filters.mobileClose);

        this.closeAllFilterItemMobile();
    },

    closeSelectMobileNoPopup: function () {
        this.$popupSelect.addClass(CssClasses.popupHide);
        this.$popupBackdrop.addClass(CssClasses.popupHide);
        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.mobileClose);

        this.closeAllFilterItemMobile();
    },

    toggleValidMobile: function () {
        let isValid = true;
        this.invalidFields = [];
        let valuesNumber = [];
        const $filterPopupItems = this.$(Selectors.popupFilterItem);
        const $btnClear = this.$(Selectors.popupFilterClear);

        const self = this;

        $filterPopupItems.each(function (id, item) {
            const $filterPopupItem = $(item);
            let isFilterItemValid = false;
            valuesNumber = [];
            const $numberInputs = $filterPopupItem
                .find(Selectors.popupFilterItemSelectMenuInputNumber);

            if (!$numberInputs.length) {
                return true;
            }
            $numberInputs
                .each(function (id, input) {
                    valuesNumber.push($(input)
                        .val());
                });

            if (valuesNumber.length) {
                if (!valuesNumber[0] && !valuesNumber[1]) {
                    isFilterItemValid = true;
                } else {
                    isFilterItemValid = self.isRangeValid(valuesNumber[0], valuesNumber[1]);
                }
            }

            if (!isFilterItemValid) {
                self.invalidFields.push($filterPopupItem
                    .find(Selectors.popupFilterItemSelectMenuInputNumber));

                isValid = false;

                return false;
            }
        });


        $btnClear.toggleClass(CssClasses.isDisabled, !isValid);
        this.isFiltersValid = isValid;
    },

    isRangeValid: function (value1, value2) {
        if (!!value1 && !!value2) {
            if (Number(value1) < Number(value2) ||
                Number(value1) === Number(value2)) {
                return true;
            }
        } else {
            if (!!value1 || !!value2) {
                return true;
            }
        }

        return false;
    },

    changeFilterMobile: function () {
        if (!this.isFiltersValid) {
            for (let i = 0; i < this.invalidFields.length; i++) {
                const $fields = this.$(this.invalidFields[i]);

                $fields.each(function (index, input) {
                    $(input)
                        .val('');
                });
            }
        }
        const self = this;

        this.valuesItemsMobile = [];
        this.$itemsActiveFilterCheckbox = [];
        this.$itemsActiveFilterNumber = [];
        let count = 0;
        let prevType = '';

        this.$popupSelect.find('input')
            .each(function (id, input) {
                const text = $(input)
                    .data('text');
                const value = $(input)
                    .data('value');
                const inputValue = $(input)
                    .val();
                const type = $(input)
                    .data('type');
                const range = $(input)
                    .data('range');

                const name = $(input)
                    .attr('name');
                const isAccessories = name === self.accessoriesFilter.getName();
                if (isAccessories) {
                    const accessoriesValue = $(input)
                        .prop('checked');
                    self.accessoriesFilter.setValue(accessoriesValue);
                }

                if (!isAccessories) {
                    if (input.type === 'checkbox') {
                        if (input.checked) {
                            count++;
                            self.valuesItemsMobile.push({
                                title: $(input)
                                    .closest(Selectors.popupFilterItem)
                                    .find(Selectors.popupFilterItemSelectText)
                                    .text()
                                    .trim(),
                                type,
                                value,
                                text,
                                range,
                                isCheckbox: true
                            });
                        }
                    } else {
                        if (!!inputValue) {
                            if (type !== prevType) {
                                count++;
                            }
                            self.valuesItemsMobile.push({
                                title: $(input)
                                    .closest(Selectors.popupFilterItem)
                                    .find(Selectors.popupFilterItemSelectText)
                                    .text()
                                    .trim(),
                                type,
                                value: inputValue,
                                text: inputValue,
                                range,
                                isCheckbox: false
                            });
                        }
                        prevType = type;
                    }
                }
            });

        app.vent.trigger(Events.eventPrefixes.productFamily + Events.filter.mobileOnChange, this.valuesItemsMobile);

        if (count === 0) {
            this.$(Selectors.itemSelectText)
                .text('Filters');
        } else {
            this.$(Selectors.itemSelectText)
                .text('Filters (' + count + ')');
        }

        this.closeSelectMobileNoPopup();
        app.vent.trigger(Events.popup.showCloseButton);
    },

    disableMobileAccessories: function () {
        this.$el.find(`input[type=checkbox][name="${this.accessoriesFilter.getName()}"]`)
            .prop('checked', false);
    }

});
