'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');
const SharePrintButtons = require('components/TopPageButtons/TopPageButtons');
const InspirationCaseRelated = require('components/InspirationCaseRelated/InspirationCaseRelated');
const InspirationCaseDetails = require('components/InspirationCaseDetails/InspirationCaseDetails');
const InspirationCaseGallery = require('components/InspirationCaseGallery/InspirationCaseGallery');
const ProductDetailsFooter = require('components/ProductDetailsFooter/ProductDetailsFooter');
const CommonBlock = require('components/Common/CommonBlock/CommonBlock');

require('./InspirationCasePopup.less');

module.exports = BasePopup.extend({

    el: '.InspirationCasePopup',

    events: {},

    initialize: function (options = {}) {
        this.popupClass = '.InspirationCasePopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(SharePrintButtons, {});
        this.registerChildView(InspirationCaseRelated, {});
        this.registerChildView(InspirationCaseDetails, {});
        this.registerChildView(InspirationCaseGallery, {});
        this.registerChildView(ProductDetailsFooter, {});
        this.registerChildView(CommonBlock, {});
    },

    triggerPopupHidden: function () {
        BasePopup.prototype.triggerPopupHidden.call(this);
    }
});
