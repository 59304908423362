'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore/underscore.js');

const ProductsItem = require('components/ProductsItem/ProductsItem');
const ProductsExtraItem = require('components/ProductsExtraItem/ProductsExtraItem');
const Slider = require('components/MobileSlider/MobileSlider');
const stickybits = require('stickybits/dist/stickybits.min');

require('./ProductsCategory.less');

const Selectors = {
    el: '.ProductsCategory',
    sliderWrapper: '.ProductsCategory-categorySliderWrapper',
    slider: '.ProductsCategory-categorySlider',
    sliderItem: '.ProductsCategory-categorySliderItem',
    sliderInner: '.ProductsCategory-categorySliderInner',
    sliderNavItem: '.ProductsCategory-categorySliderNavItem',
    item: '.ProductsCategory-item',
    itemLink: '.ProductsItem',
    categoryImage: '.ProductsCategory-categoryImg',
    link: '.ProductsCategory-categoryInfoLink',
    linkAnchor: '.ProductsCategory-categoryInfoLinkAnchor',
    category: '.ProductsCategory-category'
};

const BindFunctions = [
    'onSwipeEnd',
    '_initChildComponents',
    '_initVariables',
    'openLayoutsPopup'
];

const Services = 'Services';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.touchStart} ${Selectors.sliderWrapper}`]: this.onTouchStart,
            [`${ViewportEvents.touchMove} ${Selectors.sliderWrapper}`]: this.onTouchMove,
            [`${ViewportEvents.touchEnd} ${Selectors.sliderWrapper}`]: this.onTouchEnd,
            [`${ViewportEvents.click} ${Selectors.linkAnchor}`]: this.openLayoutsPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initChildComponents();
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$bgImg = this.$(Selectors.categoryImage);
    },

    _initChildComponents: function () {
        const categoryId = this.$el.attr('id');
        const sliderSelector = `${Selectors.el}#${categoryId} ${Selectors.slider}`;

        this.slider = new Slider({
            page: this,
            el: sliderSelector,
            innerWrapper: Selectors.sliderInner,
            navLine: Selectors.sliderNavItem,
            singleItem: Selectors.sliderItem,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            callback: this.onSwipeEnd,
            imageCount: 2
        });

        this.productsExtraItem = new ProductsExtraItem({
            page: this
        });

        this.productsItem = new ProductsItem({
            page: this
        });

        if (window.app.isPhoneLayout()) {
            return;
        }

        this.stickyBits = stickybits(Selectors.category);

        $(document)
            .on(ViewportEvents.domContentLoaded, () => {
                this.stickyBits.update();
            });
    },

    onSwipeEnd: function (activeImageNum) {
        if (activeImageNum && activeImageNum === 2) {
            this.$bgImg.addClass(Constants.cssClasses.isBlur);
        } else {
            this.$bgImg.removeClass(Constants.cssClasses.isBlur);
        }
    },

    openLayoutsPopup: function (event) {
        event.preventDefault();
        event.stopPropagation();

        const $target = $(event.target)
            .closest(Selectors.linkAnchor);

        const isServices = $target.data('title')
            .toString()
            .toLowerCase() === Services.toLowerCase();

        const eventPrefix = isServices ? Events.eventPrefixes.services : Events.eventPrefixes.layouts;

        const url = $target.data('url');
        const apiUrl = $target.data('api-url');
        app.vent.trigger(eventPrefix + Events.popup.popupOpen, {apiUrl, url});
    }
});
