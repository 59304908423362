var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/InspirationCases/InspirationCaseRuntime.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/InspirationCases/InspirationCaseRuntime.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<div class=\"InspirationCases-case s-card-link-image ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink")) {
output += "download";
;
}
output += "\"\n     data-application=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"filters")),"application"), env.opts.autoescape);
output += "\"\n     data-product=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"filters")),"product"), env.opts.autoescape);
output += "\"\n     data-location=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"filters")),"location"), env.opts.autoescape);
output += "\">\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink")) {
output += "\n        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink"), env.opts.autoescape);
output += "\" class=\"InspirationCases-caseDownloadHref\"></a>\n    ";
;
}
else {
output += "\n        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"url"), env.opts.autoescape);
output += "\" data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"url"), env.opts.autoescape);
output += "\" data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"api_url"), env.opts.autoescape);
output += "\"\n           class=\"InspirationCases-caseUrl\"></a>\n    ";
;
}
output += "\n    <div class=\"InspirationCases-caseBg\">\n        ";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink")) {
output += "\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"image"), env.opts.autoescape);
output += "\" alt=\"";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"alt")) {
output += " ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"alt"), env.opts.autoescape);
output += " ";
;
}
output += "\">\n        ";
;
}
output += "\n    </div>\n    <div class=\"InspirationCases-caseLabels\">\n        ";
frame = frame.push();
var t_6 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"labels");
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("label", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n            <div data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((t_7),"id"), env.opts.autoescape);
output += "\" class=\"InspirationCases-caseLabel s-label-rounded s-label-rounded--hover\">\n                ";
output += runtime.suppressValue(runtime.memberLookup((t_7),"text"), env.opts.autoescape);
output += "\n            </div>\n        ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink")) {
output += "\n        <div class=\"InspirationCases-caseDownloadTitle s-title-medium\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"title"), env.opts.autoescape);
output += "\n        </div>\n    ";
;
}
else {
output += "\n        <div class=\"InspirationCases-flexSpacer\"></div>\n        <div class=\"InspirationCases-caseDescription\">\n            <div class=\"InspirationCases-caseTitleWrapper s-title-normal\">\n                <span class=\"InspirationCases-caseTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"title"), env.opts.autoescape);
output += "</span>\n                ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"location")) {
output += "\n                    <span>/</span>\n                    <span class=\"InspirationCases-caseLocation\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"location"), env.opts.autoescape);
output += "</span>\n                ";
;
}
output += "\n            </div>\n            <div class=\"InspirationCases-caseProductsWrapper s-text-tiny\">\n                <span class=\"InspirationCases-caseProductsTitle\">";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "productsTitle"), env.opts.autoescape);
output += ":&nbsp;</span>\n                ";
frame = frame.push();
var t_10 = runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"products");
if(t_10) {t_10 = runtime.fromIterator(t_10);
var t_9 = t_10.length;
for(var t_8=0; t_8 < t_10.length; t_8++) {
var t_11 = t_10[t_8];
frame.set("product", t_11);
frame.set("loop.index", t_8 + 1);
frame.set("loop.index0", t_8);
frame.set("loop.revindex", t_9 - t_8);
frame.set("loop.revindex0", t_9 - t_8 - 1);
frame.set("loop.first", t_8 === 0);
frame.set("loop.last", t_8 === t_9 - 1);
frame.set("loop.length", t_9);
output += "\n                    <div class=\"InspirationCases-caseProductWrapper\">\n                        <div class=\"InspirationCases-caseProduct\">";
output += runtime.suppressValue(runtime.memberLookup((t_11),"title"), env.opts.autoescape);
output += "</div>\n                        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") != env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"products"))) {
output += "\n                            <span>,&nbsp;</span>\n                        ";
;
}
output += "\n                    </div>\n                ";
;
}
}
frame = frame.pop();
output += "\n            </div>\n        </div>\n    ";
;
}
output += "\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadLink")) {
output += "\n        <div class=\"InspirationCases-caseDownloadLink\">\n                        <span class=\"InspirationCases-caseDownloadLinkIcon\">\n                            ";
output += runtime.suppressValue((lineno = 54, colno = 53, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["product-download-icon-light",runtime.makeKeywordArgs({"caller": (function (){var macro_t_12 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_13 = "";;
frame = frame.pop();
return new runtime.SafeString(t_13);
});
return macro_t_12;})()})])), env.opts.autoescape);
output += "\n                        </span>\n            <span class=\"InspirationCases-caseDownloadLinkText s-text-tiny\">\n                            ";
output += runtime.suppressValue(runtime.contextOrFrameLookup(context, frame, "downloadTitle"), env.opts.autoescape);
output += "\n                        </span>\n            <span class=\"InspirationCases-caseDownloadLinkFormat s-text-tiny\">\n                            &nbsp;";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "case")),"downloadFormat"), env.opts.autoescape);
output += "\n                        </span>\n        </div>\n    ";
;
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/InspirationCases/InspirationCaseRuntime.html"] , dependencies)