var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/ProductFamilyAccessories/ProductFamilyAccesoriesItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/ProductFamilyAccessories/ProductFamilyAccesoriesItem.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\" data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"api_url"), env.opts.autoescape);
output += "\" data-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"url"), env.opts.autoescape);
output += "\"\n   class=\"ProductFamilyAccessories-item hide\">\n    <span class=\"ProductFamilyAccessories-itemImage\">\n        ";
output += runtime.suppressValue((lineno = 5, colno = 30, runtime.callWrap(runtime.memberLookup((t_1),"picture"), "macros[\"picture\"]", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"img"),runtime.makeKeywordArgs({"layoutSize": "260","caller": (function (){var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
return macro_t_4;})()})])), env.opts.autoescape);
output += "</span>\n    <span class=\"ProductFamilyAccessories-itemCategory s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"category"), env.opts.autoescape);
output += "</span>\n    <span class=\"ProductFamilyAccessories-itemName s-title-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"name"), env.opts.autoescape);
output += "</span>\n</a>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/ProductFamilyAccessories/ProductFamilyAccesoriesItem.html"] , dependencies)