'use strict';

const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');

const DownloadCard = require('components/Common/DownloadCard/DownloadCard');

require('./DownloadCards.less');

const Selectors = {
    el: '.DownloadCards',
    items: '.DownloadCard'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;
        this.downloadCard = new DownloadCard();
        this.$(Selectors.items).addClass(Constants.cssClasses.show);
    }
});
