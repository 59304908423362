'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore');
const FourColumnFacts = require('components/Common/FourColumnFacts/FourColumnFacts');

require('./AboutHead.less');

const Selectors = {
    aboutHead: '.AboutHead',
    aboutHeadFact: '.AboutHead-fact',
    aboutHeadArrow: '.AboutHead-arrow'
};

const BindFunctions = [
    'scrollDown',
    '_setInitialClasses',
    '_attachEvents',
    '_initChildComponents'
];

const ScrollOffset = 50;

module.exports = Backbone.View.extend({

    el: Selectors.aboutHead,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.aboutHeadArrow}`]: this.scrollDown
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        window.app.utils.fixHeightOnIos(this.$el);

        this._initChildComponents();
        this._setInitialClasses();
        this._attachEvents();
        this.onScroll();

        this.options = options;
    },

    _initChildComponents: function () {
        this.facts = new FourColumnFacts();
    },

    _attachEvents: function () {
        window.app.els.$window.on('scroll', this.onScroll);
    },

    _setInitialClasses: function () {
        const self = this;

        setTimeout(function () {
            self.$(Selectors.aboutHeadArrow)
                .addClass(Constants.cssClasses.show);
        }, 500);
    },

    onScroll: function () {
        if ($(window)
            .scrollTop() > ScrollOffset) {
            this.$(Selectors.aboutHeadArrow)
                .addClass(Constants.cssClasses.hide);
        } else {
            this.$(Selectors.aboutHeadArrow)
                .removeClass(Constants.cssClasses.hide);
        }
    },

    scrollDown: function () {
        window.app.els.$htmlBody
            .animate({scrollTop: window.app.sizes.windowHeight}, 600);
    }

});
