'use strict';

const _ = require('underscore');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

const Page = require('pages/Page/Page.js');
const Tabs = require('components/Common/Tabs/Tabs');
const FaqPopup = require('popups/FaqPopup/FaqPopup');
const TabContentTemplate = require('./TabContentRuntime.html');

require('./PageFaq.less');

const Selectors = {
    content: '.PageFaq-content',
    tabsLinks: '.PageFaq-contentLink',
    contentLinks: '.PageFaq-contentLinks',
    contentLinksMobile: '.PageFaq-contentLinks_mobileWrapper',
    mobileCategory: '.PageFaq-contentCategory_mobile',
    mobileCategories: '.PageFaq-contentTabs_mobile',
    mobileLinksWrapper: '.PageFaq-contentLinks_mobile',
    mobileLinksTitle: '.PageFaq-contentLinksTitleText_mobile',
    mobileLinksTitleWrapper: '.PageFaq-contentLinksTitle_mobile',
    mobileArrowBack: '.PageFaq-contentLinksTitleBack',
    searchInput: '.PageFaq-input',
    noResults: '.PageFaq-contentLinksNoResults',
    searchCloseButton: '.PageFaq-close'
};

const BindFunctions = [
    'onActiveTabChange',
    'filterTabs',
    'onResize',
    'showCategoriesMobile',
    'selectCategoryMobile',
    'search',
    'showLinksMobileWithoutTitle',
    'cancelSearch',
    'removeSearchQuery',
    'onLinkClick'
];

module.exports = Page.extend({

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.mobileCategory}`]: this.selectCategoryMobile,
            [`${ViewportEvents.click} ${Selectors.mobileArrowBack}`]: this.showCategoriesMobile,
            [`${ViewportEvents.input} ${Selectors.searchInput}`]: this.search,
            [`${ViewportEvents.click} ${Selectors.searchCloseButton}`]: this.removeSearchQuery,
            [`${ViewportEvents.click} ${Selectors.tabsLinks}`]: this.onLinkClick

        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.tabs = new Tabs({callback: this.onActiveTabChange});
        this.isMobile = window.app.isPhoneLayout();
        window.app.els.$window.on(ViewportEvents.resize, this.onResize);
        this.apiUrl = this.$(Selectors.content)
            .data('api-url');
        this.$contentLinks = this.$(Selectors.contentLinks);
        this.$contentLinksMobile = this.$(Selectors.contentLinksMobile);
        this.$noResults = this.$(Selectors.noResults);

        Page.prototype.initialize.call(this, options);
    },

    onResize: function () {
        this.isMobile = window.app.isPhoneLayout();
    },

    onActiveTabChange: function (tabId) {
        this.filterTabs(tabId);
        this.cancelSearch();
    },

    filterTabs: function (tabId, searchValue) {
        const data = {tabId, searchValue};
        this.$contentLinks.empty();
        this.$contentLinksMobile.empty();
        this.$noResults
            .removeClass(Constants.cssClasses.show);

        $.getJSON(this.apiUrl, data, (res) => {
            if (!res) {
                this.$noResults
                    .addClass(Constants.cssClasses.show);

                return;
            }

            res.forEach((tab) => {
                const template = TabContentTemplate.render({
                    tab
                });

                this.$contentLinks.append(template);
                this.$contentLinksMobile.append(template);
            });
        });
    },

    search: function (event) {
        const searchValue = $(event.target)
            .val();

        if (!searchValue) {
            this.tabs.setActiveTabByNumber(0);

            return;
        }
        this.$(Selectors.searchCloseButton)
            .addClass(Constants.cssClasses.show);
        this.showLinksMobileWithoutTitle();

        this.filterTabs(undefined, searchValue);

        this.tabs.disableTabs();
    },

    selectCategoryMobile: function (event) {
        const categoryId = $(event.target)
            .closest(Selectors.mobileCategory)
            .data('id');
        const categoryName = $(event.target)
            .closest(Selectors.mobileCategory)
            .data('name');

        this.$(Selectors.mobileCategories)
            .hide();
        this.$(Selectors.mobileLinksWrapper)
            .show();
        this.$(Selectors.mobileLinksTitle)
            .text(categoryName);
        this.$(Selectors.mobileLinksTitleWrapper)
            .show();
        this.filterTabs(categoryId);
    },

    showCategoriesMobile: function () {
        this.$(Selectors.mobileCategories)
            .show();
        this.$(Selectors.mobileLinksWrapper)
            .hide();
    },

    showLinksMobileWithoutTitle: function () {
        this.$(Selectors.mobileLinksTitleWrapper)
            .hide();
        this.$(Selectors.mobileCategories)
            .hide();
        this.$(Selectors.mobileLinksWrapper)
            .show();
    },

    cancelSearch: function () {
        this.$noResults
            .removeClass(Constants.cssClasses.show);
        this.$(Selectors.searchInput)
            .val('');
        this.$(Selectors.searchCloseButton)
            .removeClass(Constants.cssClasses.show);

        this.showCategoriesMobile();
    },

    onLinkClick: function (event) {
        event.preventDefault();
        const $target = $(event.target)
            .closest(Selectors.tabsLinks);

        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');
        this.openPopup({apiUrl, url});
    },

    openPopup: function (data) {
        const apiUrl = data.apiUrl;
        const url = data.url;

        const popup = new FaqPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    },

    removeSearchQuery: function () {
        this.tabs.setActiveTabByNumber(0);
        this.cancelSearch();
    }
});
