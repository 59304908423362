'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');
const _ = require('underscore');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

const ProductFamilyFilter = require('components/ProductFamilyFilter/ProductFamilyFilter');
const ProductFamilySections = require('components/ProductFamilySections/ProductFamilySections');

require('./ProductFamilyPopup.less');

const BindFunctions = [
    'disablePopupTouchEvents',
    'enablePopupTouchEvents'
];

const Selectors = {
    el: '.ProductFamilyPopup',
    wrapper: '.Popup-wrapper'
};

module.exports = BasePopup.extend({

    el: '.ProductFamilyPopup',

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this.popupClass = '.ProductFamilyPopup';
        this._attachEvents();

        BasePopup.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.mobileOpen, this.disablePopupTouchEvents);
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.mobileClose, this.enablePopupTouchEvents);
    },

    disablePopupTouchEvents: function () {
        this.$el.find(Selectors.wrapper)
            .css(Constants.cssProperties.overflow, Constants.cssValues.hidden);
    },

    enablePopupTouchEvents: function () {
        this.$el.find(Selectors.wrapper)
            .css(Constants.cssProperties.overflow, '');
    },

    initChildComponents: function () {
        this.registerChildView(ProductFamilyFilter, {page: this});
        this.registerChildView(ProductFamilySections, {page: this});
    }
});
