'use strict';

const Backbone = require('backbone/backbone');
const Link = require('components/Common/Link/Link');

require('./FeaturesIcons.less');

const Selectors = {
    el: '.FeaturesIcons'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;
        this.link = new Link();
    }
});
