'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore');

const Dropdown = require('components/Common/Dropdown/Dropdown');

require('./FooterSlim.less');

const Selectors = {
    links: '.FooterSlim-links',
    wrapper: '.FooterSlim-wrapper',
    menuDropdown: '.FooterSlim-menuDropdown',
    menuDropdownSelect: '.FooterSlim-menuDropdownSelect',
    menuDropdownMenuItem: '.FooterSlim-menuDropdownMenuItem',
    selectLinks: '.FooterSlim-selectLinks',
    menuLogo: '.FooterSlim-menuLogo',
    regionLangPopup: '.FooterSlim-RegionLang--popup',
    contactsPopup: '.FooterSlim-Contacts--popup',
    privacyPopup: '.FooterSlim-Privacy--popup',
    termsPopup: '.FooterSlim-Terms-popup',
    imprintPopup: '.FooterSlim-Imprint--popup'
};

const CssClasses = {
    isOpenDropdown: 'isOpenDropdown',
    isOpenPopup: 'isOpenPopup'
};

const BindFunctions = [
    '_initVariables',
    '_attachEvents',
    'clickOutside'
];

module.exports = Backbone.View.extend({

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.menuDropdownSelect}`]: this.toggleDropdown,
            [`${ViewportEvents.click} ${Selectors.selectLinks}`]: this.openSelectorProduct,
            [`${ViewportEvents.click} ${Selectors.menuLogo}`]: this.onLogoClick,
            [`${ViewportEvents.click} ${Selectors.regionLangPopup}`]: this.onRegionLangClick,
            [`${ViewportEvents.click} ${Selectors.contactsPopup}`]: this.onContactsClick,
            [`${ViewportEvents.click} ${Selectors.privacyPopup}`]: this.onPrivacyClick,
            [`${ViewportEvents.click} ${Selectors.termsPopup}`]: this.onTermsClick,
            [`${ViewportEvents.click} ${Selectors.imprintPopup}`]: this.onImprintClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.dropdown = new Dropdown({
            id: 0,
            modifier: 'footerSlim'
        });

        this._initVariables(options);
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.els.$body.on(ViewportEvents.click, this.clickOutside);

        app.vent.on(Events.eventPrefixes.products + Events.filter.mobileOpen, function () {
            this.$el.addClass(Constants.cssClasses.footerSlimHidden);
        }.bind(this));

        app.vent.on(Events.eventPrefixes.products + Events.filter.mobileClose, function () {
            this.$el.removeClass(Constants.cssClasses.footerSlimHidden);
        }.bind(this));

        this.$('select')
            .change(this.onMobileOptionClick);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$menuDropdown = this.$(Selectors.menuDropdown);
        this.$links = this.$(Selectors.links);
    },

    clickOutside: function (event) {
        const popupOfProducts = this.$(Selectors.links);
        const selectorLinks = this.$(Selectors.selectLinks)[0];

        //check if the clicked area is dropDown or not
        if ($(event.target)
            .closest(Constants.selectors.dropdownLinkList).length === 0) {
            this.dropdown.close();
        }

        //check if the clicked area is dropDown or not
        if (selectorLinks !== event.target && popupOfProducts.has(event.target).length === 0) {
            this.closeSelectorProduct();
        }
    },

    toggleDropdown: function () {
        this.$menuDropdown.toggleClass(CssClasses.isOpenDropdown);
    },

    openSelectorProduct: function () {
        this.$links.addClass(CssClasses.isOpenPopup);
    },

    closeSelectorProduct: function () {
        this.$links.removeClass(CssClasses.isOpenPopup);
    },

    onLogoClick: function (event) {
        if (window.app.els.$body.hasClass(Constants.cssClasses.pageIndex) ||
            window.app.els.$body.hasClass(Constants.cssClasses.pageGlobalIndex)) {
            event.preventDefault();
            window.app.els.$htmlBody.animate({'scrollTop': 0}, 400);
        }
    },

    onContactsClick: function () {
        app.vent.trigger(Events.eventPrefixes.contacts + Events.popup.popupOpen);
    },

    onRegionLangClick: function () {
        app.vent.trigger(Events.eventPrefixes.regionLang + Events.popup.popupOpen);
    },

    onPrivacyClick: function () {
        app.vent.trigger(Events.eventPrefixes.privacy + Events.popup.popupOpen);
    },

    onTermsClick: function () {
        app.vent.trigger(Events.eventPrefixes.terms + Events.popup.popupOpen);
    },

    onImprintClick: function () {
        app.vent.trigger(Events.eventPrefixes.imprint + Events.popup.popupOpen);
    },

    onMobileOptionClick: function () {
        const value = $(this)
            .val();
        if (value) {
            window.location.href = value;
        }
    }
});
