var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/Search/SearchResultItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"url"), env.opts.autoescape);
output += "\" class=\"Search-resultsItem\">\n    ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")) {
output += "\n        <span class=\"Search-resultsItemIcon\">\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"alt"), env.opts.autoescape);
output += "\">\n        </span>\n    ";
;
}
output += "\n    <span class=\"Search-resultsItemInfo ";
if(!runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"image")) {
output += " withoutImage ";
;
}
output += "\">\n        <span class=\"Search-resultsItemName\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"item"), env.opts.autoescape);
output += "\n        </span>\n        <span class=\"Search-resultsItemBrand\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"brand"), env.opts.autoescape);
output += "\n        </span>\n    </span>\n</a>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/Search/SearchResultItem.html"] , dependencies)