'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore');
const Slider = require('components/MobileSlider/MobileSlider');

require('./CardsSlider.less');

const Selectors = {
    el: '.CardsSlider',
    objectsArrowRight: '.CardsSlider-objectsArrowRight',
    objectsArrowLeft: '.CardsSlider-objectsArrowLeft',
    object: '.CardsSlider-object',
    objects: '.CardsSlider-objects',
    objectsInner: '.CardsSlider-objectsInner',
    navLine: '.CardsSlider-navLine'
};

const BindFunctions = [
    'move',
    'moveRight',
    'moveLeft',
    'onResize',
    'onObjectClick',
    'checkArrows',
    'onSwipe',
    '_initVariables',
    '_initMobileSlider',
    '_initSwipeDesktop',
    '_attachEvents',
    '_setSelectors',
    'setTransitionWidth'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._setSelectors();
        this._initVariables(options);

        if (window.app.isPhoneLayout()) {
            this._initMobileSlider();
        }

        if (window.app.settings.isDesktop) {
            this._initSwipeDesktop();
        }

        this._attachEvents();
        this.onResize();
        this.checkArrows();
    },

    _initSwipeDesktop: function () {
        this.$(this.selectors.objects)
            .swipe({
                allowPageScroll: 'auto',
                threshold: 50,
                fallbackToMouseEvents: true,
                excludedElements: 'button, input, select, textarea, .noSwipe',
                swipe: function (event, direction) {
                    (direction === 'left') && this.moveRight();
                    (direction === 'right') && this.moveLeft();
                }.bind(this)
            });
    },

    _initMobileSlider: function () {
        this.slider = new Slider({
            page: this,
            el: this.selectors.objects,
            arrowLeft: this.selectors.objectsArrowLeft,
            arrowRight: this.selectors.objectsArrowRight,
            innerWrapper: this.selectors.objectsInner,
            navLine: this.selectors.navLine,
            singleItem: this.selectors.object,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            withAnimationDesktop: true,
            transitionWidth: this.$item.outerWidth(true),
            infiniteDesktop: true,
            callback: this.onSwipe
        });
    },

    _initVariables: function (options = {}) {
        this.setElement(this.selectors.el);
        this.$item = this.$(this.selectors.object);

        this.itemWidth = this.$item.outerWidth(true);
        this.$wrapperInner = this.$(this.selectors.objectsInner);
        this.$rightArrow = this.$(this.selectors.objectsArrowRight);
        this.$leftArrow = this.$(this.selectors.objectsArrowLeft);
        this.transitionIndex = 0;
        this.maxTransitions = window.app.isPhoneLayout() ? this.$item.length - 1 : this.$item.length - 4;
        this.currentTransition = 0;
        this.options = options;
    },

    _setSelectors: function () {
        this.selectors = Selectors;
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);

        this.$(this.selectors.objectsArrowRight)
            .on(ViewportEvents.click, this.moveRight);
        this.$(this.selectors.objectsArrowLeft)
            .on(ViewportEvents.click, this.moveLeft);
        this.$(this.selectors.object)
            .on(ViewportEvents.click, this.onObjectClick);
    },

    onSwipe: function (activeImage) {
        this.transitionIndex = activeImage - 1;
        this.checkArrows();
    },

    onResize: function () {
        this.itemWidth = this.$item.outerWidth(true);
        this.currentTransition = this.transitionIndex * -this.itemWidth;
        this.move();
    },

    setTransitionWidth: function (transitionWidth) {
        const cardsTransitionWidth = transitionWidth || this.$item.outerWidth(true);
        this.slider && this.slider.setTransitionWidth(cardsTransitionWidth);
    },

    checkArrows: function () {
        if (this.transitionIndex >= this.maxTransitions) {
            this.$rightArrow.hide();
        } else {
            this.$rightArrow.show();
        }

        if (this.transitionIndex === 0) {
            this.$leftArrow.hide();
        } else {
            this.$leftArrow.show();
        }
    },

    moveRight: function () {
        if (this.transitionIndex >= this.maxTransitions) {
            return;
        }
        this.currentTransition = this.currentTransition - this.itemWidth;
        this.transitionIndex++;
        this.move(-1);
    },

    moveLeft: function () {
        if (this.transitionIndex <= 0) {
            return;
        }
        this.currentTransition = this.currentTransition + this.itemWidth;
        this.transitionIndex--;
        this.move(1);
    },

    onObjectClick: function (e) {
        const $object = $(e.currentTarget);
        const leftEdge = $object.offset().left;
        const elWidth = this.$el.width();
        const rightEdge = leftEdge + $object.width();

        if (leftEdge < 0) {
            e.preventDefault();
            this.moveLeft();
        } else if (rightEdge > elWidth) {
            e.preventDefault();
            this.moveRight();
        }
    },

    move: function (direction) {
        if (!window.app.settings.isDesktop && this.slider) {
            if (direction === -1) {
                this.slider.nextPictureSwipe();
            } else {
                this.slider.prevPictureSwipe();
            }
        } else {
            this.$wrapperInner.css('transform', 'translate3d(' + this.currentTransition + 'px, 0, 0)');
        }
        this.checkArrows();
    }
});
