'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');

require('./SearchResultInspirationItem.less');

const Template = require('./SearchResultInspirationItem.html');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

const Selectors = {
    el: '.SearchResultInspirationItem',
    link: '.SearchResultInspirationItem-link'
};

const BindFunctions = [
    'render',
    'attachElement',
    'openInspirationCasePopup'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openInspirationCasePopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    attachElement: function () {
        this.setElement(Selectors.el);
    },

    render: function (data = {}) {
        return Template.render({data});
    },

    openInspirationCasePopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.link);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        const popup = new InspirationCasePopup({
            page: this,
            backUrl: this.pageUrl
        });

        popup.openPopup(apiUrl, url);
    }


});
