'use strict';

class ApplicationError extends Error {
    constructor() {
        super(...arguments);
    }
}
class AbstractMethodError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class EntityNotFoundError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class IllegalStateError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class UnhandledRejectionError extends ApplicationError {
    constructor() {
        super(...arguments);
        if (arguments[0] instanceof Error) {
            this.reason = arguments[0];
        }
    }
}
class UnknownTypeError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class UnsupportedOperationError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class UnsupportedFeatureError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}
class UnsupportedValueError extends ApplicationError {
    constructor() {
        super(...arguments);
    }
}

module.exports = {
    ApplicationError,
    AbstractMethodError,
    EntityNotFoundError,
    IllegalStateError,
    UnhandledRejectionError,
    UnknownTypeError,
    UnsupportedFeatureError,
    UnsupportedOperationError,
    UnsupportedValueError
};
