'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');

require('./SearchResultNewsItem.less');

const Template = require('./SearchResultNewsItem.html');

const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

const Selectors = {
    el: '.SearchResultNewsItem',
    link: '.SearchResultNewsItem-link'
};

const BindFunctions = [
    'render',
    'openNewsPopup',
    'attachElement'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openNewsPopup
        };
    },

    attachElement: function () {
        this.setElement(Selectors.el);
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    render: function (data = {}) {
        return Template.render({data});
    },

    openNewsPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.link);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        const popup = new NewsPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
