'use strict';

const _ = require('underscore');

const Page = require('pages/Page/Page.js');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

const GlobalIndexSidebar = require('components/GlobalIndexSidebar/GlobalIndexSidebar');
const RadialBackgrounds = require('components/SplashPageAnimationComponents/RadialBackgrounds/RadialBackgrounds');
const PendulumLamp = require('components/SplashPageAnimationComponents/PendulumLamp/PendulumLamp');
const Vase3D = require('components/SplashPageAnimationComponents/Vase3D/Vase3D');
const VideoBackground = require('components/SplashPageAnimationComponents/VideoBackground/VideoBackground');

require('./PageGlobalIndex.less');

const CssClasses = {
    bgThemeLight: 'light',
    bgThemeDark: 'dark',
    arrowThemeLight: 'arrowLight',
    arrowThemeDark: 'arrowDark'
};

const Selectors = {
    el: '.PageGlobalIndex',
    bg: '.PageGlobalIndex-background',
    bgInner: '.PageGlobalIndex-backgroundInner',
    bgVideo: '.PageGlobalIndex-backgroundInner video',
    bgMobile: '.PageGlobalIndex-background_mobile',
    sidebar: '.GlobalIndexSidebar',
    arrowRight: '.PageGlobalIndex-navigationArrowRight',
    arrowLeft: '.PageGlobalIndex-navigationArrowLeft',
    bgDescription: '.PageGlobalIndex-navigation',
    bgDescriptionName: '.PageGlobalIndex-navigationTitleName',
    bgDescriptionLocation: '.PageGlobalIndex-navigationTitleLocation',
    navigation: '.PageGlobalIndex-navigation',
    arrows: '.PageGlobalIndex-navigationArrows',
    link: '.PageGlobalIndex-link',
    contentWrapper: '.PageGlobalIndex-contentWrapper'
};

const BindFunctions = [
    '_initVariables',
    'onScroll',
    '_attachEvents',
    'onResize',
    'setActiveBg',
    'onArrowRightClick',
    'onArrowLeftClick',
    'fixNavigationPositionOnIos',
    'updateContentColor'
];

const ChildViews = [
    {
        name: 'globalIndexSidebar',
        Constructor: GlobalIndexSidebar
    },
    {
        name: 'radialBackgrounds',
        Constructor: RadialBackgrounds
    },
    {
        name: 'pendulumLamp',
        Constructor: PendulumLamp
    },
    {
        name: 'vase3D',
        Constructor: Vase3D
    },
    {
        name: 'videoBackground',
        Constructor: VideoBackground
    }
];

const DataAttributes = {
    name: 'name',
    location: 'location',
    theme: 'theme',
    arrowTheme: 'arrow-theme'
};

module.exports = Page.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowLeft}`]: this.onArrowLeftClick,
            [`${ViewportEvents.click} ${Selectors.arrowRight}`]: this.onArrowRightClick
        };
    },


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables();
        this._initChildViews(ChildViews);
        this._attachEvents();
        this.onScroll();
        this.setActiveBg(this.activeBgNumber);
        this.fixNavigationPositionOnIos();
        this.onResize();

        Page.prototype.initialize.call(this, options);
    },

    fixNavigationPositionOnIos: function () {
        if (!window.app.isPhoneLayout()) {
            this.$(Selectors.navigation)
                .css('bottom', '');

            return;
        }

        const vh = window.app.els.$window
            .height();
        const sidebarPositionTop = this.$(Selectors.sidebar)
            .offset().top;
        const sidebarVisibleHeight = vh - sidebarPositionTop;
        const navigationOffset = 20;

        this.$(Selectors.navigation)
            .css('bottom', `${sidebarVisibleHeight + navigationOffset}px`);
    },

    _initVariables: function () {
        this.$background = this.$(Selectors.bg);
        this.$backgroundInner = this.$(Selectors.bgInner);
        this.$backgroundVideo = this.$(Selectors.bgVideo);
        this.$backgroundMobile = this.$(Selectors.bgMobile);
        this.$bgDescription = this.$(Selectors.bgDescription);
        this.$bgDescriptionName = this.$(Selectors.bgDescriptionName);
        this.$bgDescriptionLocation = this.$(Selectors.bgDescriptionLocation);
        const countOfBackgrounds = this.$background.length;
        this.activeBgNumber = Math.floor(Math.random() * countOfBackgrounds) + 1;
        this.bgCount = this.$background.length;
        this.isArrowWasClicked = false;
    },

    _attachEvents: function () {
        window.app.els.$window.on(ViewportEvents.scroll, this.onScroll);
        window.app.els.$window.on(ViewportEvents.resize, this.onResize);
        app.vent.on(Events.common.splashColorChange, this.updateContentColor);
    },

    onArrowRightClick: function () {
        this.activeBgNumber++;
        if (this.activeBgNumber > this.bgCount) {
            this.activeBgNumber = 1;
        }

        this.isArrowWasClicked = true;
        clearInterval(this.changeInterval);
        this.setActiveBg(this.activeBgNumber);
    },

    onArrowLeftClick: function () {
        this.activeBgNumber--;
        if (this.activeBgNumber < 1) {
            this.activeBgNumber = this.bgCount;
        }

        this.isArrowWasClicked = true;
        this.setActiveBg(this.activeBgNumber);
    },

    setActiveBg: function (activeBgNum) {
        this.$background.removeClass(Constants.cssClasses.active);
        const $curBg = $(`${Selectors.bg}[data-id="${activeBgNum}"]`);
        $curBg
            .addClass(Constants.cssClasses.active);
        const bgName = $curBg.data(DataAttributes.name);
        const bgLocation = $curBg.data(DataAttributes.location);
        const bgTheme = $curBg.data(DataAttributes.theme) || CssClasses.bgThemeLight;
        const arrowTheme = $curBg.data(DataAttributes.arrowTheme) || CssClasses.arrowThemeDark;

        this.$el
            .removeClass(CssClasses.bgThemeLight)
            .removeClass(CssClasses.bgThemeDark)
            .addClass(bgTheme)
            .removeClass(CssClasses.arrowThemeLight)
            .removeClass(CssClasses.arrowThemeDark)
            .addClass(arrowTheme);

        this.$bgDescriptionName.text(bgName);
        this.$bgDescriptionLocation.text(bgLocation);
        window.app.vent.trigger(Events.splashBackgroundChange);
    },

    onScroll: function () {
        const scroll = window.app.els.$window
            .scrollTop();

        const per = Math.max(Math.min(scroll / (0.5 * window.innerHeight), 1), 0);
        const bgVideoOpacityCoef = 1 - per;
        const bgDescriptionOpacityCoef = 1 - per * 5;
        this.$backgroundInner.css(Constants.cssProperties.opacity, bgVideoOpacityCoef);
        this.$backgroundVideo.css(Constants.cssProperties.opacity, bgVideoOpacityCoef); // directly set opacity of video elem for IE
        this.$bgDescription.css(Constants.cssProperties.opacity, bgDescriptionOpacityCoef);
        if (bgDescriptionOpacityCoef <= 0) {
            this.$bgDescription.css(Constants.cssProperties.visibility, Constants.cssValues.hidden);
        } else {
            this.$bgDescription.css(Constants.cssProperties.visibility, Constants.cssValues.visible);
        }
    },

    updateContentColor: function (color, backgroundColor) {
        if (app.isPhoneLayout()) {
            return;
        }

        if (color === 'light') {
            this.$(Selectors.contentWrapper).css({color: '#ffffff', backgroundColor});
            this.$(Selectors.link).removeClass('dark');

            return;
        }
        this.$(Selectors.contentWrapper).css({color: '#121212', backgroundColor});
        this.$(Selectors.link).addClass('dark');
    },

    onResize: function () {
        this.setActiveBg(this.activeBgNumber);
        this.fixNavigationPositionOnIos();

        if (app.isPhoneLayout() || app.els.$html.hasClass('isIE')) {
            return;
        }
        const containerHeight = window.innerHeight;
        const sidebarHeight = this.$(Selectors.sidebar)
            .outerHeight();
        const offsetSidebar = containerHeight - sidebarHeight;

        if (offsetSidebar < 0) {
            this.$(Selectors.sidebar)
                .css(Constants.cssProperties.top, offsetSidebar);
        }
    }
});
