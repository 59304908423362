'use strict';

const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactSupplier';

require('./ContactSupplier.less');

const Selectors = {
    contactSupplier: '.ContactSupplier',
    mobileArrowBack: '.ContactSupplier-mobileArrowBack',
    formSelect: '.ContactSupplier-formSelect',
    formSelectText: '.ContactSupplier-formSelectText',
    formSelectTextParagraph: '.ContactSupplier-formSelectText p',
    formSelectMenu: '.ContactSupplier-formSelectMenu',
    formSelectMenuVal: '.ContactSupplier-formSelectMenuVal',
    formSelectMenuValActive: `.ContactSupplier-formSelectMenuVal--${Constants.cssClasses.active}`,
    formSelectMenuValFirstChild: '.ContactSupplier-formSelectMenuVal:first-child',
    form: 'form.ContactSupplier-form',
    response: '.ContactSupplier-responseMessage'
};

const BindFunctions = [
    '_initVariables',
    '_initChildComponents',
    'changeRegionFilter',
    'submitForm'
];

const DefaultView = createContactComponent({
    selectorComponent: SelectorComponent,
    bindFunctions: BindFunctions,
    selectors: Selectors
});

module.exports = DefaultView.extend({

    el: Selectors.contactSupplier,

    events: function () {
        return {
            ...DefaultView.prototype.events.call(this),
            [`${ViewportEvents.click} ${Selectors.formSelectMenuVal}`]: this.changeRegionFilter,
            [`${ViewportEvents.submit} ${Selectors.form}`]: this.submitForm
        };
    },

    initialize: function (options = {}) {
        DefaultView.prototype.initialize.call(this, options);

        this._initChildComponents();
        this._initVariables(options);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isOpenSelectRegion = false;
        this.currentReg = $(Selectors.formSelectText)
            .attr('data-default');
    },

    _initChildComponents: function () {
        this.dropdown = new Dropdown({
            id: 0,
            modifier: 'contactSupplier',
            onChooseOption: this.changeRegionFilter
        });
    },

    changeRegionFilter: function (dropdownId, idItem, typeItem) {
        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, idItem, typeItem);

        this.isOpenSelectRegion = false;
        this.dropdown.close();
    },

    submitForm: function (e) {
        e.preventDefault();
        const self = this;
        $.ajax({
            data: this.$(Selectors.form).serialize(),
            type: this.$(Selectors.form).attr('method'),
            url: this.$(Selectors.form).attr('action'),
            success: function () {
                const responseText = window.gettext('contact.supplier.response');
                self.$(Selectors.response).text(responseText);
                self.$(Selectors.response).removeClass(Constants.cssClasses.hide);
                self.$(Selectors.form).hide();
                self.scrollTop();
            },
            error: function (response) {
                self.$(Selectors.response).text(response.responseJSON.error_text);
                self.$(Selectors.response).removeClass(Constants.cssClasses.hide);
                self.scrollTop();
            }
        });
    }
});
