'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const FourColumnFacts = require('components/Common/FourColumnFacts/FourColumnFacts');
const GlobalPageTitle = require('components/Common/GlobalPageTitle/GlobalPageTitle');
const GlobalMenuMobile = require('components/Common/GlobalMenuMobile/GlobalMenuMobile');

require('./InvestorsLandingHead.less');

const Selectors = {
    title: '.GlobalPageTitle',
    facts: '.InvestorsLandingHead-facts',
    overlay: '.InvestorsLandingHead-overlay'
};

const BindFunctions = [
    '_initChildComponents',
    '_attachEvents',
    'onSubMenuShown',
    'onSubMenuHidden',
    '_initVariables'
];

module.exports = Backbone.View.extend({

    el: '.InvestorsLandingHead',

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        window.app.utils.fixHeightOnIos(this.$el);

        this._initChildComponents();
        this._initVariables();
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.vent.on(Events.subMenuNav.navShown, this.onSubMenuShown);
        window.app.vent.on(Events.subMenuNav.navHidden, this.onSubMenuHidden);
    },

    _initVariables: function () {
        this.$title = this.$(Selectors.title);
        this.$facts = this.$(Selectors.facts);
        this.$overlay = this.$(Selectors.overlay);
    },

    onSubMenuHidden: function () {
        this.$title.removeClass(Constants.cssClasses.hide);
        this.$facts.removeClass(Constants.cssClasses.hide);
        this.$overlay.removeClass(Constants.cssClasses.show);
        app.vent.trigger(Events.popup.popupHidden);
        $(window)
            .scrollTop(this.savedScroll);
    },

    onSubMenuShown: function () {
        this.$title.addClass(Constants.cssClasses.hide);
        this.$facts.addClass(Constants.cssClasses.hide);
        this.$overlay.addClass(Constants.cssClasses.show);
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger(Events.popup.popupShown);
        $(window)
            .scrollTop(0);
    },

    _initChildComponents: function () {
        this.facts = new FourColumnFacts({
            page: this
        });
        this.title = new GlobalPageTitle({
            page: this
        });
        this.menu = new GlobalMenuMobile({
            page: this
        });
    }
});
