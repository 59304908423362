'use strict';

const _ = require('underscore');
const Backbone = require('backbone');
const Constants = require('constants/Constants');

const MobileSlider = require('components/MobileSlider/MobileSlider');

require('jquery-touchswipe');

require('./CommonGallery.less');

const Selectors = {
    el: '.CommonGallery',
    items: '.CommonGallery-items',
    arrowLeft: '.CommonGallery-items-arrowLeft',
    arrowRight: '.CommonGallery-items-arrowRight',
    itemsInnerWrapper: '.CommonGallery-itemsWrapperInner',
    navLine: '.CommonGallery-itemsNavItem',
    singleItem: '.CommonGallery-item',
    descriptionItem: '.CommonGallery-descriptionItem'
};

const BindFunctions = [
    'changeActiveDescription'
];


module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this.elem = Selectors.el;

        if (this.options.blockSelector) {
            this.setElement(options.blockSelector + '_gallery');
            this.elem = options.blockSelector + '_gallery';
        }

        this.slider = new MobileSlider({
            page: this,
            el: this.elem,
            arrowLeft: Selectors.arrowLeft,
            arrowRight: Selectors.arrowRight,
            innerWrapper: Selectors.itemsInnerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.singleItem,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            infiniteDesktop: true,
            callback: this.changeActiveDescription
        });
    },

    changeActiveDescription: function (activeElementId) {
        this.$(Selectors.descriptionItem)
            .removeClass(Constants.cssClasses.active);

        this.$(`${Selectors.descriptionItem}[data-id="${activeElementId}"]`)
            .addClass(Constants.cssClasses.active);
    }
});
