'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');

require('./PageCompanyStrategy.less');

const Selectors = {
    el: '.PageCompanyStrategy'
};

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;

        PageGlobalTemplate.prototype.initialize.call(this, options);
    }
});
