'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');

const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

require('./Tabs.less');

const Selectors = {
    el: '.Tabs',
    tab: '.Tabs-tab'
};

const BindFunctions = [
    'selectTab',
    'setActiveTab',
    'disableTabs'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.tab}`]: this.selectTab
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.callback = options.callback;
        this.initialActiveTabNumber = options.activeTab || 0;

        this.setActiveTabByNumber(this.initialActiveTabNumber);
    },

    selectTab: function (event) {
        const tabId = $(event.target)
            .closest(Selectors.tab)
            .data('id');

        this.setActiveTab(tabId);
    },

    disableTabs: function () {
        this.$(Selectors.tab)
            .removeClass(Constants.cssClasses.active);
    },

    setActiveTab: function (tabId) {
        this.$(Selectors.tab)
            .removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.tab}[data-id="${tabId}"]`)
            .addClass(Constants.cssClasses.active);

        this.callback(tabId);
    },

    setActiveTabByNumber: function (tabNumber) {
        const $activeTab = $(this.$(Selectors.tab)
            .get(tabNumber));

        $activeTab.addClass(Constants.cssClasses.active);
    }
});
