'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

const InspirationCards = require('components/Common/InspirationCard/InspirationCard');
const CardsSlider = require('./InspirationCardsMobile');
require('slick-carousel');
require('slick-carousel/slick/slick.less');

require('./CareersLandingLocations.less');

const Selectors = {
    el: '.CareersLandingLocations',
    arrowLeft: '.CareersLandingLocations-mapArrowLeft',
    arrowRight: '.CareersLandingLocations-mapArrowRight',
    mapImageMobile: '.CareersLandingLocations-map_mobile img',
    mapMobile: '.CareersLandingLocations-map_mobile',
    inspirationCard: '.InspirationCard-link',
    cards: '.CareersLandingLocations-cards',
    cardsWrapperInner: '.CareersLandingLocations-cardsWrapperInner',
    arrowLeftDesktop: '.CareersLandingLocations-cardsArrowLeft',
    arrowRightDesktop: '.CareersLandingLocations-cardsArrowRight',
    card: '.CareersLandingLocations-card'
};

const BindFunctions = [
    '_initChildComponents',
    'translateMap',
    '_initMapSwipe',
    'openPopup',
    '_initSlick'
];

const MapPositions = [
    'translate3d(0, 0, 0)',
    'translate3d(-30%, 0, 0)',
    'translate3d(-67%, 0, 0)'
];

const SwipeConstants = {
    autoPageScroll: 'auto',
    excludedElements: 'button, input, select, textarea, .noSwipe',
    directions: {
        right: 'right',
        left: 'left'
    },
    threshold: 0
};

const CssClasses = {
    isSlider: 'isSlider'
};

const TransitionSpeed = 300;
const SlidesToShow = 3;
const SlidesToScroll = 1;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowLeft}`]: this.translateMap,
            [`${ViewportEvents.click} ${Selectors.arrowRight}`]: this.translateMap,
            [`${ViewportEvents.click} ${Selectors.inspirationCard}`]: this.openPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.currentMapPosition = 1;
        this.$arrowRight = this.$(Selectors.arrowRight);
        this.$arrowLeft = this.$(Selectors.arrowLeft);
        this.$cardsWrapperInner = this.$(Selectors.cardsWrapperInner);
        this._initChildComponents();
        this._initMapSwipe();

        const isSlider = this.$(Selectors.cards)
            .hasClass(CssClasses.isSlider);

        if (!isSlider || app.isPhoneLayout()) {
            return;
        }

        this._initSlick();
    },

    _initSlick: function () {
        this.$cardsWrapperInner
            .slick({
                infinite: false,
                arrows: true,
                prevArrow: Selectors.arrowLeftDesktop,
                nextArrow: Selectors.arrowRightDesktop,
                fade: false,
                speed: TransitionSpeed,
                draggable: false,
                slidesToShow: SlidesToShow,
                slidesToScroll: SlidesToScroll
            });
    },

    _initMapSwipe: function () {
        this.$(Selectors.mapMobile)
            .swipe({
                allowPageScroll: SwipeConstants.autoPageScroll,
                threshold: SwipeConstants.threshold,
                fallbackToMouseEvents: true,
                excludedElements: SwipeConstants.excludedElements,
                swipe: function (event, direction) {
                    (direction === SwipeConstants.directions.left) &&
                    this.translateMap(undefined, Constants.translateDirections.left);

                    (direction === SwipeConstants.directions.right) &&
                    this.translateMap(undefined, Constants.translateDirections.right);
                }.bind(this)
            });
    },

    _initChildComponents: function () {
        this.cards = new InspirationCards();
        this.cardsSlider = new CardsSlider();
    },

    translateMap: function (event, direction) {
        let isArrowRightClicked;

        if (event) {
            isArrowRightClicked = $(event.target)
                .closest(Selectors.arrowRight).length;
        } else {
            isArrowRightClicked = direction === Constants.translateDirections.left;
        }

        isArrowRightClicked ? this.currentMapPosition++ : this.currentMapPosition--;

        this.$arrowRight.toggleClass(Constants.cssClasses.hide, this.currentMapPosition >= 2);
        this.$arrowLeft.toggleClass(Constants.cssClasses.hide, this.currentMapPosition <= 0);

        this.$(Selectors.mapImageMobile)
            .css('transform', MapPositions[this.currentMapPosition]);
    },

    openPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.inspirationCard);

        const $card = $target.closest(Selectors.card);
        const cardPositionLeft = $card.offset().left;
        const cardWidth = $card.width();
        if (cardPositionLeft + cardWidth > app.sizes.windowWidth) {
            this.$cardsWrapperInner.slick(Constants.slickMethods.goNext);

            return;
        }

        if (cardPositionLeft < 0) {
            this.$cardsWrapperInner.slick(Constants.slickMethods.goPrev);

            return;
        }
        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.careers +
            Events.popup.careersFactory + Events.popup.popupOpen, {apiUrl, url});
    }
});
