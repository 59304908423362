'use strict';

const _ = require('underscore');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactQualified';
const Dropdown = require('components/Common/Dropdown/Dropdown');

const ContactQualifiedItem = require('./ContactQualifiedItem.html');

require('./ContactQualified.less');

const CurrentPage = 'qualified';

const BindFunctions = [
    'onFilterDropdown',
    'setDefaultValues',
    'setFilterValues',
    'initChildComponents',
    'getApiUrl',
    'getFilteredValues',
    'renderNewItems',
    'onFilterInput',
    'attachEvents',
    'checkClearFiltersVisibility',
    'isFiltered'
];

const Selectors = {
    input: '.ContactQualified-filterItem input',
    clearFilters: '.ContactQualified-clearFilters',
    filters: '.ContactQualified-filters',
    resultsWrapper: '.ContactQualified-infoWrapper'
};

const DefaultView = createContactComponent({selectorComponent: SelectorComponent});

module.exports = DefaultView.extend({
    events: function () {
        return {
            ...DefaultView.prototype.events.call(this),
            [`${ViewportEvents.click} ${Selectors.clearFilters}`]: this.setDefaultValues
        };
    },

    initialize: function (options = {}) {
        DefaultView.prototype.initialize.call(this, options);
        _.bindAll(this, BindFunctions);
        this.initChildComponents();
        this.attachEvents();
        this.checkClearFiltersVisibility();
    },

    getApiUrl: function () {
        return this.$(Selectors.filters)
            .data('api-url');
    },

    attachEvents: function () {
        this.$(Selectors.input)
            .on(ViewportEvents.input, _.debounce(this.onFilterInput, 300)
                .bind(this));
    },

    initChildComponents: function () {
        const countryFilter = new Dropdown({
            id: 'country',
            onChooseOption: this.onFilterDropdown,
            modifier: CurrentPage
        });

        const distanceFilter = new Dropdown({
            id: 'distance',
            onChooseOption: this.onFilterDropdown,
            modifier: CurrentPage
        });

        this.filters = {
            country: {
                dropdown: countryFilter,
                model: Constants.filter.noFilterValue,
                isFiltered: false,
                defaultModel: Constants.filter.noFilterValue
            },
            zip: {
                $input: this.$(Selectors.input),
                model: '',
                isFiltered: false,
                defaultModel: ''
            },
            distance: {
                dropdown: distanceFilter,
                model: Constants.filter.noFilterValue,
                isFiltered: false,
                defaultModel: Constants.filter.noFilterValue
            }
        };

        this.setFilterValues();
    },

    setFilterValues: function () {
        this.filters.country.dropdown.setValue(undefined, this.filters.country.model);
        this.filters.zip.$input.val(this.filters.zip.model);
        this.filters.distance.dropdown.setValue(undefined, this.filters.distance.model);
        this.checkClearFiltersVisibility();
    },

    setDefaultValues: function () {
        this.filters.country.model = this.filters.country.defaultModel;
        this.filters.zip.model = this.filters.zip.defaultModel;
        this.filters.distance.model = this.filters.distance.defaultModel;

        this.setFilterValues();
        this.checkClearFiltersVisibility();
    },

    checkClearFiltersVisibility: function () {
        this.$(Selectors.clearFilters)
            .toggleClass(Constants.cssClasses.hide, !this.isFiltered());
    },

    isFiltered: function () {
        return !!Object.values(this.filters)
            .find(value => value.model !== value.defaultModel);
    },

    onFilterDropdown: function (id, itemId, itemType) {
        if (!itemType) {
            return;
        }

        this.filters[itemType].model = itemId;

        this.getFilteredValues();
        this.checkClearFiltersVisibility();
    },

    onFilterInput: function (event) {
        const value = $(event.target)
            .closest(Selectors.input)
            .val();

        this.filters.zip.model = value;

        if (!value || value.length < 3) {
            return;
        }

        this.getFilteredValues();
        this.checkClearFiltersVisibility();
    },

    getFilteredValues: function () {
        const data = {
            country: this.filters.country.model,
            zip: this.filters.zip.model,
            distance: this.filters.distance.model
        };

        $.getJSON(this.getApiUrl(), data, this.renderNewItems);
    },

    renderNewItems: function (res) {
        if (!res) {
            return;
        }

        let itemsHtml = '';

        res.forEach((item) => {
            const itemHtml = ContactQualifiedItem.render({item});

            itemsHtml += itemHtml;
        });

        if (itemsHtml === '') {
            return;
        }
        this.$(Selectors.resultsWrapper)
            .html(itemsHtml);
    }
});
