'use strict';

const _ = require('underscore');
const BasePopup = require('popups/BasePopup/BasePopup');
const ViewportEvents = require('constants/ViewportEvents');

const ProductMasterpieceCard = require('components/ProductMasterpieceCard/ProductMasterpieceCard');
const ProductMasterpieceDescription = require('components/ProductMasterpieceDescription/ProductMasterpieceDescription');
const ProductMasterpieceFooter = require('components/ProductDetailsFooter/ProductDetailsFooter');

const stickybits = require('stickybits/dist/stickybits.min');
require('./ProductMasterpiecePopup.less');

const Selectors = {
    el: '.ProductMasterpiecePopup',
    content: '.ProductMasterpiecePopup-content',
    description: '.ProductMasterpiecePopup-description',
    cardWrapper: '.ProductMasterpiecePopup-cardWrapper',
    card: '.ProductMasterpieceCard',
    descriptionInner: '.ProductMasterpieceDescription',
    popupWrapper: '.Popup-wrapper',
    topNavWrapper: '.TopNav-wrapper'
};

const BindFunctions = [
    'onResize'
];

module.exports = BasePopup.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this.popupClass = '.ProductMasterpiecePopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        this.registerChildView(ProductMasterpieceCard, {page: this});
        this.registerChildView(ProductMasterpieceDescription, {page: this});
        this.registerChildView(ProductMasterpieceFooter, {page: this});
    },

    initRenderedPopup: function () {
        BasePopup.prototype.initRenderedPopup.call(this);

        this.onResize();

        if (!window.app.els.$html.hasClass('isIE')) {
            return;
        }

        const cardHeight = this.$(Selectors.card)
            .height();
        const descriptionHeight = this.$(Selectors.descriptionInner)
            .height();
        const $stickyEl = cardHeight < descriptionHeight ? this.$(Selectors.card) : this.$(Selectors.descriptionInner);
        this.stickyBits = stickybits($stickyEl[0], {
            scrollEl: this.$el.find(Selectors.popupWrapper)[0],
            stickyBitStickyOffset: $(Selectors.topNavWrapper)
                .position().top
        });

        this.$(Selectors.descriptionInner).css('top', '');
        this.$(Selectors.card).css('top', '');

        $(document)
            .on(ViewportEvents.domContentLoaded, () => {
                this.stickyBits.update();
            });

        $(window)
            .on(ViewportEvents.resize, this.onResize);
    },

    onResize: function () {
        BasePopup.prototype.onResize.call(this);

        if (window.app.els.$html.hasClass('isIE') && this.stickyBits) {
            this.stickyBits.update({
                stickyBitStickyOffset: $(Selectors.topNavWrapper)
                    .position().top
            });

            return;
        }

        const popupContainerHeight = this.$el.innerHeight();
        const descriptionHeight = this.$(Selectors.description)
            .outerHeight();
        const cardHeight = this.$(Selectors.cardWrapper)
            .outerHeight();
        const offsetDescription = popupContainerHeight - descriptionHeight;
        const offsetCard = popupContainerHeight - cardHeight;

        if (offsetDescription < 0) {
            this.$(Selectors.description)
                .css('top', offsetDescription);
        }

        if (offsetCard < 0) {
            this.$(Selectors.cardWrapper)
                .css('top', offsetCard);
        }
    }
});
