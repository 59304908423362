'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');

require('./ProductsFilterMobile.less');

const Selectors = {
    el: '.ProductsFilterMobile',
    popup: '.ProductsFilterMobile-popup',
    itemActiveFilters: '.ProductsFilterMobile-itemActiveFilters',
    itemSelect: '.ProductsFilterMobile-itemSelect',
    itemSelectText: '.ProductsFilterMobile-itemSelectText',
    popupFilterItem: '.ProductsFilterMobile-popupFilterItem',
    popupFilterItemTitle: '.ProductsFilterMobile-popupFilterItemTitle',
    popupFilterItemSelectVal: '.ProductsFilterMobile-popupFilterItemSelectVal',
    popupFilterItemSelectText: '.ProductsFilterMobile-popupFilterItemSelectText',
    popupFilterItemSelectValActive: `.ProductsFilterMobile-popupFilterItemSelectVal--${Constants.cssClasses.active}`,
    popupFilterClear: '.ProductsFilterMobile-popupFilterClear',
    itemActiveFiltersItem: '.ProductsFilterMobile-itemActiveFiltersItem',
    popupFilterClose: '.ProductsFilterMobile-popupFilterClose'
};

const BindFunctions = [
    'clearFilter',
    '_attachEvents',
    '_initVariables',
    'setValue'
];

const CssClasses = {
    popupHide: 'ProductsFilterMobile-popup--hide',
    popupFilterClearHide: 'ProductsFilterMobile-popupFilterClear--hide',
    itemActiveFiltersItem: 'ProductsFilterMobile-itemActiveFiltersItem',
    popupFilterItemSelectValActive: `ProductsFilterMobile-popupFilterItemSelectVal--${Constants.cssClasses.active}`
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.itemSelect}`]: this.openSelect,
            [`${ViewportEvents.click} ${Selectors.popupFilterItemSelectVal}`]: this.changeFilter,
            [`${ViewportEvents.click} ${Selectors.popupFilterClear}`]: this.clearFilter,
            [`${ViewportEvents.click} ${Selectors.itemActiveFiltersItem}`]: this.clearFilter,
            [`${ViewportEvents.click} ${Selectors.popupFilterClose}`]: this.closeSelect
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);
        this._attachEvents();

        if (!this.preFiltered) {
            return;
        }

        Object.entries(this.preFiltered)
            .filter(([, value]) => !!value)
            .forEach(([key, value]) => {
                this.setValue(undefined, +value, key);
            });
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.products + Events.filter.clearFilters, this.clearFilter);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$popupSelect = this.$(Selectors.popup);
        this.preFiltered = options.preFiltered;
    },

    openSelect: function () {
        this.$popupSelect.removeClass(CssClasses.popupHide);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.mobileOpen);
        this.triggerPopupShown();
    },

    closeSelect: function () {
        this.$popupSelect.addClass(CssClasses.popupHide);
        app.vent.trigger(Events.eventPrefixes.products + Events.filter.mobileClose);
        this.triggerPopupHidded();
    },

    setValue: function ($item, idItem, typeItem) {
        if (!$item) {
            $item = this.$(`${Selectors
                .popupFilterItem}[data-type="${typeItem}"] ${Selectors.popupFilterItemSelectVal}[data-id="${idItem}"]`);
        }

        const textItem = $item.data('text');
        if (!idItem) {
            idItem = $item.data('id');
        }

        if (!typeItem) {
            typeItem = $item.data('type');
        }

        const $filterTitle = $item
            .closest(Selectors.popupFilterItem)
            .find(Selectors.popupFilterItemTitle);
        const catItem = $filterTitle.html();

        const $itemActiveFilter = document.createElement('div');
        $itemActiveFilter.className = CssClasses.itemActiveFiltersItem;
        $itemActiveFilter.innerHTML = '<span>' + catItem + '</span>&nbsp;' + textItem;

        this.$(Selectors.itemActiveFilters)
            .html('');

        this.$(Selectors.popupFilterItemSelectValActive)
            .removeClass(CssClasses.popupFilterItemSelectValActive);

        $item.addClass(CssClasses.popupFilterItemSelectValActive);

        this.$(Selectors.itemActiveFilters)
            .append($itemActiveFilter);

        this.$(Selectors.popupFilterClear)
            .toggleClass(CssClasses.popupFilterClearHide, idItem === null);

        this.$(Selectors.itemSelectText)
            .text(this.$(Selectors.itemSelectText)
                .data('default') + ' (1)');

        app.vent.trigger(Events.eventPrefixes.products + Events.filter.onFilter, idItem, typeItem);

        this.closeSelect();
    },

    changeFilter: function (e) {
        const $item = $(e.currentTarget);

        this.setValue($item);
    },

    clearFilter: function () {
        this.$(Selectors.itemActiveFilters)
            .html('');
        this.$(Selectors.popupFilterItemSelectValActive)
            .removeClass(CssClasses.popupFilterItemSelectValActive);

        this.$(Selectors.popupFilterClear)
            .addClass(CssClasses.popupFilterClearHide);

        this.$(Selectors.itemSelectText)
            .text(this.$(Selectors.itemSelectText)
                .data('default'));

        this.closeSelect();

        app.vent.trigger(Events.eventPrefixes.products + Events.filter.onFilter, null, '');
    },

    triggerPopupShown: function () {
        this.savedScroll = window.app.els.$window
            .scrollTop();
        app.vent.trigger(Events.filter.filterShown);
        window.app.els.$window
            .scrollTop(0);
    },

    triggerPopupHidded: function () {
        app.vent.trigger(Events.filter.filterHidden);
        window.app.els.$window
            .scrollTop(this.savedScroll);
    }

});
