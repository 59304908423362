'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const PerfectScrollbar = require('perfect-scrollbar').default;
const scrollLock = require('scroll-lock');

require('./ImprintPopup.less');

const Selectors = {
    el: '.ImprintPopup',
    bg: '.ImprintPopup-bg',
    close: '.ImprintPopup-close',
    inputWrapper: '.ImprintPopup-inputWrapper'
};

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    'getDataFromApi',
    '_attachEvents',
    'triggerPopupHidden',
    'triggerPopupShown'
];

const CssClasses = {
    imprintPopupHide: 'ImprintPopup--hide'
};

const ImprintHash = 'imprint';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick
        };
    },

    initialize: function (options = {}) {
        this.options = options;

        _.bindAll(this, BindFunctions);
        let self = this;

        if (window.location.hash === `#${ImprintHash}`) {
            setTimeout(function () {
                self.show();
            });
        }

        this._attachEvents();

        this.popupWrapper = Selectors.inputWrapper;


        this.getDataFromApi();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.imprint + Events.popup.popupOpen, this.show);
        app.vent.on(Events.eventPrefixes.contact + Events.overlay.mobileOverlayClose, this.closeOverlay);
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },

    show: function () {
        this.$el.removeClass(CssClasses.imprintPopupHide);
        scrollLock.addScrollableSelector(Selectors.inputWrapper);
        this.triggerPopupShown();

        window.app.els.$window.on(ViewportEvents.keyDown, this.onKeydown);
        window.app.utils.changeHash(ImprintHash);
    },


    hide: function () {
        scrollLock.removeScrollableSelector(Selectors.tabContentWrapper);

        if (window.app.isPhoneLayout()) {
            this.triggerPopupHidden();
        } else {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', this.triggerPopupHidden, 300);
        }
        this.$el.addClass(CssClasses.imprintPopupHide);
        window.app.utils.removeHash();
        window.app.els.$window.off(ViewportEvents.keyDown, this.onKeydown);
    },

    triggerPopupShown: function () {
        this.savedScroll = $(window)
            .scrollTop();

        window.app.els.$body.addClass(Constants.cssClasses.overlayShow);
        if (window.app.isPhoneLayout()) {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', () => {
                app.vent.trigger(Events.popup.popupShown);
            }, 300);

            return;
        }

        app.vent.trigger(Events.popup.popupShown);
    },

    triggerPopupHidden: function () {
        app.vent.trigger(Events.popup.popupHidden);
        window.app.els.$body.removeClass(Constants.cssClasses.overlayShow);
        window.requestAnimationFrame(() => {
            $(window)
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });
    },

    render: function (data) {
        console.log(data); // data will be used during popup render, so this console.log just to avoid lint error

        if (window.app.settings.isDesktop) {
            this.ps = new PerfectScrollbar(this.popupWrapper);
            $(this.popupWrapper)
                .on(ViewportEvents.scroll, this.onScroll);
            $(window)
                .on(ViewportEvents.resize, this.onResize);
            this.onResize();
            this.onScroll();
            this.ps.update();
        }
    },

    getDataFromApi: function () {
        // function to get data from API when popup is rendering in runtime
        // must be overrided


        // render method must be call after async request completed
        const data = {};
        this.render(data);
    },

    onCloseClick: function () {
        this.hide();
    },

    onScroll: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    onResize: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    }
});
