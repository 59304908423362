'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const UpcomingEvent = require('components/UpcomingEvent/UpcomingEvent');
const UpcomingEventTemplate = require('components/UpcomingEvent/UpcomingEventRuntime.html');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

const CampusEventPopup = require('popups/CampusEventPopup/CampusEventPopup');

require('./CampusUpcomingEvents.less');

const Selectors = {
    el: '.CampusUpcomingEvents',
    events: '.CampusUpcomingEvents-events',
    event: '.CampusUpcomingEvents-event',
    upcomingEventUrl: '.UpcomingEvent-url',
    upcomingEvent: '.UpcomingEvent',
    showMore: '.CampusUpcomingEvents-showMore button',
    eventType: '.UpcomingEvent-type'
};

const BindFunctions = [
    '_initChildComponents',
    '_initVariables',
    'onFilterDropdown',
    'fetchNewEvents',
    'onShowMoreButtonClick',
    'onTypeClick',
    'reattachEvents',
    'openEventPopup'
];

const ComponentModifier = 'campus-upcoming-events';
const InitialOffset = 6;
const EventWrapperStart = '<div class="CampusUpcomingEvents-event">';
const EventWrapperEnd = '</div>';
const TransitionCoefficient = 0.05;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.showMore}`]: this.onShowMoreButtonClick,
            [`${ViewportEvents.click} ${Selectors.eventType}`]: this.onTypeClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        if (options.modifier) {
            this.setElement(`${Selectors.el}--${options.modifier}`);
        }
        this._initChildComponents();
        this._initVariables();
        this.reattachEvents();
    },

    _initVariables: function () {
        this.filters = {
            type: '',
            location: ''
        };
        this.offset = InitialOffset;
        this.$event = this.$(Selectors.event);
        this.$eventsWrapper = this.$(Selectors.events);
        this.$showMore = this.$(Selectors.showMore);
        this.apiUrl = this.$el.data('api-url');
        this.theme = this.$el.data('theme');
    },

    _initChildComponents: function () {
        this.dropdowns = [];
        this.upcomingEvent = new UpcomingEvent();
        const typeDropdown = new Dropdown({
            id: 'type',
            onChooseOption: this.onFilterDropdown,
            modifier: ComponentModifier
        });
        const locationDropdown = new Dropdown({
            id: 'location',
            onChooseOption: this.onFilterDropdown,
            modifier: ComponentModifier
        });

        this.dropdowns.push(typeDropdown);
        this.dropdowns.push(locationDropdown);
    },

    onFilterDropdown: function (type, value) {
        this.filters[type] = value;
        this.offset = InitialOffset;
        this.fetchNewEvents(true);
    },

    onShowMoreButtonClick: function () {
        this.offset = this.$event.length;
        this.fetchNewEvents();
    },

    onTypeClick: function (event) {
        event.preventDefault();
        event.stopPropagation();
        const type = $(event.target)
            .closest(Selectors.eventType)
            .data('type');
        this.dropdowns[0].setValue(null, type);
    },

    fetchNewEvents: function (isOnFilter = false) {
        const offset = isOnFilter ? 0 : this.offset;

        const data = {
            filters: this.filters,
            offset
        };

        $.getJSON(this.apiUrl, data, (res) => {
            this.$showMore.hide();
            if (isOnFilter) {
                this.$eventsWrapper.empty();
            }

            if (!res) {
                return;
            }

            if (res.hasMore) {
                this.$showMore.show();
            }

            if (!res.items.length) {
                return;
            }
            res.items.forEach((event, index) => {
                const eventRendered = UpcomingEventTemplate.render({data: event, theme: this.theme});
                this.$eventsWrapper.append(`${EventWrapperStart}${eventRendered}${EventWrapperEnd}`);
                this.$eventsWrapper.find(`${Selectors.event}:last`)
                    .css('transition-delay', `${index * TransitionCoefficient}s`);
            });

            this.reattachEvents();

            setTimeout(() => {
                this.$(Selectors.event)
                    .addClass(Constants.cssClasses.active);
            }, 100);
        });
    },

    reattachEvents: function () {
        this.$event = this.$(Selectors.event);
        const $upcomingEventUrl = this.$event.find(Selectors.upcomingEventUrl);
        $upcomingEventUrl.off(ViewportEvents.click, this.openEventPopup);
        $upcomingEventUrl.click(this.openEventPopup);
    },

    openEventPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.upcomingEvent);

        const url = $target.data('url');
        const apiUrl = $target.data('api-url');

        const popup = new CampusEventPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
