'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const ViewportEvents = require('constants/ViewportEvents');

require('./Checkbox.less');

const Selectors = {
    el: '.Checkbox',
    input: '.Checkbox-input'
};

const BindFunctions = [
    '_toggleCheckbox',
    '_setCheckboxClass',
    'getValue',
    'getLabelText',
    'getName'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.change} ${Selectors.input}`]: this._toggleCheckbox
        };
    },

    /**
     *
     * @param options = {
     *     onModelChange - function that triggers on trigger value changed,
     *     value - initial value of trigger,
     *     name - name for this trigger,
     *     modifier - modifier for the component or page,
     *     id - unique id for this trigger
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.setElement(`${Selectors.el}--${options.modifier}_${options.id}`);
        this.onModelChange = options.onModelChange || (() => {
        });
        this.value = options.value || false;
        this.name = options.name;
        this._setCheckboxClass();
    },

    _setCheckboxClass: function () {
        this.$(Selectors.input)
            .prop('checked', this.value);
    },

    _toggleCheckbox: function () {
        this.value = !this.value;

        this._setCheckboxClass();

        this.onModelChange(this.name, this.value);
    },

    setValue: function (value) {
        this.value = value;
        this._setCheckboxClass();
        this.onModelChange(this.name, this.value);
    },

    getValue: function () {
        return this.value;
    },

    getLabelText: function () {
        return this.$el.find('label')
            .text();
    },

    getName: function () {
        return this.name;
    }
});
