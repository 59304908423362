'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const ProductOptionsPopup = require('popups/ProductFamilyPopup/ProductFamilyPopup');
const ProductFinderSlider = require('./ProductFinderSlider');
const ProductFinderSliderMobile = require('./ProductFinderSliderMobile');

require('./ProductFinder.less');

const Selectors = {
    el: '.ProductFinder',
    option: '.ProductFinder-option',
    item: '.ProductFinder-item',
    itemTitle: '.ProductFinder-itemTitle',
    allOptionsButton: '.ProductFinder-allOptions',
    optionsList: '.ProductFinder-optionsList',
    sliderInner: '.CardsSlider-objectsInner',
    bottomLineSlider: '.ProductFinder-itemOptionLine.slider',
    leftArrow: '.ProductFinder-arrowLeft',
    rightArrow: '.ProductFinder-arrowRight',
    leftArrowMobile: '.ProductFinder-arrowLeft_mobile',
    rightArrowMobile: '.ProductFinder-arrowRight_mobile',
    titleWrapper: '.ProductFinder-titleWrapper',
    contentWrapper: '.ProductFinder-accordionWrapper'
};

const BindFunctions = [
    '_setRowsHeight',
    '_attachEvents',
    'onSeeAllOptionsClick',
    '_setItemTitleFontSize',
    'onResize',
    '_initSlider',
    '_prepareFinderAccordionWrapper'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.allOptionsButton}`]: this.onSeeAllOptionsClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this.selector = options.el || Selectors.el;
        this.index = options.index;
        this.setElement(this.selector);

        this.isAccordion = 'accordion' in this.$el.data();

        this._initSlider();

        this._setRowsHeight._throttled = _.throttle(this._setRowsHeight, 50);
        this._setItemTitleFontSize._throttled = _.throttle(this._setItemTitleFontSize, 50);
        this._attachEvents();

        $(document)
            .ready(() => {
                this._setRowsHeight();
                this._setItemTitleFontSize();
                this._prepareFinderAccordionWrapper();
                this._showFinder();
            });
    },

    _showFinder: function () {
        this.$el.addClass(Constants.cssClasses.show);
    },

    _prepareFinderAccordionWrapper: function () {
        const $contentWrapper = this.$(Selectors.contentWrapper);
        $contentWrapper.css('transition', 'none');
        const isDisabled = this.$el.hasClass(Constants.cssClasses.disabled);
        this.$el.removeClass(Constants.cssClasses.disabled);
        const maxHeight = this.$(Selectors.contentWrapper)
            .height();
        $contentWrapper.css('max-height', `${maxHeight}px`);
        this.$el.toggleClass(Constants.cssClasses.disabled, isDisabled);
        $contentWrapper.css('transition', '');
    },

    _attachEvents: function () {
        window.app.els.$window.on(ViewportEvents.resize, this.onResize);
        if (!this.isAccordion) {
            return;
        }

        this.$el.find(Selectors.titleWrapper)
            .on(ViewportEvents.click, (event) => {
                const $target = $(event.target)
                    .closest(Selectors.el);
                const isElemDisabled = $target.hasClass(Constants.cssClasses.disabled);
                $target.toggleClass(Constants.cssClasses.disabled, !isElemDisabled);
            });
    },

    onResize: function () {
        this._setRowsHeight._throttled();
        this._setItemTitleFontSize._throttled();

        this._initSlider();
    },

    _initSlider: function () {
        const isPhoneLayout = window.app.isPhoneLayout();
        this.sliderMobile && this.sliderMobile.setTransitionWidth();
        if (this.sliderMobile && isPhoneLayout || this.slider && !isPhoneLayout) {
            return;
        }

        if (isPhoneLayout) {
            this.sliderMobile = new ProductFinderSliderMobile({
                $rightArrow: this.$(Selectors.rightArrowMobile),
                $leftArrow: this.$(Selectors.leftArrowMobile),
                index: this.index
            });
        } else {
            this.slider = new ProductFinderSlider({
                $rightArrow: this.$(Selectors.rightArrow),
                $leftArrow: this.$(Selectors.leftArrow),
                index: this.index
            });
        }
    },

    _setRowsHeight: function () {
        const optionsCount = this.$el.data('options-count');

        for (let i = 0; i < optionsCount; i++) {
            let maxHeight = 0;
            const $rows = this.$(`[data-row-id="${i}"]`);
            $rows.height('auto');

            $rows.each((index, row) => {
                const rowHeight = $(row)
                    .height();

                if (rowHeight > maxHeight) {
                    maxHeight = rowHeight;
                }
            });
            $rows.height(maxHeight);
        }
    },

    _setItemTitleFontSize: function () {
        const $items = this.$(Selectors.item);
        const $itemsTitles = this.$(Selectors.itemTitle);

        let maxTitleLength = 0;
        let $itemWithWidestTitle;

        $items.each((index, item) => {
            const $item = $(item);

            const $itemTitle = $item.find(Selectors.itemTitle);
            const itemTitleWidth = $itemTitle.innerWidth();
            if (itemTitleWidth > maxTitleLength) {
                maxTitleLength = itemTitleWidth;
                $itemWithWidestTitle = $(item);
            }
        });

        if (!$itemWithWidestTitle) {
            return;
        }

        const $widestTitle = $itemWithWidestTitle.find(Selectors.itemTitle);

        let titleWidth = $widestTitle.innerWidth();

        const itemWidth = $itemWithWidestTitle.innerWidth();

        while (titleWidth >= itemWidth) {
            let widestTitleFontSize = parseInt($widestTitle.css('font-size'), 10);
            if (app.isPhoneLayout() && widestTitleFontSize >= 14) {
                widestTitleFontSize = $(window)
                    .innerWidth() / 320 * 15;
            }
            $widestTitle.css('font-size', `${widestTitleFontSize - 1}px`);
            titleWidth = $widestTitle.innerWidth();
        }

        $itemsTitles.css('font-size', `${parseInt($widestTitle.css('font-size'), 10)}px`);
        this.$(Selectors.optionsList)
            .css('padding-top', `${$widestTitle.outerHeight(true)}px`);
    },

    onSeeAllOptionsClick: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.allOptionsButton);
        const url = $target.data('url');
        const apiUrl = $target.data('api-url');

        const popup = new ProductOptionsPopup();

        popup.openPopup(apiUrl, url);
    }
});
