'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const Constants = require('constants/Constants');

const SingleCardTemplate = require('./PressDownloadsItem.html');

require('./PressDownloadsItems.less');

const Selectors = {
    el: '.PressDownloadsItems',
    items: '.PressDownloadsItems-item',
    itemsWrapper: '.PressDownloadsItems-wrapper'
};

const BindFunctions = [
    'renderNewCards',
    'clearItems'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this.$(Selectors.items).addClass(Constants.cssClasses.show);
    },

    /**
     *  When filtering or showMore button is pressed then we call this function from outside to
     *  render new items to bottom or full rerender
     *
     * @param items - list of items to render
     * @param isNeedRerender - if true - all previous items clears
     */

    renderNewCards: function (items = [], isNeedRerender = false) {
        isNeedRerender && this.clearItems();

        items.forEach((item, index) => {
            const itemTransitionDelay = `${index * 0.1}s`;
            const itemTemplate = SingleCardTemplate.render({item: item});

            this.$(Selectors.itemsWrapper)
                .append(itemTemplate);

            this.$(`${Selectors.items}:last`).css('transition-delay', itemTransitionDelay);
        });
        setTimeout(() => {
            this.$(Selectors.items).addClass(Constants.cssClasses.show);
        }, 100);
    },

    clearItems: function () {
        this.$(Selectors.itemsWrapper)
            .html('');
    }
});
