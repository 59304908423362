'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');
const Events = require('constants/Events');

const InvestorsLandingHead = require('components/InvestorsLandingHead/InvestorsLandingHead');
const InvestorsStockPrice = require('components/InvestorsStockPrice/InvestorsStockPrice');
const LatestFinancialReports = require('components/Common/LatestFinancialReports/LatestFinancialReports');
const InvestorsUpcomingEvent = require('components/InvestorsUpcomingEvent/InvestorsUpcomingEvent');
const NewsInsights = require('components/Common/NewsInsights/NewsInsights');
const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

require('./PageInvestorsLanding.less');

const ChildViews = [
    {
        name: 'investorsLandingHead',
        Constructor: InvestorsLandingHead
    },
    {
        name: 'investorsStockPrice',
        Constructor: InvestorsStockPrice
    },
    {
        name: 'latestReports',
        Constructor: LatestFinancialReports
    },
    {
        name: 'investorsUpcomingEvent',
        Constructor: InvestorsUpcomingEvent
    },
    {
        name: 'newsInsights',
        Constructor: NewsInsights
    }
];

const BindFunctions = [
    'openPopup'
];

module.exports = PageLanding.extend({

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);

        PageLanding.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    },

    _attachEvents: function () {
        PageLanding.prototype._attachEvents.call(this);

        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    }
});
