'use strict';

const Backbone = require('backbone');
const _ = require('underscore');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

require('./TopNavPopup.less');

const Selectors = {
    topNavPopup: '.TopNavPopup',
    topNavPopupWrapper: '.TopNavPopup-wrapper',
    topNavPopupLink: '.TopNavPopup-link',
    topNavRightSideLink: '.TopNavPopup-subMenu--link',
    topNavBgCircle1: '.TopNavPopup-bgCircle_1',
    topNavBgCircle2: '.TopNavPopup-bgCircle_2',
    topNavBackground: '.TopNavPopup-bgBackground'
};

const CssClasses = {
    topNavPopupOpened: 'TopNavPopup--is-opened'
};

const BindFunctions = [
    '_attachEvents',
    'setMenuActiveItem'
];

module.exports = Backbone.View.extend({

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._attachEvents();

        this.setMenuActiveItem();
    },

    setMenuActiveItem: function () {
        const location = window.location.pathname;
        const $link = this.$(Selectors.topNavPopupLink);
        const $rightSideLink = this.$(Selectors.topNavRightSideLink);

        $link.each(function (index, item) {
            const $link = $(item);
            const href = $link.attr('href');

            $link.toggleClass(Constants.cssClasses.active, href === location);
        });

        $rightSideLink.each(function (index, item) {
            const $link = $(item);
            const href = $link.attr('href');

            $link.toggleClass(Constants.cssClasses.active, href === location);
        });
    },

    _attachEvents: function () {
        app.vent.on(Events.menu.showMenu, function () {
            this.$el.addClass(CssClasses.topNavPopupOpened);
            app.vent.trigger(Events.menu.menuShown);
            this.triggerPopupShown();
        }.bind(this));

        app.vent.on(Events.menu.hideMenu, function () {
            this.$el.removeClass(CssClasses.topNavPopupOpened);

            app.utils.waitForTransitionEnd(this.$el, 'visibility', this.triggerPopupHidded.bind(this), 500);
        }.bind(this));
    },

    triggerPopupShown: function () {
        this.savedScroll = $(window)
            .scrollTop();
        app.vent.trigger(Events.popup.popupShown);
        window.requestAnimationFrame(() => {
            if (window.app.isPhoneLayout()) {
                $(window)
                    .scrollTop(0);
            }
        });

        window.app.els.$window.on(ViewportEvents.mouseMove, this.onTopNavMouseMove);
    },

    triggerPopupHidded: function () {
        app.vent.trigger(Events.popup.popupHidden);
        app.vent.trigger(Events.menu.menuHidden);
        window.requestAnimationFrame(() => {
            $(window)
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });

        window.app.els.$window.off(ViewportEvents.mouseMove, this.onTopNavMouseMove);
    },

    onTopNavMouseMove: function (event) {
        const windowWidth = window.app.els.$window.innerWidth();
        const windowHeight = window.app.els.$window.innerHeight();
        const eventLeft = event.clientX;
        const eventTop = event.clientY;

        const percentageX = eventLeft / windowWidth * 100;
        const percentageY = eventTop / windowHeight * 100;

        const firstBackgroundOpacity = ((100 - percentageY) + (100 - percentageX)) / 200;
        const secondBackgroundOpacity = (percentageX - percentageY) / 100;
        const thirdBackgroundOpacity = (percentageY - (100 - percentageX)) / 100;
        const fourthBackgroundOpacity = (percentageY - percentageX) / 100;
        const fifthBackgroundOpacity = 1 - (Math.abs(percentageX - percentageY) / 100) -
            Math.abs((1 - ((percentageY + percentageX) / 100)));

        [
            firstBackgroundOpacity,
            secondBackgroundOpacity,
            thirdBackgroundOpacity,
            fourthBackgroundOpacity,
            fifthBackgroundOpacity
        ].forEach((opacity, index) => {
            this.$(`${Selectors.topNavBackground}_${index + 1}`)
                .css('opacity', opacity);
        });
    }


});
