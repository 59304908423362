'use strict';

const BasePopup = require('popups/BasePopup/BasePopup');

const TopPageButtons = require('components/TopPageButtons/TopPageButtons');
const CommonBlock = require('components/Common/CommonBlock/CommonBlock');

require('./CareersJobPopup.less');

const Selectors = {
    contentWrapper: '.CareersJobPopup-content',
    block: '.CommonBlock'
};

module.exports = BasePopup.extend({

    el: '.CareersJobPopup',

    events: {},


    initialize: function (options = {}) {
        this.popupClass = '.CareersJobPopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    initChildComponents: function () {
        if (this.childViews && this.childViews.length) {
            return;
        }
        this.registerChildView(TopPageButtons, {page: this});

        const blocksCount = this.$(Selectors.contentWrapper)
            .data('blocks-count');

        for (let i = 0; i < blocksCount; i++) {
            this.registerChildView(CommonBlock, {page: this, blockSelector: `.CareersJobPopup-block--${i}`});
        }
    }
});
