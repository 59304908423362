'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore/underscore.js');
const Dropdown = require('components/Common/Dropdown/Dropdown');

const FiltersMobile = require('components/FiltersMobile/FiltersMobile');

require('./Filters.less');

const Selectors = {
    filters: '.Filters',
    title: '.Filters-title',
    itemSelect: '.Filters-filterItemSelect',
    itemSelectText: '.Filters-filterItemSelectText',
    itemSelectTextParagraph: '.Filters-filterItemSelectText p',
    itemSelectMenuValActive: `.Filters-filterItemSelectMenuVal--${Constants.cssClasses.active}`,
    itemSelectMenuValFirstChild: '.Filters-filterItemSelectMenuVal:first-child',
    itemSelectMenuVal: '.Filters-filterItemSelectMenuVal',
    itemSelectMenu: '.Filters-filterItemSelectMenu',
    clearFilter: '.Filters-filterClear',
    dropdown: '.Dropdown .Dropdown-select'
};

const CssClasses = {
    itemSelectMenuValActive: `Filters-filterItemSelectMenuVal--${Constants.cssClasses.active}`,
    hideFilter: 'Filters-filterClear--hide'
};

const BindFunctions = [
    'onFiltered',
    'clearFilter',
    '_initVariables',
    '_attachEvents',
    '_initChildComponents',
    'toggleSelect',
    'changeFilter'
];

module.exports = Backbone.View.extend({

    el: Selectors.filters,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.clearFilter}`]: this.clearFilter
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._initChildComponents();
        this._attachEvents();

        if (!this.preFiltered || window.app.isPhoneLayout()) {
            return;
        }

        if (options.disableFilterOnLoad) {
            this.disableFiltering = true;
        }

        Object.entries(this.preFiltered)
            .filter(([, value]) => !!value)
            .forEach(([key, value]) => {
                this.dropdowns
                    .filter((dropdown) => dropdown.type === key)
                    .forEach((dropdown) => {
                        dropdown.setValue(undefined, value);
                    });
            });

        this.disableFiltering = false;
    },

    _attachEvents: function () {
        window.app.els.$body
            .on(ViewportEvents.click, this.clickOutSide.bind(this));

        app.vent.on(this.eventPrefix + Events.filter.filtered, this.onFiltered);
        app.vent.on(this.eventPrefix + Events.filter.clearFilters, this.clearFilter);
    },

    _initChildComponents: function () {
        this.filtersMobile = new FiltersMobile({
            page: this,
            currentPage: this.page,
            preFiltered: this.preFiltered
        });

        this.dropdowns = [];
        for (let i = 0; i < this.dropdownCount; i++) {
            const dropdown = new Dropdown({
                id: i,
                onSelectOpen: this.toggleSelect,
                onChooseOption: this.changeFilter,
                modifier: this.page
            });

            this.dropdowns.push(dropdown);
        }
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.page = options.currentPage;
        this.eventPrefix = this.page ? (this.page + '-') : '';
        this.isOpenSelect = false;
        this.dropdownCount = 3;
        this.preFiltered = options.preFiltered;
        this.disableFiltering = false;
    },

    onFiltered: function (countItems) {
        if (countItems !== -1) {
            this.$(Selectors.title)
                .text(window.gettext('products.filter.count_products', '', {count: countItems}));
        } else {
            this.$(Selectors.title)
                .text(this.$(Selectors.title)
                    .data('default'));
        }
    },

    toggleSelect: function (id) {
        this.dropdowns.forEach((dropdown) => {
            if (id !== dropdown.id) {
                dropdown.close();
            }
        });
    },

    clickOutSide: function (e) {
        const elSelect = this.$(Selectors.dropdown);

        //check if the clicked area is dropDown or not
        if (elSelect.has(e.target).length === 0) {
            this.closeAllSelect();
        }
    },

    closeAllSelect: function () {
        this.dropdowns.forEach((dropdown) => {
            dropdown.close();
        });
    },

    changeFilter: function (idDropdown, idItem, typeItem) {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown.id !== idDropdown) {
                dropdown.reset();
            }
        });

        this.$(Selectors.clearFilter)
            .toggleClass(CssClasses.hideFilter, idItem === -1);

        this.closeAllSelect();

        if (this.disableFiltering) {
            return;
        }

        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, idItem, typeItem);
    },

    clearFilter: function () {
        this.dropdowns.forEach((dropdown) => {
            dropdown.reset();
        });

        this.$(Selectors.clearFilter)
            .addClass(CssClasses.hideFilter);

        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, -1, '');
    },

    setValue: function (param, value, property = 'id') {
        this.dropdowns.forEach((dropdown) => {
            if (dropdown[property] !== param) {
                return;
            }

            dropdown.setValue(undefined, value);
        });
    }

});
