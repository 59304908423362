'use strict';

const _ = require('underscore');
const Backbone = require('backbone');
const Constants = require('constants/Constants');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
require('jquery-touchswipe');

const MobileSlider = require('components/MobileSlider/MobileSlider');

require('./ProductsMasterpieces.less');

const BindFunctions = [
    'onMasterpieceItemClick'
];

const Selectors = {
    el: '.ProductsMasterpieces',
    items: '.ProductsMasterpieces-items',
    arrowLeft: '.ProductsMasterpieces-items-arrowLeft',
    arrowRight: '.ProductsMasterpieces-items-arrowRight',
    innerWrapper: '.ProductsMasterpieces-itemsWrapperInner',
    navLine: '.ProductsMasterpieces-itemsNavItem',
    item: '.ProductsMasterpieces-item'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.onMasterpieceItemClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this.slider = new MobileSlider({
            page: this,
            el: Selectors.items,
            arrowLeft: Selectors.arrowLeft,
            arrowRight: Selectors.arrowRight,
            innerWrapper: Selectors.innerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.item,
            activeClassName: Constants.cssClasses.active
        });
    },

    onMasterpieceItemClick: function (event) {
        event.preventDefault();

        const $target = $(event.target).closest(Selectors.item);

        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.masterpiece + Events.popup.popupOpen, {apiUrl, url});
    }

});
