'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

const SingleNewsItemTemplate = require('./NewsLandingItemRuntime.html');
const SocialRowTemplate = require('components/SocialNetworkLine/SocialNetworkLineRuntime.html');
const SocialNetworkLine = require('components/SocialNetworkLine/SocialNetworkLine');
const $clamp = require('clamp-js');

require('./NewsLandingItems.less');

const Selectors = {
    el: '.NewsLandingItems',
    items: '.NewsLandingItems-item',
    itemLink: '.NewsLandingItems-itemLink',
    itemsWrapper: '.NewsLandingItems-wrapper',
    showMoreButton: '.NewsLandingItems-showMore',
    socialNetworkLine: '.SocialNetworkLine',
    itemTitle: '.NewsLandingItems-itemTitle',
    itemDescription: '.NewsLandingItems-itemDescription'
};

const BindFunctions = [
    'renderNewItems',
    'clearItems',
    '_initChildViews',
    '_initVariables',
    'showMore',
    'getCurrentOffset',
    'clampNewsTexts'
];

const MaxLines = 4;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.showMoreButton}`]: this.showMore,
            [`${ViewportEvents.click} ${Selectors.itemLink}`]: this.onItemClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);
        this._initChildViews();
        this.$(Selectors.items)
            .addClass(Constants.cssClasses.show);

        $(document)
            .ready(() => {
                this.clampNewsTexts();
            });
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.onShowMoreButtonClick = options.onShowMoreButtonClick;
    },

    _initChildViews: function () {
        this.socialNetworkLine = new SocialNetworkLine();
    },

    /**
     *  When filtering or showMore button is pressed then we call this function from outside to
     *  render new items to bottom or full rerender
     *
     * @param data - {
     *     items: [] - list of news items,
     *     socialItems: [] - list of social network items for SocialNetworkRow,
     *     hasMore: boolean - if there are more items to load
     *     readMore: string - text for show more button
     * }
     * @param isNeedRerender - if true - all previous items clears
     */
    renderNewItems: function (data = {}, isNeedRerender = false) {
        isNeedRerender && this.clearItems();
        const items = data.items;
        const socialNetworkItems = data.socialItems;
        const hasMore = data.hasMore;
        const readMore = data.readMore;

        items.forEach((item, index) => {
            const itemTransitionDelay = `${index * 0.1}s`;
            const itemTemplate = SingleNewsItemTemplate
                .render({item, readMore});

            this.$(Selectors.itemsWrapper)
                .append(itemTemplate);
            this.$(`${Selectors.items}:last`)
                .css('transition-delay', itemTransitionDelay);
            if (index === 3 && !this.$(Selectors.socialNetworkLine).length && socialNetworkItems
                && socialNetworkItems.length
            ) {
                const socialNetworkRowTemplate = SocialRowTemplate.render({
                    items: socialNetworkItems
                });

                this.$(Selectors.itemsWrapper)
                    .append(socialNetworkRowTemplate);
            }
        });

        setTimeout(() => {
            this.$(Selectors.items)
                .addClass(Constants.cssClasses.show);
        }, 100);

        this.clampNewsTexts();
        this.$(Selectors.showMoreButton)
            .toggleClass(Constants.cssClasses.hide, !hasMore);
    },

    clampNewsTexts: function () {
        this.$(Selectors.items)
            .each((index, item) => {
                const title = $(item)
                    .find(Selectors.itemTitle)[0];
                const description = $(item)
                    .find(Selectors.itemDescription)[0];
                $clamp(title, {clamp: 3});

                const divHeight = $(title)
                    .innerHeight();
                const lineHeight = parseFloat(window.getComputedStyle(title)
                    .getPropertyValue('line-height')
                    .replace('px', ''));

                const lines = Math.ceil(divHeight / lineHeight);
                const descriptionLines = MaxLines - lines;
                $clamp(description, {clamp: descriptionLines});
            });
    },

    clearItems: function () {
        this.$(Selectors.itemsWrapper)
            .empty();
    },

    showMore: function (event) {
        const $showMoreButton = $(event.target)
            .closest(Selectors.showMoreButton);
        const apiUrl = $showMoreButton.data('api-url');

        this.onShowMoreButtonClick(apiUrl);
    },

    getCurrentOffset: function () {
        return this.$(Selectors.items).length;
    },

    onItemClick: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.itemLink);
        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.news + Events.popup.popupOpen, {apiUrl, url});
    }
});
