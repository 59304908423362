var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/PressDownloadsItems/PressDownloadsItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/PressDownloadsItems/PressDownloadsItem.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<div class=\"PressDownloadsItems-item\">\n    <div class=\"PressDownloadsItems-itemImg\">\n        ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image")) {
output += "\n            <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"image"), env.opts.autoescape);
output += "\" alt=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"imageAlt"), env.opts.autoescape);
output += "\"/>\n        ";
;
}
else {
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"logo")) {
output += "\n            <div class=\"PressDownloadsItems-itemImgPlaceholder\">\n                <img src=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"logo"), env.opts.autoescape);
output += "\"/>\n            </div>\n        ";
;
}
;
}
output += "\n        ";
if(env.getFilter("length").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")) == 1) {
output += "\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),0)),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow\" download\n               class=\"PressDownloadsItems-itemImgOverlay PressDownloadsItems-itemImgOverlay--full\">\n                <span class=\"PressDownloadsItems-itemImgOverlayBg\"></span>\n                <span class=\"PressDownloadsItems-itemImgOverlayText c-light s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),0)),"title"), env.opts.autoescape);
output += "</span>\n            </a>\n        ";
;
}
else {
output += "\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),0)),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow\" download\n               class=\"PressDownloadsItems-itemImgOverlay PressDownloadsItems-itemImgOverlay--left\">\n                <span class=\"PressDownloadsItems-itemImgOverlayBg\"></span>\n                <span class=\"PressDownloadsItems-itemImgOverlayText c-light s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),0)),"title"), env.opts.autoescape);
output += "</span>\n            </a>\n            <div class=\"PressDownloadsItems-itemImgOverlaySepar\"></div>\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),1)),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow\" download\n               class=\"PressDownloadsItems-itemImgOverlay PressDownloadsItems-itemImgOverlay--right\">\n                <span class=\"PressDownloadsItems-itemImgOverlayBg\"></span>\n                <span class=\"PressDownloadsItems-itemImgOverlayText c-light s-text-tiny\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),1)),"title"), env.opts.autoescape);
output += "</span>\n            </a>\n        ";
;
}
output += "\n    </div>\n    <div class=\"PressDownloadsItems-itemName c-light s-title-normal\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"name"), env.opts.autoescape);
output += "</div>\n    <div class=\"PressDownloadsItems-itemPosition s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"position"), env.opts.autoescape);
output += "</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/PressDownloadsItems/PressDownloadsItem.html"] , dependencies)