'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

require('./RadialBackgrounds.less');

const Selectors = {
    el: '.RadialBackgrounds',
    background: '.PageGlobalIndex-background'
};

const BindFunctions = [
    '_initVariables',
    '_attachEvents',
    '_setInitialClasses',
    'changeBackground',
    'createElements',
    'startAnimation',
    'stopAnimation',
    'onMouseMove',
    'checkIfBackgroundActive',
    'setGradient',
    'setStartGradientOpacity'
];

const Timeout = 20;
const MouseMoveStopsDelay = 100;
const StartNextPercentageValue = 55;

const Colors = [
    {
        start: 'rgba(209, 78, 109, 1)',
        end: 'rgba(209, 78, 109, 0)',
        pure: 'rgba(209, 78, 109, $opacity)'
    },
    {
        start: 'rgba(0, 153, 204, 1)',
        end: 'rgba(0, 153, 204, 0)',
        pure: 'rgba(0, 153, 204, $opacity)'
    },
    {
        start: 'rgba(0, 34 ,117, 1)',
        end: 'rgba(0, 34, 117, 0)',
        pure: 'rgba(0, 34, 117, $opacity)'
    },
    {
        start: 'rgba(229, 61, 50, 1)',
        end: 'rgba(229, 61, 50, 0)',
        pure: 'rgba(229, 61, 50, $opacity)'
    },
    {
        start: 'rgba(100, 73, 151, 1)',
        end: 'rgba(100, 73, 151, 0)',
        pure: 'rgba(100, 73, 151, $opacity)'
    },
    {
        start: 'rgba(231, 50, 139, 1)',
        end: 'rgba(231, 50, 139, 0)',
        pure: 'rgba(231, 50, 139, $opacity)'
    },
    {
        start: 'rgba(252, 203, 69, 1)',
        end: 'rgba(252, 203, 69, 0)',
        pure: 'rgba(252, 203, 69, $opacity)'
    }
];

const InitialPercentage = 40;
const InitialZIndex = 10;
const StartGradientPercentage = 20;

const ColorDataAttributes = {
    colorStart: 'color-start',
    colorEnd: 'color-end',
    colorPure: 'color-pure'
};

const OpacityKey = '$opacity';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.createElements();
        this._initVariables();
        this._setInitialClasses();
        this._attachEvents();

        if (window.app.isPhoneLayout()) {
            $(document)
                .ready(this.startAnimation());
        }
    },

    _initVariables: function () {
        this.percentage = InitialPercentage;
        this.$background = this.$el.find('div')
            .first();
        this.currentIndex = 0;
        this.maxIndex = Colors.length - 1;
        this.isAnimationActive = false;
        this.zIndex = InitialZIndex;
        this.previousBackgrounds = [];
        this.opacityGradient = 0;
    },

    _setInitialClasses: function () {
        this.$el.find('div')
            .not('div:first-child')
            .css('opacity', 0);
        this.$background.css('opacity', 1);
        const backgroundColorStart = this.$background.data(ColorDataAttributes.colorStart);
        const backgroundColorEnd = this.$background.data(ColorDataAttributes.colorEnd);
        const gradient = 'radial-gradient(circle,'
            + backgroundColorStart + ' 0%, ' + backgroundColorEnd + ' ' + InitialPercentage + '%)';
        this.$background.css('background-image', gradient);
        this.$background.css('z-index', this.zIndex);
    },

    _attachEvents: function () {
        this.$el
            .on(ViewportEvents.mouseMove, this.onMouseMove);
        window.app.vent.on(Events.splashBackgroundChange, this.checkIfBackgroundActive);
    },

    createElements: function () {
        Colors.forEach((color) => {
            this.$el.append(`<div data-color-start="${color.start}"
                                  data-color-end="${color.end}"
                                  data-color-pure="${color.pure}"></div>`);
        });
    },

    setStartGradientOpacity: function () {
        if (this.percentage > StartGradientPercentage) {
            this.opacityGradient += 1 / (InitialPercentage - StartGradientPercentage);
        } else {
            this.opacityGradient = 0;
        }

        if (this.percentage >= InitialPercentage) {
            this.opacityGradient = 1;
        }
    },

    changeBackground: function () {
        const backgroundColorEnd = this.$background.data(ColorDataAttributes.colorEnd);
        this.setStartGradientOpacity();

        const backgroundColorStart = this.getColor(this.$background.data(ColorDataAttributes.colorPure),
            this.opacityGradient);

        window.requestAnimationFrame(() => {
            this.setGradient(
                this.$background,
                {start: backgroundColorStart, end: backgroundColorEnd},
                {start: 0, end: this.percentage});
        });
        if (this.previousBackgrounds.length) {
            this.previousBackgrounds.forEach((background) => {
                if (background.opacity !== -1 && background.opacity < 0.0) {
                    background.delete = true;

                    window.requestAnimationFrame(() => {
                        background.$background.css('opacity', 0);
                        background.$background.css('background-image', '');
                    });

                    return;
                }
                let previousBackgroundColorStart = background.$background.data(ColorDataAttributes.colorStart);
                const previousBackgroundColorEnd = background.$background.data(ColorDataAttributes.colorEnd);
                let previousBackgroundPercentage;

                if (background.percentage < 100) {
                    previousBackgroundPercentage = background.percentage;

                    background.percentage++;
                    if (background.percentage === 100) {
                        background.opacity = 1;
                    }
                } else {
                    previousBackgroundColorStart = this.getColor(
                        background.$background.data(ColorDataAttributes.colorPure),
                        background.opacity);

                    previousBackgroundPercentage = 100;

                    background.opacity -= 0.05;
                }

                window.requestAnimationFrame(() => {
                    this.setGradient(
                        background.$background,
                        {start: previousBackgroundColorStart, end: previousBackgroundColorEnd},
                        {start: 0, end: previousBackgroundPercentage});
                });
            });

            this.previousBackgrounds = this.previousBackgrounds.filter((background) => {
                return !background.delete;
            });
        }

        if (this.percentage === StartGradientPercentage) {
            window.requestAnimationFrame(() => {
                this.$background.css('opacity', 1);
            });
        }

        this.percentage++;

        if (this.percentage === StartNextPercentageValue) {
            this.percentage = StartGradientPercentage;

            this.previousBackgrounds.push({
                $background: this.$background,
                percentage: StartNextPercentageValue,
                opacity: -1,
                delete: false
            });
            this.currentIndex++;
            if (this.currentIndex > this.maxIndex) {
                this.currentIndex = 0;
            }
            this.$background = this.$el.find('div')
                .eq(this.currentIndex);
            this.zIndex++;
            window.requestAnimationFrame(() => {
                this.$background.css('z-index', this.zIndex);
            });
        }

        clearTimeout(this.backgroundsChangeTimeout);
        if (!this.isAnimationActive) {
            return;
        }

        this.backgroundsChangeTimeout = setTimeout(() => {
            window.requestAnimationFrame(() => {
                this.changeBackground();
            });
        }, Timeout);
    },

    setGradient: function ($background, colors, percentage) {
        const gradient =
            `radial-gradient(circle, ${colors.start} ${percentage.start}%, ${colors.end} ${percentage.end}%`;

        $background.css('background-image', gradient);
    },

    getColor: function (colorPure, opacity) {
        return colorPure.toString()
            .replace(OpacityKey, opacity);
    },

    startAnimation: function () {
        this.isAnimationActive = true;
        $(document)
            .ready(this.changeBackground);
    },

    stopAnimation: function () {
        this.isAnimationActive = false;
    },

    onMouseMove: function () {
        if (window.app.isPhoneLayout()) {
            return;
        }

        if (this.isAnimationActive) {
            clearTimeout(this.stopAnimationTimeout);
            this.stopAnimationTimeout = setTimeout(this.stopAnimation, MouseMoveStopsDelay);

            return;
        }

        this.startAnimation();
        this.isAnimationActive = true;
        this.stopAnimationTimeout = setTimeout(this.stopAnimation, MouseMoveStopsDelay);
    },

    checkIfBackgroundActive: function () {
        const isBgActive = this.$el.closest(Selectors.background)
            .hasClass(Constants.cssClasses.active);
        if (!isBgActive) {
            this.stopAnimation();
            this.$el
                .off(ViewportEvents.mouseMove, this.onMouseMove);

            return;
        }

        app.vent.trigger(Events.common.splashColorChange, 'dark', '#ffffff');
        this.$el.on(ViewportEvents.mouseMove, this.onMouseMove);
    }
});
