'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./FourColumnFacts.less');


const Selectors = {
    el: '.FourColumnFacts',
    fact: '.FourColumnFacts-fact',
    factValue: '.FourColumnFacts-factValue',
    factValueTitle: '.FourColumnFacts-factValueTitle',
    factValueMeasure: '.FourColumnFacts-factValueMeasure'
};

const BindFunctions = [
    '_setItemTitleFontSize',
    'onResize'
];

const OnResizeThrottle = 50;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._setItemTitleFontSize._throttled = _.throttle(this._setItemTitleFontSize, OnResizeThrottle);

        $(document)
            .ready(() => {
                setTimeout(() => {
                    this._setItemTitleFontSize();
                }, 300);
            });

        window.app.els.$window.on(ViewportEvents.resize, this.onResize);
    },

    _setItemTitleFontSize: function () {
        let maxValueTitleWidth = 0;
        let $widestTitle;
        let $widestFactMeasure;
        let $widestFactValue;
        this.$(Selectors.factValueTitle)
            .css(Constants.cssProperties.fontSize, '');

        this.$(Selectors.factValue)
            .each((index, item) => {
                const $factValue = $(item);
                const factValueWidth = $factValue
                    .innerWidth();

                const $factValueTitle = $(item)
                    .find(Selectors.factValueTitle);
                const factTitleWidth = $factValueTitle
                    .innerWidth();

                const $factValueMeasure = $(item)
                    .find(Selectors.factValueMeasure);
                const factMeasureWidth = $factValueMeasure.outerWidth(true);

                const fullTitleWidth = factTitleWidth + factMeasureWidth;

                if (fullTitleWidth > factValueWidth && fullTitleWidth > maxValueTitleWidth) {
                    maxValueTitleWidth = fullTitleWidth;
                    $widestTitle = $factValueTitle;
                    $widestFactMeasure = $factValueMeasure;
                    $widestFactValue = $factValue;
                }
            });

        if (!$widestTitle || !$widestFactValue) {
            return;
        }

        let factValueWidth = $widestFactValue.innerWidth();
        let factMeasureWidth = $widestFactMeasure.outerWidth(true);
        let factTitleWidth = $widestTitle.innerWidth();

        while ((factTitleWidth + factMeasureWidth) >= factValueWidth) {
            $widestTitle
                .css(Constants.cssProperties.fontSize,
                    `${parseInt($widestTitle.css(Constants.cssProperties.fontSize), 10) - 1}px`);
            factTitleWidth = $widestTitle.innerWidth();
        }

        this.$(Selectors.factValueTitle)
            .css(Constants.cssProperties.fontSize,
                `${parseInt($widestTitle.css(Constants.cssProperties.fontSize), 10)}px`);
    },

    onResize: function () {
        this._setItemTitleFontSize._throttled();
    }
});
