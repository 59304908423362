'use strict';

const Backbone = require('backbone');

require('./InspirationProducts.less');

module.exports = Backbone.View.extend({

    el: '.InspirationProducts',

    events: {
    },

    initialize: function () {

    }
});
