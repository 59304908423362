'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');

require('./PageInvestorsGovernance.less');

const Selectors = {
    el: '.PageInvestorsGovernance'
};

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageGlobalTemplate.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    }
});
