'use strict';

const PageLanding = require('pages/PageLanding/PageLanding.js');

const AboutHead = require('components/AboutHead/AboutHead');
const AboutInnovation = require('components/AboutInnovation/AboutInnovation');
const AboutQualityManagement = require('components/AboutQualityManagement/AboutQualityManagement');
const AboutGlobalReach = require('components/AboutGlobalReach/AboutGlobalReach');
const AboutFooter = require('components/AboutFooter/AboutFooter');
const AboutLightArchitecture = require('components/AboutLightArchitecture/AboutLightArchitecture');
const History = require('components/Common/History/History');

require('./PageAbout.less');

const ChildViews = [
    {
        name: 'aboutHead',
        Constructor: AboutHead
    },
    {
        name: 'aboutInnovation',
        Constructor: AboutInnovation
    },
    {
        name: 'aboutQualityManagement',
        Constructor: AboutQualityManagement
    },
    {
        name: 'aboutGlobalReach',
        Constructor: AboutGlobalReach
    },
    {
        name: 'aboutFooter',
        Constructor: AboutFooter
    },
    {
        name: 'aboutHistory',
        Constructor: History
    }
];

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        this.options = options;

        this._initChildViews(ChildViews);
        this.aboutLightArchitecture = new AboutLightArchitecture({
            page: this,
            pageUrl: '/about.html'
        });

        PageLanding.prototype.initialize.call(this, options);
    }
});
