'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore');

require('./InspirationCases.less');

const InspirationCaseTemplate = require('./InspirationCaseRuntime.html');

const Selectors = {
    inspirationCases: '.InspirationCases',
    noItemsClear: '.InspirationCases-noItems-clear',
    noItems: '.InspirationCases-noItems',
    showMoreWrapper: '.InspirationCases-showMoreWrapper',
    showMore: '.InspirationCases-showMore',
    showMoreSpan: '.InspirationCases-showMore span',
    case: '.InspirationCases-case',
    caseUrl: '.InspirationCases-caseUrl',
    caseHide: `.InspirationCases-case.${Constants.cssClasses.hide}`,
    hiddenElem: 'hidden',
    casesWrapper: '.InspirationCases-wrapper',
    caseLabel: '.InspirationCases-caseLabel'
};

const BindFunctions = [
    'onFilter',
    'clearFilters',
    'showMore',
    'openPopup',
    '_attachEvents',
    '_initVariables',
    'renderNewCases',
    'getCasesOffset',
    'renderFilteredCases',
    'changeFilterByLabel',
    'setQueryParameters',
    'resetQueryParameters'
];

const ShowMoreApiDataAttrName = 'show-more-api-url';
const FilterApiDataAttrName = 'filter-api-url';
const ApplicationIdAttrName = 'application-id';
const DefaultFilterValue = Constants.filter.noFilterValue;
const FiltersDefault = {
    application: DefaultFilterValue,
    product: DefaultFilterValue,
    location: DefaultFilterValue
};
const CountOfItemsPerPage = 18;

module.exports = Backbone.View.extend({

    el: Selectors.inspirationCases,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.noItemsClear}`]: this.clearFilters,
            [`${ViewportEvents.click} ${Selectors.showMore}`]: this.showMore,
            [`${ViewportEvents.click} ${Selectors.caseUrl}`]: this.openPopup,
            [`${ViewportEvents.click} ${Selectors.caseLabel}`]: this.changeFilterByLabel
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);

        this._attachEvents();
    },

    _initVariables: function (options) {
        this.filtersInstance = options.filters;
        this.countOfHidden = 0;
        this.moreApiUrl = this.$el.data(ShowMoreApiDataAttrName);
        this.filterApiUrl = this.$el.data(FilterApiDataAttrName);
        this.filters = {...FiltersDefault};
        this.applicationId = this.$el.data(ApplicationIdAttrName);
        this.isShowMoreButtonClicked = false;

        this.$casesWrapper = this.$(Selectors.casesWrapper);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.inspiration + Events.filter.onFilter, this.onFilter);
    },

    openPopup: function (event) {
        event.preventDefault();
        const $target = $(event.target);
        const $case = $target.closest(Selectors.caseUrl);
        const url = $case.data('url');
        const apiUrl = $case.data('api-url');
        app.vent.trigger(Events.eventPrefixes.inspiration + Events.popup.popupOpen, {apiUrl, url});
    },

    showMore: function () {
        if (!this.moreApiUrl) {
            return;
        }

        const offset = this.getCasesOffset();

        const data = {
            offset,
            filters: this.filters,
            limit: offset + CountOfItemsPerPage
        };

        this.isShowMoreButtonClicked = true;

        $.getJSON(this.moreApiUrl, data, this.renderNewCases);
    },

    getCasesOffset: function () {
        return this.$(Selectors.case)
            .length;
    },

    renderNewCases: function (res) {
        if (!res) {
            return;
        }

        const countOfMore = res.countOfMore;
        const hasMore = !!countOfMore;
        const cases = res.cases;
        const downloadTitle = res.downloadTitle;
        const productsTitle = res.productsTitle;

        this.$(Selectors.showMoreWrapper)
            .toggle(hasMore);
        this.$(Selectors.showMoreSpan)
            .text(countOfMore);

        cases.forEach((caseItem) => {
            const caseHtml = InspirationCaseTemplate.render({case: caseItem, downloadTitle, productsTitle});
            this.$casesWrapper.append(caseHtml);
        });

        this.$(Selectors.noItems)
            .toggleClass(Constants.cssClasses.hide, !!this.$(Selectors.case).length);

        if (this.isShowMoreButtonClicked) {
            this.isShowMoreButtonClicked = false;

            return;
        }

        app.els.$window.scrollTop(0);
    },

    renderFilteredCases: function (res) {
        this.$casesWrapper.empty();
        this.renderNewCases(res);
    },

    onFilter: function (idItem, typeItem) {
        this.filters = {...FiltersDefault};

        if (idItem !== -1) {
            this.filters[typeItem] = idItem;
        }

        const data = {
            offset: 0,
            filters: this.filters,
            limit: CountOfItemsPerPage
        };

        this.setQueryParameters();
        $.getJSON(this.filterApiUrl, data, this.renderFilteredCases);
    },

    setQueryParameters: function () {
        const filters = Object.entries(this.filters)
            .filter(([, filterValue]) => filterValue !== DefaultFilterValue);
        this.resetQueryParameters();

        if (!filters.length) {
            return;
        }

        filters.forEach((filter) => {
            const filterKey = filter[0];
            const filterValue = filter[1];

            if (!filterKey || !filterValue) {
                return;
            }

            app.utils.addQueryParameter(filterKey, filterValue);
        });
    },

    resetQueryParameters: function () {
        app.utils.resetQueryParameters();
    },

    clearFilters: function () {
        this.filters = {...FiltersDefault};
        app.vent.trigger(Events.eventPrefixes.inspiration + Events.filter.clearFilters);
    },

    changeFilterByLabel: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.caseLabel);

        const applicationId = $target.data('id');
        const applicationAttrName = this.applicationId;

        this.filtersInstance.setValue(applicationAttrName, applicationId, 'type');
    }
});
