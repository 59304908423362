'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./Trigger.less');

const Selectors = {
    el: '.Trigger',
    trigger: '.Trigger-trigger'
};

const BindFunctions = [
    '_toggleTrigger',
    '_setTriggerClass'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.trigger}`]: this._toggleTrigger
        };
    },

    /**
     *
     * @param options = {
     *     onModelChange - function that triggers on trigger value changed,
     *     value - initial value of trigger,
     *     name - name for this trigger,
     *     modifier - modifier for the component or page,
     *     id - unique id for this trigger
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.setElement(`${Selectors.el}--${options.modifier}_${options.id}`);
        this.onModelChange = options.onModelChange || (() => {});
        this.value = options.value || false;
        this.name = options.name;
        this._setTriggerClass();
    },

    _setTriggerClass: function () {
        this.$(Selectors.trigger)
            .toggleClass(Constants.cssClasses.active, this.value);
    },

    _toggleTrigger: function () {
        this.value = !this.value;

        this._setTriggerClass();

        this.onModelChange(this.name, this.value);
    },

    setValue: function (value) {
        this.value = value;
        this._setTriggerClass();
        this.onModelChange(this.name, this.value);
    }
});
