'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

require('./VideoBackground.less');

const Selectors = {
    el: '.VideoBackground',
    video: '.VideoBackground-desktop video',
    background: '.PageGlobalIndex-background'
};

const BindFunctions = [
    'attachEvents',
    'checkIfBackgroundActive'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.attachEvents();
    },

    attachEvents: function () {
        window.app.vent.on(Events.splashBackgroundChange, this.checkIfBackgroundActive);
    },

    checkIfBackgroundActive: function () {
        const isBgActive = this.$el.closest(Selectors.background)
            .hasClass(Constants.cssClasses.active);

        if (isBgActive) {
            this.$(Selectors.video)[0]
                .play();
            app.vent.trigger(Events.common.splashColorChange, 'light', '#161D8D');

            return;
        }

        this.$(Selectors.video)[0]
            .pause();
    }
});
