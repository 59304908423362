'use strict';

const Backbone = require('backbone/backbone');

require('./ProductFamilyDesigner.less');

module.exports = Backbone.View.extend({

    el: '.ProductFamilyDesigner',

    events: {},

    initialize: function () {

    }
});
