'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');

require('./LayoutsFooter.less');

const Selectors = {
    el: '.LayoutsFooter',
    item: '.LayoutsFooter-item'
};

const BindFunctions = [
    '_attachEvents',
    'openContactOverlay'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._attachEvents();
        this.options = options;
    },

    _attachEvents: function () {
        this.$el.find(`${Selectors.item}[data-name="contact"]`).on(ViewportEvents.click, this.openContactOverlay);
    },

    openContactOverlay: function (event) {
        event.preventDefault();

        window.app.views.contacts.show();
    }
});
