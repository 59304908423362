'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./ProductDetailsInspiration.less');

const BindFunctions = [
    'openInspirationPopup'
];

const Selectors = {
    el: '.ProductDetailsInspiration',
    arrowBack: '.ProductDetailsInspiration-arrowBack',
    itemLink: '.ProductDetailsInspiration-itemLink'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowBack}`]: this.closeOverlay,
            [`${ViewportEvents.click} ${Selectors.itemLink}`]: this.openInspirationPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    openInspirationPopup: function (event) {
        event.preventDefault();
        const $case = $(event.target)
            .closest(Selectors.itemLink);
        const url = $case.data('url');
        const apiUrl = $case.data('api-url');

        this.popup = new InspirationCasePopup({
            page: this
        });

        this.popup.openPopup(apiUrl, url);
    },

    closeOverlay: function () {
        app.vent.trigger(Events.popup.popupHidden);
        app.vent.trigger(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose);
    }
});
