var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/popups/SearchResultPopup/SearchResultPopupPagination/SearchResultPopupPagination.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/popups/SearchResultPopup/SearchResultPopupPagination/SearchResultPopupPagination.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n\n<div class=\"SearchResultPopupPagination SearchResultPopupPagination--";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"modifier"), env.opts.autoescape);
output += "\"\n     data-api-url=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"pageApiUrl"), env.opts.autoescape);
output += "\"\n     data-items-count=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"itemsPerPage"), env.opts.autoescape);
output += "\"\n     data-pages-count=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"countOfPages"), env.opts.autoescape);
output += "\">\n    <a class=\"SearchResultPopupPagination-prev s-title-small\" href=\"#\">\n        <span class=\"SearchResultPopupPagination-prevArrow s-title-small\"></span>\n        <span class=\"SearchResultPopupPagination-prevText s-title-small\">Previous</span>\n    </a>\n    <div class=\"SearchResultPopupPagination-pages\">\n        ";
frame = frame.push();
var t_6 = (lineno = 20, colno = 25, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "range"), "range", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"countOfPages")]));
if(t_6) {t_6 = runtime.fromIterator(t_6);
var t_5 = t_6.length;
for(var t_4=0; t_4 < t_6.length; t_4++) {
var t_7 = t_6[t_4];
frame.set("i", t_7);
frame.set("loop.index", t_4 + 1);
frame.set("loop.index0", t_4);
frame.set("loop.revindex", t_5 - t_4);
frame.set("loop.revindex0", t_5 - t_4 - 1);
frame.set("loop.first", t_4 === 0);
frame.set("loop.last", t_4 === t_5 - 1);
frame.set("loop.length", t_5);
output += "\n            <a data-id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\" id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "\"\n               class=\"SearchResultPopupPagination-pageNumber s-title-small ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"activePageNumber") && runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index") == runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"activePageNumber")) {
output += "active";
;
}
output += "\"\n               href=\"#\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "loop")),"index"), env.opts.autoescape);
output += "</a>\n        ";
;
}
}
frame = frame.pop();
output += "\n    </div>\n    <div class=\"SearchResultPopupPagination-pages--mobile\">\n        <div class=\"SearchResultPopupPagination-pagesTitle\">\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"mobileTextPagination"), env.opts.autoescape);
output += "\n        </div>\n    </div>\n    <a class=\"SearchResultPopupPagination-next s-title-small\" href=\"#\">\n        <span class=\"SearchResultPopupPagination-nextText s-title-small\">Next</span>\n        <span class=\"SearchResultPopupPagination-nextArrow s-title-small\"></span>\n    </a>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/popups/SearchResultPopup/SearchResultPopupPagination/SearchResultPopupPagination.html"] , dependencies)