'use strict';

const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactFileAComplaint';

require('./ContactFileAComplaint.less');

const Selectors = {
    filterRegionItemSelect: '.ContactFileAComplaint-filterRegionItemSelect',
    filterRegionItemSelectText: '.ContactFileAComplaint-filterRegionItemSelectText',
    filterRegionItemSelectTextParagraph: '.ContactFileAComplaint-filterRegionItemSelectText p',
    filterRegionItemSelectMenu: '.ContactFileAComplaint-filterRegionItemSelectMenu',
    filterRegionItemSelectMenuVal: '.ContactFileAComplaint-filterRegionItemSelectMenuVal',
    filterRegionItemSelectMenuValFirstChild: '.ContactFileAComplaint-filterRegionItemSelectMenuVal:first-child',
    filterRegionItemSelectMenuValActive:
        `.ContactFileAComplaint-filterRegionItemSelectMenuVal--${Constants.cssClasses.active}`,
    langContact: '.ContactFileAComplaint-langContact'
};

const BindFunctions = [
    '_initVariables',
    '_attachEvents',
    '_initChildComponents',
    'changeRegionFilter',
    'clickOutside',
    'setActiveCompliant'
];

const DefaultView = createContactComponent({
    selectorComponent: SelectorComponent,
    bindFunctions: BindFunctions,
    selectors: Selectors
});

module.exports = DefaultView.extend({
    events: function () {
        return {
            ...DefaultView.prototype.events.call(this),
            [`${ViewportEvents.click} ${Selectors.filterRegionItemSelectMenuVal}`]: this.changeRegionFilter
        };
    },

    initialize: function (options = {}) {
        DefaultView.prototype.initialize.call(this, options);

        this._initChildComponents();
        this._initVariables(options);
        this.setActiveCompliant();
        this._attachEvents();
    },


    _attachEvents: function () {
        window.app.els.$body
            .on(ViewportEvents.click, this.clickOutside);
    },

    _initChildComponents: function () {
        this.dropdown = new Dropdown({
            id: 0,
            onChooseOption: this.changeRegionFilter,
            modifier: 'contactFileAComplaint'
        });
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$langContact = this.$(Selectors.langContact);
        this.$langContactText = this.$langContact.find('span');
        this.$langContactTextFirst = this.$langContact.find('span')
            .first();
    },

    clickOutside: function (e) {
        const elDropDown = this.$(Constants.selectors.dropdownSelect);

        //check if the clicked area is dropDown or not
        if (elDropDown.has(e.target).length === 0) {
            this.$(elDropDown)
                .removeClass(Constants.cssClasses.active);
        }
    },

    setActiveCompliant: function (itemValue) {
        this.$langContactText
            .css(Constants.cssProperties.display, 'none');
        if (!itemValue) {
            this.$langContactTextFirst
                .css(Constants.cssProperties.display, 'block');
        }

        this.$langContact
            .find(`span[data-value="${itemValue}"]`)
            .css(Constants.cssProperties.display, 'block');
    },

    changeRegionFilter: function (dropdownId, idItem, typeItem, $item) {
        const itemValue = $item.data('value');

        this.setActiveCompliant(itemValue);
        app.vent.trigger(this.eventPrefix + Events.filter.onFilter, idItem, typeItem);

        this.dropdown.close();
    }
});
