'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const PerfectScrollbar = require('perfect-scrollbar').default;
const scrollLock = require('scroll-lock');

require('./SearchResultPopup.less');

const ProductsItem = require('./SearchResultProductItem/SearchResultProductItem');
const InspirationItem = require('./SearchResultInspirationItem/SearchResultInspirationItem');
const FaqItem = require('./SearchResultFaqItem/SearchResultFaqItem');
const GroupCompany = require('./SearchCompanyItem/SearchCompanyItem');
const NewsItem = require('./SearchResultNewsItem/SearchResultNewsItem');
const Pagination = require('./SearchResultPopupPagination/SearchResultPopupPagination');

const Selectors = {
    searchResultsPopup: '.SearchResultPopup',
    input: '.SearchResultPopup-input',
    item: '.SearchResultPopup-item',
    page: '.SearchResultPopup-page',
    bg: '.SearchResultPopup-bg',
    close: '.SearchResultPopup-close',
    category: '.SearchResultPopup-accordionSectionTitle',
    resultsItem: '.SearchResultPopup-resultsItem',
    accordionSectionContentLink: '.SearchResultPopup-accordionSectionContentLink',
    inspirationPrevArrow: '.SearchResultPopup-inspirationPrev',
    inspirationPageNumber: '.SearchResultPopup-inspirationPageNumber',
    inspirationNextArrow: '.SearchResultPopup-inspirationNext',
    groupPrevArrow: '.SearchResultPopup-groupPrevArrow',
    groupPageNumber: '.SearchResultPopup-groupPageNumber',
    groupNextArrow: '.SearchResultPopup-groupNextArrow',
    inputSearch: '.SearchResultPopup-inputSearch',
    inputWrapper: '.SearchResultPopup-inputWrapper',
    accordionSectionTitle: '.SearchResultPopup-accordionSectionTitle',
    zumProductsTab: '#zum-products--tab',
    extraResultsSectionTitle: '.SearchResultPopup-extraResultsSectionTitle',
    inspirationPaginationWrapper: '.SearchResultPopup-inspirationPaginationWrapper',
    tabContentWrapper: '.SearchResultPopup-tabContentWrapper',
    inspirationPageNumberMobile: '.SearchResultPopup-inspirationPageNumber--mobile',
    resultsWrapper: '.SearchResultPopup-tabContent',
    emptyResults: '.SearchResultPopup-emptyResults',
    pagination: '.SearchResultPopup-inspirationPaginationWrapper',
    zumtobel: {
        products: {
            container: '#zum-products--tab',
            tabContainer: '#zum-products',
            paginationContainer: '#zum-products--pagination'
        },
        inspiration: {
            container: '#zum-inspiration--tab',
            tabContainer: '#zum-inspiration',
            paginationContainer: '#zum-inspiration--pagination'
        },
        news: {
            container: '#zum-news--tab',
            tabContainer: '#zum-news',
            paginationContainer: '#zum-news--pagination'
        },
        faq: {
            container: '#zum-faq--tab',
            tabContainer: '#zum-faq',
            paginationContainer: '#zum-faq--pagination'
        }
    },
    thorn: {
        products: {
            container: '#thorn-products--tab',
            tabContainer: '#thorn-products',
            paginationContainer: '#thorn-products--pagination'
        },
        inspiration: {
            container: '#thorn-inspiration--tab',
            tabContainer: '#thorn-inspiration',
            paginationContainer: '#thorn-inspiration--pagination'
        },
        news: {
            container: '#thorn-news--tab',
            tabContainer: '#thorn-news',
            paginationContainer: '#thorn-news--pagination'
        },
        faq: {
            container: '#thorn-faq--tab',
            tabContainer: '#thorn-faq',
            paginationContainer: '#thorn-faq--pagination'
        }
    },
    group: {
        company: {
            container: '#group-company--tab',
            tabContainer: '#group-company',
            paginationContainer: '#group-company--pagination'
        },
        news: {
            container: '#group-news--tab',
            tabContainer: '#group-news',
            paginationContainer: '#group-news--pagination'
        },
        faq: {
            container: '#group-faq--tab',
            tabContainer: '#group-faq',
            paginationContainer: '#group-faq--pagination'
        }
    }
};

const Ids = {
    page: '#page--',
    groupPage: '#groupPage--',
    pageNum: '#pageNum--'
};

const ZumtobelCategory = 'category-1';
const ThornCategory = 'category-2';
const GroupCategory = 'category-3';

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    '_initPerfectScroll',
    '_initVariables',
    '_setInitialClasses',
    '_attachEvents',
    '_initChildComponents',
    'fetchData',
    'renderSearchResults',
    'renderZumtobelSearchResults',
    'renderThornSearchResults',
    'renderGroupSearchResults',
    '_renderItems',
    'toggleCategory',
    'isCategoryHide',
    'isCategoryItemExists',
    'fetchAdditionalItems'
];

const CssClasses = {
    topNavSearchResultsOpened: 'TopNav--searchResults-opened',
    searchResultsHide: 'SearchResultPopup--hide',
    searchResultsWithContent: 'SearchResultPopup--withcontent'
};

const SearchHash = 'search';

module.exports = Backbone.View.extend({

    el: Selectors.searchResultsPopup,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.accordionSectionContentLink}`]: this.onMenuItemClick,
            [`${ViewportEvents.click} ${Selectors.inspirationPrevArrow}`]: this.previousPage,
            [`${ViewportEvents.click} ${Selectors.inspirationPageNumber}`]: this.onPageClick,
            [`${ViewportEvents.click} ${Selectors.inspirationNextArrow}`]: this.nextPage,
            [`${ViewportEvents.click} ${Selectors.groupPrevArrow}`]: this.previousPage,
            [`${ViewportEvents.click} ${Selectors.groupPageNumber}`]: this.onPageClick,
            [`${ViewportEvents.click} ${Selectors.groupNextArrow}`]: this.nextPage,
            [`${ViewportEvents.focus} ${Selectors.inputSearch}`]: this.onFocus,
            [`${ViewportEvents.blur} ${Selectors.inputSearch}`]: this.onBlur
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);
        this._setInitialClasses();
        this._attachEvents();

        const self = this;

        if (window.location.hash === `#${SearchHash}`) {
            setTimeout(function () {
                self.show();
            });
        }

        this._initPerfectScroll();

        this.checkArrows();

        this._initChildComponents();
    },

    _attachEvents: function () {
        app.vent.on(Events.search.showSearchResult, this.show);
        app.vent.on(Events.search.hideResults, this.hide);

        const self = this;

        $(document)
            .ready(function () {
                const accordionClass = Selectors.accordionSectionTitle;

                $(accordionClass)
                    .click(function (e) {
                        if ($(this)
                            .is(`.${Constants.cssClasses.active}`)) {
                            $(this)
                                .removeClass(Constants.cssClasses.active)
                                .next(e.target)
                                .slideUp(300);
                        } else {
                            $(accordionClass)
                                .removeClass(Constants.cssClasses.active);
                            $(accordionClass)
                                .next(e.target)
                                .slideUp(300);

                            $(this)
                                .addClass(Constants.cssClasses.active)
                                .next(e.target)
                                .slideDown(300);
                        }
                        e.preventDefault();
                    });

                $(Selectors.inputSearch)
                    .on(ViewportEvents.input, _.debounce((event) => {
                        const searchString = $(event.target)
                            .val();

                        const data = {
                            apiUrl: self.apiUrl,
                            searchString
                        };

                        self.fetchData(data);
                    }, 300)
                        .bind(this));
            });
    },

    _setInitialClasses: function () {
        $(document)
            .ready(function () {
                $(Selectors.zumProductsTab)
                    .addClass(Constants.cssClasses.active);
            });
        $(Selectors.inspirationPaginationWrapper)
            .hide();
        $(`${Selectors.inspirationPaginationWrapper}${Selectors.zumtobel.products.paginationContainer}`)
            .show();
        this.$(Selectors.emptyResults)
            .hide();
        this.$(Selectors.resultsWrapper)
            .hide();
    },

    _initVariables: function (options = {}) {
        this.popupWrapper = Selectors.inputWrapper;
        this.options = options;
        this.items = $(Selectors.item);
        this.pages = $(Selectors.page);
        this.numItems = this.items.length;
        this.numPages = this.pages.length;
        this.perPage = 4;
        this.currentPage = 1;
        this.numPages = Math.ceil(this.numItems / this.perPage);
    },

    _initChildComponents: function () {
        this.productsItem = new ProductsItem();
        this.inspirationItem = new InspirationItem();
        this.faqItem = new FaqItem();
        this.company = new GroupCompany();
        this.newsItem = new NewsItem();
    },

    fetchData: function (data) {
        const searchString = data.searchString;
        this.apiUrl = data.apiUrl;
        $.getJSON(this.apiUrl, {searchString}, (res) => {
            this.renderSearchResults(res);
        });
    },

    renderSearchResults: function (searchResults) {
        // Temporarily hidden according to https://lighttrack.zumtobelgroup.com/browse/LBW-2944
        // this.$('#search-results-count')
        //     .text(searchResults.resultsCount);
        if (+searchResults.resultsCount > 0) {
            this.$(Selectors.resultsWrapper)
                .show();
            this.$(Selectors.emptyResults)
                .hide();
            this.renderZumtobelSearchResults(searchResults.zumtobel);
            this.renderThornSearchResults(searchResults.thorn);
            this.renderGroupSearchResults(searchResults.group);
        } else {
            this.$(Selectors.resultsWrapper)
                .hide();
            this.$(Selectors.emptyResults)
                .show();
        }
    },

    fetchAdditionalItems: function (renderData, paginationData, searchString) {
        const apiUrl = paginationData.apiUrl;

        $.getJSON(apiUrl, {page: paginationData.pageNumber, searchString}, (res) => {
            const data = {...renderData};
            data.data = res;

            this._renderItems(data.containers, data.data, data.modifier, data.itemInstance);
        });
    },

    _renderItems: function (containers = {}, data = {}, modifier = '', itemInstance) {
        let itemHtml = '';
        containers.$paginationContainer.empty();
        if (!data.items) {
            this.setCounter(containers.$tabContainer, 0);

            return;
        }
        const itemList = data.items;
        const countOfItems = data.countOfItems;
        const countOfPages = data.countOfPages;
        const pageApiUrl = data.paginationApiUrl;
        const itemsPerPage = itemList.length;
        const activePageNumber = data.activePageNumber || 1;
        const mobileTextPagination =
            `${data.pageTitleMobile} ${activePageNumber} ${data.pageOfMobile} ${countOfPages}`;
        itemList.forEach((item, index) => {
            item.modifier = `${modifier}_${index}`;
            const singleItemHtml = itemInstance.render(item);
            itemHtml += singleItemHtml;
        });

        this.setCounter(containers.$tabContainer, countOfItems);
        containers.$container.html(itemHtml);
        itemInstance.attachElement && itemInstance.attachElement();
        itemInstance.attachEvents && itemInstance.attachEvents();

        if (!countOfPages || countOfPages <= 1) {
            return;
        }

        const searchString = $(Selectors.inputSearch).val();

        const paginationCallback = (paginationData) => {
            const renderData = {
                containers: containers,
                data: data,
                modifier: modifier,
                itemInstance: itemInstance
            };

            $(this.popupWrapper)
                .scrollTop(0);
            this.perfectScrollbar.update();

            this.fetchAdditionalItems(renderData, paginationData, searchString);
        };
        const pagination = new Pagination({callback: paginationCallback, modifier});

        containers.$paginationContainer
            .html(pagination.render({
                countOfPages,
                pageApiUrl,
                activePageNumber,
                itemsPerPage,
                modifier,
                mobileTextPagination
            }));
        pagination.attachEvents();
    },

    setCounter: function ($container, count) {
        if (count === 0) {
            $container.hide();

            return;
        }

        // Temporarily hidden according to https://lighttrack.zumtobelgroup.com/browse/LBW-2944
        // $container.find('span')
        //     .text(`(${count})`);

        $container.show();
    },

    renderZumtobelSearchResults: function (zumtobelSearchResults) {
        const $productsContainer = this.$(Selectors.zumtobel.products.container);
        const $productsTabContainer = this.$(Selectors.zumtobel.products.tabContainer);
        const $productsPaginationContainer = this.$(Selectors.zumtobel.products.paginationContainer);
        const $inspirationsContainer = this.$(Selectors.zumtobel.inspiration.container);
        const $inspirationsTabContainer = this.$(Selectors.zumtobel.inspiration.tabContainer);
        const $inspirationsPaginationContainer = this.$(Selectors.zumtobel.inspiration.paginationContainer);
        const $faqContainer = this.$(Selectors.zumtobel.faq.container);
        const $faqTabContainer = this.$(Selectors.zumtobel.faq.tabContainer);
        const $faqPaginationContainer = this.$(Selectors.zumtobel.faq.paginationContainer);
        const $newsContainer = this.$(Selectors.zumtobel.news.container);
        const $newsTabContainer = this.$(Selectors.zumtobel.news.tabContainer);
        const $newsPaginationContainer = this.$(Selectors.zumtobel.news.paginationContainer);
        const modifier = 'zumtobel';

        const isCategoryHide = this.isCategoryHide(zumtobelSearchResults);

        this.toggleCategory(ZumtobelCategory, !isCategoryHide);

        this._renderItems(
            {
                $container: $inspirationsContainer,
                $tabContainer: $inspirationsTabContainer,
                $paginationContainer: $inspirationsPaginationContainer
            },
            zumtobelSearchResults.inspiration, `${modifier}-inspiration`, this.inspirationItem);

        this._renderItems(
            {
                $container: $productsContainer,
                $tabContainer: $productsTabContainer,
                $paginationContainer: $productsPaginationContainer
            },
            zumtobelSearchResults.products, `${modifier}-products`, this.productsItem);

        this._renderItems(
            {
                $container: $faqContainer,
                $tabContainer: $faqTabContainer,
                $paginationContainer: $faqPaginationContainer
            },
            zumtobelSearchResults.faq, `${modifier}-faq`, this.faqItem);

        this._renderItems(
            {
                $container: $newsContainer,
                $tabContainer: $newsTabContainer,
                $paginationContainer: $newsPaginationContainer
            },
            zumtobelSearchResults.news, `${modifier}-news`, this.newsItem);
    },

    renderThornSearchResults: function (thornSearchResults) {
        const $productsContainer = this.$(Selectors.thorn.products.container);
        const $productsTabContainer = this.$(Selectors.thorn.products.tabContainer);
        const $productsPaginationContainer = this.$(Selectors.thorn.products.paginationContainer);
        const $inspirationsContainer = this.$(Selectors.thorn.inspiration.container);
        const $inspirationsTabContainer = this.$(Selectors.thorn.inspiration.tabContainer);
        const $inspirationsPaginationContainer = this.$(Selectors.thorn.inspiration.paginationContainer);
        const $faqContainer = this.$(Selectors.thorn.faq.container);
        const $faqTabContainer = this.$(Selectors.thorn.faq.tabContainer);
        const $faqPaginationContainer = this.$(Selectors.thorn.faq.paginationContainer);
        const $newsContainer = this.$(Selectors.thorn.news.container);
        const $newsTabContainer = this.$(Selectors.thorn.news.tabContainer);
        const $newsPaginationContainer = this.$(Selectors.thorn.news.paginationContainer);
        const modifier = 'thorn';

        const isCategoryHide = this.isCategoryHide(thornSearchResults);

        this.toggleCategory(ThornCategory, !isCategoryHide);

        this._renderItems(
            {
                $container: $productsContainer,
                $tabContainer: $productsTabContainer,
                $paginationContainer: $productsPaginationContainer
            },
            thornSearchResults.products, `${modifier}-products`, this.productsItem);

        this._renderItems(
            {
                $container: $inspirationsContainer,
                $tabContainer: $inspirationsTabContainer,
                $paginationContainer: $inspirationsPaginationContainer
            },
            thornSearchResults.inspiration, `${modifier}-inspiration`, this.inspirationItem);

        this._renderItems(
            {
                $container: $faqContainer,
                $tabContainer: $faqTabContainer,
                $paginationContainer: $faqPaginationContainer
            },
            thornSearchResults.faq, `${modifier}-faq`, this.faqItem);

        this._renderItems(
            {
                $container: $newsContainer,
                $tabContainer: $newsTabContainer,
                $paginationContainer: $newsPaginationContainer
            },
            thornSearchResults.news, `${modifier}-news`, this.newsItem);
    },

    renderGroupSearchResults: function (groupSearchResults) {
        const $faqContainer = this.$(Selectors.group.faq.container);
        const $faqTabContainer = this.$(Selectors.group.faq.tabContainer);
        const $faqPaginationContainer = this.$(Selectors.group.faq.paginationContainer);
        const $companyContainer = this.$(Selectors.group.company.container);
        const $companyTabContainer = this.$(Selectors.group.company.tabContainer);
        const $companyPaginationContainer = this.$(Selectors.group.company.paginationContainer);
        const $newsContainer = this.$(Selectors.group.news.container);
        const $newsTabContainer = this.$(Selectors.group.news.tabContainer);
        const $newsPaginationContainer = this.$(Selectors.group.news.paginationContainer);
        const modifier = 'group';

        const isCategoryHide = this.isCategoryHide(groupSearchResults);

        this.toggleCategory(GroupCategory, !isCategoryHide);

        this._renderItems(
            {
                $container: $faqContainer,
                $tabContainer: $faqTabContainer,
                $paginationContainer: $faqPaginationContainer
            },
            groupSearchResults.faq, `${modifier}-faq`, this.faqItem);

        this._renderItems(
            {
                $container: $companyContainer,
                $tabContainer: $companyTabContainer,
                $paginationContainer: $companyPaginationContainer
            },
            groupSearchResults.company, `${modifier}-company`, this.company);

        this._renderItems(
            {
                $container: $newsContainer,
                $tabContainer: $newsTabContainer,
                $paginationContainer: $newsPaginationContainer
            },
            groupSearchResults.news, `${modifier}-news`, this.newsItem);
    },

    isCategoryItemExists: function (categoryItem) {
        return categoryItem && categoryItem.items && categoryItem.items.length;
    },

    isCategoryHide: function (category) {
        return !this.isCategoryItemExists(category.inspiration) &&
            !this.isCategoryItemExists(category.products) &&
            !this.isCategoryItemExists(category.faq) &&
            !this.isCategoryItemExists(category.news) &&
            !this.isCategoryItemExists(category.company);
    },

    onMenuItemClick: function (e) {
        const itemId = $(e.target)
            .attr('id');
        $(Selectors.accordionSectionContentLink)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.tabContentWrapper)
            .removeClass(Constants.cssClasses.active);
        $(e.target)
            .addClass(Constants.cssClasses.active);
        $('#' + itemId + '--tab')
            .addClass(Constants.cssClasses.active);

        $(Selectors.inspirationPaginationWrapper)
            .hide();

        $(`${Selectors.inspirationPaginationWrapper}#${itemId}--pagination`)
            .show();

        $(this.popupWrapper)
            .scrollTop(0);
    },

    checkArrows: function () {
        if (parseInt(this.currentPage, 10) === 1) {
            $(Selectors.inspirationPrevArrow)
                .hide();
            $(Selectors.inspirationNextArrow)
                .show();
        } else if (parseInt(this.currentPage, 10) === parseInt(this.numPages, 10)) {
            $(Selectors.inspirationNextArrow)
                .hide();
            $(Selectors.inspirationPrevArrow)
                .show();
        } else {
            $(Selectors.inspirationPrevArrow)
                .show();
            $(Selectors.inspirationNextArrow)
                .show();
        }

        $(Selectors.inspirationPageNumberMobile)
            .text('Page: ' + this.currentPage + ' of ' + this.numPages);
    },

    toggleCategory: function (categoryId, isShow) {
        this.$(`${Selectors.category}[data-id="${categoryId}"]`)
            .toggle(isShow);
    },

    previousPage: function () {
        const prevPage = parseInt(this.currentPage, 10) - 1;
        $(Selectors.page)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.inspirationPageNumber)
            .removeClass(Constants.cssClasses.active);
        $(Ids.page + prevPage)
            .addClass(Constants.cssClasses.active);
        $(Ids.groupPage + prevPage)
            .addClass(Constants.cssClasses.active);
        $(Ids.pageNum + prevPage)
            .addClass(Constants.cssClasses.active);
        this.currentPage = prevPage;
        this.checkArrows();
    },

    nextPage: function () {
        const nextPage = parseInt(this.currentPage, 10) + 1;
        $(Selectors.page)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.inspirationPageNumber)
            .removeClass(Constants.cssClasses.active);
        $(Ids.page + nextPage)
            .addClass(Constants.cssClasses.active);
        $(Ids.groupPage + nextPage)
            .addClass(Constants.cssClasses.active);
        $(Ids.pageNum + nextPage)
            .addClass(Constants.cssClasses.active);
        this.currentPage = nextPage;
        this.checkArrows();
    },

    onPageClick: function (e) {
        const itemId = $(e.target)
            .attr('data-id');
        $(Selectors.page)
            .removeClass(Constants.cssClasses.active);
        $(Selectors.inspirationPageNumber)
            .removeClass(Constants.cssClasses.active);
        $(Ids.page + itemId)
            .addClass(Constants.cssClasses.active);
        $(Ids.groupPage + itemId)
            .addClass(Constants.cssClasses.active);
        $(Ids.pageNum + itemId)
            .addClass(Constants.cssClasses.active);
        this.currentPage = itemId;
        this.checkArrows();
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },

    show: function (data) {
        $(Selectors.inputSearch)
            .val(data.searchString);
        if (!window.app.settings.isDesktop) {
            app.vent.trigger(Events.search.showCloseButton);
            $(window.app.els.$topNav)
                .addClass(CssClasses.topNavSearchResultsOpened);
        }
        this.savedScroll = $(window)
            .scrollTop();
        scrollLock.addScrollableSelector(Selectors.inputWrapper);

        clearTimeout(this.closeTimeout);
        this.$el.removeClass(CssClasses.searchResultsHide);

        app.vent.trigger(Events.popup.popupShown);
        window.app.els.$body
            .addClass(Constants.cssClasses.popupShow);
        $(this.popupWrapper)
            .scrollTop(0);
        window.app.utils.changeHash(SearchHash);

        this.$(Selectors.inputSearch)
            .focus();

        $(window)
            .on(ViewportEvents.keyDown, this.onKeydown);

        this.fetchData(data);
    },

    hide: function () {
        clearTimeout(this.closeTimeout);
        this.$el.addClass(CssClasses.searchResultsHide);

        this.closeTimeout = setTimeout(function () {
            this.$el.removeClass(CssClasses.searchResultsWithContent);
            this.$(Selectors.input)
                .val('');

            //for api => show results
            this.$(Selectors.resultsItem)
                .each(function (id, item) {
                    $(item)
                        .fadeIn();
                });
        }.bind(this), 500);

        window.app.utils.waitForTransitionEnd(this.$el, 'visibility', () => {
            app.vent.trigger(Events.popup.popupHidden);
        }, 300);
        scrollLock.removeScrollableSelector(Selectors.inputWrapper);

        window.app.els.$body
            .removeClass(Constants.cssClasses.popupShow);

        window.app.utils.removeHash();

        window.requestAnimationFrame(() => {
            $(window)
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });

        $(window)
            .off(ViewportEvents.keyDown, this.onKeydown);
    },

    _initPerfectScroll: function () {
        if (!document.querySelector(this.popupWrapper) || !window.app.settings.isDesktop) {
            return;
        }
        this.perfectScrollbar = new PerfectScrollbar(this.popupWrapper);
        $(this.popupWrapper)
            .on(ViewportEvents.scroll, this.onScroll);
        $(window)
            .on(ViewportEvents.resize, this.onResize);
        this.onResize();
        this.onScroll();
        this.perfectScrollbar.update();
    },

    onCloseClick: function () {
        this.hide();
    },

    onFocus: function () {
        this.$el.addClass(CssClasses.searchResultsWithContent);
    },

    onBlur: function () {
        this.$el.removeClass(CssClasses.searchResultsWithContent);
    },

    onScroll: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    onResize: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    }
});
