'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

//a 2D rigid body physics engine
const Matter = require('matter-js');

require('./PendulumLamp.less');

const Selectors = {
    el: '.PendulumLamp',
    lamp: '.PendulumLamp-lamp',
    lampColor1: '#pendulum-lamp-color-1',
    lampColor2: '#pendulum-lamp-color-2',
    background: '.PageGlobalIndex-background',
    lampBody: '#pendulum-lamp-body',
    ropeA: '#pendulum-lamp-rope-a',
    ropeB: '#pendulum-lamp-rope-b'
};

const BindFunctions = [
    '_attachEvents',
    '_initVariables',
    'shuffleColors',
    'checkIfBackgroundActive',
    '_onResize',
    '_onEngineTick',
    '_onMouseDown',
    '_onMouseUp',
    '_getClickCoords'
];

const Colors = [
    {backgroundColor: '#e4001d', color: 'light'},
    {backgroundColor: '#0f4295', color: 'light'},
    {backgroundColor: '#FFD500', color: 'dark'}
];

const AngularStiffness = 0.9;
const BezierLineSmoothness = 0.2;
const BoxHeight = 285;
const BoxWidth = 116;
const Damping = 1;
const DetectClickMaxDuration = 500;
const DetectClickMaxShift = 5;
const EngineConstraintIterations = 10;
const EnginePositionIterations = 30;
const EngineTimeScale = 1;
const EngineVelocityIterations = 20;
const FadeDuration = 500;
const HelpersSize = 1;
const InitialMovementForceX = 0.01;
const InitialMovementForceY = 0;
const LampBodyAngularStiffness = 0.5;
const LampBodyJointShiftFromMassCenter = -6 / 8;
const LampBodyMass = 4;
const LampBottomConstraintAngularStiffness = 0.2;
const LampBottomConstraintDamping = 0.001;
const LampBottomConstraintStiffness = 0.002;
const LampBottomConstraintX = 400;
const LampBottomConstraintY = 56000;
const MouseStiffness = 0.0002;
const RadianToDegreesConverter = 180 / Math.PI;
const RopeAnchorsPosX = 0;
const RopeAnchorsPosY = 0.5;
const RopesDistance = 32;
const RopeSegmentMass = 0.1;
const RopeSegmentsGapX = 0;
const RopeSegmentSize = 80;
const RopeSegmentsX = 1;
const RopeSegmentsY = 5;
const RopeSegmentsGapY = 40;
const RopeTopAnchorShift = 50;
const Stiffness = 1;
const WorldHeight = 800;
const WorldWidth = 800;
const RopePosX = 386 - (RopeSegmentSize - 32) / 2;
const RopePosY = 218 - (RopeSegmentSize + RopeSegmentsGapY) * RopeSegmentsY;


module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initVariables();
        this._initPhysicsScene();
        this._attachEvents();
    },

    _initVariables: function () {
        this.currentBackgroundColor = Colors[2].backgroundColor;
        this.currentLampColor1 = Colors[1].backgroundColor;
        this.currentLampColor2 = Colors[0].backgroundColor;
        this.colors = Colors;
        this.textColor = this.colors[2].color;
        this.setColors();
        this.$lamp = this.$(Selectors.lamp);
        this.$lampBody = this.$(Selectors.lampBody);
        this.$ropeA = this.$(Selectors.ropeA);
        this.$ropeB = this.$(Selectors.ropeB);
    },

    _initPhysicsScene: function () {
        this.engine = Matter.Engine.create({
            enableSleeping: true
        });
        this.runner = Matter.Runner.create();
        this.render = Matter.Render.create({
            element: this.el,
            engine: this.engine,
            options: {
                width: this.$el.width(),
                height: this.$el.height()
            }
        });

        this.rope = Matter.Composites.stack(RopePosX, RopePosY, RopeSegmentsX, RopeSegmentsY,
            RopeSegmentsGapX, RopeSegmentsGapY,
            function (x, y) {
                const body = Matter.Bodies.circle(x, y, RopeSegmentSize / 2);
                Matter.Body.setMass(body, RopeSegmentMass);

                return body;
            });

        Matter.Composites.chain(this.rope, RopeAnchorsPosX, RopeAnchorsPosY, RopeAnchorsPosX, -RopeAnchorsPosY, {
            stiffness: Stiffness,
            damping: Damping,
            angularStiffness: AngularStiffness
        });

        Matter.Composite.add(this.rope, Matter.Constraint.create({
            bodyB: this.rope.bodies[0],
            pointA: {
                x: this.rope.bodies[0].position.x,
                y: this.rope.bodies[0].position.y - RopeSegmentSize - RopeTopAnchorShift
            },
            pointB: {
                x: 0,
                y: -RopeSegmentSize
            },
            stiffness: Stiffness,
            damping: Damping,
            angularStiffness: AngularStiffness
        }));

        const lastRopeSegment = this.rope.bodies[this.rope.bodies.length - 1];
        const boxX = lastRopeSegment.position.x;
        const boxY = lastRopeSegment.position.y + RopeSegmentSize / 2 + RopeSegmentsGapY + BoxHeight / 2;
        const lampBody = Matter.Bodies.rectangle(boxX, boxY, BoxWidth, BoxHeight);
        const lampJointPosY = lampBody.position.y - BoxHeight / 2 + HelpersSize * 2;
        const lampMassCenterY = lampBody.position.y + BoxHeight / 2;
        this.lampJointA = Matter.Bodies.rectangle(boxX - RopesDistance / 2, lampJointPosY, HelpersSize, HelpersSize);
        this.lampJointB = Matter.Bodies.rectangle(boxX + RopesDistance / 2, lampJointPosY, HelpersSize, HelpersSize);
        const lampMassCenter = Matter.Bodies.rectangle(lampBody.position.x, lampMassCenterY, HelpersSize, HelpersSize);
        Matter.Body.setMass(lampBody, LampBodyMass / 2);
        Matter.Body.setMass(lampMassCenter, LampBodyMass / 2);

        this.lampBox = Matter.Body.create({
            parts: [lampBody, this.lampJointA, this.lampJointB, lampMassCenter]
        });

        Matter.Composite.add(this.rope, Matter.Constraint.create({
            bodyA: lastRopeSegment,
            bodyB: this.lampBox,
            pointA: {
                x: 0,
                y: RopeSegmentSize / 2
            },
            pointB: {
                x: 0,
                y: LampBodyJointShiftFromMassCenter * BoxHeight
            },
            stiffness: Stiffness,
            damping: Damping,
            angularStiffness: LampBodyAngularStiffness
        }));


        Matter.Composite.add(this.rope, Matter.Constraint.create({
            bodyA: this.lampBox,
            pointA: {
                x: 0,
                y: 0
            },
            pointB: {
                x: LampBottomConstraintX,
                y: LampBottomConstraintY
            },
            stiffness: LampBottomConstraintStiffness,
            damping: LampBottomConstraintDamping,
            angularStiffness: LampBottomConstraintAngularStiffness
        }));

        Matter.World.add(this.engine.world, [
            this.rope,
            this.lampBox
        ]);

        this.lampBox.originalPos = {
            x: this.lampBox.position.x,
            y: this.lampBox.position.y,
            angle: this.lampBox.angle
        };


        const wrapNoPrevent = (func) => {
            return (e) => {
                e.preventDefault = () => {
                };
                func(e);
            };
        };

        //hack the mouse cause it calls prevetDefault all the time (that's a weird solution!)
        Matter.Mouse.setElement = function (mouse, element) {
            mouse.element = element;

            element.addEventListener('mousemove', wrapNoPrevent(mouse.mousemove));
            element.addEventListener('mousedown', wrapNoPrevent(mouse.mousedown));
            element.addEventListener('mouseup', wrapNoPrevent(mouse.mouseup));

            element.addEventListener('mousewheel', wrapNoPrevent(mouse.mousewheel));
            element.addEventListener('DOMMouseScroll', wrapNoPrevent(mouse.mousewheel));

            element.addEventListener('touchmove', wrapNoPrevent(mouse.mousemove));
            element.addEventListener('touchstart', wrapNoPrevent(mouse.mousedown));
            element.addEventListener('touchend', wrapNoPrevent(mouse.mouseup));
        };


        // add mouse control
        this.mouse = Matter.Mouse.create(this.render.canvas);

        const mouseConstraint = Matter.MouseConstraint.create(this.engine, {
            mouse: this.mouse,
            constraint: {
                stiffness: MouseStiffness,
                render: {
                    visible: true
                }
            }
        });

        Matter.World.add(this.engine.world, mouseConstraint);

        // keep the mouse in sync with rendering
        this.render.mouse = this.mouse;

        // fit the render viewport to the scene
        Matter.Render.lookAt(this.render, {
            min: {
                x: 0,
                y: 0
            },
            max: {
                x: WorldWidth,
                y: WorldHeight
            }
        });

        this.engine.timing.timeScale = EngineTimeScale;
        this.engine.positionIterations = EnginePositionIterations;
        this.engine.velocityIterations = EngineVelocityIterations;
        this.engine.constraintIterations = EngineConstraintIterations;

        Matter.Body.applyForce(this.lampBox, {
            x: lampBody.position.x,
            y: lampBody.position.y + BoxHeight / 4
        }, {
            x: InitialMovementForceX,
            y: InitialMovementForceY
        });

        Matter.Events.on(this.engine, 'beforeTick', this._onEngineTick);
    },

    _attachEvents: function () {
        window.app.vent.on(Events.splashBackgroundChange, this.checkIfBackgroundActive);
    },


    _generateBezierLine: function (pointsArray) {
        const line = function (pointA, pointB) {
            const lengthX = pointB[0] - pointA[0];
            const lengthY = pointB[1] - pointA[1];

            return {
                length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
                angle: Math.atan2(lengthY, lengthX)
            };
        };

        const controlPoint = function (current, previous, next, reverse) {
            const previousPoint = previous || current;
            const nextPoint = next || current;
            const lineFromPoints = line(previousPoint, nextPoint);
            const angle = lineFromPoints.angle + (reverse ? Math.PI : 0);
            const length = lineFromPoints.length * BezierLineSmoothness;
            const x = current[0] + Math.cos(angle) * length;
            const y = current[1] + Math.sin(angle) * length;

            return [x.toFixed(1), y.toFixed(1)];
        };

        const bezierCommand = function (point, i, points) {
            const controlPointStart = controlPoint(points[i - 1], points[i - 2], point);
            const controlPointEnd = controlPoint(point, points[i - 1], points[i + 1], true);

            return 'C ' + controlPointStart[0] + ',' + controlPointStart[1] + ' ' +
                controlPointEnd[0] + ',' + controlPointEnd[1] + ' ' +
                point[0].toFixed(1) + ',' + point[1].toFixed(1);
        };

        return pointsArray.reduce(function (acc, point, i, points) {
            return i === 0 ?
                'M ' + point[0].toFixed(1) + ',' + point[1].toFixed(1) :
                acc + ' ' + bezierCommand(point, i, points);
        }, '');
    },

    _onEngineTick: function () {
        const bodyDx = this.lampBox.position.x - this.lampBox.originalPos.x;
        const bodyDy = this.lampBox.position.y - this.lampBox.originalPos.y;
        const bodyDAngle = (this.lampBox.angle - this.lampBox.originalPos.angle) * RadianToDegreesConverter;

        const rotate = [bodyDAngle, this.lampBox.position.x, this.lampBox.position.y].join(' ');
        const translate = [bodyDx, bodyDy].join(' ');

        this.$lampBody.attr('transform', ' rotate(' + rotate + ') translate(' + translate + ')');

        const ropeAPoints = [];
        const ropeBPoints = [];
        for (let i = 0; i < this.rope.bodies.length; i++) {
            let x = this.rope.bodies[i].position.x;
            let y = this.rope.bodies[i].position.y;
            let angle = this.rope.bodies[i].angle;
            ropeAPoints.push([x - Math.cos(angle) * RopesDistance / 2, y - Math.sin(angle) * RopesDistance / 2]);
            ropeBPoints.push([x + Math.cos(angle) * RopesDistance / 2, y + Math.sin(angle) * RopesDistance / 2]);
        }

        ropeAPoints.push([this.lampJointA.position.x, this.lampJointA.position.y]);
        ropeBPoints.push([this.lampJointB.position.x, this.lampJointB.position.y]);

        this.$ropeA.attr('d', this._generateBezierLine(ropeAPoints));
        this.$ropeB.attr('d', this._generateBezierLine(ropeBPoints));
    },

    shuffleColors: function () {
        let isShuffleSuccessful = true;
        const newColors = _.shuffle(this.colors);

        newColors.forEach((newColor, index) => {
            if (newColor.backgroundColor === this.colors[index].backgroundColor) {
                isShuffleSuccessful = false;
            }
        });
        if (!isShuffleSuccessful) {
            this.shuffleColors();

            return;
        }
        this.colors = newColors;
        this.currentBackgroundColor = this.colors[0].backgroundColor;
        this.textColor = this.colors[0].color;
        this.currentLampColor1 = this.colors[1].backgroundColor;
        this.currentLampColor2 = this.colors[2].backgroundColor;
        this.setColors();
    },

    setColors: function () {
        this.$el.css(Constants.cssProperties.backgroundColor, this.currentBackgroundColor);
        this.$(Selectors.lampColor1)
            .css(Constants.cssProperties.fill, this.currentLampColor1);
        this.$(Selectors.lampColor2)
            .css(Constants.cssProperties.fill, this.currentLampColor2);
        app.vent.trigger(Events.common.splashColorChange, this.textColor, this.currentBackgroundColor);
    },

    _onResize: function () {
        this.render.canvas.width = this.$el.width();
        this.render.canvas.height = this.$el.height();
    },

    _getClickCoords: function (e) {
        const touches = e.changedTouches;
        const rootNode = (document.documentElement || document.body.parentNode || document.body);
        const scrollX = (window.pageXOffset !== undefined) ? window.pageXOffset : rootNode.scrollLeft;
        const scrollY = (window.pageYOffset !== undefined) ? window.pageYOffset : rootNode.scrollTop;
        let x;
        let y;

        if (touches) {
            x = touches[0].pageX - scrollX;
            y = touches[0].pageY - scrollY;
        } else {
            x = e.pageX - scrollX;
            y = e.pageY - scrollY;
        }

        return {
            x: x,
            y: y
        };
    },

    _onMouseDown: function (e) {
        this.clickCoords = this._getClickCoords(e);
        this.clickTime = Date.now();
    },

    _onMouseUp: function (e) {
        const clickCoords = this._getClickCoords(e);
        const clickTime = Date.now();

        if (clickTime - this.clickTime > DetectClickMaxDuration) {
            return;
        }
        if (Math.abs(clickCoords.x - this.clickCoords.x) > DetectClickMaxShift ||
            Math.abs(clickCoords.y - this.clickCoords.y) > DetectClickMaxShift) {
            return;
        }

        this.shuffleColors();
    },

    checkIfBackgroundActive: function () {
        const isBgActive = this.$el.closest(Selectors.background)
            .hasClass(Constants.cssClasses.active);
        if (!isBgActive) {
            clearTimeout(this.stopTimeout);
            if (this.running) {
                this.stopTimeout = setTimeout(function () {
                    Matter.Render.stop(this.render);
                    Matter.Runner.stop(this.runner, this.engine);
                    this.running = false;
                }.bind(this), FadeDuration);
            }
            $(window)
                .off('resize', this._onResize);
            this.$el.off('mousedown touchstart', this._onMouseDown);
            this.$el.off('mouseup touchend', this._onMouseUp);

            return;
        }

        app.vent.trigger(Events.common.splashColorChange, this.textColor, this.currentBackgroundColor);
        clearTimeout(this.stopTimeout);
        if (!this.running) {
            Matter.Render.run(this.render);
            Matter.Runner.run(this.runner, this.engine);
            this.running = true;
        }
        this._onResize();
        $(window)
            .on('resize', this._onResize);
        this.$el.on('mousedown touchstart', this._onMouseDown);
        this.$el.on('mouseup touchend', this._onMouseUp);
    }
});
