'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./DropdownButton.less');

const Selectors = {
    el: '.DropdownButton',
    button: '.DropdownButton-button'
};

const BindFunctions = [
    'onButtonClick',
    'onOutsideClick'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.button}`]: this.onButtonClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        $(document)
            .ready(() => {
                $(document)
                    .click(this.onOutsideClick);
            });
    },

    onButtonClick: function () {
        this.$el.toggleClass(Constants.cssClasses.active);
    },

    onOutsideClick: function (event) {
        const $target = $(event.target);

        if ($target.closest(this.$el).length) {
            return;
        }

        this.$el.removeClass(Constants.cssClasses.active);
    }
});
