'use strict';

const _ = require('underscore');

const {UnsupportedValueError} = require('errors');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const PerfectScrollbar = require('perfect-scrollbar').default;

require('./Dropdown.less');

const Selectors = {
    el: '.Dropdown',
    select: '.Dropdown-select',
    selectModel: '.Dropdown-selectModel',
    selectModelText: '.Dropdown-selectModel p',
    selectOptions: '.Dropdown-selectOptions',
    selectOption: '.Dropdown-selectOption',
    selectOptionElement: 'option.Dropdown-selectOption',
    selectOptionActive: `.Dropdown-selectOption--${Constants.cssClasses.active}`,
    selectOptionFirstChild: '.Dropdown-selectOption:first-child',
    linkList: '.Dropdown-linkList',
    linkListActive: '.Dropdown-linkList.isOpenedLinkList',
    formValue: '.Dropdown-formValue'
};

const BindFunctions = [
    'toggleSelect',
    '_attachEvents',
    '_initVariables',
    'chooseValue',
    'reset',
    'toggleLinkList',
    'setValue',
    'getDefaultValue'
];

const CssClasses = {
    selectOptionActive: `Dropdown-selectOption--${Constants.cssClasses.active}`,
    isOpenedLinkList: 'isOpenedLinkList',
    isInvalid: 'invalid'
};

/**
 * options:
 *      onSelectOpen - callback when select is toggled to open
 *      onChooseOption - callback when option is chosen
 *      id - unique id of current dropdown,
 *      modifier - modifier of page or component
 *
 */
module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.selectOption}`]: this.chooseValue,
            [`${ViewportEvents.click} ${Selectors.linkList}`]: this.toggleLinkList
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this.setElement(`${Selectors.el}--${this.modifier}_${this.id}`);
        this.type = this.$el.data('type');
        this.defaultValue = options.value != null ? options.value : null;
        this.selector = `${Selectors.selectOptions}--${this.modifier}_${this.id}`;
        this._attachEvents();
        this.reset();
    },

    _attachEvents: function () {
        this.$el.find(Selectors.select)
            .on(ViewportEvents.click, this.toggleSelect);

        this.$('select')
            .on(ViewportEvents.change, this.chooseValue);

        $(document)
            .click((event) => {
                if (!$(event.target)
                    .closest(this.$el).length) {
                    this.$(Selectors.select)
                        .removeClass(Constants.cssClasses.active);
                    this.isOpenSelect = false;
                }
            });
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isOpenSelect = false;
        this.onSelectOpen = options.onSelectOpen;
        this.onChooseOption = options.onChooseOption;
        this.id = options.id;
        this.modifier = options.modifier;
        this.hasPlaceholder = options.hasPlaceholder;
    },

    toggleSelect: function () {
        this.isOpenSelect = !this.isOpenSelect;

        if (!this.perfectScrollbar) {
            this.perfectScrollbar = new PerfectScrollbar(this.selector);
        }

        this.isOpenSelect && this.onSelectOpen && this.onSelectOpen(this.id);

        this.$el.find(Selectors.select)
            .toggleClass(Constants.cssClasses.active, this.isOpenSelect);
    },

    reset: function () {
        // There's nothing to reset, with linklists
        if (this.$el.find(Selectors.linkList)
            .get().length > 0) {
            return;
        }
        this.value = this.getDefaultValue();
        if (this.hasPlaceholder) {
            return;
        }
        const defaultValue = this.getDefaultValue();
        if (defaultValue == null) {
            return;
        }
        // `$option` does not only contain the option-element, but also the "option" of the visual component
        const $option = this.$el.find(`${Selectors.selectOption}[data-value="${defaultValue}"]`)
            .addClass(CssClasses.selectOptionActive);
        // The option with the `defaultValue does` not exist or the Dropdown had no options
        if (!$option.length) {
            return;
        }
        const $modelText = this.$el.find(Selectors.selectModelText);
        $modelText.text($option.filter('option')
            .get(0).text);
    },

    close: function () {
        this.$(`${Selectors.select}.${Constants.cssClasses.active}`)
            .removeClass(Constants.cssClasses.active);
        this.$(Selectors.linkListActive)
            .removeClass(CssClasses.isOpenedLinkList);

        this.isOpenSelect = false;
    },

    getDefaultValue: function () {
        if (this.defaultValue != null) {
            return this.defaultValue;
        }
        const defaultValue = this.$el
            .find(Selectors.selectModel)
            .data('default-value');
        if (defaultValue != null && defaultValue !== '') {
            return defaultValue;
        }
        const $selectedOption = this.$el.find(`${Selectors.selectOptionElement}`);
        if (!$selectedOption.length) {
            return null;
        }

        return $selectedOption.get(0)
            .value;
    },

    setValue: function ($item, idItem) {
        if (!$item && idItem) {
            $item = this.$el.find(`${Selectors.selectOption}[data-value="${idItem}"]`);
        }

        try {
            idItem = this._getIdItem($item, idItem);
        } catch (e) {
            if (e instanceof UnsupportedValueError) {
                console.warn('An undefined or null value has been attempted to be set');

                return;
            }
            throw e;
        }

        let textItem = this._getTextItem($item);
        let typeItem = this._getTypeItem($item);
        if (window.app.settings.isMobile) {
            $item
                .closest(Selectors.el)
                .find(Selectors.selectModelText)
                .text(textItem);

            $item = this.$el.find(`${Selectors.selectOption}[data-value="${idItem}"]`);
        }
        this.$el.find(Selectors.formValue)
            .val(idItem);

        this.$el.find(Selectors.selectOptionActive)
            .removeClass(CssClasses.selectOptionActive);

        this.$el.find(Selectors.selectOptionFirstChild)
            .addClass(CssClasses.selectOptionActive);

        $item
            .closest(Selectors.selectOptions)
            .find(Selectors.selectOption)
            .removeClass(CssClasses.selectOptionActive);

        $item.addClass(CssClasses.selectOptionActive);

        $item
            .closest(Selectors.select)
            .find(Selectors.selectModelText)
            .text(textItem);

        this.value = idItem;

        this.onChooseOption && this.onChooseOption(this.id, idItem, typeItem, $item);
    },

    _getTextItem: function ($item) {
        if (window.app.settings.isMobile) {
            return $item.find(':selected')
                .data('text');
        }

        return $item.data('text');
    },

    _getTypeItem: function ($item) {
        if (window.app.settings.isMobile) {
            return $item.find(':selected')
                .data('type');
        }

        return $item.data('type');
    },

    _getIdItem: function ($item, idItem) {
        if (!$item && idItem) {
            $item = this.$el.find(`${Selectors.selectOption}[data-value="${idItem}"]`);
        }
        if ($item) {
            if (!idItem) {
                idItem = $item.data('value');
            }
            if (window.app.settings.isMobile) {
                idItem = $item.find(':selected')
                    .data('value');
            }
        }

        if (idItem == null) {
            throw new UnsupportedValueError();
        }

        return idItem;
    },

    chooseValue: function (e) {
        let $item = $(e.currentTarget);

        this.setValue($item);
    },

    toggleLinkList: function () {
        this.$(Selectors.linkList)
            .toggleClass(CssClasses.isOpenedLinkList);
    },

    validate: function () {
        if (this.isValid()) {
            this.$el.removeClass(CssClasses.isInvalid);

            return;
        }

        this.$el.addClass(CssClasses.isInvalid);
    },

    isValid: function () {
        if (!this.$el.hasClass('required')) {
            return true;
        }

        return this.value !== this.getDefaultValue();
    }
});
