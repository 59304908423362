'use strict';

const Page = require('pages/Page/Page.js');

require('./PageSitemap.less');

const Selectors = {
    el: '.PageSitemap'
};

module.exports = Page.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        Page.prototype.initialize.call(this, options);
    }
});
