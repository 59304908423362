'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const CommonGallery = require('components/Common/CommonGallery/CommonGallery');
const EightFacts = require('components/Common/EightFacts/EightFacts');

require('./CareersWhyZumtobel.less');

const Selectors = {
    el: '.CareersWhyZumtobel'
};

const BindFunctions = [
    '_initChildComponents'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initChildComponents();
    },

    _initChildComponents: function () {
        this.gallery = new CommonGallery({blockSelector: Selectors.el});
        this.eightFacts = new EightFacts();
    }
});
