'use strict';

const _ = require('underscore');
const Backbone = require('backbone');
const ViewportEvents = require('constants/ViewportEvents');

const LayoutsPopup = require('popups/PageLayoutsPopup/PageLayoutsPopup');

require('./ProductsItem.less');

const BindFunctions = [
    '_attachEvents',
    'onItemClick'
];

const Selectors = {
    el: '.ProductsItem'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._attachEvents();
    },

    _attachEvents: function () {
        this.$el.on(ViewportEvents.click, this.onItemClick);
    },

    onItemClick: function (event) {
        const $target = $(event.target)
            .closest(Selectors.el);

        if ('apiUrl' in $target.data()) {
            event.preventDefault();

            const url = $target
                .data('url');

            const apiUrl = $target.data('api-url');

            const popup = new LayoutsPopup({
                page: this
            });

            popup.openPopup(apiUrl, url);
        }
    }
});
