'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

require('./Layouts111Sections.less');

const LayoutsProducts = require('components/LayoutsProducts/LayoutsProducts');
const LayoutsGallery = require('components/LayoutsGallery/LayoutsGallery');
const LayoutsGetInspired = require('components/LayoutsGetInspired/LayoutsGetInspired');
const LayoutsDownloads = require('components/LayoutsDownloads/LayoutsDownloads');
const LayoutsNewsInsights = require('components/Common/NewsInsights/NewsInsights');
const LayoutsThreeColumns = require('components/LayoutsThreeColumns/LayoutsThreeColumns');
const LayoutsRichText = require('components/LayoutsRichText/LayoutsRichText');
const LayoutServiceVideo = require('components/LayoutServiceVideo/LayoutServiceVideo');
const LayoutsHowItWorks = require('components/LayoutsHowItWorks/LayoutsHowItWorks');
const LayoutsAllServices = require('components/LayoutsAllServices/LayoutsAllServices');
const LinkList = require('components/LinkList/LinkList');
const UpcomingEvents = require('components/CampusUpcomingEvents/CampusUpcomingEvents');
const LinkListSmall = require('components/LinkListSmall/LinkListSmall');

const BindFunctions = [
    '_initChildComponents'
];

const PageModifier = 'layouts';

const ChildViews = [
    {
        name: 'layoutsProducts',
        Constructor: LayoutsProducts
    },
    {
        name: 'layoutsGetInspired',
        Constructor: LayoutsGetInspired
    },
    {
        name: 'layoutsDownloads',
        Constructor: LayoutsDownloads
    },
    {
        name: 'layoutsNewsInsights',
        Constructor: LayoutsNewsInsights
    },
    {
        name: 'layoutsThreeColumns',
        Constructor: LayoutsThreeColumns
    },
    {
        name: 'layoutsRichText',
        Constructor: LayoutsRichText
    },
    {
        name: 'layoutServiceVideo',
        Constructor: LayoutServiceVideo
    },
    {
        name: 'layoutsHowItWorks',
        Constructor: LayoutsHowItWorks
    },
    {
        name: 'layoutsAllServices',
        Constructor: LayoutsAllServices
    },
    {
        name: 'linkList',
        Constructor: LinkList
    },
    {
        name: 'linkListSmall',
        Constructor: LinkListSmall
    }
];

const Selectors = {
    gallery: '.LayoutsGallery'
};

module.exports = Backbone.View.extend({

    el: '.Layouts111Sections',

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this._initChildComponents();
    },

    _initChildComponents: function () {
        if (!this.childViews) {
            this.childViews = {};
        }

        this.upcomingEvents = new UpcomingEvents({
            modifier: PageModifier
        });

        for (let i = 0; i < ChildViews.length; i++) {
            this.childViews[ChildViews[i].name] = new ChildViews[i].Constructor({
                page: this,
                modifier: PageModifier,
                id: i
            });
        }

        this.$(Selectors.gallery)
            .each((index, galleryItem) => {
                const $gallery = $(galleryItem);
                const galleryModifier = $gallery.data('modifier');
                const galleryId = $gallery.data('id');
                const name = `gallery--${galleryModifier}_${galleryId}`;

                this.childViews[name] = new LayoutsGallery({
                    page: this,
                    modifier: galleryModifier,
                    id: galleryId
                });
            });
    }
});
