'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const _ = require('underscore/underscore.js');

const ProductsFilterMobile = require('components/ProductsFilterMobile/ProductsFilterMobile');

require('./ProductsFilter.less');

const Selectors = {
    el: '.ProductsFilter',
    title: '.ProductsFilter-title',
    filterItemSelect: '.ProductsFilter-filterItemSelect',
    filterItemSelectMenu: '.ProductsFilter-filterItemSelectMenu',
    filterItemSelectMenuVal: '.ProductsFilter-filterItemSelectMenuVal',
    filterClear: '.ProductsFilter-filterClear',
    filterItemSelectText: '.ProductsFilter-filterItemSelectText',
    filterItemSelectTextP: '.ProductsFilter-filterItemSelectText p',
    selectMenuValActive: `.ProductsFilter-filterItemSelectMenuVal--${Constants.cssClasses.active}`,
    selectMenuValFirstChild: '.ProductsFilter-filterItemSelectMenuVal:first-child',
    filterHideItems: '.ProductsFilter-filterHideItems',
    filterMore: '.ProductsFilter-filterMore'
};

const BindFunctions = [
    'onFiltered',
    'clearFilter',
    '_attachEvents',
    '_initVariables',
    '_initChildComponents',
    'setValue',
    'openAllFilters'
];

const CssClasses = {
    selectMenuValActive: `ProductsFilter-filterItemSelectMenuVal--${Constants.cssClasses.active}`,
    filterClearHide: 'ProductsFilter-filterClear--hide',
    isHidden: 'isHidden',
    isOpenFilters: 'isOpenFilters'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.filterItemSelect}`]: this.toggleSelect,
            [`${ViewportEvents.click} ${Selectors.filterItemSelectMenuVal}`]: this.changeFilter,
            [`${ViewportEvents.click} ${Selectors.filterClear}`]: this.clearFilter,
            [`${ViewportEvents.click} ${Selectors.filterMore}`]: this.openAllFilters
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);

        this._initChildComponents();
        this._attachEvents();

        if (!this.preFiltered || window.app.isPhoneLayout()) {
            return;
        }

        Object.entries(this.preFiltered)
            .filter(([, value]) => !!value)
            .forEach(([key, value]) => {
                this.setValue(undefined, value, key);
            });
    },

    _attachEvents: function () {
        window.app.els.$body.on(ViewportEvents.click, this.clickOutSide.bind(this));

        app.vent.on(Events.eventPrefixes.products + Events.filter.filtered, this.onFiltered);
        app.vent.on(Events.eventPrefixes.products + Events.filter.clearFilters, this.clearFilter);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.isOpenSelect = false;
        this.preFiltered = options.preFiltered;
        this.$hideFilters = this.$(Selectors.filterHideItems);
        this.$btnOpenFilters = this.$(Selectors.filterMore);
        this.anyValue = this.$el.data('any-value');
        this.titleDefaultValue = this.$('.ProductsFilter-title')
            .data('default');
        this.titleCountValue = this.$('.ProductsFilter-title')
            .data('count-text');
        this.$title =  this.$(Selectors.title);
    },

    _initChildComponents: function () {
        this.productsFilterMobile = new ProductsFilterMobile({
            page: this,
            preFiltered: this.preFiltered
        });
    },

    onFiltered: function (countItems) {
        if (countItems !== -1) {
            this.$title.text(`${countItems} ${this.titleCountValue}`);

            return;
        }
        this.$title.text(this.titleDefaultValue);
    },

    toggleSelect: function (e) {
        const $item = $(e.currentTarget);

        if (!$(e.target)
            .closest(Selectors.filterItemSelectMenu).length) {
            if (!$item.closest(Selectors.filterItemSelect)
                .hasClass(Constants.cssClasses.active)) {
                this.closeAllSelect();
                this.isOpenSelect = !this.isOpenSelect;
            } else {
                this.isOpenSelect = false;
            }

            $item.toggleClass(Constants.cssClasses.active, this.isOpenSelect);
        }
    },

    clickOutSide: function (e) {
        const elSelect = this.$(Selectors.filterItemSelect);

        //check if the clicked area is dropDown or not
        if (elSelect.has(e.target).length === 0) {
            this.closeAllSelect();
        }
    },

    closeAllSelect: function () {
        this.isOpenSelect = false;
        this.$(`${Selectors.filterItemSelect}.${Constants.cssClasses.active}`)
            .removeClass(Constants.cssClasses.active);
    },

    setValue: function ($item, idItem, typeItem) {
        if (!$item) {
            $item = this.$(`${Selectors
                .filterItemSelect}[data-type="${typeItem}"] ${Selectors.filterItemSelectMenuVal}[data-id="${idItem}"]`);
        }

        const textItem = $item.data('text');

        if (!idItem) {
            idItem = $item.data('id');
        }

        if (!typeItem) {
            typeItem = $item.data('type');
        }

        if (idItem === this.anyValue) {
            idItem = null;
        }

        this.$(Selectors.filterItemSelectTextP)
            .text(this.$(Selectors.filterItemSelectText)
                .data('default'));

        this.$(Selectors.selectMenuValActive)
            .removeClass(CssClasses.selectMenuValActive);

        this.$(Selectors.selectMenuValFirstChild)
            .addClass(CssClasses.selectMenuValActive);

        $item
            .closest(Selectors.filterItemSelectMenu)
            .find(Selectors.selectMenuValActive)
            .removeClass(CssClasses.selectMenuValActive);

        $item.addClass(CssClasses.selectMenuValActive);

        $item
            .closest(Selectors.filterItemSelect)
            .find(Selectors.filterItemSelectTextP)
            .text(textItem);

        this.$(Selectors.filterClear)
            .toggleClass(CssClasses.filterClearHide, idItem === null);

        app.vent.trigger(Events.eventPrefixes.products + Events.filter.onFilter, idItem, typeItem);

        this.closeAllSelect();
    },

    changeFilter: function (e) {
        const $item = $(e.currentTarget);

        this.setValue($item);
    },

    clearFilter: function () {
        this.$(Selectors.filterItemSelectTextP)
            .text(this.$(Selectors.filterItemSelectText)
                .data('default'));

        this.$(Selectors.selectMenuValActive)
            .removeClass(CssClasses.selectMenuValActive);

        this.$(Selectors.selectMenuValFirstChild)
            .addClass(CssClasses.selectMenuValActive);

        this.$(Selectors.filterClear)
            .addClass(CssClasses.filterClearHide);


        app.vent.trigger(Events.eventPrefixes.products + Events.filter.onFilter, null, '');
    },

    openAllFilters: function () {
        this.isOpenFilters = !this.isOpenFilters;

        this.$hideFilters.toggleClass(CssClasses.isHidden);
        this.$btnOpenFilters.toggleClass(CssClasses.isOpenFilters);
        const $filterMoreButtonText = this.$btnOpenFilters.find('span');
        const moreText = $filterMoreButtonText.data('more');
        const lessText = $filterMoreButtonText.data('less');

        if (this.isOpenFilters) {
            $filterMoreButtonText
                .text(lessText);

            return;
        }

        $filterMoreButtonText
            .text(moreText);
    }

});
