'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');

const CommonText = require('components/Common/CommonText/CommonText');
const Link = require('components/Common/Link/Link');

require('./Accordion.less');

const Selectors = {
    el: '.Accordion',
    wrapper: '.Accordion-wrapper',
    content: '.Accordion-content'
};

const BindFunctions = [
    '_attachEvents'
];

const AccordionSlideSpeed = 300;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.commonText = new CommonText();
        this.link = new Link();
        this.modifier = options.modifier;
        this.setElement(`${Selectors.el}--${this.modifier}`);
        this._attachEvents();
    },

    _attachEvents: function () {
        const self = this;
        $(document)
            .ready(function () {
                self.$el.find(Selectors.wrapper)
                    .click(function (event) {
                        if ($(this)
                            .is(`.${Constants.cssClasses.active}`)) {
                            $(this)
                                .removeClass(Constants.cssClasses.active)
                                .next(Selectors.content)
                                .slideUp(AccordionSlideSpeed);
                        } else {
                            self.$el
                                .removeClass(Constants.cssClasses.active)
                                .next(Selectors.content)
                                .slideUp(AccordionSlideSpeed);

                            $(this)
                                .addClass(Constants.cssClasses.active)
                                .next(Selectors.content)
                                .slideDown(AccordionSlideSpeed);
                        }
                        event.preventDefault();
                    });
            });
    }
});
