'use strict';

const Backbone = require('backbone/backbone');

require('./DownloadCard.less');

const Selectors = {
    el: '.DownloadCard'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;
    }
});
