'use strict';

const Backbone = require('backbone');

const PageIndex = require('pages/PageIndex/PageIndex');
const PageInspiration = require('pages/PageInspiration/PageInspiration');
const PageProducts = require('pages/PageProducts/PageProducts');
const PageProductFamily = require('pages/PageProductFamily/PageProductFamily');
const PageNotFound = require('pages/PageNotFound/PageNotFound');
const SubscribePreferences = require('pages/SubscribePreferences/SubscribePreferences');
const PageAbout = require('pages/PageAbout/PageAbout');
const PageGlobalIndex = require('pages/PageGlobalIndex/PageGlobalIndex');
const PageCompanyLanding = require('pages/PageCompanyLanding/PageCompanyLanding');
const PageCompanyStrategy = require('pages/PageCompanyStrategy/PageCompanyStrategy');
const PageCompanyInnovation = require('pages/PageCompanyInnovation/PageCompanyInnovation');
const PageCompanyBoard = require('pages/PageCompanyBoard/PageCompanyBoard');
const PageCompanyHistory = require('pages/PageCompanyHistory/PageCompanyHistory');
const PageCompanyCompliance = require('pages/PageCompanyCompliance/PageCompanyCompliance');
const PageCompanyAnnual = require('pages/PageCompanyAnnual/PageCompanyAnnual');
const CompanyGroupAwards = require('pages/PageGroupAwards/PageGroupAwards');
const CareersApprenticeship = require('pages/PageCareersApprenticeship/PageCareersApprenticeship');
const PageNewsLanding = require('pages/PageNewsLanding/PageNewsLanding');
const PageSitemap = require('pages/PageSitemap/PageSitemap');

const PageInvestorsLanding = require('pages/PageInvestorsLanding/PageInvestorsLanding');
const PageInvestorsFinancial = require('pages/PageInvestorsFinancial/PageInvestorsFinancial');
const PageInvestorsShares = require('pages/PageInvestorsShares/PageInvestorsShares');
const PageInvestorsCalendar = require('pages/PageInvestorsCalendar/PageInvestorsCalendar');
const PageInvestorsMeetings = require('pages/PageInvestorsMeetings/PageInvestorsMeetings');
const PageInvestorsGovernance = require('pages/PageInvestorsGovernance/PageInvestorsGovernance');
const PageFaq = require('pages/PageFaq/PageFaq');

const PagePressLanding = require('pages/PagePressLanding/PagePressLanding');
const PageCareersLanding = require('pages/PageCareersLanding/PageCareersLanding');
const PageSustainabilityLanding = require('pages/PageSustainabilityLanding/PageSustainabilityLanding');
const PageFlexibleLanding = require('pages/PageFlexibleLanding/PageFlexibleLanding');
const PagePodcast = require('pages/PagePodcast/PagePodcast');
const PageCampusLanding = require('pages/PageCampusLanding/PageCampusLanding');

const ProductDetailsPopup = require('popups/ProductDetailsPopup/ProductDetailsPopup');
const ProductMasterpiecePopup = require('popups/ProductMasterpiecePopup/ProductMasterpiecePopup');
const PageLayoutsPopup = require('popups/PageLayoutsPopup/PageLayoutsPopup');
const PageServicesPopup = require('popups/PageServicesPopup/PageServicesPopup');
const NewsStoryPopup = require('popups/NewsStoryPopup/NewsStoryPopup');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');
const ProductFamilyPopup = require('popups/ProductFamilyPopup/ProductFamilyPopup');
const FaqPopup = require('popups/FaqPopup/FaqPopup');
const CareersFactoryPopup = require('popups/CareersFactoryPopup/CareersFactoryPopup');
const CareersJobPopup = require('popups/CareersJobPopup/CareersJobPopup');
const CampusCenterPopup = require('popups/CampusCenterPopup/CampusCenterPopup');
const CampusEventPopup = require('popups/CampusEventPopup/CampusEventPopup');
const AwardsPopup = require('popups/AwardsPopup/AwardsPopup');
const HistoryPopup = require('popups/HistoryPopup/HistoryPopup');

const BrandGlobal = 'global';

const PageNames = {
    index: 'index',
    inspiration: 'inspiration',
    globalIndex: 'globalIndex',
    products: 'products',
    productFamily: 'productFamily',
    about: 'about',
    notFound: 'notFound',
    subscribePreferences: 'subscribePreferences',
    company: 'company',
    companyBoard: 'board',
    companyStrategy: 'strategy',
    companyInnovation: 'innovation',
    companyHistory: 'history',
    companyCompliance: 'compliance',
    companyAnnualReports: 'annual',
    sitemap: 'sitemap',
    news: 'news',
    investorRelations: 'investor',
    investorRelationsFinancialData: 'financial',
    investorRelationsGroupShares: 'shares',
    investorRelationsFinancialCalendar: 'calendar',
    investorRelationsShareholderMeetings: 'meetings',
    investorRelationsCorporateGovernance: 'governance',
    press: 'press',
    careers: 'careers',
    sustainability: 'sustainability',
    flexibleLanding: 'flexibleLanding',
    podcast: 'podcast',
    faq: 'faq',
    campus: 'campus',
    companyGroupAwards: 'groupAwards',
    careersApprenticeship: 'careersApprenticeship'
};

module.exports = Backbone.Router.extend({
    routes: function () {
        if (window.app.settings.brand === BrandGlobal) {
            return this._getGlobalRoutes();
        }

        return this._getBrandRoutes();
    },

    _getBrandRoutes: function () {
        return {
            '': 'index',
            'index.html': 'index',
            'inspiration.html': 'inspiration',
            'products.html': 'products',
            'product-family.html': 'productFamily',
            'about.html': 'about',
            'not-found.html': 'pageNotFound',
            'inspiration/case.html': 'inspirationCasePopup',
            'product-family/product.html': 'productFamilyPopup',
            'products/layouts.html': 'layoutsPopup',
            'products/services.html': 'servicesPopup',
            'product-family/product-details.html': 'productsPopup',
            'products/product-masterpiece.html': 'productsMasterpiecePopup',
            // demo overlay pages
            'demo/layouts.html': 'demoLayouts',
            'demo/services.html': 'demoServices'
        };
    },

    _getGlobalRoutes: function () {
        return {
            '': 'indexGlobal',
            'index.html': 'indexGlobal',
            'not-found.html': 'pageNotFound',
            'subscribe-preferences.html': 'subscribePreferences',
            'company.html': 'companyLanding',
            'company/board.html': 'companyBoard',
            'company/strategy.html': 'companyStrategy',
            'company/innovation.html': 'companyInnovation',
            'company/history.html': 'companyHistory',
            'company/compliance.html': 'companyCompliance',
            'company/annual-reports.html': 'companyAnnual',
            'company/zumtobel-group-award.html': 'companyGroupAward',
            'sitemap.html': 'siteMap',
            'news.html': 'newsLanding',
            'investor-relations.html': 'investorsLanding',
            'investor-relations/financial-data.html': 'financialData',
            'investor-relations/zumtobel-group-shares.html': 'groupShares',
            'investor-relations/financial-calendar.html': 'financialCalendar',
            'investor-relations/shareholder-meetings.html': 'investorsMeetings',
            'investor-relations/corporate-governance.html': 'investorsGovernance',
            'press.html': 'pressLanding',
            'careers.html': 'careersLanding',
            'sustainability.html': 'sustainabilityLanding',
            'flexible-landingpage.html': 'flexibleLanding',
            'podcast.html': 'podcast',
            'faq.html': 'faq',
            'news/story.html': 'newsStory',
            'faq/item.html': 'faqPopup',
            'careers/factory.html': 'careersFactoryPopup',
            'careers/job.html': 'careersJobPopup',
            'careers/apprenticeship.html': 'careersApprenticeship',
            'campus.html': 'campusLandingPage',
            'campus/center.html': 'campusCenterPopup',
            'campus/event.html': 'campusEventPopup',
            'company/zumtobel-group-award/nominee.html': 'awardsPopup',
            'company/history/item.html': 'historyPopup',
            // demo overlays pages
            'demo/faq.html': 'demoFaq',
            'demo/news.html': 'demoNews'
        };
    },

    initialize: function () {

    },

    index: function () {
        this.initPage(PageIndex, PageNames.index);
    },

    indexGlobal: function () {
        this.initPage(PageGlobalIndex, PageNames.globalIndex);
    },

    inspiration: function () {
        this.initPage(PageInspiration, PageNames.inspiration);
    },

    products: function () {
        this.initPage(PageProducts, PageNames.products);
    },

    productFamily: function () {
        this.initPage(PageProductFamily, PageNames.productFamily);
    },

    pageNotFound: function () {
        this.initPage(PageNotFound, PageNames.notFound);
    },

    subscribePreferences: function () {
        this.initPage(SubscribePreferences, PageNames.subscribePreferences);
    },

    about: function () {
        this.initPage(PageAbout, PageNames.about);
    },

    companyLanding: function () {
        this.initPage(PageCompanyLanding, PageNames.company);
    },

    companyStrategy: function () {
        this.initPage(PageCompanyStrategy, PageNames.companyStrategy);
    },

    companyInnovation: function () {
        this.initPage(PageCompanyInnovation, PageNames.companyInnovation);
    },

    companyBoard: function () {
        this.initPage(PageCompanyBoard, PageNames.companyBoard);
    },

    companyHistory: function () {
        this.initPage(PageCompanyHistory, PageNames.companyHistory);
    },

    companyCompliance: function () {
        this.initPage(PageCompanyCompliance, PageNames.companyCompliance);
    },

    companyAnnual: function () {
        this.initPage(PageCompanyAnnual, PageNames.companyAnnualReports);
    },

    companyGroupAward: function () {
        this.initPage(CompanyGroupAwards, PageNames.companyGroupAwards);
    },

    newsLanding: function () {
        this.initPage(PageNewsLanding, PageNames.news);
    },

    siteMap: function () {
        this.initPage(PageSitemap, PageNames.sitemap);
    },

    investorsLanding: function () {
        this.initPage(PageInvestorsLanding, PageNames.investorRelations);
    },

    financialData: function () {
        this.initPage(PageInvestorsFinancial, PageNames.investorRelationsFinancialData);
    },

    groupShares: function () {
        this.initPage(PageInvestorsShares, PageNames.investorRelationsGroupShares);
    },

    financialCalendar: function () {
        this.initPage(PageInvestorsCalendar, PageNames.investorRelationsFinancialCalendar);
    },

    investorsMeetings: function () {
        this.initPage(PageInvestorsMeetings, PageNames.investorRelationsShareholderMeetings);
    },

    investorsGovernance: function () {
        this.initPage(PageInvestorsGovernance, PageNames.investorRelationsCorporateGovernance);
    },

    pressLanding: function () {
        this.initPage(PagePressLanding, PageNames.press);
    },

    careersLanding: function () {
        this.initPage(PageCareersLanding, PageNames.careers);
    },

    careersApprenticeship: function () {
        this.initPage(CareersApprenticeship, PageNames.careersApprenticeship);
    },

    sustainabilityLanding: function () {
        this.initPage(PageSustainabilityLanding, PageNames.sustainability);
    },
    flexibleLanding: function () {
        this.initPage(PageFlexibleLanding, PageNames.flexibleLanding);
    },
    podcast: function () {
        this.initPage(PagePodcast, PageNames.podcast);
    },

    faq: function () {
        this.initPage(PageFaq, PageNames.faq);
    },

    campusLandingPage: function () {
        this.initPage(PageCampusLanding, PageNames.campus);
    },

    inspirationCasePopup: function () {
        this.initPage(PageInspiration, PageNames.inspiration, InspirationCasePopup);
    },

    productFamilyPopup: function () {
        this.initPage(PageProductFamily, PageNames.productFamily, ProductFamilyPopup);
    },

    productsPopup: function () {
        this.initPage(PageProductFamily, PageNames.productFamily, ProductDetailsPopup);
    },

    layoutsPopup: function () {
        this.initPage(PageProducts, PageNames.products, PageLayoutsPopup);
    },

    servicesPopup: function () {
        this.initPage(PageProducts, PageNames.products, PageServicesPopup);
    },

    productsMasterpiecePopup: function () {
        this.initPage(PageProducts, PageNames.products, ProductMasterpiecePopup);
    },

    newsStory: function () {
        this.initPage(PageNewsLanding, PageNames.news, NewsStoryPopup);
    },

    faqPopup: function () {
        this.initPage(PageFaq, PageNames.faq, FaqPopup);
    },

    careersFactoryPopup: function () {
        this.initPage(PageCareersLanding, PageNames.careers, CareersFactoryPopup);
    },

    careersJobPopup: function () {
        this.initPage(PageCareersLanding, PageNames.careers, CareersJobPopup);
    },

    campusCenterPopup: function () {
        this.initPage(PageCampusLanding, PageNames.campus, CampusCenterPopup);
    },

    campusEventPopup: function () {
        this.initPage(PageCampusLanding, PageNames.campus, CampusEventPopup);
    },

    awardsPopup: function () {
        this.initPage(CompanyGroupAwards, PageNames.companyGroupAwards, AwardsPopup);
    },

    historyPopup: function () {
        this.initPage(PageCompanyHistory, PageNames.companyHistory, HistoryPopup);
    },

    demoFaq: function () {
        this.initPage(PageFaq, PageNames.faq, FaqPopup);
    },

    demoLayouts: function () {
        this.initPage(PageProducts, PageNames.products, PageLayoutsPopup);
    },

    demoNews: function () {
        this.initPage(PageNewsLanding, PageNames.news, NewsStoryPopup);
    },

    demoServices: function () {
        this.initPage(PageProducts, PageNames.products, PageServicesPopup);
    },

    initPage: function (Page, name, popup) {
        if (!this.pages) {
            this.pages = {};
        }

        if (this.pages[name]) {
            return;
        }

        this.pages[name] = new Page({
            router: this,
            popup: popup
        });
    },

    start: function () {
        const brand = window.app.settings.brand;
        const root = (!brand || brand === 'global') ? '' : '/' + window.app.settings.brand;
        Backbone.history.start({
            pushState: true,
            root: (window.app.settings.lang ? '/' + window.app.settings.lang : '') +
                root + '/',
            silent: false
        });
    }
});
