'use strict';

const _ = require('underscore');
const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');
const AwardsNominees = require('components/AwardsNominees/AwardsNominees');

const AwardsPopup = require('popups/AwardsPopup/AwardsPopup');

require('./PageGroupAwards.less');

const Selectors = {
    el: '.PageGroupAwards'
};

const BindFunctions = [
    '_initChildComponents',
    'openPopup'
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildComponents();

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initChildComponents: function () {
        this.awards = new AwardsNominees();
    },

    openPopup: function (data) {
        const popup = new AwardsPopup({
            page: this
        });

        popup.openPopup(data.apiUrl, data.url);
    }
});
