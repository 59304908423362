'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

//a 3D rendering library
const THREE = require('three');

//a library to load 3ds models into three js
const TDSLoader = require('three-3dsloader');

require('./Vase3D.less');

const Selectors = {
    el: '.Vase3D',
    background: '.PageGlobalIndex-background'
};

const Assets = {
    folderAttr: 'data-assets-folder',
    vase3d: 'vase3d.3ds'
};

const TexturesSrc = [
    'normal.jpg',
    'normal_plane.jpg'
];

const BindFunctions = [
    'attachEvents',
    'init3DScene',
    'initTextureLoader',
    'getTextures',
    'onWindowResize',
    'onPointerMove',
    'checkIfBackgroundActive',
    'initialLightMagnetToCursor',
    'updatePointLightPos',
    'setBackgroundActive'
];

const AmbientColor = 0x3c3c3c;
const AmbientIntensity = 0.3;
const CameraDistance = 50;
const CameraFarPlane = 1000;
const CameraFOV = 45;
const CameraNearPlane = 0.1;
const DetailsStrength = 1.2;
const DiffuseColor = 0xbdbdbd;
const FadeDuration = 500;
const Light1Color = 0xffffff;
const Light1Intensity = 0.4;
const Light1Pos = [10, -100, 50];
const Light2Color = 0xffffff;
const Light2Intensity = 0.3;
const Light2Pos = [-10, 100, 50];
const PlanePos = [0, 0, 5.04];
const PlaneSize = [234, 54];
const PointLightColor = 0xffffff;
const PointLightDecay = 2;
const PointLightIntensity = 0.6;
const PointLightPos = [0, 0, 40];
const PointLightPosMultiplier = 1.2;
const PointLightSpread = 140;
const ShadowBlur = 4;
const ShadowMapSize = 2048;
const ShininessColor = 0x141414;
const ShininessLevel = 100;
const SpotLight1Angle = Math.PI / 2;
const SpotLight2Angle = Math.PI / 2;
const Vase3dAngle = [-Math.PI / 2, 0, 0];
const Vase3dPos = [0, -7, 5];
const MouseFollowSpeedAcceleration = 0.003;
const LightMaxSpeedWithoutMovement = 0.1;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.isSceneReady = false;
        this.initTextureLoader();
        this.attachEvents();
    },


    initTextureLoader: function () {
        this.folder = this.$el.attr(Assets.folderAttr);


        const texturesSRC = TexturesSrc.map(texture => this.folder + texture);

        Promise.all(this.getTextures(texturesSRC))
            .then(this.init3DScene)
            .catch(err => console.error(err));
    },


    getTextures: function (texturesSources) {
        const loader = new THREE.TextureLoader();

        return texturesSources.map(textureSource => {
            return new Promise((resolve, reject) => {
                loader.load(
                    textureSource,
                    texture => resolve(texture),
                    undefined,
                    err => reject(err)
                );
            });
        });
    },

    init3DScene: function (textures) {
        this.mousePos = [0, 0];
        this.mouseTarget = [0, window.innerHeight];
        this.mouseFollowSpeed = 0;

        //selecting a renderer to use
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        this.renderer.setPixelRatio(window.devicePixelRatio && app.settings.isMobile ? window.devicePixelRatio : 1);
        this.renderer.setSize(this.$el.width(), this.$el.height());
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.physicallyBasedShading = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFShadowMap;
        this.$el.append(this.renderer.domElement);

        //initialize scene and camera
        const cameraAspect = this.$el.width() / this.$el.height();
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(CameraFOV, cameraAspect, CameraNearPlane, CameraFarPlane);
        this.camera.position.z = CameraDistance;
        this.scene.add(this.camera);

        const materialFlowers = new THREE.MeshPhongMaterial({
            color: DiffuseColor,
            specular: ShininessColor,
            shininess: ShininessLevel,
            normalMap: textures[0],
            normalScale: new THREE.Vector2(DetailsStrength, DetailsStrength)
        });

        const materialBg = new THREE.MeshPhongMaterial({
            color: DiffuseColor,
            specular: ShininessColor,
            shininess: ShininessLevel,
            normalMap: textures[1],
            normalScale: new THREE.Vector2(DetailsStrength, DetailsStrength)
        });

        (new TDSLoader()).load(this.folder + 'vase3d.3ds', function (object) {
            object.children[0].material = materialFlowers;

            object.children[0].position.x = Vase3dPos[0];
            object.children[0].position.y = Vase3dPos[1];
            object.children[0].position.z = Vase3dPos[2];

            object.children[0].rotation.x = Vase3dAngle[0];
            object.children[0].rotation.y = Vase3dAngle[1];
            object.children[0].rotation.z = Vase3dAngle[2];

            object.children[0].castShadow = true;
            object.children[0].receiveShadow = true;

            this.scene.add(object);

            this.renderer.render(this.scene, this.camera);
        }.bind(this));

        // make a bg
        const geometryBg = new THREE.PlaneGeometry(PlaneSize[0], PlaneSize[1], 1, 1);
        const planeBg = new THREE.Mesh(geometryBg, materialBg);
        planeBg.position.set(PlanePos[0], PlanePos[1], PlanePos[2]);
        planeBg.receiveShadow = true;
        this.scene.add(planeBg);

        const ambientLight = new THREE.AmbientLight(AmbientColor, AmbientIntensity);
        this.scene.add(ambientLight);

        const spotLight1 = new THREE.SpotLight(Light1Color, Light1Intensity);
        spotLight1.angle = SpotLight1Angle;
        spotLight1.position.set(Light1Pos[0], Light1Pos[1], Light1Pos[2]);
        this.scene.add(spotLight1);

        const spotLight2 = new THREE.SpotLight(Light2Color, Light2Intensity);
        spotLight2.angle = SpotLight2Angle;
        spotLight2.position.set(Light2Pos[0], Light2Pos[1], Light2Pos[2]);
        this.scene.add(spotLight2);

        this.pointLight = new THREE.PointLight(PointLightColor, PointLightIntensity, PointLightSpread, PointLightDecay);
        this.pointLight.position.set(PointLightPos[0], PointLightPos[1], PointLightPos[2]);
        this.pointLight.castShadow = true;
        this.pointLight.shadow.mapSize.width = ShadowMapSize;
        this.pointLight.shadow.mapSize.height = ShadowMapSize;
        this.pointLight.shadow.radius = ShadowBlur;
        this.scene.add(this.pointLight);

        this.renderer.render(this.scene, this.camera);

        this.isSceneReady = true;
        app.vent.trigger(Events.vase3d.sceneReady);
    },


    attachEvents: function () {
        window.app.vent.on(Events.splashBackgroundChange, this.checkIfBackgroundActive);
    },


    onWindowResize: function () {
        this.width = this.$el.width();
        this.height = this.$el.height();
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
        this.renderer.render(this.scene, this.camera);
    },


    updatePointLightPos: function () {
        const easedSpeed = -0.5 * (Math.cos(Math.PI * this.mouseFollowSpeed) - 1); //ease inoutsin

        this.mousePos[0] += (this.mouseTarget[0] - this.mousePos[0]) * easedSpeed;
        this.mousePos[1] += (this.mouseTarget[1] - this.mousePos[1]) * easedSpeed;

        const x = (this.mousePos[0] / this.width) * 2 - 1;
        const y = (this.mousePos[1] / this.height) * 2 - 1;

        this.pointLight.position.x = (x * PlaneSize[1] * this.width / this.height / 2) * PointLightPosMultiplier;
        this.pointLight.position.y = (-y * PlaneSize[1] / 2) * PointLightPosMultiplier;

        this.renderer.render(this.scene, this.camera);
    },


    onPointerMove: function (e) {
        const touches = e.changedTouches;
        let pointerX;
        let pointerY;

        if (touches) {
            pointerX = touches[0].clientX;
            pointerY = touches[0].clientY;
        } else {
            pointerX = e.clientX;
            pointerY = e.clientY;
        }

        this.wasMove = true;
        this.mouseTarget = [pointerX, pointerY];

        this.updatePointLightPos();
    },


    initialLightMagnetToCursor: function () {
        //if firmly stick light to mouse
        if (this.mouseFollowSpeed === 1) {
            return;
        }

        this.mouseFollowSpeed = Math.min(this.mouseFollowSpeed + MouseFollowSpeedAcceleration,
            (this.wasMove ? 1 : LightMaxSpeedWithoutMovement));
        this.updatePointLightPos();
        requestAnimationFrame(this.initialLightMagnetToCursor);
    },


    checkIfBackgroundActive: function () {
        const isBgActive = this.$el.closest(Selectors.background)
            .hasClass(Constants.cssClasses.active);
        if (!isBgActive) {
            clearTimeout(this.stopTimeout);
            if (this.running) {
                this.stopTimeout = setTimeout(function () {
                    $(window)
                        .off('resize', this.onWindowResize);
                    app.settings.isDesktop && $(window)
                        .off('mousemove', this.onPointerMove);
                    !app.settings.isDesktop && $(window)
                        .off('touchstart', this.onPointerMove);
                    this.running = false;
                }.bind(this), FadeDuration);
            }

            return;
        }

        app.vent.trigger(Events.common.splashColorChange, 'dark', '#bdbdbd');
        $(window)
            .on('resize', this.onWindowResize);
        app.settings.isDesktop && $(window)
            .on('mousemove', this.onPointerMove);
        !app.settings.isDesktop && $(window)
            .on('touchstart', this.onPointerMove);

        if (!this.isSceneReady) {
            app.vent.on(Events.vase3d.sceneReady, this.setBackgroundActive);

            return;
        }

        this.setBackgroundActive();
    },

    setBackgroundActive: function () {
        clearTimeout(this.stopTimeout);
        if (!this.running) {
            this.running = true;
            if (app.settings.isDesktop) {
                this.initialLightMagnetToCursor();
            } else {
                this.mouseFollowSpeed = 1;
            }
        }
        this.onWindowResize();
    }
});
