var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/SocialNetworkLine/SocialNetworkLine.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/SocialNetworkLine/SocialNetworkLine.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n\n";
var macro_t_4 = runtime.makeMacro(
[], 
["items"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("items", Object.prototype.hasOwnProperty.call(kwargs, "items") ? kwargs["items"] : []);var t_5 = "";t_5 += "\n    <div class=\"SocialNetworkLine\">\n        <div class=\"SocialNetworkLine-inner\">\n            ";
frame = frame.push();
var t_8 = runtime.contextOrFrameLookup(context, frame, "items");
if(t_8) {t_8 = runtime.fromIterator(t_8);
var t_7 = t_8.length;
for(var t_6=0; t_6 < t_8.length; t_6++) {
var t_9 = t_8[t_6];
frame.set("item", t_9);
frame.set("loop.index", t_6 + 1);
frame.set("loop.index0", t_6);
frame.set("loop.revindex", t_7 - t_6);
frame.set("loop.revindex0", t_7 - t_6 - 1);
frame.set("loop.first", t_6 === 0);
frame.set("loop.last", t_6 === t_7 - 1);
frame.set("loop.length", t_7);
t_5 += "\n                <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"link")),"url"), env.opts.autoescape);
t_5 += "\" target=\"_blank\" class=\"SocialNetworkLine-item\">\n                    <span class=\"SocialNetworkLine-itemCard\">\n                        ";
if(runtime.memberLookup((t_9),"image")) {
t_5 += "\n                            ";
t_5 += runtime.suppressValue((lineno = 23, colno = 50, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"picture"), "macros[\"picture\"]", context, [runtime.memberLookup((t_9),"image"),"jpg",runtime.makeKeywordArgs({"caller": (function (){var macro_t_10 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_11 = "";;
frame = frame.pop();
return new runtime.SafeString(t_11);
});
return macro_t_10;})()})])), env.opts.autoescape);
t_5 += "\n                        ";
;
}
else {
t_5 += "\n                            <span class=\"SocialNetworkLine-itemText s-text-small\">\n                                ";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"text"), env.opts.autoescape);
t_5 += "\n                            </span>\n                        ";
;
}
t_5 += "\n                    </span>\n                    <span class=\"SocialNetworkLine-link s-text-tiny ";
t_5 += runtime.suppressValue(runtime.memberLookup((t_9),"type"), env.opts.autoescape);
t_5 += "\">\n                        ";
if(runtime.memberLookup((t_9),"type") == "instagram") {
t_5 += "\n                            ";
t_5 += runtime.suppressValue((lineno = 32, colno = 53, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["instagram",runtime.makeKeywordArgs({"caller": (function (){var macro_t_12 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_13 = "";;
frame = frame.pop();
return new runtime.SafeString(t_13);
});
return macro_t_12;})()})])), env.opts.autoescape);
t_5 += "\n                        ";
;
}
else {
if(runtime.memberLookup((t_9),"type") == "twitter") {
t_5 += "\n                            ";
t_5 += runtime.suppressValue((lineno = 34, colno = 53, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["twitter",runtime.makeKeywordArgs({"caller": (function (){var macro_t_14 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_15 = "";;
frame = frame.pop();
return new runtime.SafeString(t_15);
});
return macro_t_14;})()})])), env.opts.autoescape);
t_5 += "\n                        ";
;
}
;
}
t_5 += "\n                        <span>";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((t_9),"link")),"title"), env.opts.autoescape);
t_5 += "</span>\n                    </span>\n                </a>\n            ";
;
}
}
frame = frame.pop();
t_5 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("SocialNetworkLine");
context.setVariable("SocialNetworkLine", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/SocialNetworkLine/SocialNetworkLine.html"] , dependencies)