'use strict';

const Backbone = require('backbone/backbone');

require('./AboutQualityManagement.less');

const Selectors = {
    aboutQualityManagement: '.AboutQualityManagement'
};

module.exports = Backbone.View.extend({

    el: Selectors.aboutQualityManagement,

    events: {},

    initialize: function (options = {}) {
        this.options = options;
    }
});
