'use strict';

const ViewportEvents = require('constants/ViewportEvents');

const CardsSlider = require('components/Common/CardsSlider/CardsSlider');

require('./ProductFinder.less');

const Selectors = {
    finder: '.ProductFinder',
    slider: '.CardsSlider',
    objects: '.CardsSlider-objects'
};

module.exports = CardsSlider.extend({
    initialize: function (options = {}) {
        this.sliderSelector = `${Selectors.finder}--${options.index} .productFinder--${ options.index }`;
        this.objectsSelector = `${this.sliderSelector} ${Selectors.objects}`;

        CardsSlider.prototype.initialize.call(this, options);
    },

    _initVariables: function (options = {}) {
        CardsSlider.prototype._initVariables.call(this, options);
        this.maxTransitions = this.$item.length - 3;
        this.$rightArrow = options.$rightArrow;
        this.$leftArrow = options.$leftArrow;

        this.$leftArrow
            .on(ViewportEvents.click, this.moveLeft);
        this.$rightArrow
            .on(ViewportEvents.click, this.moveRight);
    },

    _setSelectors: function () {
        CardsSlider.prototype._setSelectors.call(this);

        this.selectors.el = this.sliderSelector;
        this.selectors.objects = this.objectsSelector;
    }
});
