'use strict';

const _ = require('underscore');
const BasePopup = require('popups/BasePopup/BasePopup');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const stickybits = require('stickybits/dist/stickybits.es').default;

const ProductDetailsCard = require('components/ProductDetailsCard/ProductDetailsCard');
const ProductDetailsDescription = require('components/ProductDetailsDescription/ProductDetailsDescription');
const ProductDetailsFooter = require('components/ProductDetailsFooter/ProductDetailsFooter');
const ProductRapidlux = require('components/RapidluxCalculator/RapidluxCalculator');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./ProductDetailsPopup.less');

const Selectors = {
    el: '.ProductDetailsPopup',
    topNavWrapper: '.TopNav-wrapper',
    popupWrapper: '.Popup-wrapper',
    card: '.ProductDetailsCard'
};

const BindFunctions = [
    '_attachEvents',
    'openInspirationCasePopup',
    'onResize'
];

module.exports = BasePopup.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this.popupClass = Selectors.el;
        this._attachEvents();


        BasePopup.prototype.initialize.call(this, options);
    },

    initRenderedPopup: function (data) {
        BasePopup.prototype.initRenderedPopup.call(this, data);

        if (!window.app.els.$html.hasClass('isIE')) {
            return;
        }
        this.stickyBits = stickybits(this.$el.find(Selectors.card)[0], {
            scrollEl: this.$el.find(Selectors.popupWrapper)[0],
            stickyBitStickyOffset: $(Selectors.topNavWrapper)
                .position().top
        });

        $(document)
            .on(ViewportEvents.domContentLoaded, () => {
                this.stickyBits.update();
            });

        $(window)
            .on(ViewportEvents.resize, this.onResize);
    },

    _attachEvents: function () {
        window.app.vent.on(Events.eventPrefixes.inspiration + Events.popup.popupOpen, this.openInspirationCasePopup);
    },

    initChildComponents: function () {
        this.registerChildView(ProductDetailsCard, {page: this});
        this.registerChildView(ProductDetailsDescription, {page: this});
        this.registerChildView(ProductRapidlux, {page: this});
        this.registerChildView(ProductDetailsFooter, {page: this});
    },

    openInspirationCasePopup: function (data) {
        const popup = new InspirationCasePopup({
            page: this
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    onResize: function () {
        this.stickyBits.update({
            stickyBitStickyOffset: $(Selectors.topNavWrapper)
                .position().top
        });
    }
});
