'use strict';

const _ = require('underscore');
const Backbone = require('backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

const DownloadLinkTemplate = require('./ProductFamilyItemDownloadLink.html');

require('./ProductFamilyItem.less');

const Selectors = {
    el: '.ProductFamilyItem',
    link: '.ProductFamilyItem-link',
    moreIcon: '.ProductFamilyItem-moreIcon',
    dropdown: '.ProductFamilyItem-dropdown',
    dropdownLink: '.ProductFamilyItem-dropdownLink',
    loadingDownloads: '.ProductFamilyItem-dropdownLink_preparing',
    dropdownLinks: '.ProductFamilyItem-dropdownLinks'
};

const BindFunctions = [
    'triggerSkuOverlay',
    'onDropdownMouseEnter',
    'onDropdownMouseLeave',
    'onMoreIconTouch',
    'prepareDownloads'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.triggerSkuOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.downloads = null;
        this.$(Selectors.dropdown)
            .on('mouseenter', this.onDropdownMouseEnter);
        this.$(Selectors.dropdown)
            .on('mouseleave', this.onDropdownMouseLeave);
        this.$(Selectors.moreIcon)
            .on('mouseenter', this.prepareDownloads);
        document.addEventListener('touchstart', this.onMoreIconTouch);
        this.$dropdownDownloadLinks = this.$(Selectors.dropdownLinks);
        this.$dropdownLoadingDownloads = this.$(Selectors.loadingDownloads);
    },

    prepareDownloads: function () {
        if (this.downloads) {
            return;
        }
        const apiUrl = this.$el.data('downloads-api-url');
        const data = {
            article: this.$el.data('article')
        };
        $.getJSON(apiUrl, data, (res) => {
            if (!res || !res.length) {
                return;
            }

            this.downloads = res;

            res.forEach((downloadLink) => {
                const renderedLink = DownloadLinkTemplate.render({data: downloadLink});

                this.$dropdownDownloadLinks.append(renderedLink);
            });

            if (!this.$dropdownDownloadLinks.find(Selectors.dropdownLink).length) {
                return;
            }

            this.$dropdownLoadingDownloads.hide();
        });
    },

    onMoreIconTouch: function (event) {
        this.prepareDownloads();
        const $target = $(event.target)
            .closest(this.$(Selectors.moreIcon));

        if (!$target.length) {
            this.onDropdownMouseLeave();

            return;
        }

        this.onDropdownMouseEnter();
    },

    onDropdownMouseEnter: function () {
        this.$(Selectors.moreIcon)
            .addClass(Constants.cssClasses.hovered);
    },

    onDropdownMouseLeave: function () {
        this.$(Selectors.moreIcon)
            .removeClass(Constants.cssClasses.hovered);
    },

    triggerSkuOverlay: function (event) {
        event.preventDefault();

        const $target = $(event.target);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        window.app.vent.trigger(Events.eventPrefixes.productFamily
            + Events.popup.productDetailsPopupPrefix + Events.popup.popupOpen, {apiUrl, url});
    }
});
