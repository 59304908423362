'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

require('./InvestorsUpcomingEvent.less');

const Selectors = {
    el: '.InvestorsUpcomingEvent',
    itemTitle: '.InvestorsUpcomingEvent-news-item-title'
};

const BindFunctions = [
    'onResize',
    '_attachEvents',
    '_initVariables'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._attachEvents();

        this.onResize();
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);

        window.app.vent.on(Events.common.fontsLoaded, this.onResize);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.onResize = _.debounce(this.onResize, 400);
    },

    onResize: function () {
    }
});
