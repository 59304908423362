'use strict';

const _ = require('underscore');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

const Page = require('pages/Page/Page.js');

const CurrentSubscriptions =
    require('components/SubscribePreferencesComponents/CurrentSubscriptions/CurrentSubscriptions');
const UpdateProfile = require('components/SubscribePreferencesComponents/UpdateProfile/UpdateProfile');
const UpdateInterests = require('components/SubscribePreferencesComponents/UpdateInterests/UpdateInterests');

require('./SubscribePreferences.less');

const Selectors = {
    el: '.SubscribePreferences',
    main: '.SubscribePreferences-mainWrapper',
    save: '.SubscribePreferences-save',
    successMessageClose: '.SubscribePreferences-messageClose#subscribe-preferences-close',
    successMessageCloseResponse: '.SubscribePreferences-messageClose#subscribe-preferences-close-response',
    successMessage: '.SubscribePreferences-message',
    updateMessage: '.SubscribePreferences-messageOverlayWrapper',
    updateMessageTitle: '.SubscribePreferences-messageOverlayTitle',
    updateMessageDescription: '.SubscribePreferences-messageOverlayDescription',
    updateMessageButton: '.SubscribePreferences-messageOverlayButton'
};

const BindFunctions = [
    'fixHeightOnIos',
    'closeSuccessMessage',
    'showUpdatePreferencesMessage',
    '_attachEvents',
    'saveChanges',
    'closeResponseMessage'
];

const ChildViews = [
    {
        name: 'subscriptionSettings',
        Constructor: CurrentSubscriptions
    },
    {
        name: 'updateProfile',
        Constructor: UpdateProfile
    },
    {
        name: 'updateInterests',
        Constructor: UpdateInterests
    }
];

module.exports = Page.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.successMessageClose}`]: this.closeSuccessMessage,
            [`${ViewportEvents.click} ${Selectors.successMessageCloseResponse}`]: this.closeResponseMessage,
            [`${ViewportEvents.click} ${Selectors.updateMessageButton}`]: this.closeResponseMessage,
            [`${ViewportEvents.click} ${Selectors.save}`]: this.saveChanges
        };
    },

    initialize: function (options = {}) {
        this.options = options;
        _.bindAll(this, BindFunctions);

        this.$(Selectors.bgImgMobile)
            .on('load', this.fixHeightOnIos)
            .trigger('load');

        this.apiUrl = this.$el.find(Selectors.main).data('api-url');
        this._initChildViews(ChildViews);
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.vent.on(Events.searchOverlayDataUpdated, this.showUpdatePreferencesMessage);
    },

    fixHeightOnIos: function () {
        let vh = window.app.els.$window
            .height();
        this.$(Selectors.content)
            .css('height', `${vh}px`);
        setTimeout(function () {
            window.app.els.$window
                .scrollTop();
        });
    },

    closeSuccessMessage: function () {
        this.$(Selectors.successMessage)
            .addClass(Constants.cssClasses.hide);
    },


    showUpdatePreferencesMessage: function (data) {
        this.$(Selectors.updateMessageTitle)
            .text(data.title);
        this.$(Selectors.updateMessageDescription)
            .text(data.description);
        this.$(Selectors.updateMessageButton)
            .text(data.buttonText);

        this.$(Selectors.updateMessage)
            .removeClass(Constants.cssClasses.hide);
    },

    closeResponseMessage: function () {
        window.location.reload();
    },

    onChange: function () {
        this._enableSaveButton();
    },

    saveChanges: function () {
        const data = Object.values(this.childViews)
            .reduce((res, childView) => {
                return Object.assign(res, childView.getValues());
            }, {});

        this._disableSaveButton();
        $.ajax({
            type: 'POST',
            url: this.apiUrl,
            data: JSON.stringify(data),
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            success: (res) => {
                window.app.vent.trigger(Events.searchOverlayDataUpdated, res);
            },
            failure: (errMsg) => {
                console.err(errMsg);
            },
            complete: () => {
                this._enableSaveButton();
            }
        });
    },

    _disableSaveButton: function () {
        this.$(Selectors.save)
            .addClass(Constants.cssClasses.disabled);
    },

    _enableSaveButton: function () {
        this.$(Selectors.save)
            .removeClass(Constants.cssClasses.disabled);
    }
});
