'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

const NewsStoryPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

require('./NewsInsights.less');

const Selectors = {
    el: '.NewsInsights',
    link: '.NewsInsights-itemLink',
    itemTitle: '.NewsInsights-news-item-title'
};

const BindFunctions = [
    'onResize',
    '_attachEvents',
    '_initVariables',
    'openNewsPopup'
];

const DataBackUrlAttribute = 'back-url';
const DataUrlAttribute = 'url';
const DataApiUrlAttribute = 'api-url';
const DataLinesAttribute = 'data-lines';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openNewsPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._attachEvents();

        this.onResize();
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);

        window.app.vent.on(Events.common.fontsLoaded, this.onResize);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.onResize = _.debounce(this.onResize, 400);
        this.backUrl = this.$el.data(DataBackUrlAttribute);
    },

    openNewsPopup: function (event) {
        event.preventDefault();
        event.stopPropagation();

        const $target = $(event.target).closest(Selectors.link);
        const url = $target
            .data(DataUrlAttribute);

        const apiUrl = $target.data(DataApiUrlAttribute);

        const popup = new NewsStoryPopup({
            page: this,
            backUrl: this.backUrl
        });

        popup.openPopup(apiUrl, url);
    },

    onResize: function () {
        this.$(Selectors.itemTitle)
            .each(function () {
                const $item = $(this);
                const lineHeight = parseInt($item.css('line-height'), 10);
                const height = $item.height();
                const linesCount = Math.round(height / lineHeight);
                $item.attr(DataLinesAttribute, linesCount);
            });
    }
});
