'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');

require('./GlobalSlider.less');

const Selectors = {
    el: '.GlobalSlider',
    arrowLeftDesktop: '.GlobalSlider-arrowLeft',
    arrowRightDesktop: '.GlobalSlider-arrowRight',
    arrowLeftMobile: '.GlobalSlider-arrowLeft_mobile',
    arrowRightMobile: '.GlobalSlider-arrowRight_mobile',
    items: '.GlobalSlider-items',
    itemsWrapperInner: '.GlobalSlider-itemsWrapperInner_desktop',
    itemsWrapperInnerMobile: '.GlobalSlider-itemsWrapperInner_mobile'
};

const CssClasses = {
    isSlider: 'isSlider'
};

const BindFunctions = [
    '_initSlick'
];

const TransitionSpeed = 300;
const SlidesToShow = 3;
const SlidesToScroll = 1;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initComponent(options);

        const isSlider = this.$(Selectors.items)
            .hasClass(CssClasses.isSlider) || app.isPhoneLayout();

        if (!isSlider) {
            return;
        }

        this.$itemsWrapperInner = this.$(Selectors.itemsWrapperInner);
        this.$itemsWrapperInnerMobile = this.$(Selectors.itemsWrapperInnerMobile);
        this._initSlick();

        this.options = options;
    },

    _initComponent: function (options) {
        const modifier = options.modifier;
        this.arrows = !options.withoutArrows;
        if (!modifier) {
            return;
        }
        this.selector = `${Selectors.el}--${modifier}`;
        this.setElement(this.selector);
    },

    _initSlick: function () {
        let $wrapper = this.$itemsWrapperInner;
        let arrowLeft = `${this.selector} ${Selectors.arrowLeftDesktop}`;
        let arrowRight = `${this.selector} ${Selectors.arrowRightDesktop}`;
        if (app.isPhoneLayout()) {
            $wrapper = this.$itemsWrapperInnerMobile;
            arrowLeft = `${this.selector} ${Selectors.arrowLeftMobile}`;
            arrowRight = `${this.selector} ${Selectors.arrowRightMobile}`;
        }

        $wrapper
            .slick({
                infinite: false,
                arrows: this.arrows,
                prevArrow: arrowLeft,
                nextArrow: arrowRight,
                fade: false,
                speed: TransitionSpeed,
                draggable: app.isPhoneLayout(),
                slidesToShow: SlidesToShow,
                slidesToScroll: SlidesToScroll,
                variableWidth: true,
                centerMode: app.isPhoneLayout()
            });
    }
});
