var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/UpcomingEvent/UpcomingEvent.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/UpcomingEvent/UpcomingEvent.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n";
output += "\n";
var macro_t_4 = runtime.makeMacro(
[], 
["data", "theme"], 
function (kwargs) {
var callerFrame = frame;
frame = new runtime.Frame();
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
frame.set("data", Object.prototype.hasOwnProperty.call(kwargs, "data") ? kwargs["data"] : {});frame.set("theme", Object.prototype.hasOwnProperty.call(kwargs, "theme") ? kwargs["theme"] : "light");var t_5 = "";t_5 += "\n    <div class=\"UpcomingEvent\n                ";
if(runtime.contextOrFrameLookup(context, frame, "theme") == "dark") {
t_5 += " isUpcomingEventDark ";
;
}
t_5 += "\"\n         data-api-url=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"apiUrl"), env.opts.autoescape);
t_5 += "\"\n         data-url=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"url"), env.opts.autoescape);
t_5 += "\"\n    >\n        <a href=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"url"), env.opts.autoescape);
t_5 += "\" class=\"UpcomingEvent-url\"></a>\n        <div class=\"s-text-tiny UpcomingEvent-description\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"description"), env.opts.autoescape);
t_5 += "</div>\n        <div class=\"s-title-normal UpcomingEvent-title\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
t_5 += "</div>\n        <div class=\"s-text-tiny UpcomingEvent-type\" data-type=\"";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"type")),"id"), env.opts.autoescape);
t_5 += "\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"type")),"title"), env.opts.autoescape);
t_5 += "</div>\n        <div class=\"UpcomingEvent-bottom\">\n            <div class=\"UpcomingEvent-link\">\n                <div class=\"UpcomingEvent-linkArrow\">\n                    ";
t_5 += runtime.suppressValue((lineno = 36, colno = 45, runtime.callWrap(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "macros")),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["arrow-right-small",runtime.makeKeywordArgs({"caller": (function (){var macro_t_6 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_7 = "";;
frame = frame.pop();
return new runtime.SafeString(t_7);
});
return macro_t_6;})()})])), env.opts.autoescape);
t_5 += "\n                </div>\n                <span>";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"link")),"title"), env.opts.autoescape);
t_5 += "</span>\n            </div>\n            ";
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tag")) {
t_5 += "\n                <div class=\"s-text-tiny UpcomingEvent-type UpcomingEvent-type_tag\">";
t_5 += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"tag"), env.opts.autoescape);
t_5 += "</div>\n            ";
;
}
t_5 += "\n        </div>\n    </div>\n";
;
frame = callerFrame;
return new runtime.SafeString(t_5);
});
context.addExport("UpcomingEvent");
context.setVariable("UpcomingEvent", macro_t_4);
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/UpcomingEvent/UpcomingEvent.html"] , dependencies)