'use strict';

const _ = require('underscore');

const Page = require('pages/Page/Page.js');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

require('./PageNotFound.less');

const Selectors = {
    el: '.PageNotFound',
    link: 'a',
    backgroundMobileImage: '.PageNotFound-background_mobile img',
    text: '.PageNotFound-text',
    content: '.PageNotFound-content'
};

const BindFunctions = [
    'openSearchPanel'
];

module.exports = Page.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openSearchPanel
        };
    },


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        Page.prototype.initialize.call(this, options);
    },

    openSearchPanel: function (e) {
        if ($(e.target).closest(Selectors.text).length) {
            app.vent.trigger(Events.search.showSearch);
        }
    }
});
