'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');
const PerfectScrollbar = require('perfect-scrollbar').default;
const scrollLock = require('scroll-lock');

require('./PrivacyPopup.less');

const Selectors = {
    el: '.PrivacyPopup',
    bg: '.PrivacyPopup-bg',
    close: '.PrivacyPopup-close',
    inputWrapper: '.PrivacyPopup-inputWrapper'
};

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    '_initPerfectScroll',
    '_attachEvents',
    'triggerPopupHidden',
    'triggerPopupShown'
];

const CssClasses = {
    popupHide: 'PrivacyPopup--hide'
};

const PrivacyHash = 'privacy';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick
        };
    },

    initialize: function (options = {}) {
        this.options = options;

        _.bindAll(this, BindFunctions);
        const self = this;

        if (window.location.hash === `#${PrivacyHash}`) {
            setTimeout(function () {
                self.show();
            });
        }

        this._attachEvents();

        this.popupWrapper = Selectors.inputWrapper;

        this._initPerfectScroll();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.privacy + Events.popup.popupOpen, this.show);
        app.vent.on(Events.search.hideResults, this.hide);
        app.vent.on(Events.eventPrefixes.contact + Events.overlay.mobileOverlayClose, this.closeOverlay);
    },

    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },

    show: function () {
        this.$el.removeClass(CssClasses.popupHide);
        this.triggerPopupShown();

        scrollLock.addScrollableSelector(Selectors.inputWrapper);
        window.app.els.$window
            .on(ViewportEvents.keyDown, this.onKeydown);
        window.app.utils.changeHash(PrivacyHash);
    },

    hide: function () {
        if (window.app.isPhoneLayout()) {
            this.triggerPopupHidden();
        } else {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', this.triggerPopupHidden, 300);
        }
        scrollLock.removeScrollableSelector(Selectors.inputWrapper);
        this.$el.addClass(CssClasses.popupHide);
        window.app.utils.removeHash();
        window.app.els.$window
            .off(ViewportEvents.keyDown, this.onKeydown);
    },

    triggerPopupShown: function () {
        this.savedScroll = $(window)
            .scrollTop();

        window.app.els.$body
            .addClass(Constants.cssClasses.overlayShow);

        if (window.app.isPhoneLayout()) {
            window.app.utils.waitForTransitionEnd(this.$el, 'visibility', () => {
                app.vent.trigger(Events.popup.popupShown);
            }, 300);

            return;
        }

        app.vent.trigger(Events.popup.popupShown);
    },

    triggerPopupHidden: function () {
        app.vent.trigger(Events.popup.popupHidden);
        window.app.els.$body
            .removeClass(Constants.cssClasses.overlayShow);
        window.requestAnimationFrame(() => {
            window.app.els.$window
                .scrollTop(this.savedScroll);
            window.app.vent.trigger(Events.landings.attachEvents);
        });
    },

    _initPerfectScroll: function () {
        if (!window.app.settings.isDesktop) {
            return;
        }
        this.perfectScrollbar = new PerfectScrollbar(this.popupWrapper);
        $(this.popupWrapper)
            .on(ViewportEvents.scroll, this.onScroll);
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
        this.onResize();
        this.onScroll();
        this.perfectScrollbar.update();
    },

    onCloseClick: function () {
        this.hide();
    },

    onScroll: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    onResize: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    }
});
