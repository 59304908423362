'use strict';

const Backbone = require('backbone/backbone');

require('./InspirationCaseRelated.less');

module.exports = Backbone.View.extend({

    el: '.InspirationCaseRelated',

    initialize: function (options = {}) {
        this.options = options;
    }
});
