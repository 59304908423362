'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');

const Slider = require('components/MobileSlider/MobileSlider');

require('./ProductFamilyInspiration.less');

const Selectors = {
    el: '.ProductFamilyInspiration',
    arrowRight: '.ProductFamilyInspiration-arrowRight',
    arrowLeft: '.ProductFamilyInspiration-arrowLeft',
    navLine: '.ProductFamilyInspiration-navLine',
    navLines: '.ProductFamilyInspiration-navLines',
    item: '.ProductFamilyInspiration-item',
    itemBg: '.ProductFamilyInspiration-itemBg',
    itemImage: '.ProductFamilyInspiration-itemImage',
    itemBgInner: '.ProductFamilyInspiration-itemBg-inner',
    innerWrapper: '.ProductFamilyInspiration-wrapperInner',
    wrapper: '.ProductFamilyInspiration-wrapper'
};

const CssClasses = {
    singleItem: 'singleItem'
};

const BindFunctions = [
    'setWrapperLength',
    'onResize',
    'setActiveBg',
    'changeBlurBackgroundOnSwipe',
    '_initMobileSlider',
    '_setInitialClasses',
    '_attachEvents',
    '_initVariables',
    'openPopup'
];

const TransitionDelay = 0.1;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowRight}`]: this.slideNext,
            [`${ViewportEvents.click} ${Selectors.arrowLeft}`]: this.slidePrev,
            [`${ViewportEvents.click} ${Selectors.navLine}`]: this.setCurSlider,
            [`${ViewportEvents.mouseOver} ${Selectors.item}`]: this.setActiveBg,
            [`${ViewportEvents.click} ${Selectors.item}`]: this.openPopup
        };
    },

    initialize: function () {
        _.bindAll(this, BindFunctions);

        this._initVariables();
        this._attachEvents();
        this._initMobileSlider();

        // check if window load event already fired then set length immediately
        // otherwise wait for load event to fire
        if (window.app.state.isWindowLoaded) {
            this.setWrapperLength();
        } else {
            window.app.els.$window
                .on(ViewportEvents.load, this.setWrapperLength);
        }

        this._setInitialClasses();
    },

    _initVariables: function () {
        this.transform = 0;
        this.countOfPossibleTransitions = this.$(Selectors.item).length <= 4 ?
            0 : (Math.ceil((this.$(Selectors.item).length + 2) / 4) - 1);

        this.currentTransition = 0;
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
    },

    _setInitialClasses: function () {
        this.$(Selectors.arrowLeft)
            .hide();
        if (this.countOfPossibleTransitions === 0) {
            this.$(Selectors.arrowRight)
                .hide();
            this.$(Selectors.navLines)
                .hide();
        }
        this.$(`${Selectors.navLine}[data-id=1]`)
            .addClass(Constants.cssClasses.active);

        this.$(`${Selectors.itemBg}[data-id=1]`)
            .addClass(Constants.cssClasses.active);
    },

    _initMobileSlider: function () {
        const itemsCount = this.$(Selectors.item).length;
        const navLineCount = window.app.isPhoneLayout() ? Math.ceil(itemsCount) / 2 : Math.ceil(itemsCount / 4);

        this.slider = new Slider({
            page: this,
            el: Selectors.el,
            arrowLeft: Selectors.arrowLeft,
            arrowRight: Selectors.arrowRight,
            innerWrapper: Selectors.innerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.item,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            transitionWidth: $(Selectors.item)
                .outerWidth(true),
            imageCount: navLineCount,
            callback: this.changeBlurBackgroundOnSwipe
        });
    },

    onResize: function () {
        if (this.$el.hasClass(Constants.cssClasses.hide)) {
            return;
        }

        this.setWrapperLength();
    },

    setWrapperLength: function () {
        this.$el.removeClass(Constants.cssClasses.hide);
        const countOfCaseStudies = this.$(Selectors.item).length;
        const maxRowLength = Math.ceil(countOfCaseStudies / 2);
        let itemWidth;
        let itemHeight;
        if (countOfCaseStudies === 1) {
            itemWidth = this.$(Selectors.item)
                .outerWidth(true) * 2;
            this.$(Selectors.itemImage)
                .addClass(CssClasses.singleItem);
            this.$(Selectors.innerWrapper)
                .addClass(CssClasses.singleItem);
            this.$(Selectors.wrapper)
                .addClass(CssClasses.singleItem);
        } else {
            itemWidth = this.$(Selectors.item)
                .outerWidth(true) * 1.07;
        }

        if (countOfCaseStudies === 2) {
            this.$(Selectors.innerWrapper)
                .css('flex-wrap', 'unset');
            this.$(Selectors.item)
                .css('margin-right', '30px');
            itemWidth = this.$(Selectors.item)
                .outerWidth(true) * 1.07;
        }

        const maxRowLengthPx = itemWidth * maxRowLength;

        if (countOfCaseStudies === 2) {
            this.$(Selectors.innerWrapper)
                .css('width', 'fit-content');
        } else {
            this.$(Selectors.innerWrapper)
                .css('width', maxRowLengthPx + 'px');
        }

        if (countOfCaseStudies === 1) {
            this.$(Selectors.innerWrapper)
                .css('height', itemHeight + 'px');
        }

        this.$(Selectors.item).each((index, item) => {
            const delay = index % maxRowLength * TransitionDelay;
            $(item).css('transition-delay', `${delay}s`);
        });
    },

    arrowsCheck: function () {
        if (this.currentTransition >= this.countOfPossibleTransitions) {
            this.$(Selectors.arrowRight)
                .hide();
        } else {
            this.$(Selectors.arrowRight)
                .show();
        }
        if (this.currentTransition <= 0) {
            this.$(Selectors.arrowLeft)
                .hide();
        } else {
            this.$(Selectors.arrowLeft)
                .show();
        }
    },

    setCurSlider: function (e) {
        const activeSlider = parseInt($(e.target)
            .attr('data-id'), 10) - 1;
        const diff = Math.abs(this.currentTransition - activeSlider);
        if (this.currentTransition > activeSlider) {
            this.currentTransition = activeSlider;
            if (!window.app.isPhoneLayout()) {
                this.arrowsCheck();
            }
            this.$(Selectors.navLine)
                .removeClass(Constants.cssClasses.active);
            this.$(`${Selectors.navLine}[data-id="${(this.currentTransition + 1)}"]`)
                .addClass(Constants.cssClasses.active);

            this.transform = this.transform + diff * this.$(Selectors.item)
                .innerWidth() * 1.1;
            this.$(Selectors.innerWrapper)
                .css('transform', 'translate3d(' + this.transform + 'px, 0, 0)');
        } else {
            this.currentTransition = activeSlider;
            if (!window.app.isPhoneLayout()) {
                this.arrowsCheck();
            }
            this.$(Selectors.navLine)
                .removeClass(Constants.cssClasses.active);
            this.$(`${Selectors.navLine}[data-id="${(this.currentTransition + 1)}"]`)
                .addClass('active');

            this.transform = this.transform - diff * this.$(Selectors.item)
                .innerWidth() * 1.1;

            this.$(Selectors.innerWrapper)
                .css('transform', 'translate3d(' + this.transform + 'px, 0, 0)');
        }
    },

    slideNext: function () {
        this.currentTransition++;
        if (!window.app.isPhoneLayout()) {
            this.arrowsCheck();
        }
        this.$(Selectors.navLine)
            .removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.navLine}[data-id="${(this.currentTransition + 1)}"]`)
            .addClass(Constants.cssClasses.active);
        this.transform = this.transform - this.$(Selectors.item)
            .innerWidth() * 1.1;
        this.$(Selectors.innerWrapper)
            .css('transform', 'translate3d(' + this.transform + 'px, 0, 0)');
    },

    slidePrev: function () {
        this.currentTransition--;
        if (!window.app.isPhoneLayout()) {
            this.arrowsCheck();
        }
        this.$(Selectors.navLine)
            .removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.navLine}[data-id="${(this.currentTransition + 1)}"]`)
            .addClass(Constants.cssClasses.active);
        this.transform = this.transform + this.$(Selectors.item)
            .innerWidth() * 1.1;
        this.$(Selectors.innerWrapper)
            .css('transform', 'translate3d(' + this.transform + 'px, 0, 0)');
    },

    setActiveBg: function (e) {
        const id = $(e.target)
            .closest(Selectors.item)
            .data('id');
        this.$(Selectors.itemBg)
            .removeClass(Constants.cssClasses.active);


        this.$(`${Selectors.itemBg}[data-id="${id}"]`)
            .addClass(Constants.cssClasses.active);
    },

    changeBlurBackgroundOnSwipe: function (activeSlideNumber) {
        this.$(Selectors.itemBg)
            .removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.itemBg}[data-id="${activeSlideNumber}"]`)
            .addClass(Constants.cssClasses.active);
    },

    openPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.item);

        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.productFamily
            + Events.popup.inspirationPopupPrefix + Events.popup.popupOpen, {apiUrl, url});
    }
});
