'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');
const Constants = require('constants/Constants');

const DownloadCard = require('components/Common/DownloadCard/DownloadCard');
const SingleCardTemplate = require('./InvestorsFinancialItem.html');

require('./InvestorsFinancialItems.less');

const Selectors = {
    el: '.InvestorsFinancialItems',
    items: '.DownloadCard',
    itemsWrapper: '.InvestorsFinancialItems-wrapper'
};

const BindFunctions = [
    'renderNewCards',
    'clearItems'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this.downloadCard = new DownloadCard();
        this.$(Selectors.items).addClass(Constants.cssClasses.show);
    },

    /**
     *  When filtering or showMore button is pressed then we call this function from outside to
     *  render new items to bottom or full rerender
     *
     * @param cards - list of items to render
     * @param isNeedRerender - if true - all previous items clears
     */

    renderNewCards: function (cards = [], isNeedRerender = false) {
        isNeedRerender && this.clearItems();

        cards.forEach((item) => {
            const itemTemplate = SingleCardTemplate.render({item});

            this.$(Selectors.itemsWrapper)
                .append(itemTemplate);
        });
        setTimeout(() => {
            this.$(Selectors.items).addClass(Constants.cssClasses.show);
        }, 100);
    },

    clearItems: function () {
        this.$(Selectors.itemsWrapper)
            .html('');
    }
});
