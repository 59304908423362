'use strict';

const Backbone = require('backbone');
const _ = require('underscore');

const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');

const ProductFamilySection = require('components/ProductFamilySection/ProductFamilySection');

require('./ProductFamilySections.less');

const Selectors = {
    el: '.ProductFamilySections',
    section: '.ProductFamilySection',
    wrapper: '.ProductFamilySections-sections',
    threshold: '.ProductFamilyPopup-loadThreshold',
    popupWrapper: '.Popup-wrapper'
};

const BindFunctions = [
    '_attachEvents',
    '_initVariables',
    'fetchPage',
    'checkFetch',
    'onScroll',
    'onFilter',
    'initSection'
];

const RangeMin = 'min';
const MoreThanStringValue = '>';
const LessThanStringValue = '<';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this._initVariables(options);
        this._attachEvents();
        this.fetchPage();
    },

    _initVariables: function (options) {
        this.options = options;
        this.apiUrl = this.$el.data('api-url');
        this.$threshold = $(Selectors.threshold);
        this.$sectionsWrapper = this.$(Selectors.wrapper);
        this.filterResults = {};
        this.$popupWrapper = this.$el.closest(Selectors.popupWrapper);
        this._resetPagination();
    },

    _resetPagination: function () {
        this.pageNumber = 0;
        this.isLoadingComplete = false;
        this.$popupWrapper.scrollTop(0);
        this.$sectionsWrapper.empty();
        this.requestInProgress = false;
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.products + Events.filter.onFilter, this.onFilter);
        app.vent.on(Events.eventPrefixes.products + Events.filter.clearFilters, this.onFilter);
        this.$popupWrapper.on(ViewportEvents.scroll, this.onScroll);
    },

    onFilter: function (filterValues) {
        this.filterResults = {};
        Object.entries(filterValues)
            .forEach(([filterKey, filterValue]) => {
                let resultValue = [];

                filterValue.forEach((value) => {
                    const processedValue = value.range ? this.processRangeValue(value) : value.value;
                    resultValue.push(processedValue);
                });

                this.filterResults[filterKey] = resultValue;
            });
        this._resetPagination();
        this.fetchPage();
    },

    processRangeValue: function (value) {
        if (value.range === RangeMin) {
            return `${MoreThanStringValue}${value.value}`;
        }

        return `${LessThanStringValue}${value.value}`;
    },

    onScroll: function () {
        if (this.isLoadingComplete) {
            return;
        }
        this.checkFetch() && this.fetchPage();
    },

    fetchPage: function () {
        if (this.requestInProgress) {
            return;
        }
        this.pageNumber++;
        const data = {
            page: this.pageNumber,
            ...this.filterResults
        };
        $.getJSON(this.apiUrl, data, (res) => {
            this.requestInProgress = false;
            this.renderNewPage(res);
        });
        this.requestInProgress = true;
    },

    renderNewPage: function (res) {
        if (!res || !res.template) {
            return;
        }

        const countOfSections = this.$(Selectors.section).length;
        this.$sectionsWrapper.append(res.template);
        if (countOfSections < this.$(Selectors.section).length) {
            this.initSection();
        }

        if (res.complete) {
            this.isLoadingComplete = true;

            return;
        }

        this.checkFetch() && this.fetchPage();
    },

    initSection: function () {
        const $newSection = this.$(Selectors.section)
            .last();
        new ProductFamilySection({
            el: $newSection
        });
    },

    checkFetch: function () {
        const thresholdHeight = this.$threshold.height();
        const sectionsHeight = this.$el.position().top + this.$sectionsWrapper.height()
            - this.$popupWrapper.scrollTop();

        return sectionsHeight <= thresholdHeight;
    }
});
