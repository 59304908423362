'use strict';

const Backbone = require('backbone');
const stickybits = require('stickybits/dist/stickybits.min');

require('./GlobalIndexSidebar.less');

const Selectors = {
    el: '.GlobalIndexSidebar'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;
        if (window.app.isPhoneLayout()) {
            return;
        }
        const containerHeight = window.innerHeight;
        const sidebarHeight = this.$el
            .outerHeight();
        const offsetSidebar = containerHeight - sidebarHeight;

        stickybits(Selectors.el, {
            stickyBitStickyOffset: offsetSidebar
        });

        this.$el.css('top', '')
            .css('bottom', '');
    }
});
