'use strict';

const Backbone = require('backbone/backbone');

require('./LayoutsHowItWorks.less');

module.exports = Backbone.View.extend({

    el: '.LayoutsHowItWorks',

    events: {
    },

    initialize: function (options = {}) {
        this.options = options;
    }
});
