'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');

require('./PageInvestorsShares.less');

const InvestorsStockPrice = require('components/InvestorsStockPrice/InvestorsStockPrice');
const FourColumnFacts = require('components/Common/FourColumnFacts/FourColumnFacts');

const Selectors = {
    el: '.PageInvestorsShares'
};

const ChildViews = [
    {
        name: 'investorsStockPrice',
        Constructor: InvestorsStockPrice
    },
    {
        name: 'fourColumnFacts',
        Constructor: FourColumnFacts
    }
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;

        this._initChildViews(ChildViews);

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageGlobalTemplate.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    }
});
