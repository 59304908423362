'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

require('./Video.less');

const CssClass = {
    consentGivenVimeo: 'consent-vimeo',
    consentGivenYoutube: 'consent-youtube',
    consentNotGivenVimeo: 'no-consent-vimeo',
    consentNotGivenYoutube: 'no-consent-youtube'
};

const ConsentId = {
    vimeo: 'HyEX5Nidi-m',
    youtube: 'BJz7qNsdj-7|iVTAGV51C|r1ejcEi_jZ7'
};

const Selectors = {
    el: '.Video',
    player: '.Video-player',
    consentOverlay: '.Video-consent-overlay',
    consentOverlayButtonMore: '.Video-consent-overlay-inner-buttons-more',
    consentOverlayButtonAccept: '.Video-consent-overlay-inner-buttons-accept'
};

const BindFunctions = [
    '_attachEvents',
    'triggerVideoOpen',
    'attachNewElements'
];

const ConsentHandlers = {
    vimeo: setConsentVimeo,
    youtube: setConsentYoutube
};

//noinspection JSVoidFunctionReturnValueUsed
module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this._initConsent();
        this._attachEvents();

        window.setConsentVimeo = window.setConsentVimeo || setConsentVimeo;
        window.setConsentYoutube = window.setConsentYoutube || setConsentYoutube;
    },

    _attachEvents: function () {
        this.$el.on(ViewportEvents.click, this.triggerVideoOpen);
    },

    triggerVideoOpen: function (event) {
        // If the consent overlay still is visible, don't attempt to open the video
        if ($(event.target).closest(Selectors.consentOverlay).length) {
            return;
        }
        const $target = $(event.target)
            .closest(Selectors.el);
        const url = $target.data('url');
        const data = window.app.data.videos.find((video) => {
            return video.url === url;
        });

        const $element = $target.closest(Selectors.el)
            .find(Selectors.player);

        if (window.app.isPhoneLayout() && !data.description) {
            $element.addClass(Constants.cssClasses.show);
            window.app.views.videoPlayer.openMobilePlayer(data, $element);
        } else {
            window.app.vent.trigger(Events.videoPlayer.openPlayer, data);
        }
    },

    attachNewElements: function () {
        this.setElement(Selectors.el);
        this.$el.off(ViewportEvents.click, this.triggerVideoOpen);
        this._attachEvents();
    },

    _initConsent: function () {
        this.$el.each(function (idx, elmt) {
            const $elmt = $(elmt);
            const provider = $elmt.data('provider');

            // Bind events to the buttons in the consent-overlay
            $elmt.find(Selectors.consentOverlayButtonMore).on('click touchstart', (event) => {
                window.uc.ucapi.showInfoModal(ConsentId[provider]);
                event.preventDefault();
                event.stopImmediatePropagation();
            });
            $elmt.find(Selectors.consentOverlayButtonAccept).on('click touchstart', (event) => {
                window.uc.ucapi.setConsents([{
                    'templateId': ConsentId[provider],
                    'status': true
                }]);
                event.preventDefault();
                event.stopImmediatePropagation();
                ConsentHandlers[provider](true);
            });
        });
    }
});

function setConsentVimeo(consent) {
    $(document.body).toggleClass(CssClass.consentGivenVimeo, consent);
    $(document.body).toggleClass(CssClass.consentNotGivenVimeo, !consent);
}

function setConsentYoutube(consent) {
    $(document.body).toggleClass(CssClass.consentGivenYoutube, consent);
    $(document.body).toggleClass(CssClass.consentNotGivenYoutube, !consent);
}
