'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const ViewportEvents = require('constants/ViewportEvents');

require('./InputField.less');

const Selectors = {
    el: '.InputField',
    input: '.InputField-input'
};

const BindFunctions = [
    'setValue',
    'getValue',
    'validate',
    'isValid',
    '_onInputChange'
];

const InvalidCssClass = 'invalid';
const EmailRegEx = /.+@.+\..+/i;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.input} ${Selectors.input}`]: this._onInputChange,
            [`${ViewportEvents.blur} ${Selectors.input}`]: this.validate
        };
    },

    /**
     *
     * @param options = {
     *     onModelChange - function that triggers on input value changed,
     *     value - initial value of input,
     *     name - name for this trigger,
     *     modifier - modifier for the component or page,
     *     id - unique id for this input,
     *     regExp - regexp to validate input
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.setElement(`${Selectors.el}--${options.modifier}_${options.id}`);
        this.onModelChange = options.onModelChange || (() => {});
        this.value = options.value || '';
        this.name = options.name;
        this.regExp = options.regExp;
        this.$input = this.$el.find(Selectors.input);

        this.type = this.$input.data('type');
        if (this.type === 'email' && !this.regExp) {
            this.regExp = EmailRegEx;
        }
        this.isRequired = this.$el.find(Selectors.input).prop('required');
        this.$input.val(this.value);
    },

    _onInputChange: function () {
        this.$el.removeClass(InvalidCssClass);
        this.value = this.$input.val();

        this.onModelChange(this.name, this.value);
    },

    setValue: function (value) {
        this.$input.val(value);
        this.$input.trigger('input');
    },

    getValue: function () {
        return this.value;
    },

    validate: function () {
        if (this.isValid()) {
            return;
        }

        this.$el.addClass(InvalidCssClass);
    },

    isValid: function () {
        let isValidRegExp = true;
        let isValidRequired = true;

        if (this.regExp) {
            isValidRegExp = this.regExp.test(this.value);
        }
        if (this.isRequired) {
            isValidRequired = this.value && this.value !== '';
        }

        return isValidRegExp && isValidRequired;
    }
});
