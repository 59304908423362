'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./ProductFamilyHead.less');

const Selectors = {
    el: '.ProductFamilyHead',
    bg: '.ProductFamilyHead-bg',
    description: '.ProductFamilyHead-description',
    descriptionBg: '.ProductFamilyHead-descriptionBg',
    descriptionBgImg: '.ProductFamilyHead-bg img',
    link: '.ProductFamilyHead-link'
};

const BindFunctions = [
    'onPageVisibilityChange',
    '_initRotationManager',
    '_attachEvents',
    'openAllOptionsOverlay'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openAllOptionsOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        if (this.$(Selectors.descriptionBgImg).length <= 1) {
            return;
        }

        this._initRotationManager();
        this._attachEvents();
    },

    _attachEvents: function () {
        app.utils.PageVisibilityManager.addEventListener(this.onPageVisibilityChange);
    },

    _initRotationManager: function () {
        this.mainRotationManager = app.utils.startImagesRotation(this, Selectors.bg, 6000, 0);
    },

    onPageVisibilityChange: function () {
        if (app.utils.PageVisibilityManager.isPageHidden()) {
            this.mainRotationManager.pause('visibility');
        } else {
            this.mainRotationManager.resume('visibility');
        }
    },

    openAllOptionsOverlay: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.link);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        window.app.vent.trigger(Events.eventPrefixes.productFamily
            + Events.popup.allOptions + Events.popup.popupOpen, {apiUrl, url});
    }
});
