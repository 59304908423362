'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./SearchResultPopupPagination.less');

const Template = require('./SearchResultPopupPagination.html');

const Selectors = {
    el: '.SearchResultPopupPagination',
    nextButton: '.SearchResultPopupPagination-next',
    prevButton: '.SearchResultPopupPagination-prev',
    pageNumber: '.SearchResultPopupPagination-pageNumber'
};

const BindFunctions = [
    'attachEvents',
    'attachElement',
    'onPageNumberClick',
    'selectNextPage',
    'onNextButtonClick',
    'onPrevButtonClick'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.callback = options.callback;
        this.modifier = options.modifier;

        this.attachEvents();
    },

    attachElement: function () {
        this.setElement(`${Selectors.el}--${this.modifier}`);
    },

    render: function (data = {}) {
        return Template.render({data});
    },

    attachEvents: function () {
        this.attachElement();
        this.$(Selectors.pageNumber)
            .off(ViewportEvents.click, this.onPageNumberClick)
            .on(ViewportEvents.click, this.onPageNumberClick);
        this.$(Selectors.nextButton)
            .off(ViewportEvents.click, this.onNextButtonClick)
            .on(ViewportEvents.click, this.onNextButtonClick);
        this.$(Selectors.prevButton)
            .off(ViewportEvents.click, this.onPrevButtonClick)
            .on(ViewportEvents.click, this.onPrevButtonClick);

        this.checkArrows();
    },

    onPageNumberClick: function (event) {
        event.preventDefault();
        const $target = $(event.target);
        const pageNumber = parseInt($target.data('id'), 10);
        this.selectNextPage(pageNumber);
    },

    selectNextPage: function (pageNumber) {
        const apiUrl = this.$el.data('api-url');
        const itemsCount = this.$el.data('items-count');
        const offset = (pageNumber - 1) * itemsCount;

        this.callback && this.callback({pageNumber, apiUrl, offset});
    },

    onNextButtonClick: function (event) {
        event.preventDefault();
        this.checkArrows();
        let pageNumber = this.$(`${Selectors.pageNumber}.active`)
            .data('id');
        pageNumber = parseInt(pageNumber, 10) + 1;
        this.selectNextPage(pageNumber);
    },

    onPrevButtonClick: function (event) {
        event.preventDefault();
        this.checkArrows();
        let pageNumber = this.$(`${Selectors.pageNumber}.active`)
            .data('id');
        pageNumber = parseInt(pageNumber, 10) - 1;
        this.selectNextPage(pageNumber);
    },

    checkArrows: function () {
        const countOfPages = this.$el.data('pages-count');
        const activePageNumber = this.$(`${Selectors.pageNumber}.active`)
            .data('id');

        this.$(Selectors.nextButton)
            .toggleClass(Constants.cssClasses.hide, countOfPages === activePageNumber);

        this.$(Selectors.prevButton)
            .toggleClass(Constants.cssClasses.hide, activePageNumber === 1);
    }
});
