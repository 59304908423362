'use strict';

const Backbone = require('backbone/backbone');

require('./ProductFamilyDownloads.less');

module.exports = Backbone.View.extend({

    el: '.ProductFamilyDownloads',

    events: {},

    initialize: function () {

    }
});
