'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const objectFitImages = require('object-fit-images');

require('./IndexHead.less');

const Selectors = {
    indexHead: '.IndexHead',
    textsLeft: '.IndexHead-texts-left',
    textsRight: '.IndexHead-texts-right',
    texts: '.IndexHead-texts',
    imagesLeft: '.IndexHead-images-left',
    imagesRight: '.IndexHead-images-right',
    images: '.IndexHead-images',
    overlay: '.IndexHead-overlay',
    filter: '.IndexHead-filter'
};

const BindFunctions = [
    'onScroll',
    'onPageVisibilityChange',
    '_initVariables',
    '_attachEvents',
    '_initRotationManager'
];

const CssClasses = {
    textsLeft: 'IndexHead-texts-left',
    images: 'IndexHead-images'
};

module.exports = Backbone.View.extend({

    el: Selectors.indexHead,

    events: function () {
        return {
            [`${ViewportEvents.mouseEnter} ${Selectors.textsLeft}`]: this.onMouseEnter,
            [`${ViewportEvents.mouseEnter} ${Selectors.textsRight}`]: this.onMouseEnter,
            [`${ViewportEvents.mouseLeave} ${Selectors.textsLeft}`]: this.onMouseLeave,
            [`${ViewportEvents.mouseLeave} ${Selectors.textsRight}`]: this.onMouseLeave
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);

        objectFitImages();

        this._initRotationManager();

        this.onScroll();
        this._attachEvents();
    },

    _initRotationManager: function () {
        this.leftRotationManager = app.utils.startImagesRotation(this, Selectors.imagesLeft, 6000, 0);
        this.rightRotationManager = app.utils.startImagesRotation(this, Selectors.imagesRight, 6000, 3000);
    },

    _attachEvents: function () {
        $(window)
            .on(ViewportEvents.scroll, this.onScroll);

        app.utils.PageVisibilityManager.addEventListener(app.utils.onPageVisibilityChange);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.mouseLeaveTimeout = {};
        this.$images = this.$(Selectors.images);
        this.$texts = this.$(Selectors.texts);
        this.$overlay = this.$(Selectors.overlay);
        this.$filter = this.$(Selectors.filter);
    },

    onMouseEnter: function (e) {
        if (!app.settings.isDesktop) {
            return;
        }

        const $text = $(e.currentTarget);
        const pos = $text.hasClass(CssClasses.textsLeft) ? 'left' : 'right';

        clearTimeout(this.mouseLeaveTimeout[pos]);

        this.$(`${Selectors.images}-${pos}`)
            .addClass(`${CssClasses.images}-${pos}--hover`)
            .addClass(`${CssClasses.images}-${pos}--hover-transition`);

        this.mouseLeaveTimeout[pos] = setTimeout(function () {
            this.$(`${Selectors.images}-${pos}`)
                .removeClass(`${CssClasses.images}-${pos}--hover-transition`);
        }.bind(this), 300);
    },

    onMouseLeave: function (e) {
        if (!app.settings.isDesktop) {
            return;
        }

        const $text = $(e.currentTarget);
        const pos = $text.hasClass(CssClasses.textsLeft) ? 'left' : 'right';

        clearTimeout(this.mouseLeaveTimeout[pos]);

        this.$(`${Selectors.images}-${pos}`)
            .removeClass(`${CssClasses.images}-${pos}--hover`)
            .addClass(`${CssClasses.images}-${pos}--hover-transition`);

        this.mouseLeaveTimeout[pos] = setTimeout(function () {
            this.$(`${Selectors.images}-${pos}`)
                .removeClass(`${CssClasses.images}-${pos}--hover-transition`);
        }.bind(this), 300);
    },

    onPageVisibilityChange: function () {
        if (app.utils.PageVisibilityManager.isPageHidden()) {
            this.leftRotationManager.pause('visibility');
            this.rightRotationManager.pause('visibility');
        } else {
            this.leftRotationManager.resume('visibility');
            this.rightRotationManager.resume('visibility');
        }
    },

    onScroll: function () {
        if (app.state.popupShown) {
            return;
        }

        const scroll = $(window)
            .scrollTop();
        const per = Math.max(Math.min(scroll / (0.5 * window.innerHeight), 1), 0);
        this.$texts.css('opacity', 1 - Math.pow(per, 0.5) * 1.7);
        this.$overlay.css('opacity', per);
        this.$filter.css('backdrop-filter', 'blur(' + (per * 15) + 'px)');

        per > 0 ? this.leftRotationManager.pause('scroll') : this.leftRotationManager.resume('scroll');
        per > 0 ? this.rightRotationManager.pause('scroll') : this.rightRotationManager.resume('scroll');
    }
});
