'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');

const ProductFamilyItem = require('components/ProductFamilyItem/ProductFamilyItem');

require('jquery-touchswipe');

require('./ProductFamilySection.less');

const Selectors = {
    el: '.ProductFamilySection',
    item: '.ProductFamilySection-item',
    productItem: '.ProductFamilyItem'
};

const BindFunctions = [
    'openProductPopup',
    'toggleFilterOpen',
    '_initChildComponents'
];

const CssClasses = {
    isOpenFilter: 'isOpenFilter'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.openProductPopup
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this._initChildComponents();
        this._attachEvents();
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.productFamily + Events.filter.mobileOpen, this.toggleFilterOpen);
    },

    _initChildComponents: function () {
        const $productItems = this.$el.find(Selectors.productItem);
        this.productFamilyItems = [];
        const self = this;

        $productItems.each((index, item) => {
            const $item = $(item);
            this.productFamilyItems.push(new ProductFamilyItem({
                page: self,
                el: $item
            }));
        });
    },

    openProductPopup: function (e) {
        // it's a placeholder
        // when server side will be implemented, link will change the URL and make request to server
        // now clicking link just trigger already rendered popup to open
        e.preventDefault();
        e.stopPropagation();
        app.vent.trigger(Events.eventPrefixes.product + Events.popup.popupShown);
    },

    toggleFilterOpen: function () {
        this.$el.toggleClass(CssClasses.isOpenFilter);
    }
});
