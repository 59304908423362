'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');
const Events = require('constants/Events');

const SustainabilityHead = require('components/SustainabilityHead/SustainabilityHead');
const CommonText = require('components/Common/CommonText/CommonText');
const CareersFooter = require('components/CareersFooter/CareersFooter');
const Accordion = require('components/Common/Accordion/Accordion');
const Link = require('components/Common/Link/Link');
const LatestFinancialReports = require('components/Common/LatestFinancialReports/LatestFinancialReports');
const AboutLightArchitecture = require('components/AboutLightArchitecture/AboutLightArchitecture');
const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');
const NewsInsights = require('components/Common/NewsInsights/NewsInsights');
const FeaturesIcons = require('components/Common/FeaturesIcons/FeaturesIcons');
const History = require('components/Common/History/History');

require('./PageSustainabilityLanding.less');

const ChildViews = [
    {
        name: 'head',
        Constructor: SustainabilityHead
    },
    {
        name: 'commonText',
        Constructor: CommonText
    },
    {
        name: 'footer',
        Constructor: CareersFooter
    },
    {
        name: 'link',
        Constructor: Link
    },
    {
        name: 'reports',
        Constructor: LatestFinancialReports
    },
    {
        name: 'news',
        Constructor: NewsInsights
    },
    {
        name: 'features',
        Constructor: FeaturesIcons
    },
    {
        name: 'history',
        Constructor: History
    }
];

const Selectors = {
    footer: '.PageSustainabilityLanding-footer'
};

const BindFunctions = [
    'openPopup'
];

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);

        this.aboutLightArchitecture = new AboutLightArchitecture({
            page: this,
            pageUrl: 'sustainability.html'
        });

        this.accordion = new Accordion({
            modifier: 'sustainability'
        });

        PageLanding.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);

        this.$lastBlockAbsoluteTriggerElement = this.$(Selectors.footer);
        this.scrollMagicTriggerHook = 0;
    },

    _attachEvents: function () {
        PageLanding.prototype._attachEvents.call(this);

        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    }
});
