'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const FourColumnFacts = require('components/Common/FourColumnFacts/FourColumnFacts');
const GlobalPageTitle = require('components/Common/GlobalPageTitle/GlobalPageTitle');
const GlobalMenuMobile = require('components/Common/GlobalMenuMobile/GlobalMenuMobile');

require('./CompanyLandingHead.less');

const Selectors = {
    title: '.GlobalPageTitle',
    facts: '.CompanyLandingHead-facts',
    overlay: '.CompanyLandingHead-overlay'
};

const BindFunctions = [
    '_initChildComponents',
    '_attachEvents',
    'onSubMenuShown',
    'onSubMenuHidden',
    '_initVariables',
    '_toggleSubMenu'
];

module.exports = Backbone.View.extend({

    el: '.CompanyLandingHead',

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        if (window.app.isPhoneLayout()) {
            window.app.utils.fixHeightOnIos(this.$el);
        }

        this._initChildComponents();
        this._initVariables();
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.vent.on(Events.subMenuNav.navShown, this.onSubMenuShown);
        window.app.vent.on(Events.subMenuNav.navHidden, this.onSubMenuHidden);
    },

    _initVariables: function () {
        this.$title = this.$(Selectors.title);
        this.$facts = this.$(Selectors.facts);
        this.$overlay = this.$(Selectors.overlay);
    },

    _toggleSubMenu: function (show) {
        this.$title.toggleClass(Constants.cssClasses.hide, show);
        this.$facts.toggleClass(Constants.cssClasses.hide, show);
        this.$overlay.toggleClass(Constants.cssClasses.show, show);

        const event = show ? Events.popup.popupShown : Events.popup.popupHidden;
        if (show) {
            this.savedScroll = $(window).scrollTop();
        }
        const scroll = show ? 0 : this.savedScroll;
        app.vent.trigger(event);
        $(window)
            .scrollTop(scroll);
    },

    onSubMenuHidden: function () {
        this._toggleSubMenu(false);
    },

    onSubMenuShown: function () {
        this._toggleSubMenu(true);
    },

    _initChildComponents: function () {
        this.facts = new FourColumnFacts({
            page: this
        });
        this.title = new GlobalPageTitle({
            page: this
        });
        this.menu = new GlobalMenuMobile({
            page: this
        });
    }
});
