'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const Page = require('pages/Page/Page');
const Utils = require('utils/Utils');
const PerfectScrollbar = require('perfect-scrollbar').default;
const scrollLock = require('scroll-lock');

require('./BasePopup.less');

const Selectors = {
    el: '.Popup',
    close: '.Popup-close',
    wrapper: '.Popup.show .Popup-wrapper',
    bg: '.Page-popupBg',
    popupBg: '.Popup-bg'
};

const BindFunctions = [
    'getDataFromApi',
    'triggerPopupShown',
    'triggerPopupHidden',
    'openPopup',
    'closePopup',
    'onKeydown',
    'onScroll',
    'onResize',
    'destroy',
    'registerChildView',
    '_attachBaseEvents',
    '_initVariables',
    'removePopupFromDom',
    'addClassesAndEvents',
    'openRenderedPopup',
    '_setCurrentPopupEl',
    'initChildComponents',
    'hidePopup',
    'getUrl',
    'getApiUrl',
    'getTitle',
    'getMeta',
    'initRenderedPopup',
    'backButtonClicked',
    'unsubscribeFromBackButtonEvent',
    'subscribeToBackButtonEvent',
    'unsubscribeFromKeydownEvent',
    'subscribeToKeydownEvent',
    'scrollTop',
    'onPopupRendered'
];

module.exports = Page.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._setCurrentPopupEl();
    },

    /**
     *  Every time new overlay is opened, if some overlay was already opened, it goes to this.previousPopup,
     *  so we can open it again when closing current overlay
     *
     * @private
     */
    _setCurrentPopupEl: function () {
        this.backUrl = '';
        if (window.app.data.currentPopup) {
            this.previousPopup = window.app.data.currentPopup;
            if (!window.app.data.countOfStackedOverlays) {
                window.app.data.countOfStackedOverlays = 0;
            }
            window.app.data.countOfStackedOverlays++;
            this.backUrl = this.previousPopup.getUrl();
            this.previousPopupMeta = this.previousPopup.getMeta();
            this.previousPopupTitle = this.previousPopup.getTitle();
            this.previousPopup.unsubscribeFromBackButtonEvent();
            this.previousPopup.unsubscribeFromKeydownEvent();
        }
        this.subscribeToBackButtonEvent();
        this.subscribeToKeydownEvent();
        window.app.data.currentPopup = this;
    },

    _initVariables: function (options = {}) {
        // options.backUrl value will be used only if there will be no backUrl in data from server
        this.backUrl = options && options.backUrl;
        this.popupWrapperScroll = 0;
        this.popupWrapper = Selectors.wrapper;
        this.options = options;
    },

    _attachBaseEvents: function () {
        const self = this;

        $(`${this.popupClass} ${Selectors.close}`)
            .click(this.closePopup);

        $(`${this.popupClass} ${Selectors.popupBg}`)
            .click(this.closePopup);

        app.vent.on(Events.popup.hideCloseButton, function () {
            self.$(Selectors.close)
                .hide();
        }.bind(this));

        app.vent.on(Events.popup.showCloseButton, function () {
            self.$(Selectors.close)
                .show();
        }.bind(this));
    },

    /**
     *  Render popup with data from API
     *
     * @param data
     * @param url
     */
    render: function (data, url) {
        // if there was already an overlay shown, we hide it
        if (this.previousPopup) {
            this.previousPopup.hidePopup();
        }

        // append rendered template from JSON coming from server
        // to div.Page-popup elem
        window.app.els.$popupContainer
            .append(data.template);

        this.navigateToUrl(url);

        Utils.setMetaTags(data.meta);
        Utils.setTitle(data.title);

        this.initRenderedPopup(data);
    },

    onPopupRendered: function () {
        // to override in actual popup components
    },

    initRenderedPopup: function (data, isPopupAlreadyRendered) {
        this.onPopupRendered();
        window.app.views.video.attachNewElements();
        window.app.utils.attachClickOnAnchorLinks();
        let cssClassStackedOverlay = `${this.popupClass}--${(window.app.data.countOfStackedOverlays || 0)}`;
        cssClassStackedOverlay = cssClassStackedOverlay.replace('.', '');
        $(`${this.popupClass}:last`)
            .addClass(cssClassStackedOverlay);
        this.popupClass += `.${cssClassStackedOverlay}`;

        this.setElement(this.popupClass);

        this.initChildComponents();

        this.data = data;

        this.addClassesAndEvents();
        app.utils.attachClickOnPopupLinks();

        if (!isPopupAlreadyRendered) {
            return;
        }

        const currentUrl = window.location.href;
        history.replaceState({}, document.title, this.backUrl);
        history.pushState({}, document.title, currentUrl);

        if (this.backUrl) {
            return;
        }

        this.backUrl = data.backUrl;
    },

    initChildComponents: function () {
        // this function must be overrided to init child components for popup
    },

    addClassesAndEvents: function () {
        // reattach all events to newly created popup
        this._attachBaseEvents();

        this.triggerPopupShown();

        $(this.popupClass)
            .addClass(Constants.cssClasses.show);

        if (window.app.isPhoneLayout()) {
            scrollLock.addScrollableSelector(this.popupClass + this.popupWrapper);

            return;
        }
        setTimeout(() => {
            this.perfectScrollbar = new PerfectScrollbar(this.popupClass + this.popupWrapper, {
                suppressScrollX: true
            });

            this.perfectScrollbar.update();
        });
    },

    getDataFromApi: function (isPopupAlreadyRendered = false) {
        // render method must be call after async request completed
        $.getJSON(this.apiUrl, (data) => {
            if (data.backUrl && !window.app.data.countOfStackedOverlays) {
                this.backUrl = data.backUrl;
            }

            if (isPopupAlreadyRendered) {
                this.initRenderedPopup(data, isPopupAlreadyRendered);

                return;
            }
            this.render(data, this.url);
        });
    },

    triggerPopupShown: function (skipTriggerGlobalFunction) {
        this.savedScroll = $(window)
            .scrollTop();
        if (!skipTriggerGlobalFunction) {
            app.vent.trigger(Events.popup.popupShown);
        }
        window.app.els.$body
            .addClass(Constants.cssClasses.popupShow);
        if ($(this.popupClass)
            .hasClass(Constants.cssClasses.halfPagePopup)) {
            app.vent.trigger(Events.popup.halfPagePopupShown);
            window.app.els.$body
                .addClass(Constants.cssClasses.halfPagePopupShown);
        }
        $(Selectors.bg)
            .addClass(Constants.cssClasses.show);
        if (!window.app.isPhoneLayout()) {
            return;
        }
        $(window)
            .scrollTop(0);
    },

    triggerPopupHidden: function (withoutRouteChange) {
        app.vent.trigger(Events.popup.popupHidden);

        window.app.els.$body
            .removeClass(Constants.cssClasses.popupShow);

        window.requestAnimationFrame(() => {
            $(window)
                .scrollTop(this.savedScroll);
            this.onPopupClose && this.onPopupClose();
        });

        scrollLock.removeScrollableSelector(this.popupClass + this.popupWrapper);

        if (this.backUrl && !withoutRouteChange) {
            app.state.isCloseButtonClicked = true;
            history.back();
        }

        // popup should be removed from dom when closed.
        // this should be moved to BasePopup.js when all popup logic will be implemented

        let newMeta;
        let newTitle;

        if (this.previousPopup) {
            this.previousPopup.showPopup();
            this.previousPopup.subscribeToBackButtonEvent();
            this.previousPopup.subscribeToKeydownEvent();
            window.app.data.currentPopup = this.previousPopup;
            this.previousPopup = undefined;
            newMeta = this.previousPopupMeta;
            newTitle = this.previousPopupTitle;
        } else {
            window.app.data.currentPopup = undefined;
            newMeta = this.data.pageMeta;
            newTitle = this.data.pageTitle;
            window.app.data.countOfStackedOverlays = 0;
        }

        // we need to reverse all changes in meta tags and title when it's closing
        Utils.setMetaTags(newMeta);
        Utils.setTitle(newTitle);
    },

    /**
     *  Open popup in runtime. Get all data from API (using provided apiUrl) and
     *  add popup template (already rendered on server) to the DOM.
     *
     */
    openPopup: function (apiUrl, url, onPopupClose) {
        // by now fake api url don't match actual url from data-url (because data is coming from json. So
        // apiUrl parameter is hardcoded on openPopup function call)
        this.onPopupClose = onPopupClose;
        this.apiUrl = apiUrl;
        this.url = url;
        this.getDataFromApi();
    },

    /**
     *  If popup is already rendered and placed on the page (the case when popup opens directly by url)
     *  we just add classes and events to the DOM elements and set data.

     */
    openRenderedPopup: function (onPopupClose) {
        if (onPopupClose) {
            this.onPopupClose = onPopupClose;
        }
        const $popup = $(this.popupClass);

        this.apiUrl = $popup.data('api-url');
        this.url = window.location.pathname;

        this.getDataFromApi(true);
    },

    removePopupFromDom: function () {
        $(this.popupClass)
            .remove();
        if (window.app.state.countOfOpenedPopups === 0) {
            window.app.els.$popupContainer.html('');
        }
        this.destroy();
    },

    hidePopup: function () {
        $(this.popupClass)
            .addClass(Constants.cssClasses.hide)
            .removeClass(Constants.cssClasses.show);
    },

    showPopup: function () {
        $(this.popupClass)
            .removeClass(Constants.cssClasses.hide)
            .addClass(Constants.cssClasses.show);
        this.triggerPopupShown(true);
    },

    closePopup: function (event, withoutRouteChange) {
        app.state.isCloseButtonClicked = false;
        $(this.popupClass)
            .addClass(Constants.cssClasses.hide);
        if ($(this.popupClass)
            .hasClass(Constants.cssClasses.halfPagePopup)) {
            app.vent.trigger(Events.popup.halfPagePopupHidden);
        }
        $(Selectors.bg)
            .removeClass(Constants.cssClasses.show);

        window.app.els.$body
            .removeClass(Constants.cssClasses.halfPagePopupShown);
        window.app.els.$html
            .css('overflow', '');
        const self = this;
        app.utils.waitForTransitionEnd(this.$el, 'visibility', function () {
            $(self.popupClass)
                .removeClass(Constants.cssClasses.show)
                .removeClass(Constants.cssClasses.hide);

            self.removePopupFromDom();
        }, 600);

        this.triggerPopupHidden(withoutRouteChange);
        this.unsubscribeFromBackButtonEvent();
        this.unsubscribeFromKeydownEvent();

        app.vent.trigger(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose);
    },

    onKeydown: function (event) {
        if (event.keyCode === Constants.keyCodes.esc) {
            this.closePopup(event, false);
        }
    },

    onScroll: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    onResize: function () {
        this.popupWrapperScroll = $(this.popupWrapper)
            .scrollTop() + $(this.popupWrapper)
            .innerHeight();
    },

    registerChildView(ViewConstructor, data) {
        if (!this.childViews) {
            this.childViews = [];
        }
        const view = new ViewConstructor(data);
        this.childViews.push(view);
    },

    destroy: function () {
        if (this.childViews) {
            for (let i = 0; i < this.childViews.length; i++) {
                if (this.childViews[i]) {
                    this.childViews[i].undelegateEvents();
                    this.childViews[i].stopListening();
                    this.childViews[i].unbind();
                }
            }
        }
        this.undelegateEvents();

        $(this.popupClass)
            .removeData()
            .unbind();

        this.remove();
        Backbone.View.prototype.remove.call(this);
    },

    backButtonClicked: function () {
        if (window.app.state.isHashChanging) {
            return;
        }
        if (app.state.isCloseButtonClicked) {
            app.state.isCloseButtonClicked = false;

            return;
        }

        this.closePopup(undefined, true);
    },

    getUrl: function () {
        return this.url;
    },

    getApiUrl: function () {
        return this.apiUrl;
    },

    getTitle: function () {
        return this.data.title;
    },

    getMeta: function () {
        return this.data.meta;
    },

    unsubscribeFromBackButtonEvent: function () {
        window.app.els.$window
            .off(ViewportEvents.popState, this.backButtonClicked);
    },

    subscribeToBackButtonEvent: function () {
        window.app.els.$window
            .on(ViewportEvents.popState, this.backButtonClicked);
    },

    subscribeToKeydownEvent: function () {
        window.app.els.$window
            .on(ViewportEvents.keyDown, this.onKeydown);
    },

    unsubscribeFromKeydownEvent: function () {
        window.app.els.$window
            .off(ViewportEvents.keyDown, this.onKeydown);
    },

    navigateToUrl: function (url) {
        history.pushState({}, document.title, url);
    },

    scrollTop: function (scrollTop = 0) {
        $(this.popupWrapper)
            .scrollTop(scrollTop);
    }
});
