'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

require('./TopPageButtons.less');

const Selectors = {
    el: '.TopPageButtons',
    buttonsShare: '.TopPageButtons-buttonsShare',
    shareDropdown: '.TopPageButtons-shareDropdown'
};

const BindFunctions = [
    'toggleShareDropdown',
    '_attachEvents'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.buttonsShare}`]: this.toggleShareDropdown
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._attachEvents();
    },

    _attachEvents: function () {
        const self = this;
        window.app.els.$body
            .on(ViewportEvents.click, function (e) {
                if ($(e.target)
                    .closest(Selectors.shareDropdown).length === 0
                    && ($(e.target)
                        .closest(Selectors.buttonsShare).length === 0)) {
                    self.closeDropdown();
                }
            });
    },

    toggleShareDropdown: function () {
        this.$(Selectors.shareDropdown)
            .toggleClass(Constants.cssClasses.active);
    },

    closeDropdown: function () {
        this.$(Selectors.shareDropdown)
            .removeClass(Constants.cssClasses.active);
    }
});
