'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const CampusCenterPopup = require('popups/CampusCenterPopup/CampusCenterPopup');
const Events = require('constants/Events');

require('./CampusHead.less');

const Selectors = {
    el: '.CampusHead',
    linkOverlay: '.CampusHead-linkOverlay',
    liveLink: '.CampusHead-smallLink_live',
    eventLinksWrapper: '.CampusHead-smallLinksWrapper'
};

const BindFunctions = [
    'openPopup',
    'openLiveEvent'
];

const ScrollToAnchorDuration = 200;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.linkOverlay}`]: this.openPopup,
            [`${ViewportEvents.click} ${Selectors.liveLink}`]: this.openLiveEvent
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        const hash = window.location.hash.replace('#', '');
        if (!hash) {
            return;
        }

        const eventHashName = this.$(Selectors.eventLinksWrapper)
            .data('live-hash');

        if (!eventHashName || hash !== eventHashName) {
            // find element by hash
            const $el = $(`#${hash}`);
            if (!$el.length) {
                return;
            }
            window.addEventListener('load', function () {
                $([document.documentElement, document.body])
                    .animate({
                        scrollTop: $el.offset().top
                    }, ScrollToAnchorDuration);
            });

            return;
        }

        const event = {
            target: this.$(Selectors.liveLink)
        };
        this.openLiveEvent(event);
    },

    openPopup: function (event) {
        event.preventDefault();
        const $target = $(event.target)
            .closest(Selectors.linkOverlay);
        const apiUrl = $target.data('api-url');
        const url = $target.data('url');

        const popup = new CampusCenterPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    },

    openLiveEvent: function (event) {
        const $target = $(event.target)
            .closest(Selectors.liveLink);
        const url = $target.data('url');
        const data = window.app.data.videos.find((video) => {
            return video.url === url;
        });

        window.app.vent.trigger(Events.videoPlayer.openPlayer, data);
    }
});
