var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/ContactComponents/ContactRegionalOffices/ContactRegionalCardItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"type") == "link") {
output += "\n    <div class=\"ContactRegionalOffices-cardLink\">\n        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow\"></a>\n        <div class=\"ContactRegionalOffices-cardLinkTitle s-title-normal\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n        <div class=\"ContactRegionalOffices-cardLink-link s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"link")),"title"), env.opts.autoescape);
output += "</div>\n    </div>\n";
;
}
else {
output += "\n    <div class=\"ContactRegionalOffices-cardContact\">\n        <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"email")),"url"), env.opts.autoescape);
output += "\" target=\"_blank\" rel=\"nofollow\"></a>\n        <div class=\"ContactRegionalOffices-cardContactTitle s-title-normal\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"title"), env.opts.autoescape);
output += "</div>\n        <div class=\"ContactRegionalOffices-cardContact-contact\">\n            <div class=\"ContactRegionalOffices-infoName s-title-small ContactRegionalOffices-line\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"name"), env.opts.autoescape);
output += "</div>\n            <div class=\"ContactRegionalOffices-infoPosition s-text-small ContactRegionalOffices-line\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"position"), env.opts.autoescape);
output += "</div>\n            <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"phone")),"link"), env.opts.autoescape);
output += "\" class=\"ContactRegionalOffices-infoPhone s-text-small ContactRegionalOffices-line\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"phone")),"title"), env.opts.autoescape);
output += "</a>\n        </div>\n        <div class=\"ContactRegionalOffices-cardLink-link s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "item")),"email")),"title"), env.opts.autoescape);
output += "</div>\n    </div>\n";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/ContactComponents/ContactRegionalOffices/ContactRegionalCardItem.html"] , dependencies)