'use strict';

const _ = require('underscore');
const BasePopup = require('popups/BasePopup/BasePopup');

const CampusCenterPopupHead = require('components/CampusCenterPopupHead/CampusCenterPopupHead');
const Layouts111Sections = require('components/Layouts111Sections/Layouts111Sections');
const Loader = require('@googlemaps/js-api-loader').Loader;

require('./CampusCenterPopup.less');

const MapId = 'c098049c2dbe0958';
const GoogleMapsVersion = 'weekly';
// eslint-disable-next-line
const PinImage = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjJweCIgaGVpZ2h0PSIzM3B4IiB2aWV3Qm94PSIwIDAgMjIgMzMiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+Q29tYmluZWQgU2hhcGU8L3RpdGxlPgogICAgPGcgaWQ9IkRFU0tUT1AtLS1HUk9VUCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjQtQ0FNUFVTLS8tMi1DQU1QVVMtQ0VOVEVSIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtODY2LjAwMDAwMCwgLTM1NTIuMDAwMDAwKSIgZmlsbD0iIzExMTExMiIgc3Ryb2tlPSIjRkZGRkZGIj4KICAgICAgICAgICAgPGcgaWQ9IkNvbnRlbnQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwMC4wMDAwMDAsIDcwMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxnIGlkPSJHb29nbGUtTWFwcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDI2NDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTQ3NywyMTMgQzQ4Mi41MjI3OTEsMjEzIDQ4NywyMTcuMzQ3MDY3IDQ4NywyMjIuNzA4NyBDNDg3LDIyNC4zODc4ODUgNDg2LjU2LDIyNS45NjczNTQgNDg1Ljc4Njk3NywyMjcuMzQ1NTA5IEw0NzYuOTU1MzQ5LDI0NSBMNDY4LjIyMjc5MSwyMjcuMzYxOTcxIEM0NjcuNDQzNzIxLDIyNS45ODAwNTQgNDY3LDIyNC4zOTQ0NyA0NjcsMjIyLjcwODcgQzQ2NywyMTcuMzQ3MDY3IDQ3MS40NzcyMDksMjEzIDQ3NywyMTMgWiBNNDc3LDIxOSBDNDc0Ljc5MDg2MSwyMTkgNDczLDIyMC43OTA4NjEgNDczLDIyMyBDNDczLDIyNS4yMDkxMzkgNDc0Ljc5MDg2MSwyMjcgNDc3LDIyNyBDNDc5LjIwOTEzOSwyMjcgNDgxLDIyNS4yMDkxMzkgNDgxLDIyMyBDNDgxLDIyMC43OTA4NjEgNDc5LjIwOTEzOSwyMTkgNDc3LDIxOSBaIiBpZD0iQ29tYmluZWQtU2hhcGUiPjwvcGF0aD4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+';

const BindFunctions = [
    'loadGoogleMaps'
];

const Selectors = {
    map: '#google-map'
};

//noinspection JSVoidFunctionReturnValueUsed
module.exports = BasePopup.extend({

    el: '.CampusCenterPopup',

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.popupClass = '.CampusCenterPopup';

        BasePopup.prototype.initialize.call(this, options);
    },

    onPopupRendered: function () {
        // Show consent popover for Google Maps when it's rendered, if necessary
        // This is happening here and not on the `Page.html` as this may happen asynchronously
        window.uc.blockElements({
            'S1pcEj_jZX': '#google-map'
        });
        window.uc.reloadOnOptIn('S1pcEj_jZX');
        window.uc.reloadOnOptOut('S1pcEj_jZX');
        // If consent for Google Maps was given, load it
        if (window.UC_UI.getServicesBaseInfo().find(data => (data.id === 'S1pcEj_jZX')).consent.status) {
            this.loadGoogleMaps();
        }
    },

    loadGoogleMaps: async function () {
        const $map = $(Selectors.map);
        const mapElem = $map[0];
        const latitude = parseFloat($map.data('center-lat'));
        const longitude = parseFloat($map.data('center-lng'));
        const zoom = parseFloat($map.data('zoom'));
        const pinTitle = $map.data('pin-title');

        const loader = new Loader({
            apiKey: API_KEY_GOOGLE_MAPS,
            version: GoogleMapsVersion,
            mapIds: [MapId]
        });
        const latLng = {lat: latitude, lng: longitude};
        await loader.load();
        this.map = new window.google.maps.Map(mapElem, {
            mapId: MapId,
            center: latLng,
            zoom
        });
        new window.google.maps.Marker({
            position: latLng,
            map: this.map,
            title: pinTitle,
            icon: PinImage
        });
    },

    initChildComponents: function () {
        this.registerChildView(CampusCenterPopupHead, {page: this});
        this.registerChildView(Layouts111Sections, {page: this});
    }
});
