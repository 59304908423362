'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./GlobalPageTitle.less');

const Selectors = {
    el: '.GlobalPageTitle'
};

const BindFunctions = [
    '_attachEvents',
    'onTitleClick'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.isLinkExternal = !!this.$el.data('external');

        this._attachEvents();
    },

    _attachEvents: function () {
        this.$el.on(ViewportEvents.click, this.onTitleClick);
    },

    onTitleClick: function (event) {
        if (this.isLinkExternal) {
            return;
        }

        event.preventDefault();
        const $target = $(event.target)
            .closest(Selectors.el);

        const url = $target.data('url');
        const apiUrl = $target.data('api-url');

        app.vent.trigger(Events.eventPrefixes.news + Events.popup.popupOpen, {apiUrl, url});
    }
});
