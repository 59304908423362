'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const DownloadCard = require('components/Common/DownloadCard/DownloadCard');
const Constants = require('constants/Constants');
require('./LatestFinancialReports.less');
const Slider = require('components/MobileSlider/MobileSlider');

const BindFunctions = [
    '_initMobileSlider'
];

const Selectors = {
    el: '.LatestFinancialReports',
    mobileEl: '.LatestFinancialReports-cardsMobile',
    innerWrapper: '.LatestFinancialReports-cardsMobileInner',
    navLine: '.LatestFinancialReports-navLine',
    itemMobile: '.DownloadCard'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.downloadCard = new DownloadCard();
        this._initMobileSlider();
    },

    _initMobileSlider: function () {
        this.slider = new Slider({
            page: this,
            el: Selectors.mobileEl,
            innerWrapper: Selectors.innerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.itemMobile,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            withAnimationDesktop: true,
            infiniteDesktop: true,
            transitionWidth: this.$(`${Selectors.innerWrapper} ${Selectors.itemMobile}`).outerWidth(true)
        });
    }

});
