'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');

const ViewportEvents = require('constants/ViewportEvents');

require('./InspirationCard.less');

const Selectors = {
    el: '.InspirationCard',
    link: '.InspirationCard-link'
};

const BindFunctions = [
    '_initVariables',
    'onCardClick'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.onCardClick
        };
    },

    /**
     *
     * @param options = {
     *     onInspirationCardClick: function - takes data-url as argument to load overlay data
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.onInspirationCardClick = options.onInspirationCardClick;
    },

    onCardClick: function (event) {
        if (!this.onInspirationCardClick) {
            return;
        }

        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.link);
        const url = $target
            .data('url');
        const apiUrl = $target.data('api-url');

        this.onInspirationCardClick({url, apiUrl});
    }
});
