'use strict';

const createContactComponent = require('../ContactComponentFactory/ContactComponentFactory');
const SelectorComponent = '.ContactHrAndJobs';

require('./ContactHrAndJobs.less');

module.exports = createContactComponent({selectorComponent: SelectorComponent});


