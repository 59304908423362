var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/ContactComponents/ContactRegionalOffices/ContactRegionalTeamItem.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"ContactRegionalOffices-teamMember\">\n    <div class=\"ContactRegionalOffices-teamImage\">\n        <img src=\"";
output += runtime.suppressValue((lineno = 2, colno = 27, runtime.callWrap(runtime.contextOrFrameLookup(context, frame, "static"), "static", context, [runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"image")])), env.opts.autoescape);
output += "\" alt=\"\">\n    </div>\n    <div class=\"ContactRegionalOffices-teamName s-text-normal\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"name"), env.opts.autoescape);
output += "</div>\n    <div class=\"ContactRegionalOffices-teamPosition s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"position"), env.opts.autoescape);
output += "</div>\n    <a class=\"ContactRegionalOffices-teamEmail s-text-small u-Link\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"email"), env.opts.autoescape);
output += "</a>\n    <div class=\"ContactRegionalOffices-teamPhone s-text-small\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "member")),"phone"), env.opts.autoescape);
output += "</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/ContactComponents/ContactRegionalOffices/ContactRegionalTeamItem.html"] , dependencies)