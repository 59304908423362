'use strict';

const Page = require('pages/Page/Page.js');
const _ = require('underscore');
const Events = require('constants/Events');
require('./PageInspiration.less');

const Filters = require('components/Filters/Filters');
const InspirationCases = require('components/InspirationCases/InspirationCases');
const InspirationProducts = require('components/InspirationProducts/InspirationProducts');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

const Const = {
    popupsBackUrl: '/inspiration.html',
    filtersCurrentPage: 'inspiration',
    filtersElSelector: '.Filters--inspiration'
};

const BindFunctions = [
    'openPopup'
];

module.exports = Page.extend({

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        const urlParams = new URLSearchParams(window.location.search);
        const preFiltered = {
            application: urlParams.get('application'),
            product: urlParams.get('product'),
            location: urlParams.get('location')
        };

        this.filters = new Filters({
            currentPage: Const.filtersCurrentPage,
            el: Const.filtersElSelector,
            preFiltered: preFiltered,
            disableFilterOnLoad: true
        });

        this.cases = new InspirationCases({filters: this.filters});

        this.inspirationProducts = new InspirationProducts();

        this._attachEvents();

        Page.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.inspiration + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        this.popup = new InspirationCasePopup({
            page: this,
            backUrl: Const.popupsBackUrl
        });

        this.popup.openPopup(data.apiUrl, data.url);
    }
});
