'use strict';

const Events = require('constants/Events');
const _ = require('underscore/underscore');
const Page = require('pages/Page/Page.js');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

const ProductsFilter = require('components/ProductsFilter/ProductsFilter');
const ProductsSections = require('components/ProductsSections/ProductsSections');
const ProductsMasterpieces = require('components/ProductsMasterpieces/ProductsMasterpieces');

const ProductMasterpiecePopup = require('popups/ProductMasterpiecePopup/ProductMasterpiecePopup');
const PageLayoutsPopup = require('popups/PageLayoutsPopup/PageLayoutsPopup');
const PageServicesPopup = require('popups/PageServicesPopup/PageServicesPopup');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./PageProducts.less');

const Selectors = {
    masterpieces: '.PageProducts-masterpieces',
    marketingItem: '.PageProducts-marketingItem',
    marketingItemUrl: '.PageProducts-marketingItemUrl'
};

const BindFunctions = [
    'onFiltered',
    'openPageLayouts',
    '_attachEvents',
    '_initVariables',
    'openPageServices',
    'openProductMasterpiecePopup'
];

const ChildViews = [
    {
        name: 'productsSections',
        Constructor: ProductsSections
    },
    {
        name: 'productsMasterpieces',
        Constructor: ProductsMasterpieces
    }
];

const ServiceType = 'service';

module.exports = Page.extend({

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.marketingItemUrl}`]: this.openPopup
        };
    },


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        const urlParams = new URLSearchParams(window.location.search);

        const preFiltered = {};

        for (let [key, value] of urlParams.entries()) {
            preFiltered[key] = value;
        }

        this._initVariables(options);
        this._initChildViews(ChildViews);

        this.productsFilter = new ProductsFilter({preFiltered});

        this._attachEvents();

        Page.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.products + Events.filter.filtered, this.onFiltered);
        app.vent.on(Events.eventPrefixes.layouts + Events.popup.popupOpen, this.openPageLayouts);
        app.vent.on(Events.eventPrefixes.services + Events.popup.popupOpen, this.openPageServices);
        app.vent.on(Events.eventPrefixes.masterpiece + Events.popup.popupOpen, this.openProductMasterpiecePopup);
        app.vent.on(Events.eventPrefixes.inspiration + Events.popup.popupOpen, this.openInspirationPopup);
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.popupUrl = '/products/product.html';
        this.popupLayoutsUrl = '/products/layouts.html';
        this.popupServicesUrl = '/products/services.html';
        this.backUrl = '/products.html';
        this.$masterPieces = this.$(Selectors.masterpieces);
    },

    onFiltered: function (countItems) {
        this.$masterPieces.toggleClass(Constants.cssClasses.hide, countItems !== -1);
    },

    openProductMasterpiecePopup: function (data) {
        const popup = new ProductMasterpiecePopup({
            page: this,
            backUrl: this.backUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openPageLayouts: function (data) {
        const popup = new PageLayoutsPopup({
            page: this,
            backUrl: this.backUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openPageServices: function (data) {
        const popup = new PageServicesPopup({
            page: this,
            backUrl: this.backUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openInspirationPopup: function (data) {
        const popup = new InspirationCasePopup({
            page: this,
            backUrl: this.backUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    },

    openPopup: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.marketingItem);
        const apiUrl = $target.data('api-url');
        const url = $target.data('url');
        const isService = $target.data('type') === ServiceType;

        if (isService) {
            this.openPageServices({apiUrl, url});

            return;
        }

        this.openPageLayouts({apiUrl, url});
    }
});
