var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../nunjucks.config.runtime.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["utils/macros.html"] = require( "utils/macros.html" );




var shim = require("/builds/ebiz/zg.website-design/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["assets/app/components/ProductFamilyItem/ProductFamilyItemDownloadLink.html"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
env.getTemplate("utils/macros.html", false, "assets/app/components/ProductFamilyItem/ProductFamilyItemDownloadLink.html", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("macros", t_1);
output += "\n\n<div class=\"ProductFamilyItem-dropdownLink\">\n    <a href=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"url"), env.opts.autoescape);
output += "\" class=\"ProductFamilyItem-dropdownLinkUrl\" download></a>\n    <div class=\"ProductFamilyItem-dropdownLinkIcon\">\n        ";
output += runtime.suppressValue((lineno = 5, colno = 33, runtime.callWrap(runtime.memberLookup((t_1),"svg_sprite"), "macros[\"svg_sprite\"]", context, ["file-icon",runtime.makeKeywordArgs({"caller": (function (){var macro_t_4 = runtime.makeMacro(
[], 
[], 
function (kwargs) {
var callerFrame = frame;
frame = frame.push(true);
kwargs = kwargs || {};
if (Object.prototype.hasOwnProperty.call(kwargs, "caller")) {
frame.set("caller", kwargs.caller); }
var t_5 = "";;
frame = frame.pop();
return new runtime.SafeString(t_5);
});
return macro_t_4;})()})])), env.opts.autoescape);
output += "\n    </div>\n    <div class=\"ProductFamilyItem-dropdownLinkTitle\">";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "data")),"title"), env.opts.autoescape);
output += "</div>\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
})});
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["assets/app/components/ProductFamilyItem/ProductFamilyItemDownloadLink.html"] , dependencies)