'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');

const Slider = require('components/MobileSlider/MobileSlider');

require('./ProductFamilyRoom.less');

const Selectors = {
    el: '.ProductFamilyRoom',
    item: '.ProductFamilyRoom-item',
    mobileEl: '.ProductFamilyRoom-mobile',
    arrowLeft: '.ProductFamilyRoom-arrowLeft',
    arrowRight: '.ProductFamilyRoom-arrowRight',
    wrapperInner: '.ProductFamilyRoom-wrapperInner',
    navLine: '.ProductFamilyRoom-navLine',
    navLines: '.ProductFamilyRoom-navLines',
    itemMobile: '.ProductFamilyRoom-itemMobile',
    image: '.ProductFamilyRoom-image'
};

const BindFunctions = [
    'setActiveItem',
    'initSlider',
    'onResize',
    '_attachEvents'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.item}`]: this.setActiveItem
        };
    },

    initialize: function () {
        _.bindAll(this, BindFunctions);

        this.initSlider();
        this._attachEvents();
    },

    _attachEvents: function () {
        window.app.els.$window.on(ViewportEvents.resize, this.onResize);
    },

    onResize: function () {
        this.initSlider();
    },

    initSlider: function () {
        if (window.app.isPhoneLayout()) {
            this.slider = new Slider({
                page: this,
                el: Selectors.mobileEl,
                arrowLeft: Selectors.arrowLeft,
                arrowRight: Selectors.arrowRight,
                innerWrapper: Selectors.wrapperInner,
                navLine: Selectors.navLine,
                singleItem: Selectors.itemMobile,
                activeClassName: Constants.cssClasses.active,
                clickableNav: true,
                withAnimationDesktop: true,
                infiniteDesktop: true
            });
        } else {
            this.$(`${Selectors.item}[data-id=1]`).addClass(Constants.cssClasses.active);
            this.$(`${Selectors.image}[data-id=1]`).addClass(Constants.cssClasses.active);
        }
    },

    setActiveItem: function (e) {
        const itemId = $(e.target).closest(Selectors.item)
            .data('id');
        this.$(Selectors.image).removeClass(Constants.cssClasses.active);
        this.$(Selectors.item).removeClass(Constants.cssClasses.active);
        this.$(`${Selectors.item}[data-id="${itemId}"]`).addClass(Constants.cssClasses.active);
        this.$(`${Selectors.image}[data-id="${itemId}"]`).addClass(Constants.cssClasses.active);
    }
});
