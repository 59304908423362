'use strict';

const Backbone = require('backbone');
const _ = require('underscore');
const { UnsupportedValueError } = require('errors');

require('./UpdateProfile.less');
const Dropdown = require('components/Common/Dropdown/Dropdown');
const InputField = require('components/Common/InputField/InputField');

const Selectors = {
    el: '.UpdateProfile',
    fields: '.UpdateProfile-field',
    emailField: '.UpdateProfile-field input[name="emailAddress"]'
};

const BindFunctions = [
    '_initChildComponents',
    '_addToModel',
    'onInputChange',
    'onSelectChange'
];

const Modifier = 'update-profile';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.page = options.page;
        this.model = {};
        this._initChildComponents();

        this.checkChanges();
        // The email-address is not supposed to be editable
        this.$el.find(Selectors.emailField).attr('disabled', true);
    },

    _initChildComponents: function () {
        this.$(Selectors.fields)
            .each((index, field) => {
                const $field = $(field);
                const name = $field.data('name');
                const type = $field.data('type');
                const value = $field.data('value');
                let inputField;
                if (type === 'input') {
                    inputField = new InputField({
                        onModelChange: this.onInputChange,
                        value,
                        modifier: Modifier,
                        id: index,
                        name
                    });
                } else {
                    new Dropdown({
                        modifier: Modifier,
                        id: index,
                        onChooseOption: this.onSelectChange,
                        value: value
                    });
                }

                this._addToModel(name, value, inputField);
            });
    },

    _addToModel: function (name, value, inputField) {
        this.model[name] = {
            value,
            defaultValue: value,
            inputField
        };
    },

    onSelectChange(id, idItem, itemType) {
        if (!itemType) {
            throw new UnsupportedValueError(idItem);
        }
        this.model[itemType].value = idItem;
        this.checkChanges();
    },

    onInputChange(name, value) {
        this.model[name].value = value;
        this.checkChanges();
    },

    checkChanges: function () {
        let wasModelChanged = false;
        let isChangesValid = true;
        const modelValues = Object.values(this.model);
        modelValues.forEach((modelValue) => {
            if (!wasModelChanged) {
                wasModelChanged = modelValue.value !== modelValue.defaultValue;
            }

            if (modelValue.inputField && isChangesValid) {
                isChangesValid = modelValue.inputField.isValid();
            }
        });

        const isSaveButtonEnabled = wasModelChanged && isChangesValid;
        isSaveButtonEnabled && this.page.onChange();
    },

    getValues() {
        return Object.entries(this.model)
            .reduce((res, [key, value]) => {
                res[key] = value.value;

                return res;
            }, {});
    }
});
