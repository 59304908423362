'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./LayoutsAllServices.less');

const Selectors = {
    tabLink: '.LayoutsAllServices-tabHref'
};

module.exports = Backbone.View.extend({

    el: '.LayoutsAllServices',

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.tabLink}`]: this.setActiveTab
        };
    },

    initialize: function (options = {}) {
        this.options = options;
    },

    setActiveTab: function (event) {
        event.preventDefault();

        const tabId = $(event.target).closest(Selectors.tabLink)
            .data('id');
        app.vent.trigger(Events.productServicesTabChange, tabId);
    }
});
