'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

require('./SearchCompanyItem.less');

const Template = require('./SearchCompanyItem.html');

const Selectors = {
    el: '.SearchCompanyItem'
};

const BindFunctions = [
    'render'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    render: function (data = {}) {
        return Template.render({data});
    }
});
