'use strict';

module.exports = {
    eventPrefixes: {
        product: 'product-',
        productFamily: 'product-family-',
        contact: 'contact-',
        contacts: 'contacts-',
        products: 'products-',
        imprint: 'imprint-',
        inspiration: 'inspiration-',
        layouts: 'layouts-',
        services: 'services-',
        masterpiece: 'masterpiece-',
        privacy: 'privacy-',
        regionLang: 'region-lang-',
        terms: 'terms-',
        index: 'index-',
        news: 'news-',
        careers: 'careers-',
        companyAnnual: 'company-annual-'
    },

    common: {
        fontsLoaded: 'fonts-loaded',
        scrollTop: 'scroll-top',
        splashBackgroundChange: 'splash-background-change',
        splashColorChange: 'splash-color-change'
    },

    search: {
        showSearch: 'show-search',
        hideSearch: 'hide-search',
        showSearchResult: 'show-search-result',
        searchShown: 'search-shown',
        searchHidden: 'search-hidden',
        showCloseButton: 'search-show-close-button',
        hideResults: 'search-hide-results'
    },

    menu: {
        showMenu: 'show-menu',
        hideMenu: 'hide-menu',
        menuShown: 'menu-shown',
        menuHidden: 'menu-hidden'
    },

    subMenuNav: {
        navShown: 'nav-shown',
        navHidden: 'nav-hidden'
    },

    overlay: {
        mobileOverlayClose: 'mobile-overlay-close'
    },

    filter: {
        onFilter: 'on-filter',
        filtered: 'filter-filtered',
        clearFilters: 'filter-clear-filters',
        mobileOpen: 'filter-mobile-open',
        mobileClose: 'filter-mobile-close',
        mobileChange: 'filter-mobile-change',
        mobileOnChange: 'filter-mobile-on-change',
        filterShown: 'filter-shown',
        filterHidden: 'filter-hidden',
        openMore: 'filter-open-more',
        toggleValidMobile: 'toggle-valid-mobile',
        closeButtonShow: 'close-button-show',
        closeButtonHide: 'close-button-hide',
        disableMobileAccessories: 'disable-mobile-accessories'
    },

    popup: {
        popupOpen: 'popup-open',
        popupShown: 'popup-shown',
        popupHidden: 'popup-hidden',
        popupShow: 'popup-show',
        hideCloseButton: 'close-button-hide',
        showCloseButton: 'close-button-show',
        halfPagePopupShown: 'half-page-popup-shown',
        halfPagePopupHidden: 'half-page-popup-hidden',
        inspirationPopupPrefix: 'inspiration-',
        newsPopupPrefix: 'news-',
        productDetailsPopupPrefix: 'product-details-',
        inspirationCasePopupPrefix: 'inspiration-case-',
        servicesPopupPrefix: 'services-',
        careersFactory: 'careers-factory-',
        careersJob: 'careers-job-',
        allOptions: 'all-options-'
    },

    videoPlayer: {
        openPlayer: 'video-player-open',
        closePlayer: 'video-player-close',
        youtubeApiReady: 'youtube-ready'
    },

    landings: {
        detachEvents: 'landings-detach-events',
        attachEvents: 'landings-attach-events'
    },

    vase3d: {
        sceneReady: 'vase-scene-ready'
    },

    productFamilyItemDropdown: {
        open: 'product-family-item-dropdown-open',
        close: 'product-family-item-dropdown-close',
        onClose: 'product-family-item-dropdown-on-close',
        setDownloadLinks: 'product-family-item-dropdown-set-download-links',
        setDraggableLinks: 'product-family-item-dropdown-set-draggable-links'
    },
    searchOverlayDataUpdated: 'search-overlay-data-updated',
    productServicesTabChange: 'product-services-tab-change'
};
