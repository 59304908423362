'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');

require('./AboutGlobalReach.less');

const Selectors = {
    aboutGlobalReach: '.AboutGlobalReach',
    link: '.AboutGlobalReach-link'
};

const BindFunctions = [
    'openContactOverlay'
];

module.exports = Backbone.View.extend({

    el: Selectors.aboutGlobalReach,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.link}`]: this.openContactOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
    },

    openContactOverlay: function (event) {
        event.preventDefault();

        app.views.contacts.show();
    }
});
