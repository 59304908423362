'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');
const Events = require('constants/Events');

const Cards = require('components/CompanyCards/CompanyCards');
const TopicCards = require('components/TopicCards/TopicCards');
const Accordion = require('components/Common/Accordion/Accordion');
const Link = require('components/Common/Link/Link');
const LatestFinancialReports = require('components/Common/LatestFinancialReports/LatestFinancialReports');
const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');
const NewsInsights = require('components/Common/NewsInsights/NewsInsights');
const PressLandingHead = require('components/PressLandingHead/PressLandingHead');
const IndexApplications = require('components/IndexApplications/IndexApplications');
const CareersEmployeeExperience = require('components/CareersEmployeeExperience/CareersEmployeeExperience');

require('./PageFlexibleLanding.less');

const ChildViews = [
    {
        name: 'pressLandingHead',
        Constructor: PressLandingHead
    },
    {
        name: 'link',
        Constructor: Link
    },
    {
        name: 'reports',
        Constructor: LatestFinancialReports
    },
    {
        name: 'news',
        Constructor: NewsInsights
    },
    {
        name: 'cards',
        Constructor: Cards
    },
    {
        name: 'employeeExperience',
        Constructor: CareersEmployeeExperience
    },
    {
        name: 'topicCards',
        Constructor: TopicCards
    },
    {
        name: 'indexApplications',
        Constructor: IndexApplications
    }
];

/*const Selectors = {
    newsBlock: '.PageFlexibleLanding-newsBlock'
};*/

const BindFunctions = [
    'openPopup'
];

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initChildViews(ChildViews);


        this.accordion = new Accordion({
            modifier: 'sustainability'
        });

        PageLanding.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);

    //    this.$lastBlockAbsoluteTriggerElement = this.$(Selectors.newsBlock);
    //    this.scrollMagicTriggerHook = 0;
    },

    _attachEvents: function () {
        PageLanding.prototype._attachEvents.call(this);

        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    }
});
