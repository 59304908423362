'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');

const InspirationPopup = require('popups/InspirationCasePopup/InspirationCasePopup');

require('./LayoutsGetInspired.less');


const BindFunctions = [
    'openInspirationOverlay'
];

const Selectors = {
    el: '.LayoutsGetInspired',
    inspirationItemLink: '.LayoutsGetInspired-item a'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.inspirationItemLink}`]: this.openInspirationOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
    },

    openInspirationOverlay: function (event) {
        event.preventDefault();

        const $target = $(event.target)
            .closest(Selectors.inspirationItemLink);
        const url = $target
            .data('url');

        const apiUrl = $target.data('api-url');

        const popup = new InspirationPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
