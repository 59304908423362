'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const FourColumnFacts = require('components/Common/FourColumnFacts/FourColumnFacts');
const GlobalPageTitle = require('components/Common/GlobalPageTitle/GlobalPageTitle');
const GlobalMenuMobile = require('components/Common/GlobalMenuMobile/GlobalMenuMobile');

require('./CareersLandingHead.less');

const Selectors = {
    title: '.GlobalPageTitle',
    facts: '.CareersLandingHead-facts',
    overlay: '.CareersLandingHead-overlay',
    arrow: '.CareersLandingHead-arrow'
};

const BindFunctions = [
    '_initChildComponents',
    '_attachEvents',
    'onSubMenuShown',
    'onSubMenuHidden',
    '_initVariables',
    '_toggleSubMenu',
    'onScroll'
];

const ScrollOffset = 50;

module.exports = Backbone.View.extend({

    el: '.CareersLandingHead',

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrow}`]: this.scrollDown
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        window.app.utils.fixHeightOnIos(this.$el);

        this._initChildComponents();
        this._initVariables();
        this._attachEvents();

        setTimeout(function () {
            self.$(Selectors.arrow)
                .addClass(Constants.cssClasses.show);
        }, 500);
    },

    _attachEvents: function () {
        window.app.vent.on(Events.subMenuNav.navShown, this.onSubMenuShown);
        window.app.vent.on(Events.subMenuNav.navHidden, this.onSubMenuHidden);
        window.app.els.$window.on(ViewportEvents.scroll, this.onScroll);
    },

    _initVariables: function () {
        this.$title = this.$(Selectors.title);
        this.$facts = this.$(Selectors.facts);
        this.$overlay = this.$(Selectors.overlay);
    },

    _toggleSubMenu: function (show) {
        this.$title.toggleClass(Constants.cssClasses.hide, show);
        this.$facts.toggleClass(Constants.cssClasses.hide, show);
        this.$overlay.toggleClass(Constants.cssClasses.show, show);

        const event = show ? Events.popup.popupShown : Events.popup.popupHidden;
        if (show) {
            this.savedScroll = $(window)
                .scrollTop();
        }
        const scroll = show ? 0 : this.savedScroll;
        app.vent.trigger(event);
        $(window)
            .scrollTop(scroll);
    },

    onSubMenuHidden: function () {
        this._toggleSubMenu(false);
    },

    onSubMenuShown: function () {
        this._toggleSubMenu(true);
    },

    _initChildComponents: function () {
        this.facts = new FourColumnFacts({
            page: this
        });
        this.title = new GlobalPageTitle({
            page: this
        });
        this.menu = new GlobalMenuMobile({
            page: this
        });
    },

    onScroll: function () {
        this.$(Selectors.arrow)
            .toggleClass(Constants.cssClasses.hide, window.app.els.$window.scrollTop() > ScrollOffset);
    },

    scrollDown: function () {
        window.app.els.$htmlBody
            .animate({scrollTop: window.app.sizes.windowHeight}, 600);
    }
});
