'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');
const Accordion = require('components/Common/Accordion/Accordion');

require('./PageCompanyBoard.less');

const Selectors = {
    el: '.PageCompanyBoard'
};

const AccordionModifier = 'pageCompanyBoard';

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;
        this.accordion = new Accordion({
            modifier: AccordionModifier
        });
        PageGlobalTemplate.prototype.initialize.call(this, options);
    }
});
