'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');

require('./MobileSlider.less');

const defaultOptions = {
    singleItem: '.MobileSlider-image',
    arrowRight: '.MobileSlider-arrowRight',
    arrowLeft: '.MobileSlider-arrowLeft',
    navLine: '.MobileSlider-navLine',
    innerWrapper: '.MobileSlider-imageWrapperInner',
    activeClassName: 'active',
    clickableNav: false,
    transitionCoefficient: 1,
    infiniteDesktop: false
};

const BindFunctions = [
    'setActiveImageDesktop',
    'setNextActiveImageDesktop',
    'setPrevActiveImageDesktop',
    'setCurActiveImageDesktop',
    'onTouchStart',
    'onTouchMove',
    'onTouchEnd',
    'nextPictureSwipe',
    'prevPictureSwipe',
    'onNavLineTouch',
    'setActiveNavLine',
    '_attachEvents',
    'initSlideAnimationClasses',
    'prepareForNextAnimation',
    'getOption',
    'getJqueryOption',
    'getZeroTouchFromEvent',
    'getSwipeCoefficient',
    'getSwipeDirection',
    'getChangedTouchFromEvent',
    'getTransitionWidth',
    '_initVariables',
    'setTransitionWidth'
];

module.exports = Backbone.View.extend({

    el: '.MobileSlider',

    events: {},

    /**
     *
     *
     * @param options = {
     *     el - main mobile slider wrapper. Must contain image wrapper and image inner wrapper inside
     *     singleItem - single image wrapper class
     *     arrowRight - arrow right class (for desktop version with mobile resolution)
     *     arrowLeft - arrow left class (for desktop version with mobile resolution)
     *     navLine - single nav line class,
     *     innerWrapper - inner image wrapper class,
     *     activeClassName - name of the class used in CSS to make items or nav lines active
     *                      (optional. Default is 'active')
     *     transitionCoefficient - if need to shift on swipe more or less than one item width,
     *     imageCount - different image count from $(item).length,
     *     infiniteDesktop - if carousel is looped,
     *     callback - function called when swipe is done. Takes 1 argument - active slide number
     *     withAnimationDesktop - is slide change needed animation,
     *     transitionWidth - exact width of one transition
     * }
     */
    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);

        if (!options.el) {
            this.setElement('.MobileSlider--' + options.index);
        }

        // set the first nav line active by default
        this.setActiveNavLine(this.activeImage);

        // if desktop with mobile resolution, then apply desktop logic (with arrows without touch events)
        if (window.app.settings.isDesktop) {
            this.setActiveImageDesktop(this.activeImage);
            if (!this.infiniteDesktop) {
                this.$arrowLeft.hide();
            }
        }

        this.imageWrapperShift = this.getTransitionWidth();
        this.$imageInnerWrapper = options.innerWrapper ?
            this.$(options.innerWrapper) : this.$(defaultOptions.innerWrapper);

        this.currentShift = 0;
        this.swipeCoefficient = 1;


        this._attachEvents();

        if (this.withAnimationDesktop) {
            this.initSlideAnimationClasses();
        }
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.activeImage = 1;
        this.singleItem = this.getOption(options.singleItem, defaultOptions.singleItem);
        this.$singleItem = this.$(this.singleItem);
        this.imageCount = this.getOption(options.imageCount, this.$singleItem.length);
        this.$arrowRight = this.getJqueryOption(options.arrowRight, defaultOptions.arrowRight);
        this.$arrowLeft = this.getJqueryOption(options.arrowLeft, defaultOptions.arrowLeft);
        this.$navLine = this.getJqueryOption(options.navLine, defaultOptions.navLine);
        this.navLine = this.getOption(options.navLine, defaultOptions.navLine);
        this.activeClassName = this.getOption(options.activeClassName, defaultOptions.activeClassName);
        this.clickableNav = this.getOption(options.clickableNav, defaultOptions.clickableNav);
        this.transitionCoefficient = this.getOption(options.transitionCoefficient,
            defaultOptions.transitionCoefficient);
        this.infiniteDesktop = this.getOption(options.infiniteDesktop, defaultOptions.infiniteDesktop);
        this.callback = this.getOption(options.callback, undefined);
        this.withAnimationDesktop = this.getOption(options.withAnimationDesktop, false);
        this.transitionWidth = this.getOption(options.transitionWidth, undefined);

        // if swipe in progress
        this.isSwipe = false;
    },

    getTransitionWidth: function () {
        return this.transitionWidth || this.$singleItem.innerWidth() * this.transitionCoefficient;
    },

    setTransitionWidth: function (transitionWidth) {
        this.transitionWidth = transitionWidth || this.$singleItem.innerWidth() * this.transitionCoefficient;
        this.imageWrapperShift = this.transitionWidth;
    },

    getJqueryOption: function (option, defaultOption) {
        return option ? this.$(option) : this.$(defaultOption);
    },

    getOption: function (option, defaultOption) {
        return option ? option : defaultOption;
    },

    initSlideAnimationClasses: function () {
        for (let i = 1; i <= this.imageCount; i++) {
            const $slide = this.$(this.singleItem + '[data-id=' + i + ']');

            if (i === this.imageCount) {
                $slide.addClass('prev');
            } else {
                if (i > 1) {
                    $slide.addClass('next');
                }
            }
        }
    },

    prepareForNextAnimation: function () {
        this.$singleItem.removeClass('prev');
        this.$singleItem.removeClass('next');

        let nextSlideNum;
        let prevSlideNum;

        if (this.activeImage === this.imageCount) {
            nextSlideNum = 1;
        } else {
            nextSlideNum = this.activeImage + 1;
        }

        if (this.activeImage === 1) {
            prevSlideNum = this.imageCount;
        } else {
            prevSlideNum = this.activeImage - 1;
        }

        const $nextSlide = this.$(this.singleItem + '[data-id=' + nextSlideNum + ']');
        const $prevSlide = this.$(this.singleItem + '[data-id=' + prevSlideNum + ']');

        $nextSlide.addClass('next');
        $prevSlide.addClass('prev');
    },

    _attachEvents: function () {
        if (window.app.settings.isDesktop) {
            this.$arrowRight.click(this.setNextActiveImageDesktop);
            this.$arrowLeft.click(this.setPrevActiveImageDesktop);
            this.$singleItem.click(this.setNextActiveImageDesktop);
            if (this.clickableNav) {
                if (window.app.isPhoneLayout()) {
                    this.$navLine.click(this.onNavLineTouch);
                } else {
                    this.$navLine.click(this.setCurActiveImageDesktop);
                }
            }
        }

        this.$imageInnerWrapper.on('touchstart', this.onTouchStart);
        this.$imageInnerWrapper.on('touchmove', this.onTouchMove);
        this.$imageInnerWrapper.on('touchend', this.onTouchEnd);
        if (this.clickableNav) {
            this.$navLine.on('touchstart', this.onNavLineTouch);
        }
    },

    setActiveImageDesktop: function (number) {
        const $images = this.$singleItem;
        const $image = this.$(this.singleItem + '[data-id=' + number + ']');

        if (this.callback) {
            this.callback(this.activeImage);
        }

        $images.removeClass(this.activeClassName);
        $image.addClass(this.activeClassName);

        if (this.withAnimationDesktop) {
            this.prepareForNextAnimation();
        }
    },

    setActiveNavLine: function (number) {
        const $lines = this.$navLine;
        const $navLine = this.$(this.navLine + '[data-id=' + number + ']');

        $lines.removeClass(this.activeClassName);
        $navLine.addClass(this.activeClassName);
    },

    checkArrows: function () {
        if (this.infiniteDesktop) {
            return;
        }
        if (this.activeImage === this.imageCount) {
            if (!this.infinite) {
                this.$arrowRight.hide();
            }
        } else {
            this.$arrowRight.show();
        }
        if (this.activeImage === 1) {
            if (!this.infinite) {
                this.$arrowLeft.hide();
            }
        } else {
            this.$arrowLeft.show();
        }
    },

    setNextActiveImageDesktop: function () {
        this.activeImage++;
        if (this.activeImage > this.imageCount) {
            this.activeImage = 1;
        }

        this.checkArrows();
        if (this.infinite && this.activeImage > this.imageCount) {
            this.activeImage = 1;
        }
        this.setActiveImageDesktop(this.activeImage);
        this.setActiveNavLine(this.activeImage);
    },

    setPrevActiveImageDesktop: function () {
        this.activeImage--;
        if (this.activeImage < 1) {
            this.activeImage = this.imageCount;
        }

        this.checkArrows();
        if (this.infinite && this.activeImage < 1) {
            this.activeImage = this.imageCount;
        }
        this.setActiveImageDesktop(this.activeImage);
        this.setActiveNavLine(this.activeImage);
    },

    setCurActiveImageDesktop: function (e) {
        this.activeImage = parseInt($(e.target)
            .attr('data-id'), 10);
        this.checkArrows();
        this.setActiveImageDesktop(this.activeImage);
        this.setActiveNavLine(this.activeImage);
    },

    onNavLineTouch: function (e) {
        this.activeImage = parseInt($(e.target)
            .attr('data-id'), 10);
        this.setActiveNavLine(this.activeImage);
        this.currentShift = -(this.activeImage - 1) * this.imageWrapperShift;
        this.$imageInnerWrapper.css('transform', 'translate3d(' + this.currentShift + 'px, 0, 0)');
    },

    onTouchStart: function (e) {
        if (e && e.originalEvent && e.originalEvent.touches && e.originalEvent.touches[0]) {
            this.touchStartX = e.originalEvent.touches[0].clientX;
            this.touchStartY = e.originalEvent.touches[0].clientY;
            this.startTime = new Date().getTime();
        }
    },

    getZeroTouchFromEvent: function (e) {
        return e && e.originalEvent && e.originalEvent.touches && e.originalEvent.touches[0];
    },

    getChangedTouchFromEvent: function (e) {
        return e && e.changedTouches && e.changedTouches[0] && e.changedTouches[0].clientX;
    },

    getSwipeCoefficient: function (direction) {
        if (direction === 1 && (this.activeImage) === (this.imageCount)) {
            return 0.3;
        }

        if (direction === -1 && this.activeImage === 1) {
            return 0.3;
        }

        return 1;
    },

    getSwipeDirection: function (xStart, xEnd) {
        return (xStart - xEnd > 0) ? 1 : -1;
    },

    onTouchMove: function (e) {
        const touch = this.getZeroTouchFromEvent(e);
        if (touch) {
            if (!this.isTransitionInProgress) {
                this.touchMoveX = touch.clientX;
                this.touchMoveY = touch.clientY;
                let deltaX;
                let deltaY;

                if (!this.isSwipe) {
                    deltaX = Math.abs(this.touchStartX - this.touchMoveX);
                    deltaY = Math.abs(this.touchStartY - this.touchMoveY);
                }

                if ((deltaX > deltaY) || this.isSwipe) {
                    this.isSwipe = true;
                    e.preventDefault();
                    e.stopPropagation();

                    const direction = this.getSwipeDirection(this.touchStartX, this.touchMoveX);

                    this.swipeCoefficient = this.getSwipeCoefficient(direction);

                    this.liveShift = 0;

                    this.liveShift = this.currentShift +
                        ((this.touchMoveX - this.touchStartX) * this.swipeCoefficient);

                    this.$imageInnerWrapper.css('transform', 'translate3d(' + this.liveShift + 'px, 0, 0)');
                    this.$imageInnerWrapper.css('transition', 'none');
                }
            }
        }
    },

    onTouchEnd: function (e) {
        if (this.isSwipe) {
            this.isSwipe = false;
            e.preventDefault();
            e.stopPropagation();
            this.isTransitionInProgress = true;
            const touchEndX = this.getChangedTouchFromEvent(e);
            this.$imageInnerWrapper.css('transition', '');

            const delta = Math.abs(this.touchStartX - touchEndX);
            const direction = this.getSwipeDirection(this.touchStartX, touchEndX);
            const endTime = new Date().getTime();
            if (Math.abs(endTime - this.startTime) > 500) {
                if (delta > this.imageWrapperShift / 3) {
                    this.pictureSwipe(direction);
                } else {
                    this.$imageInnerWrapper
                        .css(
                            'transform',
                            'translate3d(' + (-(this.activeImage - 1) * this.imageWrapperShift) + 'px, 0, 0)'
                        );
                }
            } else {
                this.pictureSwipe(direction);
            }

            this.isTransitionInProgress = false;
        }
    },

    pictureSwipe: function (direction) {
        if (direction === 1) {
            this.nextPictureSwipe();
        } else {
            this.prevPictureSwipe();
        }
    },

    nextPictureSwipe: function () {
        if (this.activeImage < this.imageCount) {
            this.activeImage++;
            this.setActiveNavLine(this.activeImage);
            this.currentShift = -(this.activeImage - 1) * this.imageWrapperShift;
            this.$imageInnerWrapper.css('transform', 'translate3d(' + this.currentShift + 'px, 0, 0)');
            if (this.callback) {
                this.callback(this.activeImage);
            }
        } else {
            this.$imageInnerWrapper
                .css('transform', 'translate3d(' + (-(this.imageCount - 1) * this.imageWrapperShift) + 'px, 0, 0)');
        }
    },

    prevPictureSwipe: function () {
        if (this.activeImage > 1) {
            this.activeImage--;
            this.setActiveNavLine(this.activeImage);
            this.currentShift = -(this.activeImage - 1) * this.imageWrapperShift;
            this.$imageInnerWrapper.css('transform', 'translate3d(' + this.currentShift + 'px, 0, 0)');
            if (this.callback) {
                this.callback(this.activeImage);
            }
        } else {
            this.$imageInnerWrapper.css('transform', 'translate3d(' + 0 + 'px, 0, 0)');
        }
    }
});
