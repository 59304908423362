'use strict';

const _ = require('underscore');
const PageLanding = require('pages/PageLanding/PageLanding.js');
const Events = require('constants/Events');
const Constants = require('constants/Constants');

const NewsLandingHead = require('components/NewsLandingHead/NewsLandingHead');
const NewsLandingItems = require('components/NewsLandingItems/NewsLandingItems');
const RowFilter = require('components/Common/RowFilter/RowFilter');

const NewsPopup = require('popups/NewsStoryPopup/NewsStoryPopup');

require('./PageNewsLanding.less');

const ChildViews = [
    {
        name: 'newsLandingHead',
        Constructor: NewsLandingHead
    }
];

const BindFunctions = [
    '_initRowFilter',
    'onFilter',
    'getDataFromApi',
    'onShowMoreButtonClick',
    'renderNewItems',
    '_attachEvents',
    'openPopup'
];

const Selectors = {
    items: '.NewsLandingItems'
};

module.exports = PageLanding.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        const urlParams = new URLSearchParams(window.location.search);
        const preFiltered = urlParams.get(Constants.queryStringParams.category) || Constants.filter.noFilterValue;

        this._initChildViews(ChildViews);
        this._initRowFilter(preFiltered);
        this._initItemsComponent();

        PageLanding.prototype.initialize.call(this, options);
    },

    openPopup: function (data) {
        this.detachOnScroll();
        const popup = new NewsPopup({
            page: this,
            backUrl: '/news.html'
        });

        popup.openPopup(data.apiUrl, data.url, this.attachOnScroll);
    },

    _initRowFilter: function (preFilteredId) {
        this.currentFilterId = preFilteredId;
        this.rowFilter = new RowFilter({
            preFiltered: preFilteredId,
            callback: this.onFilter
        });
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.news + Events.popup.popupOpen, this.openPopup);

        PageLanding.prototype._attachEvents.call(this);
    },

    _initItemsComponent: function () {
        this.itemsComponent = new NewsLandingItems({
            onShowMoreButtonClick: this.onShowMoreButtonClick
        });
    },

    _initVariables: function () {
        PageLanding.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    },

    getDataFromApi: function (callback, params) {
        // Get parameters to send them to the server
        const newFilterId = params.newFilterId || Constants.filter.noFilterValue;
        const offset = (Constants.queryStringParams.offset in params) ?
            params[Constants.queryStringParams.offset] : this.itemsComponent.getCurrentOffset();

        // data is needed to load new items from server according to
        // current filter value and offset (when show more button is clicked)
        const data = {
            filter: newFilterId,
            offset
        };

        $.getJSON(params.apiUrl, data, (res) => {
            if (!res) {
                return;
            }

            const socialApiUrl = this.$(Selectors.items)
                .data('social-api-url');

            $.getJSON(socialApiUrl, (socialItems) => {
                if (!socialItems) {
                    return;
                }

                res.socialItems = socialItems;

                callback(res, params);
            });
        });
    },

    onFilter: function (newFilterId, apiUrl) {
        this.currentFilterId = newFilterId;

        this.getDataFromApi(this.renderNewItems,
            {
                isNeedRerender: true,
                newFilterId: this.currentFilterId,
                offset: 0,
                apiUrl
            });
    },

    renderNewItems: function (res, params) {
        this.itemsComponent.renderNewItems(res, params.isNeedRerender);
        if (this.currentFilterId === Constants.filter.noFilterValue) {
            window.history.replaceState(null, null, window.location.pathname);

            return;
        }

        const queryStr = `?${Constants.queryStringParams.category}=${this.currentFilterId}`;
        window.history.replaceState(null, null, queryStr);
    },

    onShowMoreButtonClick: function (apiUrl) {
        this.getDataFromApi(this.renderNewItems,
            {
                isNeedRerender: false,
                newFilterId: this.currentFilterId,
                apiUrl
            });
    }
});
