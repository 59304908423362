'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const GlobalSlider = require('components/GlobalSlider/GlobalSlider');
const ViewportEvents = require('constants/ViewportEvents');
const CampusCenterPopup = require('popups/CampusCenterPopup/CampusCenterPopup');

require('./CampusLightCenters.less');

const Selectors = {
    el: '.CampusLightCenters',
    place: '.CampusLightCenters-place'
};

const BindFunctions = [
    'openCenterOverlay'
];

const CampusLightCentersModifier = 'campus-light-centers';

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.place}`]: this.openCenterOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.globalSlider = new GlobalSlider({
            modifier: CampusLightCentersModifier
        });
    },

    openCenterOverlay: function (event) {
        event.preventDefault();
        const $target = $(event.target)
            .closest(Selectors.place);
        const url = $target.data('url');
        const apiUrl = $target.data('api-url');

        const popup = new CampusCenterPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    }
});
