'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Slider = require('components/MobileSlider/MobileSlider');

require('./SocialNetworkLine.less');

const Selectors = {
    el: '.SocialNetworkLine',
    item: '.SocialNetworkLine-item',
    innerWrapper: '.SocialNetworkLine-inner'
};

const BindFunctions = [
    '_initMobileSlider'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this._initMobileSlider();
    },

    _initMobileSlider: function () {
        this.slider = new Slider({
            page: this,
            el: Selectors.el,
            innerWrapper: Selectors.innerWrapper,
            singleItem: Selectors.item,
            transitionWidth: this.$(`${Selectors.innerWrapper} ${Selectors.item}`)
                .outerWidth(true)
        });
    }
});
