'use strict';

const _ = require('underscore/underscore.js');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');

let _popupLinkClasses;

module.exports = {
    blockScrollOnSwipe,
    PageVisibilityManager: createPageVisibilityManager(),
    splitMultilineToSeparateSpans,
    waitForTransitionEnd,
    startImagesRotation,
    fixHeightOnIos,
    onVisibileInViewport,
    setMetaTags,
    addMetaTag,
    setTitle,
    changeHash,
    removeHash,
    attachClickOnAnchorLinks,
    initPopupLinkClasses,
    attachClickOnPopupLinks,
    onPopupLinkClick,
    addQueryParameter,
    removeQueryParameter,
    clearQueryParameters,
    getQueryParameterValue,
    onAnchorClick,
    resetQueryParameters,
    openPopup
};

function blockScrollOnSwipe(el) {
    if (!el) {
        return;
    }
    let x;
    let y;
    let scroll;

    const onTouchStart = function (e) {
        if (e.touches.length > 1) {
            return;
        }

        scroll = undefined;
        x = e.touches[0].pageX;
        y = e.touches[0].pageY;
    };

    const onTouchMove = function (e) {
        if (scroll === 'v') {
            return;
        }

        const dX = e.touches[0].pageX - x;
        const dY = e.touches[0].pageY - y;

        if (scroll === 'h') {
            e.preventDefault();
        } else if (Math.abs(dX) > 5 || Math.abs(dY) > 5) {
            scroll = Math.abs(dX) > Math.abs(dY) ? 'h' : 'v';
        }
    };

    el.addEventListener('touchstart', onTouchStart, {passive: false});
    el.addEventListener('touchmove', onTouchMove, {passive: false});
}

function createPageVisibilityManager() {
    // Set the name of the hidden property and the change event for visibility
    let hidden;
    let visibilityChange;
    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof document.mozHidden !== 'undefined') {
        hidden = 'mozHidden';
        visibilityChange = 'mozvisibilitychange';
    } else if (typeof document.msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof document.webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    const add = function (callback) {
        if (!hidden) {
            return false;
        }
        document.addEventListener(visibilityChange, callback);
    };

    const remove = function (callback) {
        if (!hidden) {
            return false;
        }
        document.removeEventListener(visibilityChange, callback);
    };

    const isHidden = function () {
        return document[hidden];
    };

    return {
        addEventListener: add,
        removeEventListener: remove,
        isPageHidden: isHidden
    };
}

function splitMultilineToSeparateSpans(params) {
    //fix container height to prevent other content on page repaint
    const height = params.$container.height();
    params.$container.css({
        overflow: 'hidden',
        height: height
    });

    //remove previous division
    params.$container.empty();

    //split text to words
    const words = params.text.split(' ');
    let line = '';
    let prevLine = '';
    let $span;
    let oneLineHeight;

    for (let i = 0; i < words.length; i++) {
        prevLine = line;
        line += words[i] + ' ';

        if (!prevLine) {
            //in some rare cases new line doesn't go on new line (cause last space in line doesn't render)
            if ($span) {
                $('<br>')
                    .appendTo(params.$container);
            }
            $span = $('<span>')
                .addClass(params.className)
                .appendTo(params.$container);
        }

        $span.text(line);

        if (!prevLine) {
            oneLineHeight = $span.height();
        }

        //if current word jumps to second line
        if ($span.height() > oneLineHeight * 1.5) {
            $span.text(prevLine);
            i--;
            line = '';
        }
    }

    //reset fixed height
    params.$container.css({
        overflow: '',
        height: ''
    });
}

function waitForTransitionEnd($obj, property, cb, safeTimeout) {
    const transEndEventNames = {
        'transition': 'transitionend',
        'WebkitTransition': 'webkitTransitionEnd',
        'MozTransition': 'transitionend',
        'OTransition': 'oTransitionEnd',
        'msTransition': 'MSTransitionEnd'
    };

    const getTransitionEndName = function () {
        if (!window.getComputedStyle || !document.documentElement) {
            return 'transitionend';
        }

        const styles = window.getComputedStyle(document.documentElement, '');

        for (let i in transEndEventNames) {
            if (styles[i] !== undefined) {
                return transEndEventNames[i];
            }
        }

        return 'transitionend';
    };

    const transEndEventName = getTransitionEndName();

    let transitionSafeTimeout = 0;

    const transitionEndCallback = function (e) {
        if (e) {
            const prop = e.originalEvent.propertyName.toLowerCase();
            if (!$(e.target)
                .is($obj)) {
                return;
            }
            if (prop.indexOf(property) === -1) {
                return;
            }
        }

        $obj.off(transEndEventName, transitionEndCallback);

        clearTimeout(transitionSafeTimeout);

        cb();
    };

    const resetAllHandlers = function () {
        $obj.off(transEndEventName, transitionEndCallback);

        clearTimeout(transitionSafeTimeout);
    };


    $obj.on(transEndEventName, transitionEndCallback);

    transitionSafeTimeout = setTimeout(transitionEndCallback, safeTimeout);

    return resetAllHandlers;
}

function startImagesRotation(self, parentSelector, duration, delay) {
    let current = 0;
    let images = [];
    let zIndex = 1;
    let state = {};
    let startTimeout;
    let nextTimeout;

    const loadNext = function () {
        const next = (current + 1) % images.length;
        if (images[next].isLoadingStarted) {
            return;
        }

        images[next].$image
            .each(function () {
                $(this)
                    .attr('srcset', $(this)
                        .attr('data-srcset'));
                $(this)
                    .removeAttr('data-srcset');
            });

        images[next].$image.attr('src', images[next].$image.attr('data-src'));
        images[next].$image.removeAttr('data-src');
        images[next].$image.css('display', '');

        images[next].isLoadingStarted = true;
    };

    const start = function () {
        clearTimeout(startTimeout);
        clearInterval(nextTimeout);

        startTimeout = setTimeout(function () {
            nextTimeout = setInterval(function () {
                images[current].$image.addClass('fade-out');

                current = (current + 1) % images.length;
                images[current].$image
                    .removeClass('fade-out')
                    .addClass('fade-in')
                    .css('z-index', ++zIndex);

                setTimeout(function () {
                    images[current].$image.removeClass('fade-in');
                }, 17);

                loadNext();
            }, duration);
        }, delay);
    };

    self.$(parentSelector + ' img')
        .each(function () {
            images.push({
                isLoadingStarted: !!$(this)
                    .attr('src'),
                $image: $(this)
            });
        });

    setTimeout(loadNext, 500 + delay);
    start();


    return {
        pause: function (tp) {
            if (state[tp] === 'paused') {
                return;
            }

            clearTimeout(startTimeout);
            clearInterval(nextTimeout);

            state[tp] = 'paused';
        },
        resume: function (tp) {
            if (state[tp] === 'playing') {
                return;
            }
            state[tp] = 'playing';

            const isAllPlaying = _.every(state, function (item) {
                return item === 'playing';
            });

            isAllPlaying && start();
        }
    };
}

function fixHeightOnIos($el, percentage = 100) {
    let viewportHeight = $(window)
        .height();
    const scaledHeight = viewportHeight / 100 * percentage;
    $el.css('height', `${scaledHeight}px`);
}

function onVisibileInViewport($el, callback) {
    if ('IntersectionObserver' in window) {
        const imageObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    const item = entry.target;
                    callback();
                    imageObserver.unobserve(item);
                }
            });
        });

        imageObserver.observe($el[0]);
    } else {
        const checkVisible = _.throttle(function () {
            if ($el.offset().top < ($(window)
                .height() + $(window)
                .scrollTop())) {
                callback();
                $(window)
                    .off('resize scroll orientationchange', checkVisible);
            }
        }, 100);

        $(window)
            .on('resize scroll orientationchange', checkVisible);

        checkVisible();
    }
}

function setMetaTags(metaList) {
    $('meta')
        .remove();
    metaList.forEach(({kind, name, value}) => {
        addMetaTag(kind, name, value);
    });
}

function addMetaTag(kind, name, value) {
    const meta = document.createElement('meta');
    meta.setAttribute(kind, name);
    meta.setAttribute('content', value);
    $('head')
        .append(meta);
}

function setTitle(title) {
    document.title = title;
}

function changeHash(newHash) {
    window.app.state.isHashChanging = true;
    window.location.hash = newHash;
    setTimeout(() => {
        window.app.state.isHashChanging = false;
    });
}

function removeHash() {
    const hash = '#/';
    window.app.utils.changeHash(hash);
}

function attachClickOnAnchorLinks() {
    $(Constants.selectors.anchorLink)
        .off(ViewportEvents.click, onAnchorClick);
    $(Constants.selectors.anchorLink)
        .on(ViewportEvents.click, onAnchorClick);
}

function initPopupLinkClasses(popupLinkClasses) {
    // can't import popup constructors in Utils, because Utils are imported a way earlier
    // that _.extend function exist so when importing popup BasePopup.extend is not a function
    // raised, so store popupLinkClasses in a variable
    _popupLinkClasses = popupLinkClasses;
}

function attachClickOnPopupLinks() {
    $('a')
        .off(ViewportEvents.click, onPopupLinkClick)
        .on(ViewportEvents.click, onPopupLinkClick);
}

function onPopupLinkClick(event) {
    const $target = $(event.target)
        .closest('a');
    _popupLinkClasses.forEach((popupLinkClass) => {
        if (!$target.hasClass(popupLinkClass.linkClass)) {
            return;
        }
        event.preventDefault();
        const apiUrl = $target.data('api-url');
        const url = $target.data('url');
        const popupConstructor = popupLinkClass.popupConstructor;
        openPopup(popupConstructor, apiUrl, url);
    });
}

function addQueryParameter(key, value = '') {
    if (!key) {
        return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.append(key, value);
    let urlSearchParamsString = urlParams.toString();
    if (!value) {
        urlSearchParamsString = urlSearchParamsString.replace('=', '');
    }
    clearQueryParameters();
    const newUrl = `${window.location.href}?${urlSearchParamsString}`;
    window.history.pushState({}, '', newUrl);
}

function removeQueryParameter(key) {
    if (!key) {
        return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(key);
    const separator = [...urlParams].length ? '?' : '';
    clearQueryParameters();
    const newUrl = `${window.location.href}${separator}${urlParams.toString()}`;
    window.history.pushState({}, '', newUrl);
}

function clearQueryParameters() {
    const urlParamsString = window.location.search;
    const newUrl = window.location.href.replace(urlParamsString, '');
    window.history.pushState({}, '', newUrl);
}

function getQueryParameterValue(key) {
    if (!key) {
        return null;
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has(key)) {
        return null;
    }

    return urlParams.get(key);
}

function onAnchorClick(event) {
    event.preventDefault();
    const aid = $(this)
        .attr('href');
    const scrollTop = $(aid)[0]
        .offsetTop - 50;
    const scrollElementSelector =
        window.app.commonSelectors.popupWrapper;
    $(scrollElementSelector)
        .animate({scrollTop: scrollTop}, 'slow');
}

function resetQueryParameters() {
    window.history.pushState(null, '', window.location.pathname);
}

function openPopup(popupConstructor, apiUrl, url) {
    const popup = new popupConstructor();

    popup.openPopup(apiUrl, url);
}
