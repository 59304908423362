'use strict';

module.exports = {
    click: 'click',
    mouseEnter: 'mouseenter',
    mouseLeave: 'mouseleave',
    mouseUp: 'mouseup',
    mouseDown: 'mousedown',
    mouseMove: 'mousemove',
    focus: 'focus',
    blur: 'blur',
    input: 'input',
    keyUp: 'keyup',
    change: 'change',
    mouseOver: 'mouseover',
    touchStart: 'touchstart',
    touchMove: 'touchmove',
    touchEnd: 'touchend',
    resize: 'resize',
    scroll: 'scroll',
    orientationChange: 'orientationchange',
    keyDown: 'keydown',
    submit: 'submit',
    popState: 'popstate',
    load: 'load',
    transitionEnd: 'transitionend webkitTransitionEnd oTransitionEnd',
    domContentLoaded: 'DOMContentLoaded'
};
