'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore');

require('./ProductMasterpieceDescription.less');

const ProductMasterpieceSpecifications =
    require('components/ProductMasterpieceSpecifications/ProductMasterpieceSpecifications');

require('slick-carousel');
require('slick-carousel/slick/slick.less');
const TopPageButtons = require('components/TopPageButtons/TopPageButtons');

const BindFunctions = [
    '_initChildComponents'
];

const Selectors = {
    el: '.ProductMasterpieceDescription',
    arrowLeft: '.ProductMasterpieceDescription-sliderArrowLeft',
    arrowRight: '.ProductMasterpieceDescription-sliderArrowRight',
    contactButton: '.ProductMasterpieceDescription-headButton[data-id="contact"]',
    dotsContainer: '.ProductMasterpieceDescription-sliderNav',
    sliderWrapperInner: '.ProductMasterpieceDescription-sliderImageWrapper'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.contactButton}`]: this.openContactOverlay
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;
        this.$wrapperInner = this.$(Selectors.sliderWrapperInner);

        this._initChildComponents();
    },

    _initChildComponents: function () {
        this.specifications = new ProductMasterpieceSpecifications({
            page: this
        });

        const prevArrow = window.app.settings.isMobile ? false : Selectors.arrowLeft;
        const nextArrow = window.app.settings.isMobile ? false : Selectors.arrowRight;

        this.$wrapperInner
            .slick({
                infinite: false,
                prevArrow,
                nextArrow,
                draggable: false,
                dots: true
            });

        this.topButtons = new TopPageButtons({
            page: this
        });
    },

    openContactOverlay: function (event) {
        event.preventDefault();
        window.app.views.contacts.show();
    }
});
