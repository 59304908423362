'use strict';

const Backbone = require('backbone/backbone');
const ViewportEvents = require('constants/ViewportEvents');
const Events = require('constants/Events');

require('./ProductDetailsPhotometry.less');

const Selectors = {
    el: '.ProductDetailsPhotometry',
    arrowBack: '.ProductDetailsPhotometry-arrowBack'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.arrowBack}`]: this.closeOverlay
        };
    },

    initialize: function (options = {}) {
        this.options = options;
    },

    closeOverlay: function () {
        app.vent.trigger(Events.popup.popupHidden);
        app.vent.trigger(Events.eventPrefixes.product + Events.overlay.mobileOverlayClose);
    }
});
