'use strict';

const Backbone = require('backbone/backbone');

require('./InvestorsFinancialCalendar.less');

const DropdownButton = require('components/Common/DropdownButton/DropdownButton');

const Selectors = {
    el: '.InvestorsFinancialCalendar'
};

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        this.options = options;
        this.subscribeToCalendar = new DropdownButton();
    }
});
