'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const ItemTemplate = require('./AwardsNomineesItemRuntime.html');
const AwardsPopup = require('popups/AwardsPopup/AwardsPopup');

require('./AwardsNominees.less');

const Selectors = {
    el: '.AwardsNominees',
    tab: '.AwardsNominees-tab',
    itemsWrapper: '.AwardsNominees-items',
    items: '.AwardsNominees-item'
};

const BindFunctions = [
    '_setActiveTab',
    '_initVariables',
    'reattachEvents',
    'openAwardsPopup'
];

const DefaultTransition = 0.05;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: function () {
        return {
            [`${ViewportEvents.click} ${Selectors.tab}`]: this.selectTab
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._initVariables();
        this._setActiveTab();
        this.reattachEvents();

        this.$(Selectors.items)
            .addClass(Constants.cssClasses.active);
    },

    _initVariables: function () {
        this.$tabs = this.$(Selectors.tab);
        this.$itemsWrapper = this.$(Selectors.itemsWrapper);
    },

    _setActiveTab: function () {
        this.$tabs
            .first()
            .addClass(Constants.cssClasses.active);
    },

    reattachEvents: function () {
        const $items = this.$(Selectors.items);

        $items.off(ViewportEvents.click, this.openAwardsPopup);
        $items.click(this.openAwardsPopup);
    },

    openAwardsPopup: function (event) {
        event.preventDefault();
        const $target = $(event.target)
            .closest(Selectors.items);

        const url = $target.data('url');
        const apiUrl = $target.data('api-url');

        const popup = new AwardsPopup({
            page: this
        });

        popup.openPopup(apiUrl, url);
    },

    selectTab: function (event) {
        this.$tabs
            .removeClass(Constants.cssClasses.active);
        const $target = $(event.target)
            .closest(Selectors.tab);
        $target.addClass(Constants.cssClasses.active);

        const apiUrl = $target.data('api-url');
        const data = $target.data('value');

        $.getJSON(apiUrl, data, (res) => {
            if (!res) {
                return;
            }

            this.$itemsWrapper.empty();

            res.forEach((item, index) => {
                const itemRendered = ItemTemplate.render({item});
                this.$itemsWrapper.append(itemRendered);
                this.$itemsWrapper.find(`${Selectors.items}:last`)
                    .css('transition-delay', `${index * DefaultTransition}s`);
            });

            this.reattachEvents();
            setTimeout(() => {
                this.$(Selectors.items)
                    .addClass(Constants.cssClasses.active);
            }, 100);
        });
    }
});
