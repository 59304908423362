'use strict';

const _ = require('underscore');
const Page = require('pages/Page/Page.js');
const Events = require('constants/Events');

require('./PageIndex.less');

const IndexHead = require('components/IndexHead/IndexHead');
const IndexApplications = require('components/IndexApplications/IndexApplications');
const IndexMission = require('components/IndexMission/IndexMission');
const IndexInsights = require('components/IndexInsights/IndexInsights');
const InspirationCasePopup = require('popups/InspirationCasePopup/InspirationCasePopup');

const ChildViews = [
    {
        name: 'indexHead',
        Constructor: IndexHead
    },
    {
        name: 'indexApplications',
        Constructor: IndexApplications
    },
    {
        name: 'indexMission',
        Constructor: IndexMission
    },
    {
        name: 'indexInsights',
        Constructor: IndexInsights
    }
];

const BindFunctions = [
    'onInspirationCardClick',
    '_attachEvents'
];

const popupBackUrl = '/index.html';

module.exports = Page.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initChildViews(ChildViews);
        this._attachEvents();

        Page.prototype.initialize.call(this, options);
    },

    _attachEvents: function () {
        app.vent.on(Events.eventPrefixes.index
            + Events.popup.inspirationPopupPrefix
            + Events.popup.popupOpen, this.onInspirationCardClick);
    },

    onInspirationCardClick: function (data) {
        const popup = new InspirationCasePopup({
            page: this,
            backUrl: popupBackUrl
        });

        popup.openPopup(data.apiUrl, data.url);
    }
});
