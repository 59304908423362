'use strict';

const _ = require('underscore');
const Backbone = require('backbone/backbone');

const CommonText = require('components/Common/CommonText/CommonText');
const CommonColumnsTemplate = require('components/Common/CommonColumnsTemplate/CommonColumnsTemplate');
const ThreeColumnFacts = require('components/Common/ThreeColumnFacts/ThreeColumnFacts');
const CommonDonut = require('components/Common/CommonDonut/CommonDonut');
const TwoColumnsTable = require('components/Common/TwoColumnsTable/TwoColumnsTable');
const DownloadLinks = require('components/Common/DownloadLinks/DownloadLinks');
const DownloadCards = require('components/Common/DownloadCards/DownloadCards');
const CommonListLinks = require('components/Common/CommonListLinks/CommonListLinks');
const CommonGallery = require('components/Common/CommonGallery/CommonGallery');
const InvestorsGovernanceTable = require('components/InvestorsGovernanceTable/InvestorsGovernanceTable');

require('./CommonBlock.less');

const Selectors = {
    el: '.CommonBlock',
    video: '.Video',
    donut: '.CommonDonut'
};

const BindFunctions = [
    '_initChildComponents'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;
        this.blockSelector = options.blockSelector;
        this._initChildComponents();
    },

    _initChildComponents: function () {
        this.commonText = new CommonText();
        this.commonColumnsTemplate = new CommonColumnsTemplate();
        this.threeColumnFacts = new ThreeColumnFacts();
        this.$(Selectors.donut).each((index, donut) => {
            const dataIndex = $(donut).data('index');
            new CommonDonut({
                index: dataIndex
            });
        });
        this.twoColumnsTable = new TwoColumnsTable();
        this.downloadLinks = new DownloadLinks();
        this.downloadCards = new DownloadCards();
        this.commonListLinks = new CommonListLinks();
        this.table = new InvestorsGovernanceTable();
        this.gallery = new CommonGallery({blockSelector: this.blockSelector});
    }
});
