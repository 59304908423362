'use strict';

const Backbone = require('backbone/backbone');
const _ = require('underscore');

const Constants = require('constants/Constants');
require('./GlobalMenu.less');

const Selectors = {
    el: '.GlobalMenu',
    item: '.GlobalMenu-item'
};

const BindFunctions = [
    '_setInitialClasses'
];

const BaseTransitionDelay = 0.1;

module.exports = Backbone.View.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);
        this.options = options;

        this._setInitialClasses();
    },

    _setInitialClasses: function () {
        this.$(Selectors.item)
            .each((index, item) => {
                $(item)
                    .css({
                        'transition-delay': BaseTransitionDelay * (1 + index) + 's'
                    });
            });
        this.$(Selectors.item)
            .addClass(Constants.cssClasses.show);
    }
});
