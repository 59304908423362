'use strict';

const PageGlobalTemplate = require('pages/PageGlobalTemplate/PageGlobalTemplate');

require('./PageInvestorsFinancial.less');

const InvestorsFinancialCards = require('components/InvestorsFinancialCards/InvestorsFinancialCards');
const InvestorsFinancialTable = require('components/InvestorsFinancialTable/InvestorsFinancialTable');

const Selectors = {
    el: '.PageInvestorsFinancial'
};

const ChildViews = [
    {
        name: 'investorsFinancialCards',
        Constructor: InvestorsFinancialCards
    },
    {
        name: 'investorsFinancialTable',
        Constructor: InvestorsFinancialTable
    }
];

module.exports = PageGlobalTemplate.extend({

    el: Selectors.el,

    initialize: function (options = {}) {
        this.options = options;

        this._initChildViews(ChildViews);

        PageGlobalTemplate.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        PageGlobalTemplate.prototype._initVariables.call(this);
        this.isLastBackgroundAbsolute = false;
    }
});
