'use strict';

const _ = require('underscore');
const Page = require('pages/Page/Page.js');
const ViewportEvents = require('constants/ViewportEvents');
const Constants = require('constants/Constants');
const Events = require('constants/Events');
const ScrollMagic = require('scrollmagic');
const VimeoPlayer = require('@vimeo/player').default;

require('./PageLanding.less');

const Selectors = {
    block: '.PageLanding-block',
    blockBgMobile: '.PageLanding-blockBg_mobile',
    blockBg: '.PageLanding-blockBg',
    blockBgWrapper: '.PageLanding-blockBgWrapper',
    pageBgOverlay: '.PageLanding-bgOverlay',
    footer: '.Footer',
    filterBg: '.PageLanding-filterBg',
    opacityBg: '.PageLanding-opacityBg'
};

const BindFunctions = [
    'onResize',
    'setBlock',
    '_attachEvents',
    '_initVariables',
    'detachOnScroll',
    'attachOnScroll',
    '_initScrollMagic',
    'stopVideoBackground',
    'runVideoBackground'
];

const ScrollMagicConstants = {
    bottomOfThePageHook: 1.00,
    topOfThePageHook: 0,
    progressEvent: 'progress'
};

module.exports = Page.extend({

    events: {},


    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this.options = options;

        this._initVariables();

        this.setBlock();
        this._initScrollMagic();

        this._attachEvents();

        Page.prototype.initialize.call(this, options);
    },

    _initVariables: function () {
        this.isLastBackgroundAbsolute = true;
        this.$overlay = this.$(Selectors.pageBgOverlay);
        this.activeBgNumber = 1;
        this.$lastBlockAbsoluteTriggerElement = window.app.els.$footer;
        this.lastBlockAbsoluteOffset = 0;
        this.scrollMagicTriggerHook = ScrollMagicConstants.bottomOfThePageHook;
        const iframe = this.$('#bg-video');
        if (!iframe.length) {
            return;
        }
        this.player = new VimeoPlayer(iframe[0]);
        this.isPlayerRunning = true;
    },

    _attachEvents: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);

        const self = this;
        $(document)
            .ready(function () {
                self.$(`${Selectors.blockBgWrapper}[${Constants.dataAttributes.animation}]`)
                    .addClass(Constants.cssClasses.animation);
            });

        window.app.vent.on(Events.landings.detachEvents, this.detachOnScroll);
        window.app.vent.on(Events.landings.attachEvents, this.attachOnScroll);
        window.app.vent.on(Events.videoPlayer.openPlayer, this.stopVideoBackground);
        window.app.vent.on(Events.videoPlayer.closePlayer, this.runVideoBackground);
    },

    stopVideoBackground: function () {
        if (!this.player) {
            return;
        }

        this.player.pause();
    },

    runVideoBackground: function () {
        if (!this.player || !this.isPlayerRunning) {
            return;
        }

        this.player.play();
    },

    setBlock: function () {
        this.blockClass = Selectors.block;
        this.bgClass = window.app.isPhoneLayout() ? Selectors.blockBgMobile : Selectors.blockBg;
        this.$block = this.$(this.blockClass);
        this.lastBlockIndex = this.isLastBackgroundAbsolute ? this.$(this.blockClass).length - 1 : -1;
    },

    _initScrollMagic: function () {
        this.scrollMagicController = new ScrollMagic.Controller();

        const self = this;

        this.$block.each((index, item) => {
            new ScrollMagic.Scene({
                triggerElement: item,
                triggerHook: ScrollMagicConstants.topOfThePageHook,
                duration: window.app.sizes.windowHeight
            }).addTo(this.scrollMagicController)
                .on(ScrollMagicConstants.progressEvent, (event) => {
                    const progress = event.progress;
                    if (index === self.lastBlockIndex) {
                        return;
                    }

                    window.requestAnimationFrame(() => {
                        self.$(Selectors.filterBg)
                            .css({
                                [Constants.cssProperties.backdropFilter]: 'blur(' + (progress * 25) + 'px)'
                            });
                    });

                    window.requestAnimationFrame(() => {
                        self.$(Selectors.opacityBg)
                            .css({
                                [Constants.cssProperties.opacity]: (progress / 2)
                            });
                    });

                    if (!this.player || index !== 0) {
                        return;
                    }

                    if (event.progress > 0) {
                        this.player.pause();
                        this.isPlayerRunning = false;

                        return;
                    }

                    this.player.play();
                    this.isPlayerRunning = true;
                });
            const $itemNext = $(item)
                .next()[0];
            const $itemLast = $(item)
                .last()[0];

            if (!$itemNext) {
                return;
            }
            new ScrollMagic.Scene({
                triggerElement: $itemNext,
                triggerHook: ScrollMagicConstants.bottomOfThePageHook,
                duration: window.app.sizes.windowHeight / 2
            }).addTo(this.scrollMagicController)
                .on(ScrollMagicConstants.progressEvent, (event) => {
                    const opacity = event.progress;

                    if (index === self.lastBlockIndex && this.isLastBackgroundAbsolute) {
                        return;
                    }

                    if ($itemNext === $itemLast) {
                        return;
                    }
                    window.requestAnimationFrame(() => {
                        self.$(`${self.bgClass}[${Constants.dataAttributes.id}="${(index + 2)}"]`)
                            .closest(Selectors.blockBgWrapper)
                            .css({opacity});
                    });

                    window.requestAnimationFrame(() => {
                        self.$(Selectors.opacityBg)
                            .css({
                                [Constants.cssProperties.opacity]: (0.5 - opacity / 2)
                            });
                    });

                    window.requestAnimationFrame(() => {
                        self.$(Selectors.filterBg)
                            .css({
                                [Constants.cssProperties.backdropFilter]: 'blur(' + (25 - opacity * 25) + 'px)'
                            });
                    });
                });
        });

        if (!this.isLastBackgroundAbsolute) {
            return;
        }

        const lastBlockSelector = `${this.bgClass}[${Constants.dataAttributes.id}="${(this.lastBlockIndex + 1)}"]`;
        const $lastBlock = this.$(lastBlockSelector);

        this.scrollMagicScene = new ScrollMagic.Scene({
            offset: this.lastBlockAbsoluteOffset,
            triggerElement: this.$lastBlockAbsoluteTriggerElement[0],
            triggerHook: this.scrollMagicTriggerHook
        }).setClassToggle($lastBlock[0], Constants.cssClasses.absolute)
            .addTo(this.scrollMagicController);
    },

    onResize: function () {
        this.setBlock();
    },

    detachOnScroll: function () {
        window.app.els.$window
            .off(ViewportEvents.resize, this.onResize);
    },

    attachOnScroll: function () {
        window.app.els.$window
            .on(ViewportEvents.resize, this.onResize);
    }
});
