'use strict';

const Backbone = require('backbone');
const _ = require('underscore');
const Constants = require('constants/Constants');

const MobileSlider = require('components/MobileSlider/MobileSlider');

require('jquery-touchswipe');

require('./LayoutsGallery.less');

const Selectors = {
    el: '.LayoutsGallery',
    items: '.LayoutsGallery-items',
    arrowLeft: '.LayoutsGallery-items-arrowLeft',
    arrowRight: '.LayoutsGallery-items-arrowRight',
    itemsInnerWrapper: '.LayoutsGallery-itemsWrapperInner',
    navLine: '.LayoutsGallery-itemsNavItem',
    singleItem: '.LayoutsGallery-item',
    descriptionItem: '.LayoutsGallery-descriptionItem'
};

const BindFunctions = [
    '_initSlider',
    '_initGallery',
    'changeActiveDescription'
];

module.exports = Backbone.View.extend({

    el: Selectors.el,

    events: {},

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initGallery(options);
        this._initSlider();
    },

    _initGallery: function (options) {
        this.options = options;
        const gallerySelector = `${Selectors.el}--${options.modifier}_${options.id}`;
        this.setElement(gallerySelector);
        this.sliderSelector = `${gallerySelector} ${Selectors.items}`;
    },

    _initSlider: function () {
        this.slider = new MobileSlider({
            page: this,
            el: this.sliderSelector,
            arrowLeft: Selectors.arrowLeft,
            arrowRight: Selectors.arrowRight,
            innerWrapper: Selectors.itemsInnerWrapper,
            navLine: Selectors.navLine,
            singleItem: Selectors.singleItem,
            activeClassName: Constants.cssClasses.active,
            clickableNav: true,
            infiniteDesktop: true,
            callback: this.changeActiveDescription
        });
    },

    changeActiveDescription: function (activeElementId) {
        this.$(Selectors.descriptionItem)
            .removeClass(Constants.cssClasses.active);

        this.$(`${Selectors.descriptionItem}[data-id="${activeElementId}"]`)
            .addClass(Constants.cssClasses.active);
    }
});
