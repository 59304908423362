'use strict';

const Backbone = require('backbone');
const Events = require('constants/Events');
const Constants = require('constants/Constants');
const ViewportEvents = require('constants/ViewportEvents');
const _ = require('underscore/underscore.js');

const ItemTemplate = require('./SearchResultItem.html');

require('./Search.less');

const Selectors = {
    search: '.Search',
    input: '.Search-input',
    bg: '.Search-bg',
    close: '.Search-close',
    resultsItem: '.Search-resultsItem',
    results: '.Search-resultsItems',
    showAllResultsLink: '.Search-resultsLink'
};

const BindFunctions = [
    'show',
    'hide',
    'onKeydown',
    '_attachEvents',
    '_initVariables',
    'getResults',
    'clearResults',
    'addResult',
    'onAllResultsClick',
    'showAllResults'
];

const SearchRequestTimeout = 500;

module.exports = Backbone.View.extend({

    el: Selectors.search,

    events: function () {
        return {
            [`${ViewportEvents.focus} ${Selectors.input}`]: this.onFocus,
            [`${ViewportEvents.blur} ${Selectors.input}`]: this.onBlur,
            [`${ViewportEvents.input} ${Selectors.input}`]: this.onInput,
            [`${ViewportEvents.keyUp} ${Selectors.input}`]: this.onEnter,
            [`${ViewportEvents.click} ${Selectors.bg}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.close}`]: this.onCloseClick,
            [`${ViewportEvents.click} ${Selectors.showAllResultsLink}`]: this.onAllResultsClick
        };
    },

    initialize: function (options = {}) {
        _.bindAll(this, BindFunctions);

        this._initVariables(options);
        this._attachEvents();
    },

    _initVariables: function (options = {}) {
        this.options = options;
        this.$input = this.$(Selectors.input);
        this.$searchResults = this.$(Selectors.results);
    },

    _attachEvents: function () {
        app.vent.on(Events.search.showSearch, this.show);
        app.vent.on(Events.search.hideSearch, this.hide);
    },

    onEnter: function (event) {
        if (event.keyCode !== Constants.keyCodes.enter) {
            return;
        }
        event.preventDefault();
        $(event.target)
            .blur();

        this.showAllResults();
    },

    onAllResultsClick: function (event) {
        event.preventDefault();
        this.showAllResults();
    },

    showAllResults: function () {
        this.hide();
        const apiUrl = this.$el.data('api-url');
        app.vent.trigger(Events.search.showSearchResult, {searchString: this.$input.val(), apiUrl});
    },

    onFocus: function () {
        this.$el.addClass(Constants.cssClasses.search.searchIsFocus);
    },

    onInput: function () {
        const inputVal = this.$input.val();
        const self = this;

        if (!inputVal) {
            this.clearResults();

            return;
        }

        if (this.timeoutRequestSearch) {
            clearTimeout(this.timeoutRequestSearch);
        }

        this.timeoutRequestSearch = setTimeout(() => {
            this.$el.toggleClass(Constants.cssClasses.search.searchWithContent, !!inputVal);
            self.getResults(inputVal);
        }, SearchRequestTimeout);
    },

    clearResults: function () {
        const self = this;
        clearTimeout(self.timeout);
        this.$el.removeClass(Constants.cssClasses.search.searchWithContent);
        app.utils.waitForTransitionEnd(this.$el, 'visibility', function () {
            self.$searchResults.html('');
        }, 500);
    },

    addResult: function (itemHtml) {
        this.$searchResults.append(itemHtml);
    },

    getResults: function (inputVal) {
        const data = {inputVal};
        const self = this;
        const dataUrl = self.$(Selectors.input)
            .attr('data-url');
        $.getJSON(dataUrl, data, (res) => {
            if (!res) {
                return;
            }
            self.$searchResults.html('');

            res.forEach((item) => {
                if (!item) {
                    return;
                }
                const itemHtml = ItemTemplate.render({
                    data: item
                });
                self.addResult(itemHtml);
            });
        });
    },

    onBlur: function () {
        this.$el.removeClass(Constants.cssClasses.search.searchIsFocus);
    },


    onKeydown: function (e) {
        if (e.keyCode === Constants.keyCodes.esc) {
            this.hide();
        }
    },


    show: function () {
        clearTimeout(this.closeTimeout);
        this.$el.removeClass(Constants.cssClasses.search.searchHide);
        this.$(Selectors.input)
            .focus();

        app.vent.trigger(Events.search.searchShown);

        $(window)
            .on(ViewportEvents.keyDown, this.onKeydown);

        if (!window.app.isPhoneLayout()) {
            return;
        }

        app.vent.trigger(Events.popup.popupShown);
    },


    hide: function () {
        clearTimeout(this.closeTimeout);
        this.$el.addClass(Constants.cssClasses.search.searchHide);

        this.closeTimeout = setTimeout(function () {
            this.$el.removeClass(Constants.cssClasses.search.searchWithContent);
            this.$input.val('');

            //for api => show results
            this.$(Selectors.resultsItem)
                .each(function (id, item) {
                    $(item)
                        .fadeIn();
                });
        }.bind(this), 500);

        app.vent.trigger(Events.search.searchHidden);

        $(window)
            .off(ViewportEvents.keyDown, this.onKeydown);

        if (!window.app.isPhoneLayout()) {
            return;
        }

        app.vent.trigger(Events.popup.popupHidden);
    },


    onCloseClick: function () {
        this.hide();
    }
});
